import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { userSlice, patientInfoSlice, patientId, patientFullName } from "../slices/userSlice"; 
import thunk from "redux-thunk";
import { healthCampSlice } from "../slices/healthCampSlice";
import {campDataSlice} from "../slices/campDataSlice";

const persistConfig = {
  key: "root",
  storage
};

const rootReducer = combineReducers({
  user: userSlice.reducer, 
  patientInfo: patientInfoSlice.reducer,
  patientId: patientId.reducer ,
  name: healthCampSlice.reducer,
  patientFullName:patientFullName.reducer,
  campData:campDataSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer, 
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk]
});
export let persistor = persistStore(store);
