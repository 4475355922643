import { createService, readService, updateService } from "../HttpService";

export const addMCH = (body) => {
  return createService("hospitals", body);
};

export const readMCH = (params) => {
  return readService("hospitals", params);
};

export const MCHUpdate = (id, param, body) => {
  return updateService(
    `hospitals/${id}`,
    param,

    body
  );
};

export const readDoctors = (params) => {
  return readService("doctors", params);
};

export const getStateData = () => {
  return readService("metadata/states", {});
};
export const getDistrictData = (stateId) => {
  return readService(`metadata/districts/${stateId}`, {});
};
export const getPlaceData = (distId) => {
  return readService(`metadata/places/${distId}`, {});
};
export const getBloodGroup = () => {
  return readService("metadata/bloodGroups", {});
};
export const updateDoctor = (doctorId, formData, n) => {
  return updateService(`doctors/${doctorId}`, n, formData);
};
// updateNurse;
export const updateNurse = (nurseId, {}, formData) => {
  return updateService(`nurses/${nurseId}`, {}, formData);
};
export const getAllNurses = () => {
  return readService("nurses", {});
};

export const readNurses = (params) => {
  return readService("nurses", params);
};
