import moment from 'moment';
export const checkFromat = (date) =>
    /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(date);
const checkDateTimeFromat = (date) =>
    /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/.test(
        date
    );
const timeStampFormat = (date) =>
    /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/.test(
        date
    );
export const formatDate = (date) => {
    let formattedDate = moment(
        date,
        checkFromat(date) ? "YYYY-MM-DD" : "DD/MM/YYYY"
    ).format("DD-MM-YYYY");
    return formattedDate === "Invalid date" ? "" : formattedDate;
};
export const formatDatePatient = (date) => {
  let formattedDate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
  return formattedDate === "Invalid date" ? "" : formattedDate;
};

export const format24HrDateOnly = (date) => {
    let formattedDate = moment(
        date,
        checkDateTimeFromat(date) || timeStampFormat(date)
            ? "YYYY-MM-DD HH:mm:ss A"
            : "DD-MM-YYYY hh:mm:ss"
    ).format("DD-MM-YYYY");

    return formattedDate === "Invalid date" ? "" : formattedDate;
};

export const formatDateToHHMM = (date) => {
    let formattedDate =  moment(date, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY");
    return formattedDate === "Invalid date" ? "" : formattedDate;
};

export const isDateParam = (dateString) => {
    const format = "YYYY-MM-DD HH:mm";
    return moment(dateString, format, true).isValid();
  };
export const formatDateFunc = (date) => {
    let formattedDate = moment(
        date,
        checkDateTimeFromat(date) || timeStampFormat(date)
            ? "YYYY-MM-DD HH:mm:ss A"
            : "DD-MM-YYYY hh:mm:ss"
    ).format("DD/MM/YYYY");

    return formattedDate === "Invalid date" ? "" : formattedDate;
};

export const formatDateToWeek = (date) => {
    let formattedDate = moment(
        date,
        checkDateTimeFromat(date) || timeStampFormat(date)
            ? "YYYY-MM-DD HH:mm:ss A"
            : "DD-MM-YYYY hh:mm:ss"
    ).format("Do MMMM YYYY dddd");

    return formattedDate === "Invalid date" ? "" : formattedDate;
};

export const ConvertYMD = (date) => {
    let formattedDate = moment(
      date,
      checkDateTimeFromat(date) || timeStampFormat(date)
            ? "YYYY-MM-DD HH:mm:ss A"
            : "DD-MM-YYYY hh:mm:ss"
    ).format("YYYY-MM-DD");
    return formattedDate === "Invalid date" ? "" : formattedDate;
  };

export const formatTime = (time) => {
    const formattedTime = moment(time).format("hh:mm A");
    return formattedTime;
  };

  export const formatTimeHM = (timeData)=>{
    const time = moment(timeData, "HH:mm:ss").format("HH:mm A");
    return time

  }