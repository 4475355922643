import {
  createService,
  readService,
  updateService,
  readByKey,
} from "../HttpService";

export const getStates = (params) => {
  return readService("metadata/states", params);
};

export const getDistrictsByState = (key) => {
  return readByKey("metadata/districts", key);
};

export const getPlacesByDistrict = (key) => {
  return readByKey("metadata/places", key);
};
export const getBooldGroup = () => {
  return readService("metadata/bloodGroups");
};
export const getGenders = () => {
  return readService("metadata/genders");
};
export const getQualifications = () => {
  return readService("metadata/qualifications");
};


export const getRelationship=()=>{
  return readService("metadata/relationships");
}
export const getAddictions=()=>{
  return readService("metadata/addictions");
}

export const getMeritalStatus=()=>{
    return readService("metadata/maritalStatus");

}

export const getMedicalTestsMetadata = () => {
  return readService("metadata/medicalTests");
};

export const getSeverity = () => {
  return readService("metadata/doctorFieldSeverity");
};

export const getCategory = () => {
  return readService("metadata/doctorFieldCategory");
};

export const getOccurrence = () => {
  return readService("metadata/doctorFieldOccurrence");
};

export const getVerificationStatus = () => {
  return readService("metadata/doctorFieldVerifyStatus");
};

export const getOutcome = () => {
  return readService("metadata/doctorFieldOutcome");
};

export const getDoctorPharmacyDose = () => {
  return readService("metadata/doctorPharmacyFieldDosage");
};

export const getDoctorPharmacyFrequency = () => {
  return readService("metadata/doctorPharmacyFieldFrequency");
};

export const getDoctorPharmacyWhen = () => {
  return readService("metadata/doctorPharmacyFieldWhen");
};
