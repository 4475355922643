import React, { useState } from "react";
import "./HealthSurvey.scss";
import Layout from "../LayoutComp/Layout";
import AddButton from "../AddButton/AddButton";
import SearchInput from "../SearchInput/SearchInput";
import CustomTable from "../CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import { TablePagination } from "@mui/material";
import StatusIcon from "../../Assets/icons/Status.svg";
import view from "../../Assets/icons/view.svg";
import edit from "../../Assets/icons/edit.svg";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import filterIcon from "../../Assets/icons/filter_icon.svg";
import FilterModal from "../Modals/FilterModal/FilterModal";
import HealthFilterModal from "./HealthFilterModal/HealthFilterModal";

const SingleRow = (props) => {
  const navigate = useNavigate();

  const { data, type } = props;

  return (
    <tr
      // onClick={() => console.log(" ==========> pressed")}
      style={{ cursor: "pointer" }}
    >
      <td>HVSM-01</td>
      <td>
        <span>Priyanka Singhal</span>
      </td>
      <td>Haryana</td>
      <td>Dehradun</td>
      <td>Haridwar</td>
      <td>07 March 2022</td>
      <td>11 march 2022</td>
      <td>10:00AM-5:00PM</td>
      {/* <td>
        <PrimaryButton
          name="Create Survey"
          onClick={() => navigate("/home/health-survey/add-new")}
        />
      </td> */}
      <td>
        <span
          onClick={() =>
            // navigateToPatientNewTabPage()
            navigate("/home/health-survey/details/1")
          }
        >
          <img src={view} alt="" />
        </span>
      </td>
    </tr>
  );
};

const HealthSurvey = (props) => {
  const { sideNavState, setSideNavState } = props;

  const [searchText, setSearchText] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);

  const navigate = useNavigate();

  const handleSearchClear = () => {
    setSearchText("");
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  const searchHandler = async () => {
    console.log(" ==========> handle search called");
  };

  const handleCreateReport = () => {
    console.log("pressed handleCreateReport");
    // navigate("/home/admin/add-new");
  };

  const HEALTH_SURVEY_HEADING = [
    "Camp Id",
    "Camp Name",
    "State",
    "District",
    "City",
    "From",
    "To",
    "Time",
    "Action",
  ];

  const data1 = [
    { name: "rinku", gender: "Male" },
    { name: "Ravi", gender: "Female" },
    { name: "Ravi", gender: "Female" },
    { name: "Ravi", gender: "Female" },
    { name: "Ravi", gender: "Female" },
    { name: "Ravi", gender: "Female" },
    { name: "Ravi", gender: "Female" },
  ];

  const openFilterModal = () => {
    setShowFilterModal(true);
  };

  const handleFilterClose = () => {
    setShowFilterModal(false);
  };

  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      <div
        id="health-survey"
        style={{ padding: 20, border: "1px solid #00000029" }}
      >
        <div className="main-container">
          <div style={{ display: "flex", gap: 20 }}>
            <SearchInput
              searchText={searchText}
              onChange={handleSearchInput}
              onSearchClear={handleSearchClear}
              onSearchClick={searchHandler}
            />
            <button className="filter-btn" onClick={openFilterModal}>
              <span>Filter</span>
              <img src={filterIcon} alt="" className="ps-2" />
            </button>
          </div>

          {/* <AddButton name="Create Report" onClick={handleCreateReport} /> */}
          <button className="header-button">Survey Report</button>
        </div>
        <div className="patientdo">
          <CustomTable
            Row={SingleRow}
            data={data1}
            Headings={HEALTH_SURVEY_HEADING}
          />
          <div className="flex justify-content-end table-page">
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={100}
              page={1}
              onPageChange={() => console.log(" ==========>hello ")}
              rowsPerPage={10}
              onRowsPerPageChange={() => console.log(" ==========> hello")}
            />
          </div>
        </div>
        <FilterModal visible={showFilterModal} onClose={handleFilterClose}>
          <HealthFilterModal />
        </FilterModal>
      </div>
    </Layout>
  );
};

export default HealthSurvey;
