export const ContactValidator = (setError, setMessage, value) => {
  // if (name === "contact_number") {

  // Regex pattern to validate a mobile number starting with 9, 8, or 7 and consisting of 10 digits
  const mobileNumberPattern = /^\d{10}$/;

  if (!mobileNumberPattern.test(value)) {
    setError((prev) => ({
      ...prev,
      contact_number: true,
    }));
    setMessage("Mobile number must contain 10 digits");
  } else {
    setError((prev) => ({
      ...prev,
      contact_number: false,
    }));
    setMessage("");
  }
  // }
};

export const aadharNumber = (setError, setAadharMessage, value) => {
  // if (name === "contact_number") {

  // Regex pattern to validate a mobile number starting with 9, 8, or 7 and consisting of 10 digits
  const pattentToCheck = /^\d{12}$/;
  if (!pattentToCheck.test(value)) {
    setError((prev) => ({
      ...prev,
      aadhar: true,
    }));
    setAadharMessage("Aadhar number must contain 12 digits");
  } else {
    setError((prev) => ({
      ...prev,
      aadhar: false,
    }));
    setAadharMessage("");
  }
};

// extra condtion adding by prajwal.
// export const aadharNumber = (setError, setAadharMessage, value) => {
//   // Regex pattern to validate a mobile number starting with 9, 8, or 7 and consisting of 12 digits
//   const mobileNumberPattern = /^[789]\d{11}$/;

//   // Check if the value contains only numeric digits
//   const isNumeric = /^\d+$/.test(value);

//   if (!isNumeric) {
//     setError((prev) => ({
//       ...prev,
//       aadhar: true,
//     }));
//     setAadharMessage("Aadhar number must contain only numeric digits");
//   } else if (!mobileNumberPattern.test(value)) {
//     setError((prev) => ({
//       ...prev,
//       aadhar: true,
//     }));
//     setAadharMessage("Aadhar number must contain 12 digits");
//   } else {
//     setError((prev) => ({
//       ...prev,
//       aadhar: false,
//     }));
//     setAadharMessage("");
//   }
// };

export const pinCodeValidate = (setError, setPinCodeMessage, value) => {
  // if (name === "contact_number") {

  // Regex pattern to validate a mobile number starting with 9, 8, or 7 and consisting of 10 digits
  const mobileNumberPattern = /^\d{6}$/;

  if (!mobileNumberPattern.test(value)) {
    setError((prev) => ({
      ...prev,
      pincode: true,
    }));
    setPinCodeMessage("Pincode must contain 6 digits");
  } else {
    setError((prev) => ({
      ...prev,
      pincode: false,
    }));
    setPinCodeMessage("");
  }
  // }
};
export const charLengthCheck = (
  setError,
  setCharLengthMsg,
  value,
  charlength,
  nameKey
) => {
  // if (name === "contact_number") {

  // Regex pattern to validate a mobile number starting with 9, 8, or 7 and consisting of 10 digits

  if (value.length <= charlength) {
    setError((prev) => ({
      ...prev,
      [nameKey]: true,
    }));
    setCharLengthMsg("Text should contain atleast 4 characters");
  } else {
    setError((prev) => ({
      ...prev,
      [nameKey]: false,
    }));
    setCharLengthMsg("");
  }
  // }
};
export const emailValidator = (setError, setEmailMsg, value) => {
  // if (name === "contact_number") {

  // Regex pattern to validate a mobile number starting with 9, 8, or 7 and consisting of 10 digits
  var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!pattern.test(value)) {
    setError((prev) => ({
      ...prev,
      email: true,
    }));
    setEmailMsg("Please Enter a valid Email");
  } else {
    setError((prev) => ({
      ...prev,
      email: false,
    }));
    setEmailMsg("");
  }
  // }
};
export const passwordValidator = (
  setError,
  setPassMessage,
  value,
  charlength
) => {
  // if (name === "contact_number") {

  // Regex pattern to validate a mobile number starting with 9, 8, or 7 and consisting of 10 digits
  console.log("valueeeee", value);
  if (value.length < 8) {
    setError((prev) => ({
      ...prev,
      password: true,
    }));
    setPassMessage("Password must contain 8 characters");
  } else {
    setError((prev) => ({
      ...prev,
      password: false,
    }));
    setPassMessage("");
  }
  // }
};
