import React, { useEffect, useState } from "react";
import "./SideNav.scss";
import navClickIcon from "../../Assets/icons/SideNavIcons/navClickIcon.png";
import navCloseIcon from "../../Assets/icons/SideNavIcons/navCloseIcon.png";
import firstAid from "../../Assets/icons/SideNavIcons/first-aid.png";
import healthCamp from "../../Assets/icons/SideNavIcons/health-camp.png";
import wheelChair from "../../Assets/icons/SideNavIcons/wheelchair.png";
import home from "../../Assets/icons/SideNavIcons/home.png";
import {
  ImsAdminIcon,
  HealthSurvey,
  DataOperatorIcon,
  BusDriverIcon,
  PharmacyIcon,
  HospitalIcon,
  CoordinatorIcon,
  HealthCampIcon,
  BusIcon,
  PathologistIcon,
  NurseIcon,
  PharmacistIcon,
  PatientIcon,
} from "../../Utils/ImageUtils";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";

export default function SideNav({ sideNavState, setSideNavState }) {
  const navigate = useNavigate();
  const [navItemSelected, setNavItemSelected] = useState();
  const [navItems, setNavItems] = useState([]);
  const [toggle, setToggle] = useState("");
  const [sideNav, setSideNav] = useState(false);

  const location = useLocation();

  const navLinks = [
    { url: "/home/dashboard", name: "Dashboard", icon: home },
    { url: "/home/doctors", name: "Doctors", icon: firstAid },
    { url: "/home/patient", name: "Patient", icon: PatientIcon },
    { url: "/home/hospital", name: "Hospital", icon: HospitalIcon },
    { url: "/home/nurses", name: "Nurses", icon: NurseIcon },
    { url: "/home/healthcamp", name: "Health Camp", icon: HealthCampIcon },
    {
      url: "/home/data-operator",
      name: "Data Operator",
      icon: DataOperatorIcon,
    },
    // { url: "/home/admin", name: "Admin", icon: healthCamp },
    // { url: "/home/health-survey", name: "Health Survey", icon: healthCamp },
  ];
  const cordinator = [
    { url: "/home/dashboard", name: "Dashboard", icon: home },
    { url: "/home/doctors", name: "Doctors", icon: firstAid },
    { url: "/home/patient", name: "Patient", icon: PatientIcon },
    // { url: "/home/hospital", name: "Hospital", icon: HospitalIcon },
    { url: "/home/nurses", name: "Nurses", icon: NurseIcon },
    { url: "/home/healthcamp", name: "Health Camp", icon: HealthCampIcon },
    {
      url: "/home/data-operator",
      name: "Data Operator",
      icon: DataOperatorIcon,
    },
    // { url: "/home/admin", name: "Admin", icon: healthCamp },
    // { url: "/home/health-survey", name: "Health Survey", icon: healthCamp },
    {
      url: "/home/pathologist-co-ordinator",
      name: "Pathologist",
      icon: PathologistIcon,
    },
    {
      url: "/home/pharmacist-co-ordinator",
      name: "Pharmacist",
      icon: PharmacistIcon,
    },
    {
      url: "/home/co-pharmacy",
      name: "Pharmacy",
      icon: PharmacyIcon,
    },
  ];
  const imsAdmins = [
    { url: "/home/dashboard", name: "Dashboard", icon: home },
    { url: "/home/doctors", name: "Doctors", icon: firstAid },
    { url: "/home/patient", name: "Patient", icon: PatientIcon },
    { url: "/home/hospital", name: "MCH", icon: HospitalIcon },
    { url: "/home/nurses", name: "Nurses", icon: NurseIcon },
    { url: "/home/co-ordinator", name: "Co-Ordinator", icon: CoordinatorIcon },

    { url: "/home/healthcamp", name: "Health Camp", icon: HealthCampIcon },
    {
      url: "/home/data-operator",
      name: "Data Operator",
      icon: DataOperatorIcon,
    },
    { url: "/home/pharmacy-ims", name: "Pharmacy", icon: PharmacyIcon },

    // { url: "/home/admin", name: "Admin", icon: ImsAdminIcon },
    // { url: "/home/health-survey", name: "Health Survey", icon: HealthSurvey },
    {
      url: "/home/pathologist-co-ordinator",
      name: "Pathologist",
      icon: PathologistIcon,
    },
    {
      url: "/home/pharmacist-co-ordinator",
      name: "Pharmacist",
      icon: PharmacistIcon,
    },
    {
      url: "/home/bus-info",
      name: "Bus",
      icon: BusIcon,
    },
    {
      url: "/home/bus-driver",
      name: "Bus driver",
      icon: BusDriverIcon,
    },
  ];
  const dataOperator = [
    { url: "/home/dataoprpat", name: "Patient", icon: PatientIcon },
  ];
  const pathologist = [
    {
      url: "/home/pathologistpatient",
      name: "Pathologist",
      icon: PathologistIcon,
    },
  ];
  const parmacist = [
    { url: "/home/parmacist", name: "Parmacist", icon: wheelChair },
  ];

  const doctor = [
    { url: "/home/doctorsindex", name: "Doctors", icon: wheelChair },
  ];
  const nurse = [{ url: "/home/nurseindex", name: "Nurse", icon: NurseIcon }];

  useEffect(() => {
    setNavItemSelected(0);
    const role = localStorage.getItem("role");
    //To persist the role even when page refresh
    setToggle(role);
  }, []);

  useEffect(() => {
    const sideNavData =
      toggle == "admins"
        ? navLinks
        : toggle == "data-operators"
        ? dataOperator
        : toggle == "pharmacists"
        ? parmacist
        : toggle == "lab-technicians"
        ? pathologist
        : toggle == "doctors"
        ? doctor
        : toggle == "mch-admins"
        ? cordinator
        : toggle == "ims-admins"
        ? imsAdmins
        : toggle == "nurses"
        ? nurse
        : null;
    setNavItems(sideNavData);
  }, [toggle]);

  const sideNavToggleClick = () => {
    setSideNavState(!sideNavState);
  };

  const onSectionSelect = (event, index, url) => {
    setNavItemSelected(index);
    localStorage.setItem("selectedNav",index)
    console.log(index);
    navigate(url);

  };
  useEffect(()=>{
    if (
      localStorage.getItem("selectedNav") == null ||
      localStorage.getItem("selectedNav") == ""
    ) {
      setNavItemSelected(0);
    } else {
      setNavItemSelected(localStorage.getItem("selectedNav"));
    }
  },[])
 console.log("locallll", localStorage.getItem("selectedNav"),navItemSelected);
  const { userProfile } = useSelector((state) => state.user);

  useEffect(() => {
    if (sideNavState)
      setTimeout(() => {
        setSideNav(true);
      }, 1000);
  }, []);
  return (
    <>
      {![
        "data-operators",
        "nurses",
        "doctors",
        "pharmacists",
        "lab-technicians",
      ].includes(userProfile?.userGroup) &&
        sideNav && (
          <div
            id="sideNavContainer"
            style={{
              width:
                toggle === "data-operators" ||
                toggle === "nurses" ||
                toggle === "doctors" ||
                toggle === "pharmacists" ||
                toggle === "lab-technicians"
                  ? "0%"
                  : sideNavState
                  ? "20%"
                  : "5%",
              overflow: "scroll",
            }}
          >
            <div className="sideNavBtnCard">
              <img
                src={sideNavState ? navClickIcon : navCloseIcon}
                alt=""
                onClick={sideNavToggleClick}
              />
            </div>
            {/* <div > */}
            <div>
              <ul>
                {navItems?.map(({ url, name, icon }, index) => (
                  <li>
                    <a
                      onClick={(e) => onSectionSelect(e, index, url)}
                      className={navItemSelected == index ? "active" : ""}
                    >
                      <div
                        style={{
                          width: 30,
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img src={icon} alt="" />
                      </div>
                      {sideNavState && (
                        <p className=" d-none d-sm-none d-md-none d-lg-block">
                          {name}
                        </p>
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
    </>
  );
}
