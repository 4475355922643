import { useEffect, useState } from "react";
import DefaultImage from "../../../../../Assets/Images/defaultProfile.png";
import "./view.scss";
import { getPatientInfo } from "../../../../../Services/AddPatient/AddPatient";

const GeneralInfoTab = ({ patientId, setPatientId }) => {
  console.log("patientId-->", patientId);
  const [patientInfoData, setPatientInfoData] = useState([]);

  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    patientInfo(patientId);
  }, [patientId]);

  const patientInfo = async (patientId) => {
    const res = await getPatientInfo(patientId);
    console.log("resss", res);
    setPatientInfoData(res.data);
  };

  return (
    <div id="generalInfo" className="text-center">
      <button
        className="btn mb-2"
        style={{ backgroundColor: "#191BC3", color: "white" }}
      >
        General Information
      </button>
      {console.log(" ==========> patientInfoData", patientInfoData)}
      <div className="row d-flex ">
        <div className="col-2 right-border">
          <div
            className=" d-flex flex-column"
            style={{
              position: "relative",
              top: "25%",
              width: "80px",
              left: "25%",
            }}
          >
            <img
              src={
                !imageError && patientInfoData?.image
                  ? patientInfoData?.image
                  : DefaultImage
              }
              className="w-auto"
              alt=""
              onError={() => {
                console.log("error in image load ");
                setImageError(true);
              }}
            />
            {/* <img src={DefaultImage} className="w-auto"></img> */}
            <p>Profile Photo</p>
          </div>
        </div>

        <div className="col-5 right-border ps-5">
          <div className="grid-container">
            <p className="font-style">Patient Name</p>
            <p>{patientInfoData?.full_name || "-"}</p>
            <p className="font-style">Gender</p>
            <p>{patientInfoData?.gender || "-"}</p>
            <p className="font-style">Age</p>
            <p>{patientInfoData?.age || "-"}</p>
            <p className="font-style">Mobile No</p>
            <p>{patientInfoData?.contact_number || "-"}</p>
            <p className="font-style">Occupation</p>
            <p>{patientInfoData?.occupation || "-"}</p>
            <p className="font-style">Annual Income</p>
            <p>{patientInfoData?.annual_income || "-"}</p>
            {/* <p className="font-style">Document Type</p>
            <p>{patientInfoData?.doc_type || "-"}</p>
            <p className="font-style">Document No</p>
            <p>{patientInfoData?.doc_number || "-"}</p> */}
            <p className="font-style">Aayushman Bharat Card</p>
            <p>{patientInfoData?.ayush_card || "-"}</p>

            <p className="font-style">Total Family Members</p>
            <p>{patientInfoData?.family_count || "-"}</p>
          </div>
        </div>

        <div className="col-5 ps-5">
          <div className="grid-container">
            <p className="font-style">Total Earning Members</p>
            <p>{patientInfoData?.earning_count || "-"}</p>
            <p className="font-style">Technique of farming</p>
            <p>{patientInfoData?.farm_technique || "-"}</p>
            <p className="font-style">Spouse Occupation</p>
            <p>{patientInfoData?.spouse_occupation || "-"}</p>
            <p className="font-style">Total Children</p>
            <p>{patientInfoData?.children_count || "-"}</p>
            <p className="font-style">Aaadhar Number</p>
            <p>{patientInfoData?.aadhar || "-"}</p>
            <p className="font-style">Marital Status</p>
            <p>{patientInfoData?.marital_status || "-"}</p>
            {/* <p className="font-style">Addiction</p>
            <p>78897979</p> */}
            {/* <p className="font-style">Other Addicted member in family</p> */}
            {/* <p>
              {patientInfoData?.familyDetails?.map(
                (val) =>
                  val.addiction && (
                    <li style={{ listStyle: "none" }}>
                      {val.family_member_name}
                    </li>
                  )
              )}
            </p> */}
            {/* <p className="font-style">Other Addicted member in family</p>
            <p>78897979</p> */}

            <p className="font-style">Bank Account</p>
            <p>{patientInfoData?.bank_account || "Not Available"}</p>
            <p className="font-style">family Member's Name</p>
            <p>
              {patientInfoData?.familyDetails?.map((val) => (
                <li style={{ listStyle: "none" }}>{val.family_member_name}</li>
              ))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfoTab;
