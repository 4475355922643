import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import searchCloseIcon from "../../../../../../../Assets/icons/search-close-icon.png";
import searchIcon from "../../../../../../../Assets/icons/search_icon.svg";
import { Form } from "react-bootstrap";
import moment from "moment";
import { useEffect } from "react";
import close_icon from "../../../../../../../Assets/icons/close-rounded.svg";
import {
  getCategory,
  getOccurrence,
  getOutcome,
  getSeverity,
  getVerificationStatus,
} from "../../../../../../../Services/Metadata/Metadata";
import "./MedicalDiagnosisModal.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  backgroundColor: "#fff",
  boxShadow: 24,
};

const ButtonPairs = (props) => {
  const { onResetPress, onAddClick, modalAction } = props;

  return (
    <div className="button-pair-container">
      <button className="hollow-button-new-diagnosis" onClick={onResetPress}>
        Reset
      </button>
      <button className="fill-button-new-diagnosis" onClick={onAddClick}>
        {modalAction === "" ? "Add" : "Update"}
      </button>
    </div>
  );
};

const initialState = {
  category: "",
  comments: "",
  issue: "",
  occurrence: "",
  outcome: "",
  end_date: "",
  severity: "",
  start_date: "",
  verification_status: "",
};

const SearchBarComp = ({
  searchText,
  handleSearchClear,
  handleSearchInput,
  handleSearchClick,
}) => {
  return (
    <label className="label-input">
      <input
        type="text"
        className="form-control search-input"
        placeholder="Search by name"
        onChange={handleSearchInput}
        value={searchText}
      />
      {searchText && (
        <button
          className="search-btn-close"
          onClick={handleSearchClear}
          style={{
            border: "none",
            position: "absolute",
            right: 90,
            margin: "auto",
            top: 4,
          }}
        >
          <img src={searchCloseIcon} alt="" width={"24px"} />
        </button>
      )}
      <button className="search-btn" onClick={handleSearchClick}>
        <img src={searchIcon} alt="" />
        <span>Search</span>
      </button>
    </label>
  );
};

const NewMedicalDiagnosisModal = (props) => {
  const {
    showModal,
    setShowModal,
    diagnosisDetail,
    mainObject,
    updateDoctorDiagnosis,
    modalAction,
  } = props;

  const [newDiagnosis, setNewDiagnosis] = useState({
    issue: "",
    start_date: "",
    end_date: "",
    severity: "",
    category: "",
    occurrence: "",
    verification_status: "",
    outcome: "",
    comments: "",
  });
  const [Error, setError] = useState();
  const [doctorDiagnosisArray, setDoctorDiagnosisArray] = useState([]);

  // modalAction==="View";
  const viewMode = modalAction === "View";
  useEffect(() => {
    setDoctorDiagnosisArray(mainObject?.doctor_diagnosis || []);
  }, [mainObject]);

  useEffect(() => {
    setNewDiagnosis(diagnosisDetail);
  }, [diagnosisDetail]);

  const [severityDrop, setSeverityDrop] = useState([]);
  const [categoryDrop, setCategoryDrop] = useState([]);
  const [occurrenceDrop, setOccurrenceDrop] = useState([]);
  const [verificationStatusDrop, setVerificationStatusDrop] = useState([]);
  const [outComeDrop, setOutComeDrop] = useState([]);

  const getSeverityDrop = async () => {
    const result = await getSeverity();
    setSeverityDrop(result?.data);
  };

  const getCategoryDrop = async () => {
    const result = await getCategory();
    setCategoryDrop(result?.data);
  };

  const getOccurrenceDrop = async () => {
    const result = await getOccurrence();
    setOccurrenceDrop(result?.data);
  };

  const getVerificationStatusDrop = async () => {
    const result = await getVerificationStatus();
    setVerificationStatusDrop(result?.data);
  };

  const getOutcomeDrop = async () => {
    const result = await getOutcome();
    setOutComeDrop(result?.data);
  };

  useEffect(() => {
    getSeverityDrop();
    getCategoryDrop();
    getOccurrenceDrop();
    getVerificationStatusDrop();
    getOutcomeDrop();
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  const setFieldValueHandler = (e) => {
    const { name, value } = e.target;

    if (name === "start_date" || name === "end_date") {
      setNewDiagnosis((prevState) => ({
        ...prevState,
        [name]: moment(value).format("YYYY-MM-DD"),
      }));
    } else {
      setNewDiagnosis((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleResetPress = () => {
    if (newDiagnosis?.id) {
      setNewDiagnosis({ ...initialState, id: newDiagnosis?.id });
    } else {
      setNewDiagnosis(initialState);
    }
  };

  const handleAddPress = () => {
    const {
      issue,
      start_date,
      end_date,
      severity,
      category,
      occurrence,
      verification_status,
      outcome,
      comments,
    } = newDiagnosis;
    if (
      !issue
    ) {
      setError("Please Fill all The Feilds!...");
    } else {
      if (newDiagnosis?.id) {
        const result = doctorDiagnosisArray?.map((item) => {
          if (item?.id === newDiagnosis?.id) {
            return newDiagnosis;
          } else {
            return item;
          }
        });
        updateDoctorDiagnosis(result);
        setDoctorDiagnosisArray(result);
      } else {
        setDoctorDiagnosisArray([...doctorDiagnosisArray, newDiagnosis]);
        updateDoctorDiagnosis([...doctorDiagnosisArray, newDiagnosis]);
      }
      closeModal();
    }
  };

  return (
    <div>
      <Modal open={showModal} onClose={closeModal}>
        <Box sx={style} id="medical-diagnosis-modal">
          <div className="new-medicaldiagnosis-title-container">
            {viewMode ? "Details" : "Enter Details"}
            <div style={{ textAlign: "left" }} id="close-logo">
              <img
                src={close_icon}
                alt="Close"
                className="close-icon"
                onClick={closeModal}
              />
            </div>
          </div>
          <div className="row p-4">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <Form.Group controlId="formBasicName">
                <Form.Label className="refer-to-mch-label">
                  Title of the problem
                </Form.Label>
                <Form.Control
                  name="issue"
                  placeholder="Issue"
                  value={newDiagnosis?.issue}
                  onChange={setFieldValueHandler}
                  disabled={viewMode}
                  style={{ backgroundColor: "#fff" }}
                />
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <Form.Group controlId="formBasicName">
                <Form.Label className="refer-to-mch-label">
                  Start Date
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Start Date"
                  name="start_date"
                  format={"YYYY-MM-DD"}
                  value={newDiagnosis?.start_date}
                  onChange={setFieldValueHandler}
                  disabled={viewMode}
                  style={{ backgroundColor: "#fff" }}
                />
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <Form.Group controlId="formBasicName">
                <Form.Label className="refer-to-mch-label">End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="End Date"
                  name="end_date"
                  format={"YYYY-MM-DD"}
                  value={newDiagnosis?.end_date}
                  onChange={setFieldValueHandler}
                  disabled={viewMode}
                  style={{ backgroundColor: "#fff" }}
                />
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <Form.Group controlId="formBasicName">
                <Form.Label className="refer-to-mch-label">Severity</Form.Label>
                <Form.Select
                  aria-label="select"
                  onChange={setFieldValueHandler}
                  name="severity"
                  value={newDiagnosis?.severity}
                  disabled={viewMode}
                  style={{ backgroundColor: "#fff" }}
                >
                  <option>Select Severity</option>
                  {severityDrop?.length > 0 &&
                    severityDrop?.map((item, index) => (
                      <option value={item.severity} key={index}>
                        {item.severity}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <Form.Group controlId="formBasicName">
                <Form.Label className="refer-to-mch-label">Category</Form.Label>
                <Form.Select
                  aria-label="select"
                  onChange={setFieldValueHandler}
                  name="category"
                  value={newDiagnosis?.category}
                  disabled={viewMode}
                  style={{ backgroundColor: "#fff" }}
                >
                  <option>Select Category</option>
                  {categoryDrop.length > 0 &&
                    categoryDrop?.map((item, index) => (
                      <option value={item.category} key={index}>
                        {item.category}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <Form.Group controlId="formBasicName">
                <Form.Label className="refer-to-mch-label">
                  Occurrence
                </Form.Label>
                <Form.Select
                  aria-label="select"
                  onChange={setFieldValueHandler}
                  name="occurrence"
                  value={newDiagnosis?.occurrence}
                  disabled={viewMode}
                  style={{ backgroundColor: "#fff" }}
                >
                  <option>Occurrence</option>
                  {occurrenceDrop.length > 0 &&
                    occurrenceDrop?.map((item, index) => (
                      <option value={item.occurrence} key={index}>
                        {item.occurrence}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <Form.Group controlId="formBasicName">
                <Form.Label className="refer-to-mch-label">
                  Verification Status
                </Form.Label>
                <Form.Select
                  aria-label="select"
                  onChange={setFieldValueHandler}
                  name="verification_status"
                  value={newDiagnosis?.verification_status}
                  disabled={viewMode}
                  style={{ backgroundColor: "#fff" }}
                >
                  <option>Select Verification Status</option>
                  {verificationStatusDrop?.length > 0 &&
                    verificationStatusDrop?.map((item, index) => (
                      <option value={item.verification_status} key={index}>
                        {item.verification_status}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <Form.Group controlId="formBasicName">
                <Form.Label className="refer-to-mch-label">Outcome</Form.Label>
                <Form.Select
                  aria-label="select"
                  onChange={setFieldValueHandler}
                  name="outcome"
                  value={newDiagnosis?.outcome}
                  disabled={viewMode}
                  style={{ backgroundColor: "#fff" }}
                >
                  <option>Select Outcome</option>
                  {outComeDrop?.length > 0 &&
                    outComeDrop?.map((item, index) => (
                      <option value={item.outcome} key={index}>
                        {item.outcome}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
              <Form.Group controlId="formBasicName">
                <Form.Label className="refer-to-mch-label">Comments</Form.Label>
                <Form.Control
                  type=""
                  as="textarea"
                  rows={4}
                  placeholder="Comments"
                  name="comments"
                  value={newDiagnosis?.comments}
                  onChange={setFieldValueHandler}
                  disabled={viewMode}
                  style={{ backgroundColor: "#fff" }}
                />
              </Form.Group>
            </div>
          </div>
          {!viewMode && (
            <>
              <div className="bottom-line" />
              <ButtonPairs
                onResetPress={handleResetPress}
                onAddClick={handleAddPress}
                modalAction={modalAction}
              />
            </>
          )}
          {/* <p style={{ color: "red" }}>{Error ? Error : ""}</p> */}
        </Box>
      </Modal>
    </div>
  );
};

export default NewMedicalDiagnosisModal;
