import React, { useState } from 'react'
import Table from '../../View/Distributed Medicine/Table/Table'
import Layout from '../../../../LayoutComp/Layout';
import backBtnIcon from "../../../../../Assets/icons/back.svg"
import addIcon from "../../../../../Assets/icons/addIcon.svg"
import { useNavigate } from 'react-router-dom';
import GeneralInfoTab from '../../View/GeneralInfoTab/GeneralInfoTab';
const ViewAllPatient = ({ sideNavState, setSideNavState }) => {
    const navigate= useNavigate()
    const [generalInfo,setGeneralInfo]=useState(false)
    const generalInfoHandler=()=>{
      setGeneralInfo(true)
      console.log("calleddd")
    }
  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      {!generalInfo ? (
        <div>
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => navigate("/home/addnewpatient",{
     state: {
       pathto:"addNewPatient"
     }
         })}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>

          <div>
            <div className="d-flex justify-content-end mb-4 me-5">
              <button
                className="add-btn"
                onClick={() => navigate("/home/addnewpatient",{
     state: {
       pathto:"addNewPatient"
     }
         })}
              >
                <img src={addIcon} alt="" style={{ marginRight: "10px" }} />
                Add New Patient
              </button>
            </div>

            <Table
              type={"viewAllPatient"}
              generalInfoHandler={generalInfoHandler}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => setGeneralInfo(false)}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>

          <GeneralInfoTab />
        </div>
      )}
    </Layout>
  );
};

export default ViewAllPatient