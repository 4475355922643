import React from 'react'
import { Modal } from 'react-bootstrap'
import './ConfirmationModal.scss'
import successIcon from '../../Assets/Images/success.png'


export default function ConfirmationModal(props) {
    return (
        <Modal dialogClassName="confirmation-modal" show={props.isHidden} onHide={props.onClose} centered>
            <img src={successIcon} alt='' />
            <p className='msg-text mt-3'>{props.message}</p>
        </Modal>
    )
}
