import { Avatar, Box, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { DOCUMENT_OPTIONS, GENDER_OPTIONS } from "../../../Utils/constants";
import HollowButton from "../../HollowButton/HollowButton";
import Layout from "../../LayoutComp/Layout";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import RenderInput from "../../RenderInput/RenderInput";
import RenderSelect from "../../RenderSelect/RenderSelect";
import "./AdminForm.css";

const AdminForm = (props) => {
  const { sideNavState, setSideNavState } = props;

  const initialValues = { name: "", document: "Aadhar", gender: "male" };

  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      <Typography>Add New Admin</Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => console.log(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values, handleReset }) => (
          <>
            <Grid container spacing={4}>
              <Grid
                item
                xs={4}
                justifyContent="center"
                alignItems="center"
                className="avatar-container"
              >
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  // className="avatar"
                  style={{ width: "80%", height: "80%" }}
                />
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <RenderInput
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      title="Name"
                      placeholder="Name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RenderInput
                      name="contactNumber"
                      value={values.contactNumber}
                      onChange={handleChange}
                      title="Contact Number"
                      placeholder="Contact Number"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RenderInput
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      title="State"
                      placeholder="State"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RenderInput
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      title="City"
                      placeholder="City"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RenderInput
                      name="bloodGroup"
                      value={values.bloodGroup}
                      onChange={handleChange}
                      title="Blood Group"
                      placeholder="Blood Group"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RenderInput
                      name="age"
                      value={values.age}
                      onChange={handleChange}
                      title="Age"
                      placeholder="Age"
                      type="number"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <RenderSelect
                  name="gender"
                  title="Gender"
                  value={values.gender}
                  onChange={handleChange}
                  options={GENDER_OPTIONS}
                  placeholder="Gender"
                />
              </Grid>
              <Grid item xs={4}>
                <RenderSelect
                  name="document"
                  title="Document"
                  value={values.document}
                  onChange={handleChange}
                  options={DOCUMENT_OPTIONS}
                />
              </Grid>
              <Grid item xs={4}>
                <RenderInput
                  name="aadharNumber"
                  value={values.aadharNumber}
                  onChange={handleChange}
                  title={`${values.document} Number`}
                  placeholder={`${values.document} Number`}
                />
              </Grid>
              <Grid item xs={4}>
                <RenderInput
                  name="hospital"
                  value={values.hospital}
                  onChange={handleChange}
                  title="Hospital"
                  placeholder="Hospital"
                />
              </Grid>
              <Grid item xs={4}>
                <RenderInput
                  name="education"
                  value={values.education}
                  onChange={handleChange}
                  title="Education"
                  placeholder="Education"
                />
              </Grid>
              <Grid item xs={4}>
                <RenderInput
                  name="userName"
                  value={values.userName}
                  onChange={handleChange}
                  title="User Name"
                  placeholder="User Name"
                />
              </Grid>
              <Grid item xs={4}>
                <RenderInput
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  title="Password"
                  placeholder="Password"
                />
              </Grid>
              <Grid item xs={4}>
                <RenderInput
                  name="ims"
                  value={values.ims}
                  onChange={handleChange}
                  title="Select IMS"
                  placeholder="IMS"
                />
              </Grid>
            </Grid>
            <Box className="button-pair">
              <HollowButton
                name="Reset"
                onClick={handleReset}
                className="hollow-button"
              />
              <PrimaryButton
                name="Save"
                onClick={() => console.log(" ==========> button pressed")}
                className="hollow-button"
              />
            </Box>
          </>
        )}
      </Formik>
    </Layout>
  );
};

export default AdminForm;
