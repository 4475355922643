import React, { useEffect, useState } from "react";
import Layout from "../../../LayoutComp/Layout";
import { useNavigate } from "react-router";
import backBtnIcon from "../../../../Assets/icons/back.svg";
import addIcon from "../../../../Assets/icons/addIcon.svg";
import GeneralInfoTab from "../../../Patient/HealthCampPatient/View/GeneralInfoTab/GeneralInfoTab";
import { TablePagination } from "@mui/material";
import "./allPatientPage.scss";
import { patientHealthCampList } from "../../../../Services/AddPatient/AddPatient";
import { getSlno } from "../../../../Utils/getSlno";
import Spinner from "../../../Spinner/Spinner";
import Filter from "../../../Filter/Filter";
import {EditIcon,View} from "../../../../Utils/ImageUtils";

const AllPatientsPage = ({ sideNavState, setSideNavState }) => {
 
  const [patientInHC, setPatientInHC] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [showSpinner, setShowSpinner] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPageVal, setRowsPerPageVal] = useState(5);
  const [patientId, setPatientId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedSearchOption, setSelectedSearchOption] = useState("name");
  const [selectedPlaceholder, setPlaceholder] = useState("Search by Name")
  const [isEdit,setIsEdit] = useState(false);
  const navigate = useNavigate();
  const healthCampId = localStorage.getItem("healthCampid");
  const [generalInfo, setGeneralInfo] = useState(false);
  const [patientData,setPatientData] = useState({})

  const generalInfoHandler = () => {
    setGeneralInfo(true);
  };

  useEffect(() => {
    GetPatientInHealthCamp(offset);
  }, [rowsPerPageVal, pageSize]);

  useEffect(()=>{
    if(isEdit){
      navigate("/home/addnewpatientform",{state:patientData})
    }
  },[isEdit])

  const GetPatientInHealthCamp = async (offset = 0) => {
    setShowSpinner(true);
    try {
      let params = {
        offset: offset,
        limit: rowsPerPageVal
      };
      if (searchText.length > 0) params = { ...params, [selectedSearchOption]: searchText };
      const res = await patientHealthCampList(healthCampId, params);
      setPatientInHC(getSlno(res?.data?.body, offset));
      setTotalRecords(res?.data?.count);
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = rowsPerPageVal * pageIndex;
    GetPatientInHealthCamp(offset);
    setOffset(offset);
  };

  useEffect(() => {
    if (searchText === "") GetPatientInHealthCamp(offset);
  }, [searchText]);


  const handleChangeRowsPerPage = (e) => {
    setPageSize(e?.targte?.value);
    setRowsPerPageVal(parseInt(e.target.value, 10));
    GetPatientInHealthCamp(offset);
  };

  const searchHandler = () => {
    GetPatientInHealthCamp(0);
  };

  const handleSearchClear = () => {
    setSearchText("");
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  const updateFilter = (value, label) => {
    handleSearchClear()
    setSelectedSearchOption(value)
    if (label) setPlaceholder(label)
  }

  return (
    <Layout
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
      hidenav={true}
    >
      {!generalInfo ? (
        <div id="hello">
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => navigate("/home/dataoprpat")}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>
          <div>
            <div
              className="d-flex"
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0px 40px",
              }}
            >

              <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-8">
                <Filter
                  selectedSearchOption={selectedSearchOption}
                  updateFilter={updateFilter}
                  selectedPlaceholder={selectedPlaceholder}
                  handleSearchInput={handleSearchInput}
                  searchText={searchText}
                  handleSearchClear={handleSearchClear}
                  searchHandler={searchHandler}
                />
              </div>
              <div>
                <button
                  className="add-btn"
                  onClick={() => navigate("/home/addquickregistration")}
                >
                  <img src={addIcon} alt="" style={{ marginRight: "3px" }} />
                  Quick Registration
                </button>
                <button
                  className="add-btn"
                  onClick={() => navigate("/home/addnewpatientform")}
                >
                  <img src={addIcon} alt="" style={{ marginRight: "3px" }} />
                  Add New Patient
                </button>

              </div>
            </div>

            {patientInHC ? (
              <>
                <div className="patientdo" style={{ margin: "2%" }}>
                  <table className=" " style={{ width: "100%", margin: "0" }}>
                    <thead>
                      <tr>
                        <th>Sl No</th>
                        <th>Patient Name</th>
                        <th>Gender</th>
                        <th>Mobile No</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {patientInHC?.length > 0 && patientInHC.map((item, i) => {
                        return (
                          <tr
                           key={item.id}
                            onClick={() => navigate("/home/allPatientsPage")}
                            style={{ cursor: "pointer" }}
                          >
                            <td>
                              <>{item?.index}</>
                            </td>
                            <td>{item?.full_name}</td>
                            <td>{item?.gender}</td>
                            <td>{item?.contact_number}</td>
                            <td>
                              <button
                                className="add-btn"
                                onClick={generalInfoHandler}
                              >
                               <p  onClick={() => setPatientId(item.id)}>
                               <img src={View} alt="ViewIcon" style={{ marginRight: "3px" }} />
                                View
                                </p>  
                              </button>
                              <button
                                className="add-btn"
                                onClick={() => { setIsEdit(true); setPatientData(item) }}
                              >
                                <img src={EditIcon} alt="EditIcon" style={{ marginRight: "3px" }} />
                                Edit
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="flex justify-content-end table-page">
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[5, 10, 25]}
                      count={totalRecords}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPageVal}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="warning-text-card">No Records Found!</div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => setGeneralInfo(false)}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>

          <GeneralInfoTab patientId={patientId} setPatientId={setPatientId} />
        </div>
      )}
      {showSpinner && <Spinner />}
    </Layout>
  );
};

export default AllPatientsPage;
