import React from "react";
import Layout from "../../LayoutComp/Layout";
import FullWidthTabs from "../../TabComp/FullWidthTabs";
// import HealthCP from "./HealthCampPatient/HealthCP";
// import MCHReferredPat from "./MCHReferredPat/MCHReferredPat";
import { useNavigate } from "react-router-dom";
import backBtnIcon from "../../../Assets/icons/back.svg";
import NurseHealthCP from "./NurseHealthCamp/NurseHealthCamp";
import NMCHRefPat from "./NurseHealthCamp/NurseGeneralInfTabsPage/NurseMCHreferred pat/NMCHRefPat";
import { useSelector } from "react-redux";

const NursePatientTabs = ({ sideNavState, setSideNavState }) => {
  
  const { patientId } = useSelector((state) => state.patientId);

  // console.log("patient id=======================", patientId)

  const childrenArry = [<NurseHealthCP />,<NMCHRefPat sideNavState={sideNavState} campId={patientId} />];
  const navigate = useNavigate();

  const tabstitle = ["Health Camp Patient", "MCH referred Patient"];

  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState} hidenav={true}>
      <div className="py-2 px-3" style={{ cursor: "pointer"}}>
        <span
          className="back-btn-row"
          onClick={() => navigate("/home/nurseindex")}
        >
          <img src={backBtnIcon} alt="" />
          <span>Back</span>
        </span>
      </div>
      <FullWidthTabs tabstitle={tabstitle} 
      DoctorsTabData={childrenArry} 
      />
    </Layout>
  );
};

export default NursePatientTabs;
