import React, { useState } from "react";
import "./nurseTable.scss";
import searchCloseIcon from "../../../Assets/icons/search-close-icon.png";
import searchIcon from "../../../Assets/icons/search_icon.svg";
import filterIcon from "../../../Assets/icons/filter_icon.svg";
import PatientFiterModal from "../../PatientFilterModal/PatientFiterModal";
import { useNavigate } from "react-router";
import whitEye from "../../../Assets/icons/view-white.svg";
import view from "../../../Assets/icons/view.svg";
import pdficon from "../../../Assets/Images/pdficon.png";
import { TablePagination } from "@mui/material";
import edit from "../../../Assets/icons/edit.svg";

const SearchBarComp = ({
  searchText,
  handleSearchClear,
  handleSearchInput,
  placeHolder,
}) => {
  return (
    <label className="label-input">
      <input
        type="text"
        className="form-control search-input"
        // placeholder={`Search by ${placeHolder}`}
        placeholder={placeHolder ? placeHolder : "Search..."}
        onChange={handleSearchInput}
        value={searchText}
      />
      {searchText && (
        <button className="search-btn-close" onClick={handleSearchClear}>
          <img src={searchCloseIcon} alt="" width={"24px"} />
        </button>
      )}

      <button
        className="search-btn"
      //    onClick={()=>searchHandler()}
      >
        <img src={searchIcon} alt="" />
        <span>Search</span>
      </button>
    </label>
  );
};

const FilterComponent = ({ setShowModal }) => {
  return (
    <div className="col-6 ms-2">
      <button className="filter-btn" onClick={() => setShowModal(true)}>
        <span>Filter</span>
        <img src={filterIcon} alt="" className="ps-2" />
      </button>
    </div>
  );
};

const TableComp = ({
  isSearchBar = false,
  isFilter = false,
  selectOptions = [],
  tableData = [],
  navigateTo,
  editNavigateTo,
  thHeadData,
  patientInfo,
  setAction,
  setEditItems,
  totalRecords,
  page,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  setOffset,
  placeHolder,
  rowShouldNot
}) => {
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const handleSearchClear = () => {
    setSearchText("");
  };
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  const closeModal = () => {
    setShowModal(!showModal);
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = rowsPerPage * pageIndex;
    setOffset(offset);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const theadData = thHeadData ?? tableData.map((val) => Object.keys(val));
  console.log("theadDatatheadData", theadData[0]);
  const res = tableData.map((item, index) => {
    return Object.keys(item).map((val) => {
      return item[val];
    });
  });
  // console.log("res", theadData);
  return (
    <div id="doctorId">
      <div className="d-flex flex-column  flex-md-row flex-lg-row flex-lg-row  align-items-center align-items-md-end pt-4">
        {isSearchBar && (
          <div
            className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4"
            style={{ marginLeft: "20px" }}
          >
            <SearchBarComp
              searchText={searchText}
              placeHolder={placeHolder}
              handleSearchClear={handleSearchClear}
              handleSearchInput={handleSearchInput}
            />
          </div>
        )}
        {isFilter && <FilterComponent setShowModal={setShowModal} />}
      </div>
      <div className="patientdo" style={{ margin: "2%" }}>
        {tableData?.length ?
          <table className=" " style={{ width: "100%", margin: "0" }}>
            <thead>
              <tr>
                {thHeadData && thHeadData !== undefined
                  ? theadData.map((val) => {
                    return (
                      <th key={val}>
                        {val.startsWith("Action") ? val.slice(0, 6) : val}
                      </th>
                    );
                  })
                  : theadData[0]?.map((val) => {
                    return (
                      <th key={val}>
                        {val.startsWith("Action") ? val.slice(0, 6) : val}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item, index) => {
                return (
                  <>
                    <tr
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={
                        rowShouldNot ? (e) => e.stopPropagation() :
                          theadData[0].includes("Prescription") ||
                            theadData[0].includes("Report Name") ||
                            theadData[0].includes("PrescriptionMCH") ||
                            theadData[0].includes("ActionNPrescription") ||
                            theadData[0].includes("ActionNMedical") ||
                            theadData[0].includes("ActionMCHNPrescription")
                            ? //   theadData[0].includes("ActionNurseHealthInfo")
                            navigateTo()
                            : theadData[0].includes("ActionMCHferredPat") ||
                              theadData[0].includes("ActionHealthCamp") ||
                              theadData[0].includes("ActionNHealthInfo") ||
                              theadData[0].includes("ActionNurseMedicalDiag") ||
                              theadData[0].includes("ActionNurseGiView") ||
                              theadData[0].includes("ActionNurseMCH")
                              ? null
                              : () => navigate(`/home/${navigateTo}`)
                      }
                    // onClick={() => {
                    //   if (theadData[0].includes("Prescription")) {
                    //     navigateTo()
                    //   } else {
                    //     navigate(`/home/${navigateTo}`);
                    //   }
                    //   }
                    // }
                    >
                      {Object.keys(item).map((key) => {
                        let cellContent;
                        switch (key) {
                          case "ActionNurseGiView":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  navigate("/home/nursegeneralitp", {
                                    state: {
                                      patientInfo: patientInfo.filter(
                                        (patientId) => item.id === patientId.id
                                      ),
                                    },
                                  });
                                }}
                              >
                                {console.log(" ==========>option  ", {
                                  state: {
                                    patientInfo: patientInfo.filter(
                                      (patientId) => item.id === patientId.id
                                    ),
                                  },
                                })}
                                <p className="view-all px-2 py-1">
                                  view
                                  <img src={whitEye}></img>
                                </p>
                              </div>
                            );
                            break;
                          case "ActionNurseMCH":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  navigate("/home/nursenmch");
                                }}
                              >
                                <p className="view-all px-2 py-1">
                                  view
                                  <img src={whitEye}></img>
                                </p>
                              </div>
                            );
                            break;
                          case "ActionNMedical":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  navigateTo();
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={view}></img>
                                  123 kb
                                </p>
                              </div>
                            );
                            break;
                          case "ActionNMCHMedical":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  navigateTo();
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={view}></img>
                                  123 kb
                                </p>
                              </div>
                            );
                            break;

                          case "Report Name":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={pdficon}></img>
                                  Prescription
                                </p>
                              </div>
                            );
                            break;

                          case "ActionMCHferredPat":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  navigate("/home/doctorsmchtabs");
                                }}
                              >
                                <p className="view-all px-2 py-1">
                                  view
                                  <img src={whitEye}></img>
                                </p>
                              </div>
                            );
                            break;
                          case "Prescription":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={pdficon}></img>
                                  Prescription
                                </p>
                              </div>
                            );
                            break;
                          case "Prescription MCH":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={pdficon}></img>
                                  Prescription
                                </p>
                              </div>
                            );
                            break;
                          case "ActionNPrescription":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  navigateTo();
                                  // event.stopPropagation();
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={view}></img>
                                  123 kb
                                </p>
                              </div>
                            );
                            break;
                          case "ActionMCHNPrescription":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  navigateTo();
                                  // event.stopPropagation();
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={view}></img>
                                  123 kb
                                </p>
                              </div>
                            );
                            break;
                          case "ActionNHealthInfo":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                {/* <div className="px-2 py-1 flex !flex-row"> */}
                                <div
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setAction("view");
                                    setEditItems(item);
                                  }}
                                >
                                  <img className="me-3" src={view}></img>
                                </div>
                                {/* <div onClick={()=>{
                                setAction("edit")
                                setEditItems(item)
                              }}>
                                <img className="me-3" src={edit} />
                              </div> */}
                                {/* </div> */}
                              </div>
                            );
                            break;
                          case "ActionNurseMedicalDiag":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                {/* <div className="px-2 py-1 flex !flex-row"> */}
                                <div onClick={navigateTo()}>
                                  <img className="me-3" src={view}></img>
                                </div>

                                {/* </div> */}
                              </div>
                            );
                            break;

                          // add more cases as needed
                          case "visit_id":
                            return null;
                          default:
                            cellContent = (
                              <div>
                                {typeof item[key] !== "object" ? item[key] : ""}
                              </div>
                            );
                            break;
                        }
                        return <td key={key}>{cellContent}</td>;
                      })}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table> :
          tableData?.length === 0 && (
            <div className="warning-text-card">No Records Found!</div>
          )}
        {tableData?.length ?
        <div className="flex justify-content-end table-page">
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>: undefined
        }
      </div>
      {
        <PatientFiterModal
          isHidden={showModal}
          onClose={closeModal}
          selectOptions={selectOptions}
        // setFilterDataObj={setFilterDataObj}
        />
      }
    </div>
  );
};

export default TableComp;
