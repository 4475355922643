const awsConfig = {
  Auth:{
      region: 'ap-south-1',
      userPoolId: 'ap-south-1_Qct7Xm6i7',
      userPoolWebClientId: '1phnp3jafqhr8avatf8ts6fj1l',
      identityPoolId:'ap-south-1:b576331d-2374-454b-b170-dc4584967063'
  },
  Storage:{
    AWSS3:{
      bucket:'health-camp-bucket',
      region:'ap-south-1'
    }
  }
}

export default awsConfig;