import React from "react";
import PrimaryButton from "../../../../../../PrimaryButton/PrimaryButton";
import TopBar from "./TopBar";
import AddIncon from "../../../../../../../Assets/icons/addIcon.svg";
import "./MedicalDiagnosis.scss";

const AddMedicalDiagnosis = (props) => {
  const { open, onTopBarClick, table, setShowModal, setModalAction, type } =
    props;

  const viewType = type === "View";

  return (
    <div>
      <div className="tabs-main-container">
        <TopBar
          open={open}
          content="Medical Diagnosis"
          onClick={onTopBarClick}
        />
        {open ? (
          <div
            className="lab-test py-4"
            style={{ width: "100%" }}
            id="medical-diagnosis-tab"
          >
            {!viewType ? (
              <div className=" flex flex-row mb-3  text-end">
                <PrimaryButton
                  name="Add New Medical Diagnosis"
                  img={<img src={AddIncon} />}
                  className="py-2 px-3"
                  style={{ color: "green" }}
                  onClick={() => {
                    setModalAction("");
                    setShowModal(true);
                  }}
                />
              </div>
            ) : null}

            {table}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AddMedicalDiagnosis;
