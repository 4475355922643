import generalInfoProfile from "../../../../../../Assets/Images/general-info-profile.png";
import DefaultImage from "../../../../../../Assets/Images/defaultProfile.png";
import "../../../../../Patient/View/GeneralInfoTab/view.scss";
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

const NGeneralInfoComp = () => {
  
  const { patientList }= useSelector((state)=> state.patientInfo)
  return (
    <div id="generalInfo" className="text-center">
      <button
        className="btn mb-2"
        style={{ backgroundColor: "#191BC3", color: "white" }}
      >
        General Information
      </button>
      <div className="row d-flex ">
        <div className="col-2 right-border">
          <div
            className=" d-flex flex-column"
            style={{
              position: "relative",
              top: "25%",
              width: "80px",
              left: "25%",
            }}
          >
            <img src={DefaultImage} className="w-auto"></img>
            <p>Profile Photo</p>
          </div>
        </div>

        <div className="col-5 right-border ps-5">
          <div className="grid-container">
            <p className="font-style">Patient Name</p>
            <p>{patientList[0]?.full_name}</p>
            <p className="font-style">Gender</p>
            <p>{patientList[0]?.gender}</p>
            <p className="font-style">Age</p>
            <p>{patientList[0]?.age}</p>
            <p className="font-style">Mobile No</p>
            <p>{patientList[0]?.contact_number}</p>
            <p className="font-style">Occupation</p>
            <p>{patientList[0]?.occupation}</p>
            <p className="font-style">Annual Income</p>
            <p>{patientList[0]?.annual_income}</p>
            <p className="font-style">Document Type</p>
            <p>{patientList[0]?.doc_type}</p>
            <p className="font-style">Document No</p>
            <p>{patientList[0]?.doc_number}</p>
          </div>
        </div>

        <div className="col-5 ps-5">
          <div className="grid-container">
            <p className="font-style">Total Earning Members</p>
            <p>{patientList[0]?.earning_count}</p>
            <p className="font-style">Technique of farming</p>
            <p>{patientList[0]?.farm_technique}</p>
            <p className="font-style">Spouse Occupation</p>
            <p>{patientList[0]?.spouse_occupation}</p>
            <p className="font-style">Total Children</p>
            <p>{patientList[0]?.children_count}</p>
            <p className="font-style">Aadhar Card of Children</p>
            <p>{patientList[0]?.aadhar}</p>
            <p className="font-style">Marital Status</p>
            <p>{patientList[0]?.marital_status}</p>
            <p className="font-style">Bank Account</p>
            <p>{patientList[0]?.bank_account}</p>
            <p className="font-style">Family Member’s Name</p>
            <p>{""}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NGeneralInfoComp;
