import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import backBtnIcon from "../../Assets/icons/back.svg";
import "./PatientCreateViewEdit.scss";
import attachment from "../../Assets/icons/attachment.svg";
import {
  createService,
  readService,
  updateService,
} from "../../Services/HttpService";
import ProfileIconBlack from "../../Assets/icons/Profile-Icon-Black.svg";
import { Form } from "react-bootstrap";
import Layout from "../LayoutComp/Layout";

export default function PatientCreateViewEdit({
  sideNavState,
  setSideNavState,
}) {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("iddd", state);
  const [healthCampData, setHealthCampData] = useState([]);
  const [isAddNewPatient, setIsAddNewPatient] = useState(false);
  const [fieldValue, setFieldValue] = useState({
    displayName: null,
    gender: null,
    phone: null,
    start_digit: null,
    documentType: null,
    documentNumber: null,
    healthcampId: null,
    description: null,
  });

  useEffect(() => {
    setIsAddNewPatient(true);
  }, []);

  // console.log("health camp is==================", healthCampData.data)

  useEffect(() => {
    const healthCampData = async () => {
      const params = {
        offset: 0,
        limit: 20,
      };
      const data = await readService("/health/camp", params);
      if (data) {
        setHealthCampData(data);
      }
    };
    healthCampData();
  }, []);

  useEffect(() => {
    if (state.action === "Edit" || state.action === "View") {
      const { item } = state;
      if (state) {
        setFieldValue(item);
      }
    }
  }, []);

  const navigateBack = () => {
    navigate("/home/addnewpatient", {
      state: {
        pathto: "addNewPatient",
      },
    });
  };

  const updateData = (e) => {
    setFieldValue({
      ...fieldValue,
      [e.target.name]: e.target.value,
    });
  };

  const addNewPatient = async (fieldValue) => {
    delete fieldValue.start_digit;
    let response = await createService("/health/patient", fieldValue);
    if (response) {
      navigate("/home/patient");
      alert("created successfully");
    }
  };

  const editPatient = async (fieldValue) => {
    delete fieldValue.start_digit;
    const params = {
      patientId: state.id,
    };

    let response = await updateService("/health/patient", params, fieldValue);
    if (response) {
      navigate("/home/patient");
      alert("updated successfully!!");
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setIsAddNewPatient(false);
    if (state.action === "Add") {
      addNewPatient(fieldValue);
    } else if (state.action === "Edit") {
      editPatient(fieldValue);
    }
  };
  console.log("pat", isAddNewPatient);
  return (
    <div id="patient_view_container">
      {isAddNewPatient ? (
        <div
          style={{
            width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
            marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
            transition: "0.5s",
            position: "absolute",
          }}
        >
          {" "}
          <div className="pt-3 px-3">
            <span className="back-btn-row" onClick={navigateBack}>
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </span>
          </div>
          <div className="add-card">
            <p className="add-text">{`${state.action} ${
              state.action === "Add" ? "New" : ""
            } Patient`}</p>
            {/* <button className="btn-attach">
            <img src={attachment} alt="" style={{ paddingRight: "10px" }} />
            Attach Report
          </button> */}
          </div>
          <form onSubmit={onSubmit}>
            <div className="main-container">
              <div className="row m-0 justify-content-between">
                <div className="col-lg-4 col-sm-12 col-md-4">
                  <p>Patient Name</p>
                  <input
                    name="displayName"
                    className="select-input"
                    onChange={updateData}
                    value={fieldValue.displayName}
                  />
                </div>
                <div className="col-lg-4 col-sm-12 col-md-4">
                  <p>Gender</p>
                  <select
                    name="gender"
                    className="select-input"
                    placeholder="Select Gender"
                    //   defaultValue={"DEFAULT"}
                    onChange={updateData}
                    value={fieldValue.gender}
                  >
                    <option value="DEFAULT" disabled>
                      Select Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
                <div className="col-lg-4 col-sm-12 col-md-4">
                  <p>Mobile No.</p>
                  <div className="d-flex justify-content-between">
                    <input
                      name="start_digit"
                      value={"+91"}
                      //   onChange={updateData}
                      className="select-input"
                      style={{ width: "3rem", marginRight: "0.5rem" }}
                    />
                    <input
                      name="phone"
                      onChange={updateData}
                      className="select-input tablet-size"
                      value={fieldValue.phone}
                    />
                  </div>
                </div>
              </div>
              <div className="row mx-0 my-4">
                <div className="col-lg-4 col-sm-12 col-md-4">
                  <p>Document Type</p>
                  <select
                    name="documentType"
                    className="select-input"
                    placeholder="Document Type"
                    defaultValue={"DEFAULT"}
                    onChange={updateData}
                    value={fieldValue.documentType}
                  >
                    <option value="DEFAULT" disabled>
                      Document Type
                    </option>
                    <option value="Aadhar">Aadhar</option>
                    <option value="Pan">Pan</option>
                  </select>
                </div>
                <div className="col-lg-4 col-sm-12 col-md-4">
                  <p>Document No.</p>
                  <input
                    name="documentNumber"
                    onChange={updateData}
                    className="select-input"
                    value={fieldValue.documentNumber}
                  />
                </div>
                {/* <div className="col-lg-4 col-sm-12 col-md-4">
                <p>Select Health Camp</p>
                <select
                  name="healthcampId"
                  className="select-input"
                  placeholder="Select Health Camp"
                  defaultValue={"DEFAULT"}
                  onChange={updateData}
                  value={fieldValue.healthcampId}
                >
                  <option value="DEFAULT" disabled>
                    Select Health Camp
                  </option>
                  {healthCampData?.data?.camps?.map((item) => (
                    <option value={item._id} key={item._id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div> */}
              </div>
              {/* <div className="row mx-0 my-4">
              <div className="col-12">
                <p>Disease Description</p>
                <textarea
                  name="description"
                  onChange={updateData}
                  style={{ width: "100%" }}
                  value={fieldValue.description}
                />
              </div>
            </div> */}
              {state.action !== "View" && (
                <div className="btn-card">
                  <button
                    type="submit"
                    className="btn-reset"
                    style={{
                      display: state.action === "Edit" ? "none" : undefined,
                    }}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="btn-save"
                    onClick={() => setIsAddNewPatient(false)}
                  >
                    {state.action === "Edit" ? "Update" : "Save"}
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      ) : (
        <>
          <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
            <div
              className="pre-back-btn-row d-flex justify-content-start align-items-center mb-3"
              onClick={() => setIsAddNewPatient(true)}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
            <div className="patient-general-info">
              <div className="d-flex flex-row justify-content-between ">
                <h5>General Information</h5>
                {/* {action === "View" && (
                  <div>
                    <img
                      src={close_icon}
                      alt=""
                      onClick={() => setAction(null)}
                    />
                  </div>
                )} */}
              </div>
              <div className="d-flex row justify-content-start">
                <div className="d-flex flex-row">
                  <div className="col-lg-2 col-sm-12 col-md-6 d-flex justify-content-start align-items-center">
                    <div
                      className=" row img-upload d-flex flex-column align-items-center justify-content-start"
                      // onClick={() => inputRef.current.click()}
                    >
                      <img src={ProfileIconBlack} />
                    </div>
                  </div>
                  {/* <div className="d-flex row  justify-content-start"> */}
                  <div style={{ width: "100%" }}>
                    <div className="d-flex row">
                      <div className="col-lg-4 col-sm-12 col-md-6">
                        <Form.Group co0ntrolId="formBasicEmail">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="number"
                            // placeholder="Enter mobile number"
                            // onChange={(e) => setPhone(e.target.value)}
                            // value={phone}
                          />
                        </Form.Group>
                      </div>
                      <div
                        className="col-lg-4 col-sm-12 col-md-6"
                        // style={{ width: "33%" }}
                      >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Middle Name</Form.Label>
                          <Form.Control
                            type="middleName"
                            // placeholder="Middle Name"
                            // onChange={(e) => setEmail(e.target.value)}
                            // value={email}
                          />
                        </Form.Group>
                      </div>
                      <div
                        className=" col-lg-4 col-sm-12 col-md-6"
                        // style={{ width: "33%" }}
                      >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="Last Name"
                            // placeholder="Enter ema/il"
                            // onChange={(e) => setEmail(e.target.value)}
                            // value={email}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="d-flex row">
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        // style={{ width: "42.5%" }}
                      >
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control
                            type=""
                            // placeholder="qualification"
                            // onChange={(e) => setQualification(e.target.value)}
                            // value={qualification}
                          />
                        </Form.Group>
                      </div>
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        // style={{ width: "42.5%" }}
                      >
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Age</Form.Label>
                          <Form.Control
                            type=""
                            // placeholder="qualification"
                            // onChange={(e) => setQualification(e.target.value)}
                            // value={qualification}
                          />
                        </Form.Group>
                      </div>
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        // style={{ width: "42.5%" }}
                      >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Gender</Form.Label>
                          <Form.Select aria-label="select">
                            <option>Select</option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex row justify-content-start">
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Mobile No</Form.Label>
                  </Form.Group>
                </div>

                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Occupation</Form.Label>
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Annual Income</Form.Label>
                    {/* <Form.Select aria-label="select">
                        <option>Select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Select> */}
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Document Type</Form.Label>
                    <Form.Select aria-label="select">
                      <option>Select</option>
                      <option value="1">Adahar</option>
                      <option value="2">Pan</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Document No</Form.Label>
                    <Form.Control
                      type="number"
                      // placeholder="specialisation"
                      // onChange={(e) => setSpecialisation(e.target.value)}
                      // value={specialisation}
                    />
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Ayushmann Card</Form.Label>
                    <Form.Control
                      type=""
                      // placeholder="specialisation"
                      // onChange={(e) => setSpecialisation(e.target.value)}
                      // value={specialisation}
                    />
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Total Family Member's</Form.Label>
                    <Form.Control
                      type=""
                      // placeholder="specialisation"
                      // onChange={(e) => setSpecialisation(e.target.value)}
                      // value={specialisation}
                    />
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Total Earning Members</Form.Label>
                    <Form.Control
                      type=""
                      // placeholder="specialisation"
                      // onChange={(e) => setSpecialisation(e.target.value)}
                      // value={specialisation}
                    />
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Technic of Farming</Form.Label>
                    <Form.Control
                      type=""
                      // placeholder="specialisation"
                      // onChange={(e) => setSpecialisation(e.target.value)}
                      // value={specialisation}
                    />
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Marital Status</Form.Label>
                    <Form.Select aria-label="select">
                      <option>Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Spouse Occupation</Form.Label>
                    <Form.Control
                      type=""
                      // placeholder="specialisation"
                      // onChange={(e) => setSpecialisation(e.target.value)}
                      // value={specialisation}
                    />
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Total Children</Form.Label>
                    <Form.Control
                      type=""
                      // placeholder="specialisation"
                      // onChange={(e) => setSpecialisation(e.target.value)}
                      // value={specialisation}
                    />
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Adhar Card of Children</Form.Label>
                    <Form.Select aria-label="select">
                      <option>Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="btn-card mt-3">
              <button
                type="submit"
                className="btn-reset"
                style={{
                  display: state.action === "Edit" ? "none" : undefined,
                }}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn-save"
                onClick={() => setIsAddNewPatient(false)}
              >
                {state.action === "Edit" ? "Update" : "Save"}
              </button>
            </div>
          </Layout>
        </>
      )}
    </div>
  );
}
