import React from 'react'
// import Lottie from "react-lottie";
import animationData from "../../../../Assets/Success.json";

const SuccessLottie = () => {
  const options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      options
      {/* <Lottie options={options} /> */}
    </div>
  );
}

export default SuccessLottie