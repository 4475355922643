import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
// import RenderSelect from "../../RenderSelect/RenderSelect";

const SingleRow = (props) => {
  const { name, content } = props;

  return (
    <div className="filter-row" style={{ alignItems: "center" }}>
      <p style={{ margin: 0, width: 400 }}>{name}</p>
      {content}
    </div>
  );
};

const Select = (props) => {
  const { options, value, setValue, name } = props;

  return (
    <select
      className="select-input"
      placeholder="Select Health Camp"
      onChange={(e) => {
        setValue(e.target.value);
      }}
      value={value}
    >
      <option value="DEFAULT">Select {name}</option>
      {options?.map((item) => {
        return <option value={item.id}>{item.name}</option>;
      })}
    </select>
  );
};

const CoPathologistFilterModal = (props) => {
  const {
    stateDropDown,
    filterState,
    setFilterState,
    filterDistrictDrop,
    filterDistrict,
    setFilterDistrict,
    filterPlaceDrop,
    filterPlace,
    setFilterPlace,
  } = props;

  return (
    <div className="health-filter-modal">
      <SingleRow
        name="Select State"
        content={
          <Select
            options={stateDropDown}
            value={filterState}
            setValue={setFilterState}
            name="State"
          />
        }
      />
      <SingleRow
        name="Select District"
        content={
          <Select
            options={filterDistrictDrop}
            value={filterDistrict}
            setValue={setFilterDistrict}
            name="District"
          />
        }
      />
      <SingleRow
        name="Place"
        content={
          <Select
            options={filterPlaceDrop}
            value={filterPlace}
            setValue={setFilterPlace}
            name="Place"
          />
        }
      />
    </div>
  );
};

export default CoPathologistFilterModal;
