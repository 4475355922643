
import React from 'react'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Form, Row, Button, InputGroup } from "react-bootstrap";
import { NavLink as RouterLink, Link } from 'react-router-dom';
import './newPasswordStyle.scss'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
function NewPassword() {
  return (
    <div className='newPasswordContainer'>
      <Stack direction="row" spacing={2}>
        <Avatar alt="key" src="./assets/images/auth/Group 38360.png" sx={{ width: 64, height: 64, background: 'whitesmoke' }} />
      </Stack>
      <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 4 }}>
        <span className='forgotText'> New</span>&nbsp;<span className='passwordText'> Password? </span>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 4 }}>
        <span className='passwordIntoText'>Password must be minimum 8 character. Contain at least 1 uppercase, 1 lower case, 1 special character and 1 number</span>
      </Stack>


      <Form className="newPasswordForm">
        <Row>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label className="formLabel">New Password</Form.Label>
            <Form.Control className="newPassword-formControl" type="password" placeholder="Enter email" />
          </Form.Group>
        </Row>
        <Row>

          <Form.Group className="mb-3 position-relative" controlId="formGroupEmail">
            <Form.Label className="formLabel">New Password</Form.Label>
            <Form.Control className="newPassword-formControl" type="password" placeholder="Enter email" />
            <  RemoveRedEyeIcon className='newPassword-removeRedEyeIcon'/>
            <VisibilityOffIcon className='newPassword-removeRedEyeIcon'/> 
          </Form.Group>
        </Row>

        <div className="d-flex justify-content-center ">
          <Button as={Link} className="newPassword-ChangePassword mt-3" to='/verification-code' variant="primary" type="submit">
            Change Password
          </Button>
        </div>
      </Form>
    </div >
  )
}
export default NewPassword;