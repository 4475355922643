import React, { useState, useEffect } from "react";
import Layout from "../../LayoutComp/Layout";
import { useNavigate } from "react-router";
import whitEye from "../../../Assets/icons/view-white.svg";
import backBtnIcon from "../../../Assets/icons/back.svg";
import "./pathologistPatientDetails.scss";
import { useSelector, useDispatch } from "react-redux";
import { readService } from "../../../Services/HttpService";
import { TablePagination } from "@mui/material";
import { setPatientId } from "../../Redux/slices/userSlice";
import UploadIcon from "../../../Assets/icons/upload-icon.svg";
import Filter from "../../Filter/Filter";
import { patientHealthCampList } from "../../../Services/AddPatient/AddPatient";

const PathologistPatientDetails = ({ sideNavState, setSideNavState }) => {

  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [generalInfo, setGeneralInfo] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [offset, setOffset] = useState(0);
  const [patientInfo, setPatientInfo] = useState([]);
  const [selectedSearchOption, setSelectedSearchOption] = useState("name");
  const [selectedPlaceholder, setPlaceholder] = useState("Search by Name")
  let healthCampId = JSON.parse(localStorage.getItem("healthCampid"));

  const getPatientDetails = async (offset = 0, searchText) => {
    let params = {
      limit: pageSize,
      offset: offset,
    };
    if (searchText) params = { ...params, [selectedSearchOption]: searchText };
    let patient = await patientHealthCampList(healthCampId, params);
    if (patient) {
      setTotalRecords(patient.data.count);
      setPatientInfo(patient.data.body);
    }
  };

  useEffect(() => {
    getPatientDetails(0, searchText);
  }, [pageSize]);

  useEffect(() => {
    getPatientDetails(offset, searchText);
  }, []);

  const patientInfoHandler = (item) => {
    dispatch(setPatientId(item.id));
    navigate("/home/pathologistView");
  };

  const handleSearchClear = () => {
    setSearchText("");
    getPatientDetails(0, "");
  };

  useEffect(() => {
    if (searchText === "") getPatientDetails(offset, searchText);
  }, [searchText]);

  const searchHandler = () => {
    getPatientDetails(offset, searchText);
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  /**Pagination page change handle */
  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = pageSize * pageIndex;
    getPatientDetails(offset, searchText);
    setOffset(offset);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateFilter = (value,label) => {
    handleSearchClear()
    setSelectedSearchOption(value)
    if(label)setPlaceholder(label)
  }

  return (
    <Layout
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
      hidenav={true}
    >
      {!generalInfo ? (
        <div>
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => navigate("/home/pathologistpatient")}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>
          <div>
            <div id="pathologistDetails" style={{ margin: "2%" }}>
              <Filter
                selectedSearchOption={selectedSearchOption}
                updateFilter={updateFilter}
                selectedPlaceholder={selectedPlaceholder}
                handleSearchInput={handleSearchInput}
                searchText={searchText}
                handleSearchClear={handleSearchClear}
                searchHandler={searchHandler}
              />

              {patientInfo?.length > 0 ? (<div>
                <table className=" " style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <th>SL.No</th>
                      <th>Patient Name</th>
                      <th>Gender</th>
                      <th>Mobile No.</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patientInfo?.map((item, i) => {
                      return (
                        <tr style={{ cursor: "pointer" }} >
                          <td>{i + 1}</td>
                          <td>{item.full_name}</td>
                          <td>{item.gender}</td>
                          <td>{item.contact_number}</td>
                          <td>
                            <div className="btn-group " role="group" aria-label="Basic example">
                              <button type="button" className="btn btn-primary" onClick={() => patientInfoHandler(item)} > <img src={whitEye}></img>View</button>
                              <button type="button" className="btn btn-secondary" onClick={() => navigate("/home/upload-report-view")} >Upload   <img src={UploadIcon}></img></button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="flex justify-content-end table-page">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25]}
                    count={totalRecords}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              ) : (
                <div className="warning-text-card">No Records Found!</div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => setGeneralInfo(false)}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default PathologistPatientDetails;
