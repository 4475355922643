export const DOCUMENT_OPTIONS = [
  { label: "Aadhar", value: "Aadhar" },
  // { label: "PAN Card", value: "PAN Card" },
];

export const GENDER_OPTIONS = [
  { name: "Male", value: "Male"},
  { name: "Female", value: "Female" },
  { name: "Other", value: "other" },
];

export const Ward = [
  { id: "1", name: "Yes" },
  { id: "0", name: "No" },
];
