import React, { useState } from "react";
import "./doctorvtwo.scss";
import searchCloseIcon from "../../../Assets/icons/search-close-icon.png";
import searchIcon from "../../../Assets/icons/search_icon.svg";
import filterIcon from "../../../Assets/icons/filter_icon.svg";
import PatientFiterModal from "../../PatientFilterModal/PatientFiterModal";
import { useNavigate } from "react-router";
import whitEye from "../../../Assets/icons/view-white.svg";
import view from "../../../Assets/icons/view.svg";
import pdficon from "../../../Assets/Images/pdficon.png";
import { TablePagination } from "@mui/material";

const SearchBarComp = ({
  searchText,
  handleSearchClear,
  handleSearchInput,
  handleSearchPress,
}) => {
  return (
    <label className="label-input">
      <input
        type="text"
        className="form-control search-input"
        placeholder="Search by Doctor's name"
        onChange={handleSearchInput}
        value={searchText}
      />
      {searchText && (
        <button className="search-btn-close" onClick={handleSearchClear}>
          <img src={searchCloseIcon} alt="" width={"24px"} />
        </button>
      )}

      <button className="search-btn" onClick={handleSearchPress}>
        <img src={searchIcon} alt="" />
        <span>Search</span>
      </button>
    </label>
  );
};

const FilterComponent = ({ setShowModal }) => {
  return (
    <div className="col-6 ms-2">
      <button className="filter-btn" onClick={() => setShowModal(true)}>
        <span>Filter</span>
        <img src={filterIcon} alt="" className="ps-2" />
      </button>
    </div>
  );
};

const TableComp = ({
  isSearchBar = false,
  isFilter = false,
  selectOptions = [],
  tableData = [],
  navigateTo,
  editNavigateTo,
  hidenav,
  searchText,
  setSearchText,
  handleSearchPress,
  count,
  // handleChangeRowsPerPage,
  // handleChangePage,
  page,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  setOffset,
}) => {
  // const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  // const [totalRecords, setTotalRecords] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const [page, setPage] = React.useState(0);

  const navigate = useNavigate();
  const handleSearchClear = () => {
    setSearchText("");
  };
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  const closeModal = () => {
    setShowModal(!showModal);
  };
  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = rowsPerPage * pageIndex;
    setOffset(offset);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const theadData = tableData?.map((val) =>
    Object.keys(val).filter((val) => val != "id")
  );
  const res = tableData?.map((item, index) => {
    return Object.keys(item).map((val) => {
      return item[val];
    });
  });
  return (
    <div id="doctorId">
      <div className="d-flex flex-column  flex-md-row flex-lg-row flex-lg-row  align-items-center align-items-md-end pt-4">
        {isSearchBar && (
          <div
            className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4"
            style={{ marginLeft: "20px" }}
          >
            <SearchBarComp
              searchText={searchText}
              handleSearchClear={handleSearchClear}
              handleSearchInput={handleSearchInput}
              handleSearchPress={handleSearchPress}
            />
          </div>
        )}
        {isFilter && <FilterComponent setShowModal={setShowModal} />}
      </div>
      <div className="patientdo" style={{ margin: "2%" }}>
        <table className=" " style={{ width: "100%", margin: "0" }}>
          <thead>
            <tr>
              {theadData?.[0]?.map((val) => {
                return (
                  <th key={val}>
                    {val.startsWith("Action") ? val.slice(0, 6) : val}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item, index) => {
              return (
                <>
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={
                      theadData[0].includes("Report Name")
                        ? () => navigateTo(item.id)
                        : theadData[0].includes("Prescription") ||
                          theadData[0].includes("PrescriptionMCH") ||
                          theadData[0].includes("ActionHealthInfo") ||
                          theadData[0].includes("ActionDoctorMedicalDiag")
                        ? navigateTo()
                        : theadData[0].includes("ActionMCHferredPat") ||
                          theadData[0].includes("ActionHealthCamp") ||
                          theadData[0].includes("ActionNurseMedicalDiag")
                        ? null
                        : () => navigate(`/home/${navigateTo}`)
                    }
                    // onClick={() => {
                    //   if (theadData[0].includes("Prescription")) {
                    //     navigateTo()
                    //   } else {
                    //     navigate(`/home/${navigateTo}`);
                    //   }
                    //   }
                    // }
                  >
                    {Object.keys(item)
                      .filter((key) => key != "id")
                      .map((key) => {
                        let cellContent;
                        switch (key) {
                          case "ActionHealthCamp":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="view-all px-2 py-1">
                                  view
                                  <img src={whitEye}></img>
                                </p>
                              </div>
                            );
                            break;
                          case "ActionMedical":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={() => {
                                  // event.stopPropagation();
                                  // console.log("item.report".item.report)
                                  navigateTo(item.id);
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={view}></img>
                                  123 kb
                                </p>
                              </div>
                            );
                            break;
                          case "ActionPrescription":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  navigateTo();
                                  // event.stopPropagation();
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={view}></img>
                                  123 kb
                                </p>
                              </div>
                            );
                            break;
                          case "Report Name":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={pdficon}></img>
                                  {item[key]}
                                </p>
                              </div>
                            );
                            break;

                          case "ActionMCHferredPat":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  navigate("/home/doctorsmchtabs");
                                }}
                              >
                                <p className="view-all px-2 py-1">
                                  view
                                  <img src={whitEye}></img>
                                </p>
                              </div>
                            );
                            break;
                          case "PrescriptionMCH":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={pdficon}></img>
                                  Prescription
                                </p>
                              </div>
                            );
                            break;
                          case "ActionMCHPrescription":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  navigateTo();
                                  // event.stopPropagation();
                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={view}></img>
                                  123 kb
                                </p>
                              </div>
                            );
                            break;
                          case "ActionHealthInfo":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  navigateTo();
                                  // event.stopPropagation();

                                  // navigate("/home/doctorsgeneralitp");
                                }}
                              >
                                <p className="px-2 py-1">
                                  <img className="me-3" src={view}></img>
                                  123 kb
                                </p>
                              </div>
                            );
                            break;
                          case "ActionDoctorMedicalDiag":
                            cellContent = (
                              <div
                                className="d-flex justify-content-center"
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                {/* <div className="px-2 py-1 flex !flex-row"> */}
                                <div onClick={navigateTo()}>
                                  <img className="me-3" src={view}></img>
                                </div>

                                <div onClick={editNavigateTo()}>Edit</div>
                                {/* </div> */}
                              </div>
                            );
                            break;

                          // add more cases as needed
                          default:
                            cellContent = <div>{item[key]}</div>;
                            break;
                        }
                        return <td key={key}>{cellContent}</td>;
                      })}
                  </tr>
                </>
              );
            })}
            {/* {tableData.map((item, i) => {
              return (
                <tr
                  onClick={() => navigate("/home/patienttabs")}
                  style={{ cursor: "pointer" }}
                >
                  <td>{item}</td>
                  <td>{item}</td>
                </tr>
              );
            })} */}
          </tbody>
        </table>
        {/* {doctorList?.data?.doctors?.length === 0 && (
              <div className="warning-text-card">No Records Found!</div>
            )} */}
        <div className="flex justify-content-end table-page">
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {
        <PatientFiterModal
          isHidden={showModal}
          onClose={closeModal}
          selectOptions={selectOptions}
          // setFilterDataObj={setFilterDataObj}
        />
      }
    </div>
  );
};

export default TableComp;
