import React, { useEffect, useState } from "react";
import Layout from "../../../LayoutComp/Layout";
import view from "../../../../Assets/icons/view.svg";
import backBtnIcon from "../../../../Assets/icons/back.svg";
import searchCloseIcon from "../../../../Assets/icons/search-close-icon.png";
import searchIcon from "../../../../Assets/icons/search_icon.svg";

import "./AddPatient.scss";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const AddPatient = ({ sideNavState, setSideNavState }) => {
  const { state } = useLocation();
  console.log("state---->", state);
  const [isRegisterPatient, setIsRegisterPatient] = useState(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  const handleSearchClear = () => {
    setSearchText("");
  };
  useEffect(() => {
    if (state.pathto == "addNewPatient") {
      setIsRegisterPatient(true);
    }
  }, []);
  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      <div id="addpatients">
        {!isRegisterPatient ? (
          <div>
            <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
              <label className="label-input mb-4">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search by name"
                  onChange={handleSearchInput}
                  value={searchText}
                />
                {searchText && (
                  <button
                    className="search-btn-close"
                    onClick={handleSearchClear}
                  >
                    <img src={searchCloseIcon} alt="" width={"24px"} />
                  </button>
                )}
                <button
                  className="search-btn"
                  // onClick={searchHandler}
                >
                  <img src={searchIcon} alt="" />
                  <span>Search</span>
                </button>
              </label>
            </div>
            <div className="healthCamp-table-container" style={{ margin: "0" }}>
              <table className="healthcamp-table">
                {/* <div className='w-1'> */}
                <thead>
                  <tr>
                    <th>Camp ID</th>
                    <th>Champ Name</th>
                    <th>State</th>
                    <th>District</th>
                    <th>City</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3, 4].map((item) => {
                    return (
                      <tr
                        className="cursor-pointer"
                        onClick={() => setIsRegisterPatient(true)}
                      >
                        {" "}
                        <td>HVSM-01</td>
                        <td>Free Health Camp</td>
                        <td>UttaraKhand</td>
                        <td>Deharadun</td>
                        <td>Haridwar</td>
                        <td>7th March 2022</td>
                        <td>12th March 2022</td>
                        <td>10:00AM-5:00PM</td>
                        <td>
                          <span className="me-1">
                            <img src={view} alt="" />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                {/* </div> */}
              </table>
              {/* {patientDetails?.data?.patients.length === 0 && (
          <div className="warning-text-card">No Records Found!</div>
        )} */}
              <div className="flex justify-content-end patient-table-page">
                {/* <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="pt-3 px-3">
              <div
                className="pre-back-btn-row d-flex justify-content-start align-items-center"
                onClick={() => setIsRegisterPatient(false)}
              >
                <img src={backBtnIcon} alt="" />
                <span>Back</span>
              </div>
            </div>
            <div className="register-outer-container">
              <div className="register-patient d-flex flex-column">
                <div className="col-lg-7 col-sm-12 col-md-6  d-flex flex-column ">
                  <p className="mb-2 text-start">
                    Enter the patient's Mobile no
                  </p>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="number"
                      placeholder="Enter mobile number"
                      //   onChange={(e) => setPhone(e.target.value)}
                      value="977272777"
                    />
                  </Form.Group>
                  <p
                    style={{ color: "#191BC3", cursor: "pointer" }}
                    className="text-end mb-2"
                    onClick={() =>
                      navigate("/home/patientcrud", {
                        state: {
                          id: 1,
                          action: "Add",
                          item: {},
                        },
                      })
                    }
                  >
                    Register New Patient
                  </p>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <button className="btn px-5 py-2 btn-reset">Reset</button>
                    </div>
                    <div>
                      <button className="btn px-5 py-2 btn-submit">
                        Submit
                      </button>
                    </div>
                  </div>
                  <p
                    className="text-center mt-2 "
                    style={{ color: "#191BC3", cursor: "pointer" }}
                    onClick={() => navigate("/home/viewAllPatient")}
                  >
                    View All Patients
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default AddPatient;
