import React, { useEffect, useState } from "react";
import "./DataOperator.scss";
import Layout from "../LayoutComp/Layout";
// import AddButton from "../AddButton/AddButton";
import SearchInput from "../SearchInput/SearchInput";
import CustomTable from "../CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { TablePagination, Typography } from "@mui/material";
// import StatusIcon from "../../Assets/icons/Status.svg";
import view from "../../Assets/icons/view.svg";
import edit from "../../Assets/icons/edit.svg";
import { readDataOperator } from "../../Services/Coordinator";
import { useSelector } from "react-redux";

const SingleDataOperatorRow = (props) => {
  const { userProfile } = useSelector((state) => state.user);
  const { data, type } = props;
  const navigate = useNavigate();

  return (
    <tr
      // onClick={() => console.log(" ==========> pressed")}
      style={{ cursor: "pointer" }}
    >
      <td>{data.id}</td>
      <td>
        {/* <span>
          <img className="admin-profile-img" src={Avatar} alt="profile_img" />
        </span> */}
        {data.name}
      </td>
      <td>
        {/* <span>
          <img className="admin-profile-img" src={Avatar} alt="profile_img" />
        </span> */}
        {data.username}
      </td>
      {userProfile?.userGroup !== "mch-admins" && (
        <td>{data?.works_at_name}</td>
      )}
      <td>{data.state}</td>
      <td>{data.district}</td>
      <td>{data.place}</td>
      <td>
        <span
          onClick={() =>
            navigate("/home/data-operator/form", {
              state: {
                action: "view",
                doId: data.id,
              },
            })
          }
          className="me-1"
        >
          <img style={{ cursor: "pointer" }} src={view} alt="" />
        </span>
        <span
          className="ms-1"
          onClick={() =>
            navigate("/home/data-operator/form", {
              state: {
                action: "edit",
                doId: data.id,
              },
            })
          }
        >
          <img style={{ cursor: "pointer" }} src={edit} alt="" />
        </span>
      </td>
    </tr>
  );
};

const DataOperator = (props) => {
  const { sideNavState, setSideNavState } = props;
  const { userProfile } = useSelector((state) => state.user);
  const [searchText, setSearchText] = useState("");
  // const [showModal, setShowModal] = useState(false);
  const [tdata, setTData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [rowsPerPageVal, setRowsPerPageVal] = useState(5);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(null);
  const navigate = useNavigate();

  const DATA_OPERATOR_HEADING = [
    "Data Operator ID",
    "Name",
    "Username",
    "Hospital Name",
    "State",
    "District",
    "Place",
    "Action",
  ];

  const filteredData = DATA_OPERATOR_HEADING?.filter(
    (item) => item !== "Hospital Name"
  );

  const handleSearchClear = () => {
    setSearchText("");
    getDataOperator(offset, "");
  };

  useEffect(() => {
    if (searchText == "") {
      getDataOperator(offset, "");
    }
  }, [searchText]);
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  const searchHandler = async () => {
    getDataOperator(offset, searchText);
    console.log(" ==========> handle search called");
  };

  const handleAddNewDataOperator = () => {
    console.log("pressed add new");
    navigate("/home/data-operator/form", {
      state: {
        action: "add",
      },
    });
    // navigate("/admin/add-new");
  };
  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPageVal * pageIndex;
    getDataOperator(offSet, searchText);
    setOffset(offSet);
  };
  const handleChangeRowsPerPage = (e) => {
    setPageSize(e?.targte?.value);
    setRowsPerPageVal(parseInt(e.target.value, 10));
    getDataOperator(offset, searchText);
  };

  useEffect(() => {
    getDataOperator(0, searchText);
  }, [rowsPerPageVal]);

  const getDataOperator = async (offSet = 0, searchText) => {
    let params = {
      offset: offSet,
      limit: rowsPerPageVal,
    };
    if (searchText) params = { ...params, name: searchText };
    const res = await readDataOperator(params);
    setTData(res.data.body);
    setTotalCount(res?.data?.count);
  };

  
  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      <div
        id="data-operator"
        style={{ padding: 30, border: "1px solid #00000029" }}
      >
        <div className="main-container">
          <div
            style={{ display: "flex", alignItems: "flex-end", width: "100%" }}
          >
            <SearchInput
              searchText={searchText}
              onChange={handleSearchInput}
              onSearchClear={handleSearchClear}
              onSearchClick={searchHandler}
            />

            {/* <div style={{ marginLeft: 30 }}>
              <Typography>Select month of the year</Typography>
              <RenderSelect
                placeholder="Select Month of the year"
                options={[{ label: "Hello", value: "Hello" }]}
              />
            </div> */}
          </div>
          <PrimaryButton
            name="Add New Data Operator"
            onClick={handleAddNewDataOperator}
          />
        </div>
        <div className="patientdo">
          <CustomTable
            Row={SingleDataOperatorRow}
            data={tdata}
            Headings={
              userProfile?.userGroup !== "mch-admins"
                ? DATA_OPERATOR_HEADING
                : filteredData
            }
          />
          {tdata.length === 0 && (
            <div className="warning-text-card">No Records Found!</div>
          )}
          <div className="flex justify-content-end table-page">
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPageVal}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DataOperator;
