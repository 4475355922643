import React, { useEffect } from "react";
import "./RenderSelect.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavItem } from "react-bootstrap";
import { Typography } from "@mui/material";

const QualificationSelect = (props) => {
  const {
    value,
    options,
    name,
    onChange,
    requiredprop,
    placeholder,
    title,
    defaultValue,
    disabled,
  } = props;
  return (
    <>
      {title && (
        <Typography style={{ marginBottom: "0.4rem" }}>
          {title}
          {requiredprop && <span className="required-field">*</span>}
        </Typography>
      )}

      <Select
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        fullWidth
        size="small"
        disabled={disabled}
      >
        <MenuItem value="">Select</MenuItem>
        {options?.map((item) => {
          return (
            <MenuItem key={item.degree} value={item.degree}>
              {item.degree}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default QualificationSelect;
