import React, { useEffect, useState } from "react";
import "./CreateHealthCamp.scss";
import backBtnIcon from "../../Assets/icons/back.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import closeIcon from "../../Assets/icons/close-rounded.svg";
import plusIcon from "../../Assets/icons/plus_icon.svg";
import AssignDoctorModal from "../AssignDoctorModal/AssignDoctorModal";
import { useNavigate } from "react-router";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";

import {
  createService,
  readService,
  updateService,
} from "../../Services/HttpService";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Spinner from "../Spinner/Spinner";
import AssignNurseModal from "../AssignNurseModal/AssignNurseModal";
import {
  getDistrictsByState,
  getPlacesByDistrict,
  getStates,
} from "../../Services/Metadata/Metadata";
import { readMCH } from "../../Services/MCH";
import {
  getHealthCampById,
  readAllBuses,
  readDataOperator,
} from "../../Services/Coordinator";
import {
  ConvertYMD,
  format24HrDateOnly,
  formatTime,
} from "../../Utils/DateUtils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useLocation } from "react-router-dom";
import AssignPharmaModal from "../AssignPharmsModal/AssignPharmaModal";
import AssignPatholModal from "../AssignPatholModal/AssignPatholModal";
import PopupDropdwon from "../CommonPopupDropdown/PopupDropdown";
import { errorToast, successToast } from "../../Utils/toasters";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";

export default function CreateHealthCamp({ sideNavState, setSideNavState }) {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [imsData, setImsData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [showDropdown, setShowDropDown] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [offset, setOffset] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedState, setSelectedState] = useState("DEFAULT");
  const [selectedCity, setSelectedCity] = useState("DEFAULT");
  const [startDate, setStartDate] = useState();
  const [toDate, setToDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [action, setAction] = useState(null);
  const [venue, setVenue] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [selectedNurses, setSelectedNurses] = useState([]);
  const [selectedPharmacist, setSelectedPharmacist] = useState([]);
  const [selectedPathologist, setSelectedPathologist] = useState([]);
  const [confirmatiomMsg, setConfirmationMsg] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [allBuses, setAllBuses] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [districtId, setDestrictId] = useState(null);
  const [districtData, setDistrictData] = useState([]);
  const [placesData, setPlacesData] = useState([]);
  const [dataOperator, setDataOperator] = useState([]);
  const [initialValues, setInitialValues] = useState({
    from_date: "",
    to_date: "",
    state_id: "select",
    district_id: "",
    place_id: null,
    start_time: "",
    end_time: "select",
    doctors_assigned: "",
    dataoperators_assigned: "select",
    bus_reg_no: "select",
    venue: "",
    name: "",
    hospital_id: "select",
    ims_id: ""
  });
  const { userProfile } = useSelector((state) => state.user);
  const userType = useSelector((s) => s.user.userProfile.userGroup);
  const isMchAdmin = userType === "mch-admins";

  // console.log("selected doctor is===================", userProfile?.id)
  const location = useLocation();
  const { hospitalId, actions, healthCampId } = location.state;

  // useEffect(() => {
  //   setSelectedId(userProfile?.id);
  // }, []);

  const closeModal = () => {
    setShowDropDown(!showDropdown);
  };

  const closeModalFilter = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    getImsHospitals();
  }, []);

  const getImsHospitals = async () => {
    const params = {
      limit: pageSize,
      offset: offset,
    };
    const result = await readService("imshospitals", params);
    setImsData(result.data.body);
    setTotalCount(result.data.count);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
    navigateBack();
  };

  const navigateBack = () => {
    navigate("/home/healthcamp");
  };

  useEffect(() => {
    if (actions === "Update") {
      const getHealthCampId = async () => {
        const params = {
          id: healthCampId,
        };
        try {
          const response = await getHealthCampById(params);
          // setHealthCampDetails(response.data.body);
          let data = response.data.body;
          const dummy_doctors = data?.[0]?.doctor_assignments?.map((item) => ({
            id: item?.doctor_id,
            name: item?.doctor_name,
          }));
          setSelectedDoctor(dummy_doctors);

          const dummy_nurses = data?.[0]?.nurse_assignments?.map((item) => ({
            id: item?.nurse_id,
            name: item?.nurse_name,
          }));
          setSelectedNurses(dummy_nurses);

          const dummy_pathologist = data?.[0]?.pathologist_assignments?.map(
            (item) => ({
              id: item?.pathologist_id,
              name: item?.pathologist_name,
            })
          );
          setSelectedPathologist(dummy_pathologist);

          const dummy_pharmacist = data?.[0]?.pharmacist_assignments?.map(
            (item) => ({
              id: item?.pharmacist_id,
              name: item?.pharmacist_name,
            })
          );
          setSelectedPharmacist(dummy_pharmacist);

          setInitialValues({
            place_id: data[0]?.place_id,
            dataoperators_assigned:
              data?.[0]?.dataoperator_assignments[0]?.dataoperator_id,
            bus_reg_no: data[0]?.bus_reg_no,
            venue: data[0]?.venue,
            name: data[0]?.name,
            hospital_id: data[0]?.hospital_id,
          });
          setStateId(data[0]?.state_id);
          setDestrictId(data[0]?.district_id);
          setStartDate(new Date(data[0]?.from_date));
          setToDate(new Date(data[0]?.to_date));
          let strtTime = data[0]?.start_time?.split(":");
          let endTime = data[0]?.end_time?.split(":");
          const defaultStartTime = new Date();
          const defaultEndTime = new Date();
          defaultStartTime.setHours(strtTime[0]);
          defaultStartTime.setMinutes(strtTime[1]);
          defaultEndTime.setHours(endTime[0]);
          defaultEndTime.setMinutes(endTime[1]);
          setStartTime(defaultStartTime);
          setStartTime(defaultStartTime);
          setEndTime(defaultEndTime);

          const pharmaArr = [];
          data[0]?.pharmacist_id?.forEach((id, index) => {
            pharmaArr.push({ id: id, name: data[0].pharmacist_names[index] });
          });

          const patholArr = [];
          data[0]?.pathologist_id?.forEach((id, index) => {
            patholArr.push({ id: id, name: data[0].pathologist_names[index] });
          });

          const doctorArr = [];
          data[0]?.doctor_id?.forEach((id, index) => {
            doctorArr.push({ id: id, name: data[0].doctor_names[index] });
          });

          const nurseArr = [];
          data[0]?.nurse_id?.forEach((id, index) => {
            nurseArr.push({ id: id, name: data[0].nurse_names[index] });
          });
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };
      getHealthCampId();
    }
  }, [actions]);

  useEffect(() => {
    const getAllDataOperator = async () => {
      try {
        const response = await readDataOperator();
        setDataOperator(response.data.body);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    const getAllBuses = async () => {
      try {
        const response = await readAllBuses();
        setAllBuses(response.data.body);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    getAllDataOperator();
    getAllBuses();
  }, []);

  const getDistrict = async (value) => {
    setStateId(value);
    let district = await getDistrictsByState(value);
    setDistrictData(district.data);
  };

  useEffect(() => {
    getDistrict(stateId);
  }, [stateId]);

  const getPlaces = async (value) => {
    setDestrictId(value);
    let places = await getPlacesByDistrict(value);
    setPlacesData(places.data);
  };

  useEffect(() => {
    getPlaces(districtId);
  }, [districtId]);

  useEffect(() => {
    async function getData() {
      let state = await getStates();
      setStateData(state?.data);
    }
    getData();
  }, []);

  const handleToDate = (date) => {
    setToDate(date);
  };
  const handleFromDate = (date) => {
    setStartDate(date);
    setToDate((prevEndDate) =>
      prevEndDate && date > prevEndDate ? date : prevEndDate
    );
  };

  const unSelectDoctorFromList = (item) => {
    let tempDoctorList = [...selectedDoctor];
    let index = tempDoctorList.findIndex((ele) => ele.id === item.id);
    if (index !== -1) {
      tempDoctorList.splice(index, 1);
      setSelectedDoctor(tempDoctorList);
    }
  };
  const unSelectPharmaList = (item) => {
    let tempPharmaList = [...selectedPharmacist];
    let index = tempPharmaList.findIndex((ele) => ele.id === item.id);
    if (index !== -1) {
      tempPharmaList.splice(index, 1);
      setSelectedPharmacist(tempPharmaList);
    }
  };
  const unSelectPatholList = (item) => {
    let tempPatholList = [...selectedPathologist];
    let index = tempPatholList.findIndex((ele) => ele.id === item.id);
    if (index !== -1) {
      tempPatholList.splice(index, 1);
      setSelectedPathologist(tempPatholList);
    }
  };
  const unSelectNurseFromList = (item) => {
    let tempNurseList = [...selectedNurses];
    let index = tempNurseList.findIndex((ele) => ele.id === item.id);
    if (index !== -1) {
      tempNurseList.splice(index, 1);
      setSelectedNurses(tempNurseList);
    }
  };

  const resetCreateNewFrom = () => {
    setSelectedCity("DEFAULT");
    setSelectedState("DEFAULT");
    setVenue("");
    setSelectedDoctor([]);
    setInitialValues({
      from_date: "",
      to_date: "",
      state_id: "",
      district_id: "",
      place_id: "",
      start_time: "",
      end_time: "select",
      doctors_assigned: "",
      dataoperators_assigned: "select",
      bus_reg_no: "select",
      venue: "",
      name: "",
      hospital_id: "select",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // if (hospitalId && stateId && districtId && date
    //   && selectedDoctor.length > 0) {

    setShowSpinner(true);
    let doctrArr =
      selectedDoctor && selectedDoctor.map((item) => Number(item.id));
    let nurseArr =
      selectedNurses && selectedNurses.map((item) => Number(item.id));
    let pharamArr =
      selectedPharmacist && selectedPharmacist.map((item) => Number(item.id));
    let patholArr =
      selectedPathologist && selectedPathologist.map((item) => Number(item.id));

    formData.append("doctors_assigned", doctrArr);
    formData.append("nurses_assigned", nurseArr);
    formData.append("pharmacists_assigned", pharamArr);
    formData.append("pathologists_assigned", patholArr);
    formData.append("from_date", ConvertYMD(startDate));
    formData.append("to_date", ConvertYMD(toDate));
    formData.append("start_time", formatTime(startTime));
    formData.append("end_time", formatTime(endTime));
    formData.append("hospital_id", userProfile?.works_at);
    // formData.append("ims_id", selectedId);
    if (actions !== "Update") {
      try {
        let response = await createService("/healthCamps", formData);

        if (response.status < 200 || response.status > 300) {
          setShowSpinner(false);
          errorToast(enqueueSnackbar);
        } else {
          // navigate("/home/healthcamp");
          successToast(enqueueSnackbar, "Created Successfully!!");
          setPage(0);
          setAction(null);
          setShowSpinner(false);
          navigate(-1);
        }
      } catch (e) {
        setShowSpinner(false);
      }
    } else if (hospitalId && stateId && districtId) {
      try {
        let response = await updateService(
          `/healthCamps/${healthCampId}`,
          {},
          formData
        );

        if (response?.status < 200 || response?.status > 300) {
          errorToast(enqueueSnackbar, response.data);
        } else {
          successToast(enqueueSnackbar);
          setPage(0);
          setAction(null);
          setShowSpinner(false);
          navigate(-1);
        }
      } catch (e) {
        setShowSpinner(false);
      }
    }
    // navigate(-1);
  };

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInitialValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleStartTime = (time) => {
    setStartTime(time);
  };
  const handleEndTime = (time) => {
    setEndTime(time);
  };

  const handleDropDownShow = () => {
    if (userProfile?.userGroup === "ims-admins") {
      setShowDropDown(true);
    }
  };

  //  const handleDisablebtn=()=>{
  //   if(!stateId || !districtId || initialValues.place_id)
  //  }
  // console.log(
  //   "stateId",
  //   stateId,
  //   districtId,
  //   initialValues.place_id,
  //   date,
  //   toDate,
  //   startTime,
  //   endTime,
  //   initialValues.dataoperators_assigned,
  //   initialValues.name,
  //   initialValues.venue,
  //   selectedId,
  //   selectedDoctor[0],
  //   selectedNurses[0],
  //   selectedPharmacist[0],
  //   selectedPathologist[0]
  // );

  const handleDisablebtn = () => {
    if (
      !stateId ||
      !districtId ||
      !initialValues.place_id ||
      !startDate ||
      !toDate ||
      !startTime ||
      !endTime ||
      !initialValues.dataoperators_assigned ||
      !initialValues.name ||
      !initialValues.venue ||
      !selectedId ||
      !selectedDoctor?.length > 0 ||
      !selectedNurses?.length > 0 ||
      !selectedPharmacist?.length > 0 ||
      !selectedPathologist?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div
      id="create-health-camp"
      style={{
        width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
        marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
        transition: "0.5s",
        position: "absolute",
      }}
    >
      {/* <div className="pt-3 px-3" style={{ backgroundColor: "red" }}>
        <span className="back-btn-row" onClick={navigateBack}>
          <img src={backBtnIcon} alt="" />
          <span style={{ color: "#000" }}>Back</span>
        </span>
      </div> */}
      <div className=" px-1" style={{ cursor: "pointer" }}>
        <span className="back-btn" onClick={() => navigate(-1)}>
          <img src={backBtnIcon} alt="" />

          <span>Back</span>
        </span>
      </div>
      <form noValidate onSubmit={handleSubmit}>
        <div className="chc-container">
          <p className="title-text">
            {healthCampId ? "Edit Health Camp" : "Create New Health Camp"}
          </p>
          <div className="row">
            <div className="col-4">
              <p style={{ fontWeight: "bold" }}>
                Select State <span className="required-field">*</span>
              </p>
              <select
                className="select-input"
                placeholder="Select state"
                value={stateId}
                name="state_id"
                defaultValue={"default"}
                onChange={(e) => getDistrict(e.target.value)}
              >
                <option disabled selected="selected" value={"default"}>
                  -- Select State --
                </option>
                {stateData?.length > 0 &&
                  stateData?.map((state, index) => (
                    <option value={state.id} key={index}>
                      {state.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-4">
              <p style={{ fontWeight: "bold" }}>
                Select District <span className="required-field">*</span>
              </p>
              <select
                className="select-input"
                placeholder="Select District"
                defaultValue={"default"}
                value={districtId}
                name="district_id"
                onChange={(e) => getPlaces(e.target.value)}
              >
                <option disabled selected="selected" value="default">
                  -- Select District --
                </option>
                {districtData?.length > 0 &&
                  districtData?.map((district, index) => (
                    <option value={district.id} key={index}>
                      {district.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="col-4">
              <p style={{ fontWeight: "bold" }}>
                Select City <span className="required-field">*</span>
              </p>
              <select
                className="select-input"
                placeholder="Select City"
                defaultValue={"DEFAULT"}
                name="place_id"
                onChange={onChangeHandler}
                value={initialValues.place_id}
              >
                <option disabled selected="selected" value="">
                  -- Select City --
                </option>
                {placesData?.length > 0 &&
                  placesData.map((place, index) => (
                    <option value={place.id} key={index}>
                      {place.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-4">
              <p style={{ fontWeight: "bold" }}>
                Start Date <span className="required-field">*</span>
              </p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  wrapperClassName="datepicker"
                  value={startDate}
                  onChange={handleFromDate}
                />
              </LocalizationProvider>
            </div>
            <div className="col-4">
              <p style={{ fontWeight: "bold" }}>
                End Date <span className="required-field">*</span>
              </p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  wrapperClassName="datepicker"
                  value={toDate}
                  onChange={handleToDate}
                  minDate={startDate}
                />
              </LocalizationProvider>
            </div>
            <div className="col-4">
              <p style={{ fontWeight: "bold" }}>
                Start Time <span className="required-field">*</span>
              </p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileTimePicker
                  value={startTime}
                  onChange={handleStartTime}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-4">
              <p style={{ fontWeight: "bold" }}>
                End Time <span className="required-field">*</span>
              </p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileTimePicker value={endTime} onChange={handleEndTime} />
              </LocalizationProvider>
            </div>
            {/* <div className="col-4">
              <p style={{ fontWeight: "bold" }}>Select MCH</p>
              <select
                className="select-input"
                placeholder="MCH Name Here"
                defaultValue={"default"}
                onChange={onChangeHandler}
                name="hospital_id"
                value={initialValues.hospital_id}
              >
                <option disabled selected="selected" value="select">-- Select MCH --</option>
                {hospitals?.length > 0 && hospitals?.map((hospital, index) => (
                  <option value={hospital.id} key={hospital.id} >{hospital.name}</option>
                ))}
              </select>
            </div> */}
            <div className="col-4">
              <p style={{ fontWeight: "bold" }}>
                Select Data Operator <span className="required-field">*</span>
              </p>
              <select
                className="select-input"
                placeholder="Select City"
                // defaultValue={"default"}
                onChange={onChangeHandler}
                value={initialValues.dataoperators_assigned}
                name="dataoperators_assigned"
              >
                <option disabled selected="selected" value="select">
                  -- Select Data Operator --
                </option>
                {dataOperator?.length > 0 &&
                  dataOperator?.map((operator, index) => (
                    <option value={operator.id} key={operator.id}>
                      {operator.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-4">
              <p style={{ fontWeight: "bold" }}>
                Title of The Health Camp{" "}
                <span className="required-field">*</span>
              </p>
              <input
                className="select-input"
                placeholder="Enter title"
                value={initialValues.name}
                onChange={onChangeHandler}
                name="name"
              />
            </div>
          </div>
          <div className="row my-4">
            <div className="col-4">
              <p style={{ fontWeight: "bold" }}>
                Address <span className="required-field">*</span>
              </p>
              <input
                className="select-input"
                placeholder="Enter Address"
                value={initialValues.venue}
                onChange={onChangeHandler}
                name="venue"
              />
            </div>
            <div className="col-4">
              <p style={{ fontWeight: "bold" }}>Select Bus</p>
              <select
                className="select-input"
                placeholder="Select City"
                onChange={onChangeHandler}
                value={initialValues.bus_reg_no}
                name="bus_reg_no"
              >
                <option disabled selected="selected" value="select">
                  -- Select Bus --
                </option>
                {allBuses?.length > 0 &&
                  allBuses?.map((bus, index) => (
                    <option value={bus.reg_no} key={bus.id}>
                      {bus.reg_no}
                    </option>
                  ))}
              </select>
            </div>
            {!isMchAdmin ? (
              <>
                <div className="col-4" onClick={handleDropDownShow}>
                  <p style={{ fontWeight: "bold" }}>Select MCH</p>
                  <select
                    className="select-input"
                    placeholder="MCH Name Here"
                    defaultValue={"default"}
                    onChange={onChangeHandler}
                    name="ims_id"
                    value={selectedId}
                  >
                    <option disabled selected="selected" value="select">
                      -- Select MCH --
                    </option>
                    {imsData?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            ) : null}
          </div>
          <div className="row my-4">
            <div className="col-5 assign-doctor">
              <div className="d-flex justify-content-between">
                <p style={{ fontWeight: "bold" }}>
                  Assign Doctor <span className="required-field">*</span>
                </p>
                <div>
                  <span
                    onClick={() => {
                      setShowModal(true);
                      setAction("doctor-modal");
                    }}
                  >
                    <img src={plusIcon} alt="" />
                    <span className="text-assign">Assign Doctor</span>
                  </span>
                </div>
              </div>
              <div className="chips-card-doctor">
                {selectedDoctor?.length ? (
                  <p
                    style={{ fontWeight: "bold" }}
                    className="selected-dct-text"
                  >
                    Selected Doctor
                  </p>
                ) : undefined}
                <div className="chips-list-card">
                  {selectedDoctor &&
                    selectedDoctor.map((items) => {
                      return (
                        <span className="chips">
                          <span>{items.name}</span>
                          <img
                            src={closeIcon}
                            alt=""
                            className="cursor-pointer close-img"
                            width={"20px"}
                            onClick={() => unSelectDoctorFromList(items)}
                          />
                        </span>
                      );
                    })}
                  {selectedDoctor?.length === 0 && (
                    <div
                      className="text-center"
                      style={{
                        marginTop: selectedDoctor?.length ? undefined : "20px",
                      }}
                    >
                      No Doctors Selected!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-5 assign-nurse">
              <div className="d-flex justify-content-between">
                <p style={{ fontWeight: "bold" }}>
                  Assign Nurse <span className="required-field">*</span>
                </p>
                <div>
                  <span
                    onClick={() => {
                      setShowModal(true);
                      setAction("nurse-modal");
                    }}
                  >
                    <img src={plusIcon} alt="" />
                    <span className="text-assign">Assign Nurse</span>
                  </span>
                </div>
              </div>
              <div className="chips-card-nurse ">
                {selectedNurses?.length ? (
                  <p className="selected-dct-text">Selected Nurse</p>
                ) : undefined}
                <div className="chips-list-card">
                  {selectedNurses &&
                    selectedNurses.map((items) => {
                      return (
                        <span className="chips">
                          <span>{items.name}</span>
                          <img
                            src={closeIcon}
                            alt=""
                            className="cursor-pointer close-img"
                            width={"20px"}
                            onClick={() => unSelectNurseFromList(items)}
                          />
                        </span>
                      );
                    })}
                  {selectedNurses?.length === 0 && (
                    <div
                      className="text-center"
                      style={{
                        marginTop: selectedNurses?.length ? undefined : "20px",
                      }}
                    >
                      No Nurses Selected!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-5 assign-doctor">
              <div className="d-flex justify-content-between">
                <p style={{ fontWeight: "bold" }}>
                  Assign Pharmacist <span className="required-field">*</span>
                </p>
                <div>
                  <span
                    onClick={() => {
                      setShowModal(true);
                      setAction("pharma-modal");
                    }}
                  >
                    <img src={plusIcon} alt="" />
                    <span className="text-assign">Assign Pharmacist</span>
                  </span>
                </div>
              </div>
              <div className="chips-card-doctor">
                {selectedPharmacist?.length ? (
                  <p
                    style={{ fontWeight: "bold" }}
                    className="selected-dct-text"
                  >
                    Selected Pharmacist
                  </p>
                ) : undefined}
                <div className="chips-list-card">
                  {selectedPharmacist &&
                    selectedPharmacist.map((items) => {
                      return (
                        <span className="chips">
                          <span>{items.name}</span>
                          <img
                            src={closeIcon}
                            alt=""
                            className="cursor-pointer close-img"
                            width={"20px"}
                            onClick={() => unSelectPharmaList(items)}
                          />
                        </span>
                      );
                    })}
                  {selectedPharmacist?.length === 0 && (
                    <div
                      className="text-center"
                      style={{
                        marginTop: selectedPharmacist?.length
                          ? undefined
                          : "20px",
                      }}
                    >
                      No Pharmacist Selected!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-5 assign-nurse">
              <div className="d-flex justify-content-between">
                <p style={{ fontWeight: "bold" }}>
                  Assign Pathologist <span className="required-field">*</span>
                </p>
                <div>
                  <span
                    onClick={() => {
                      setShowModal(true);
                      setAction("pathol-modal");
                    }}
                  >
                    <img src={plusIcon} alt="" />
                    <span className="text-assign">Assign Pathologist</span>
                  </span>
                </div>
              </div>
              <div className="chips-card-nurse ">
                {selectedPathologist?.length ? (
                  <p className="selected-dct-text">Selected Pathologist</p>
                ) : undefined}
                <div className="chips-list-card">
                  {selectedPathologist &&
                    selectedPathologist.map((items) => {
                      return (
                        <span className="chips">
                          <span>{items.name}</span>
                          <img
                            src={closeIcon}
                            alt=""
                            className="cursor-pointer close-img"
                            width={"20px"}
                            onClick={() => unSelectPatholList(items)}
                          />
                        </span>
                      );
                    })}
                  {selectedPathologist?.length === 0 && (
                    <div
                      className="text-center"
                      style={{
                        marginTop: selectedPathologist?.length
                          ? undefined
                          : "20px",
                      }}
                    >
                      No Pathologist Selected!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="px-3">
              {actions && actions === "Create" && (
                <button
                  type="button"
                  className="btn-reset"
                  onClick={resetCreateNewFrom}
                >
                  Reset
                </button>
              )}
              <button
                type="submit"
                className="btn-create mx-4"
                disabled={action == "Create" && handleDisablebtn()}
              >
                {actions}
              </button>
            </div>
          </div>
          {action == "Create" && (
            <p className="text-danger note-text">
              <span>
                <LabelImportantIcon />
              </span>
              : Kindly complete all the required fields to submit the form.
            </p>
          )}
        </div>
      </form>
      {showDropdown && (
        <PopupDropdwon
          isHidden={showDropdown}
          onClose={closeModal}
          data={imsData}
          setSelectedId={setSelectedId}
          totalCount={totalCount}
          setImsData={setImsData}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          offset={offset}
          setOffset={setOffset}
          setTotalCount={setTotalCount}
        />
      )}
      {action && action === "doctor-modal" && (
        <AssignDoctorModal
          isHidden={showModal}
          onClose={closeModalFilter}
          setSelectedDoctor={setSelectedDoctor}
          selectedDoctor={selectedDoctor}
        />
      )}
      {action && action === "nurse-modal" && (
        <AssignNurseModal
          isHidden={showModal}
          onClose={closeModalFilter}
          setSelectedNurses={setSelectedNurses}
          selectedNurses={selectedNurses}
        />
      )}
      {action && action === "pharma-modal" && (
        <AssignPharmaModal
          isHidden={showModal}
          onClose={closeModalFilter}
          setSelectedPharmacist={setSelectedPharmacist}
          selectedPharmacist={selectedPharmacist}
        />
      )}
      {action && action === "pathol-modal" && (
        <AssignPatholModal
          isHidden={showModal}
          onClose={closeModalFilter}
          setSelectedPathologist={setSelectedPathologist}
          selectedPathologist={selectedPathologist}
        />
      )}
      {
        <ConfirmationModal
          isHidden={showConfirmationModal}
          onClose={closeConfirmationModal}
          message={confirmatiomMsg}
        />
      }
      {showSpinner && <Spinner />}
    </div>
  );
}
