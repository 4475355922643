import React, { useState } from "react";
import "../../../Doctorsvtwo/PatientTablePage/HealthCampPatient/Tabs/Components/MedicalDiagnosisTabComponents/ReferredToMch";
// import PrimaryButton from "../../../../../../PrimaryButton/PrimaryButton";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import TopBar from "../../../Doctorsvtwo/PatientTablePage/HealthCampPatient/Tabs/Components/MedicalDiagnosisTabComponents/TopBar";
import { readService } from "../../../../Services/HttpService";

const MedicallAdvice = (props) => {
  const {
    open,
    onTopBarClick,
  
    doctorAdvice,
    activeTab,
    setActiveTab,
    medicalAdviceData,
    type,
  } = props;
  const viewType = type === "View";

  const { enqueueSnackbar } = useSnackbar();

  const [genderDrop, setGenderDrop] = useState([]);
  const  [hospitals,setHospitals]=useState([])
  const [medicalAdvice, setMedicalAdvice] = useState({
    advice: "Go to hospital",
    is_hospital_referred: "",
    hospital_id: "100001",
    refer_notes: "",
    prescription: "",
  });

  const Options = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];

  useEffect(() => {
    setMedicalAdvice(doctorAdvice);
  }, [doctorAdvice]);

  useEffect(() => {
    getMch();
  }, []);

  const getMch = async () => {
    const result = await readService("hospitals");
    setHospitals(result.data.body)

    console.log(" ==========>all hospitals", result);
  };

//   const getGenderData = async () => {
//     const result = await getGenders();
//     setGenderDrop(result.data);
//   };
  const setFieldValueHandler = (e) => {
    const { name, value } = e.target;

    setMedicalAdvice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

//   const handleSubmit = async () => {
//     if (!viewType) {
//       try {
//         const result = await updateService(
//           `patient/diagnosis/${id}/${visitId}`,
//           {},
//           {
//             doctor_advice: JSON.stringify(medicalAdvice),
//           }
//         );
//         successToast(enqueueSnackbar);
//         setActiveTab(activeTab + 1);
//       } catch (error) {
//         errorToast(enqueueSnackbar);
//       }
//     }
//   };

  return (
    <div id="referred-to-mch-tab">
      <div className="tabs-main-container">
        <div className="mb-3">
          <TopBar
            open={open}
            content="Medical Advice"
            onClick={onTopBarClick}
          />
        </div>
        {open ? (
          <div>
            <div className="row">
              <div
                className="col-lg-4 col-md-6 col-sm-12 mb-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="refer-to-mch-label">
                    Refer to MCH
                  </Form.Label>
                  <Form.Select
                    aria-label="select"
                    onChange={setFieldValueHandler}
                    name="is_hospital_referred"
                    value={medicalAdviceData.is_hospital_referred}
                    disabled={viewType}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <option>Refer To MCH</option>
                    {Options.length > 0 &&
                      Options?.map((item, index) => (
                        <option value={item.value} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label
                    className="refer-to-mch-label"
                    style={{ marginTop: 10 }}
                  >
                    MCH Name
                  </Form.Label>
                  {/* <div >Refer to MCH</div> */}
                  <Form.Select
                    aria-label="select"
                    onChange={(e) => console.log(" ==========> e", e)}
                    name="gender"
                    value={medicalAdviceData?.hospital_id}
                    defaultValue={medicalAdviceData?.hospital_id}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <option>Select MCH</option>
                    {hospitals.length > 0 &&
                      hospitals?.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12 mb-3">
                <Form.Group controlId="formBasicName">
                  <Form.Label className="refer-to-mch-label">
                    Reference Notes
                  </Form.Label>
                  <Form.Control
                    type=""
                    as="textarea"
                    rows={5}
                    placeholder="Reference Note"
                    name="refer_notes"
                    value={medicalAdviceData.refer_notes}
                    onChange={setFieldValueHandler}
                    disabled={viewType}
                    style={{ backgroundColor: "#fff" }}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                <Form.Group controlId="formBasicName">
                  <Form.Label className="refer-to-mch-label">
                    Prescription
                  </Form.Label>
                  <Form.Control
                    type=""
                    as="textarea"
                    rows={10}
                    placeholder="Prescription"
                    name="prescription"
                    value={medicalAdviceData.prescription}
                    onChange={setFieldValueHandler}
                    disabled={viewType}
                    style={{ backgroundColor: "#fff" }}
                  />
                </Form.Group>
              </div>
            </div>
            {/* {!viewType ? (
              <div className=" flex flex-row text-end mb-3">
                <PrimaryButton
                  name="Save"
                  // img={<img src={UploadIcon} />}
                  className="py-2 px-3"
                  style={{ color: "green" }}
                  onClick={handleSubmit}
                />
              </div>
            ) : null} */}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MedicallAdvice;
