import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import "../../../../../../Components/Doctorsvtwo/PatientTablePage/HealthCampPatient/Tabs/HealthInfo.scss";
import TableComp from "../../../../NurseTableComp/TableComp";
import PrimaryButton from "../../../../../PrimaryButton/PrimaryButton";
import AddIncon from "../../../../../../Assets/icons/addIcon.svg";
import backBtnIcon from "../../../../../../Assets/icons/back.svg";
import DeleteIcon from "../../../../../../Assets/icons/delete-icon.svg";
import edit from "../../../../../../Assets/icons/edit.svg";
import { useNavigate } from "react-router";
import "./nghealth.scss";
import moment from "moment";
import HealthInfoModal from "../../../../../HealthInfoModal/HealthInfoModal";
import {
  getHealthBasicInfoData,
  addPatientBasicHealthInfo,
  createBasicHealthData,
  getAllPatientVisits,
  getPatientsVisitsById,
  updatePatientBasicHealthInfo,
} from "../../../../../../Services/PatientBasicHealthInfo";
import ConfirmationModal from "../../../../../ConfirmationModal/ConfirmationModal";
import {
  ConvertYMD,
  format24HrDateOnly,
  formatDateToHHMM,
} from "../../../../../../Utils/DateUtils";
import { errorToast } from "../../../../../../Utils/toasters";
// import { enqueueSnackbar } from "notistack";
import { successToast } from "../../../../../../Utils/toasters";
import { useSnackbar } from "notistack";

const NHealthInfo = (props) => {
  const { patientInfo } = props;
  const [action, setAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [confirmatiomMsg, setConfirmationMsg] = useState("");
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [fieldData, setFieldData] = useState([]);
  const [chronicData, setChronicData] = useState([]);
  const [patientVisits, setPatientVisits] = useState([]);
  const [ids, setIds] = useState("");
  const [allergies, setAllergies] = useState("");
  const [immuneDetails, setImmunDetails] = useState("");
  const [surgeries, setSurgeries] = useState("");
  const [visitsDetails, setVisitsDetails] = useState([]);
  const [editItems, setEditItems] = useState({});
  const [familyHistory, setFamilyHistory] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [selectedBloodSample, setSelectedBloodSample] = useState("");
  const [selectedUrineSample, setSelectedUrineSample] = useState("");
  const { enqueueSnackbar } = useSnackbar();
 
  const [fieldValue, setFieldValue] = useState({
    weight: "",
    height: "",
    bp_systolic: "",
    bp_diastolic: "",
    pulse: "",
    respiratory_rate: "",
    grbs: "",
    temperature: "",
    spo2: "",
    notes: "",
    health_issues: "",
    prelim_diagnosis: "",
    nurse_advice: "",
  });
  // rinku

  // console.log("localtion is=====================", fieldValue.grbs)

  useEffect(() => {
    getPatientVisits();
  }, [rowsPerPage, page]);

  const getPatientVisits = async () => {
    const params = {
      limit: rowsPerPage,
      offset: offset,
    };
    try {
      const response = await getAllPatientVisits(patientInfo[0]?.id, params);
      if (response) {
        setTotalRecords(response.data.count);
        setPatientVisits(response.data.body);
      }
    } catch (err) {
      // console.log("error is================", err)
    }
  };


  useEffect(()=>{
    async function getData(){
      const response = await getHealthBasicInfoData(patientInfo[0]?.id);
      console.log("basic health info"+JSON.stringify(response))
      if(response.status === 200){
        let data = response?.data
        setAllergies(data?.allergies)
        setImmunDetails(data?.immunisation_details)
        setSurgeries(data?.surgery_history)
        setFamilyHistory(data?.family_health_history)

        var chronicList = []
        data?.chronicDiseases.map((item,index)=>{
           delete item.patient_id
           item.id = index+1
           chronicList.push(item)
        })
        setChronicData(chronicList)

        var addictionList = []
        data?.healthAddictions.map((item,index)=>{
           delete item.patient_id
           item.id = index+1
           addictionList.push(item)
        })
        setFieldData(addictionList)

      }
    }
    getData()
  },[])


  useEffect(() => {
    if (action === "edit" || action === "view") {
      const getPatientVisitsById = async () => {
        try {
          const response = await getPatientsVisitsById(
            patientInfo[0]?.id,
            editItems?.visit_id
          );
          if (response) {
            setVisitsDetails(response.data);
          }
        } catch (err) {
          // console.log("error is================", err)
        }
      };
      getPatientVisitsById();
    }
  }, [action]);

  useEffect(() => {
    if (action === "edit" || action === "view") {
      setFieldValue({
        ...visitsDetails,
        weight: visitsDetails[0]?.weight,
        height: visitsDetails[0]?.height,
        bp_systolic: visitsDetails[0]?.bp_systolic,
        bp_diastolic: visitsDetails[0]?.bp_diastolic,
        pulse: visitsDetails[0]?.pulse,
        respiratory_rate: visitsDetails[0]?.respiratory_rate,
        grbs: visitsDetails[0]?.grbs,
        temperature: visitsDetails[0]?.temperature,
        spo2: visitsDetails[0]?.spo2,
        notes: visitsDetails[0]?.notes,
        health_issues: visitsDetails[0]?.health_issues,
        prelim_diagnosis: visitsDetails[0]?.prelim_diagnosis,
        nurse_advice: visitsDetails[0]?.nurse_advice,
      });
      setSelectedBloodSample(
        visitsDetails[0]?.blood_sample === true ? "yes" : "no"
      );
      setSelectedUrineSample(
        visitsDetails[0]?.urine_sample === true ? "yes" : "no"
      );
    }
  }, [action]);

  // console.log("patient info is===============", totalRecords)

  const navigateTo = () => {
    setAction("view");
  };
  const editNavigateTo = () => {
    setAction("edit");
  };
  const addHealthInfoHandler = (actionVal) => {
    setAction(actionVal);
    setFieldValue({
      weight: "",
      height: "",
      bp_systolic: "",
      bp_diastolic: "",
      pulse: "",
      respiratory_rate: "",
      grbs: "",
      temperature: "",
      spo2: "",
      notes: "",
      health_issues: "",
      prelim_diagnosis: "",
      nurse_advice: "",
    });
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
    navigateBack();
  };

  const navigateBack = () => {
    navigate("/home/nursegeneralitp");
  };

  const closeModal = () => {
    setShowModal(!showModal);
  };

  const handleBloodSample = (e) => {
    setSelectedBloodSample(e.target.value);
  };

  const handleUrineSample = (e) => {
    setSelectedUrineSample(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(" ==========>handle submit ", fieldValue);

    const formData = new URLSearchParams();
    // const formData = new FormData(e.target);
    const formData1 = new FormData();

    Object.keys(fieldValue).map((item, i) => {
      // console.log(" ==========> [item]", item);
      // console.log(" ==========> fieldValue[item]", fieldValue[item]);
      formData.append(item, fieldValue[item]);
    });
    formData.delete("blood_sample");
    formData.delete("urine_sample");
    formData.append(
      "blood_sample",
      selectedBloodSample === "yes" ? true : false
    );
    formData.append(
      "urine_sample",
      selectedUrineSample === "yes" ? true : false
    );

    if (action === "Add") {
      try {
        const response = await addPatientBasicHealthInfo(
          patientInfo[0]?.id,
          formData
        );
        if (response.status < 200 || response.status > 300) {
          let msg = response?.data?.errors.map((err) => err.msg);
          errorToast(enqueueSnackbar, msg.join(""));
        } else {
          setConfirmationMsg("Health Info added Successfully!");
          setShowConfirmationModal(true);
          successToast(enqueueSnackbar, "Created Successfully!!");
          setPage(0);
          setAction(null);
          getPatientVisits();
        }
      } catch (err) {
        // console.log("error is================", err)
      }
    } else {
      try {
        const response = await updatePatientBasicHealthInfo(
          patientInfo[0]?.id,
          formData
        );
        if (response.status < 200 || response.status > 300) {
          errorToast(enqueueSnackbar, response.data);
        } else {
          successToast(enqueueSnackbar);
          setPage(0);
          setAction(null);
          getPatientVisits();
        }
      } catch (err) {
        // console.log("error is================", err)
      }
    }
  };

  const handleClick = async () => {
    const filteredData = []
    // const filteredData = chronicData?.map(({ name, remarks, start_year }) => ({
    //   name,
    //   remarks,
    //   start_year: start_year ? ConvertYMD(start_year) : null,
    // }));
    chronicData?.map(item =>{
      let obj = {}
      if(item?.start_year && item?.start_year != null)obj.start_year = ConvertYMD(item.start_year)
      if(item.name && item.name.length > 0 )obj.name = item.name
      if(item.remarks && item.remarks.length > 0)obj.remarks = item.remarks
      filteredData.push(obj)
    })

    const filteredAddiction = []
    fieldData?.map(item =>{
      let obj = {}
      if(item?.start_year && item?.start_year != null)obj.start_year = ConvertYMD(item.start_year)
      if(item?.end_year && item?.end_year != null)obj.end_year = ConvertYMD(item.end_year)
      if(item.addiction && item.addiction.length > 0 )obj.addiction = item.addiction
      if(item.remarks && item.remarks.length > 0)obj.remarks = item.remarks
      filteredAddiction.push(obj)
    })

    // const filteredAddiction = fieldData?.map(
    //   ({ addiction, remarks, start_year, end_year }) => ({
    //     addiction,
    //     remarks,
    //     start_year: start_year ? ConvertYMD(start_year) : null,
    //     end_year: end_year ? ConvertYMD(end_year) : null,
    //   })
    // );

    let obj = {
      allergies,
      family_health_history: familyHistory,
      immunisation_details: immuneDetails,
      surgery_history: surgeries,
    };

    const requestData = {
      health_basics: JSON.stringify(obj),
      health_chronic: JSON.stringify(filteredData),
      health_addictions: JSON.stringify(filteredAddiction),
    };

    try {
      const response = await createBasicHealthData(
        patientInfo[0]?.id,
        requestData
      );
      if (response.status !== 200) {
        errorToast(enqueueSnackbar, response.data);
      } else {
        successToast(enqueueSnackbar);
        setPage(0);
        setAction(null);
        setAllergies("");
        setImmunDetails("");
        setSurgeries("");
        setFamilyHistory("");
        setChronicData([]);
        setFieldData([]);
      }
    } catch (err) {
      // console.log("error is================", err)
    }
  };

  const handleReset = () => {
    setAllergies("");
    setImmunDetails("");
    setSurgeries("");
    setFamilyHistory("");
    setChronicData([]);
    setFieldData([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFieldValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const thHeadData = ["Sl.No", "Date", "ActionNHealthInfo"];
  const extractedData = patientVisits?.map((obj, i) => {
    let index = i + 1;
    let ActionNHealthInfo = thHeadData[2];
    let { visit_time, visit_id } = obj;
    if (visit_time) {
      visit_time = formatDateToHHMM(visit_time);
    }
    return { index, visit_time, visit_id, ActionNHealthInfo };
  });

  const handleDelete = (id, action) => {
    if (action === "chronic") {
      let filteredData = chronicData?.filter((item) => item.id !== id);
      setChronicData(filteredData);
    } else {
      let filteredData = fieldData?.filter((item) => item.id !== id);
      setFieldData(filteredData);
    }
  };

  const handleBackButton = () => {
    setAction(null);
    setFieldValue({
      weight: "",
      height: "",
      bp_systolic: "",
      bp_diastolic: "",
      pulse: "",
      respiratory_rate: "",
      grbs: "",
      temperature: "",
      spo2: "",
      notes: "",
      health_issues: "",
      prelim_diagnosis: "",
      nurse_advice: "",
    });
  };

  return (
    <div>
      {action == "Add" ? (
        <>
          {" "}
          <form noValidate onSubmit={handleSubmit}>
            <div id="nurseHealthInfo">
              <div className="py-2 px-3" style={{ cursor: "pointer" }}>
                <span className="back-btn-row" onClick={handleBackButton}>
                  <img src={backBtnIcon} alt="" />
                  <span>Back</span>
                </span>
              </div>
              <div className="text-center">
                <button
                  className="btn mb-2"
                  style={{ backgroundColor: "#191BC3", color: "white" }}
                >
                  Vitals
                </button>
                <div className="d-flex row">
                  <div className="col-lg-4 col-md-6 col-sm-12 text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="font-style-bold">
                          Weight
                        </Form.Label>
                        <Form.Label style={{ opacity: "0.5" }}>
                          5-150(kg)
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="number"
                        placeholder="weight"
                        name="weight"
                        onChange={handleChange}
                        value={fieldValue.weight}
                      />
                    </Form.Group>
                  </div>

                  <div className=" col-lg-4 col-md-6 col-sm-12 text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="font-style-bold">
                          Height/Length
                        </Form.Label>
                        <Form.Label style={{ opacity: "0.5" }}>
                          30-100(cm)
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="number"
                        placeholder="height"
                        name="height"
                        onChange={handleChange}
                        value={fieldValue.height}
                      />
                    </Form.Group>
                  </div>

                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="font-style-bold">
                          Bp Systolic
                        </Form.Label>
                        <Form.Label style={{ opacity: "0.5" }}>
                          100-180(mmHg)
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="number"
                        placeholder="by systolic"
                        name="bp_systolic"
                        onChange={handleChange}
                        value={fieldValue.bp_systolic}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="font-style-bold">
                          Bp Diastolic
                        </Form.Label>
                        <Form.Label style={{ opacity: "0.5" }}>
                          70-120 (MMHg)
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="number"
                        placeholder="by diastolic"
                        name="bp_diastolic"
                        onChange={handleChange}
                        value={fieldValue.bp_diastolic}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="font-style-bold">
                          Pulse
                        </Form.Label>
                        <Form.Label style={{ opacity: "0.5" }}>
                          60-180(bpm)
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="number"
                        placeholder="pulse"
                        name="pulse"
                        onChange={handleChange}
                        value={fieldValue.pulse}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="font-style-bold">
                          Respiratory Rate
                        </Form.Label>
                        <Form.Label style={{ opacity: "0.5" }}>
                          10-50
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="number"
                        placeholder="respiratory rate"
                        name="respiratory_rate"
                        onChange={handleChange}
                        value={fieldValue.respiratory_rate}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="font-style-bold">
                          GRBS
                        </Form.Label>
                        <Form.Label style={{ opacity: "0.5" }}>
                          140 or below(mg/dL)
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="number"
                        placeholder="grbs"
                        name="grbs"
                        onChange={handleChange}
                        value={fieldValue.grbs}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="font-style-bold">
                          Temperature
                        </Form.Label>
                        <Form.Label style={{ opacity: "0.5" }}>
                          93-105(F)
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="number"
                        placeholder="temperature"
                        name="temperature"
                        onChange={handleChange}
                        value={fieldValue.temperature}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="font-style-bold">
                          SPo2
                        </Form.Label>
                        <Form.Label style={{ opacity: "0.5" }}>
                          96-99(%)
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="number"
                        placeholder="SPo2"
                        name="spo2"
                        onChange={handleChange}
                        value={fieldValue.spo2}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">BMI</Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="bmi"
                        name=""
                        onChange={handleChange}
                        value={""}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">
                        BMI Status
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="bmi status"
                        onChange={handleChange}
                        value={""}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">
                        Other Notes
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="notes"
                        name="notes"
                        onChange={handleChange}
                        value={fieldValue.notes}
                      />
                    </Form.Group>
                  </div>
                </div>

                <hr class="hr" />
                <button
                  className="btn mb-3"
                  style={{ backgroundColor: "#191BC3", color: "white" }}
                >
                  sample Collections
                </button>
                <div className="d-flex row justify-content-center">
                  <div className=" d-flex flex-column col-lg-6 col-md-6 col-sm-12 text-center">
                    <div className="d-flex justify-content-center ">
                      <p className="me-3">Blood Sample</p>
                      <Form.Check
                        //   disabled
                        inline
                        type={"radio"}
                        label={`yes`}
                        name="blood_sample"
                        id={`disabled-default-radio`}
                        value="yes"
                        checked={selectedBloodSample === "yes"}
                        onChange={handleBloodSample}
                      />
                      <Form.Check
                        //   disabled
                        inline
                        type={"radio"}
                        label={`No`}
                        id={`disabled-default-radio`}
                        value="no"
                        checked={selectedBloodSample === "no"}
                        onChange={handleBloodSample}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <p className="me-3">Urine Sample</p>
                      <Form.Check
                        //   disabled
                        inline
                        type={"radio"}
                        name="urine_sample"
                        label={`yes`}
                        id={`disabled-default-radio`}
                        value="yes"
                        checked={selectedUrineSample === "yes"}
                        onChange={handleUrineSample}
                      />
                      <Form.Check
                        //   disabled
                        inline
                        type={"radio"}
                        label={`No`}
                        id={`disabled-default-radio`}
                        value="no"
                        checked={selectedUrineSample === "no"}
                        onChange={handleUrineSample}
                      />
                    </div>
                  </div>
                </div>
                <hr class="hr" />
                <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                  <Form.Group className="mb-3 " controlId="formBasicEmail">
                    <Form.Label className="font-style-bold">
                      Health Issues and symptoms
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="health issues and symptons"
                      name="health_issues"
                      onChange={handleChange}
                      value={fieldValue.health_issues}
                    />
                  </Form.Group>
                </div>
                <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                  <Form.Group className="mb-3 " controlId="formBasicEmail">
                    <Form.Label className="font-style-bold">
                      Preliminary Diagnosis Assessment
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="preliminary diagnosis"
                      name="prelim_diagnosis"
                      onChange={handleChange}
                      value={fieldValue.prelim_diagnosis}
                    />
                  </Form.Group>
                </div>
                <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                  <Form.Group className="mb-3 " controlId="formBasicEmail">
                    <Form.Label className="font-style-bold">
                      Nurse Advise
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="nurse advise"
                      name="nurse_advice"
                      onChange={handleChange}
                      value={fieldValue.nurse_advice}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex flex-row">
                <button
                  className="reset-btn-nhealth me-3"
                  variant="outline-primary"
                  onClick={handleReset}
                >
                  <b>Reset</b>
                </button>
                <button className="reset-btn-nhealth-save" type="submit">
                  <b>Submit</b>
                </button>
              </div>
            </div>
          </form>
        </>
      ) : action == "view" ? (
        <>
          <div className="py-2 px-3" style={{ cursor: "pointer" }}>
            <span className="back-btn-row" onClick={() => setAction(null)}>
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </span>
          </div>
          <div className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="btn-container">
                <button
                  className="btn mb-2"
                  style={{
                    backgroundColor: "#191BC3",
                    color: "white",
                    width: "200px",
                  }}
                >
                  Vitals
                </button>
              </div>
              <div className="date-container">
                <div
                  style={{
                    border: "1px solid #70707080",
                    padding: "5px 30px",
                    marginLeft: "auto",
                    borderRadius: "10px",
                  }}
                >
                  {moment(visitsDetails[0]?.record_time).format("DD MMMM YYYY")}
                </div>
              </div>
            </div>
            <div className="d-flex row">
              <div
                className="d-flex flex-row justify-content-center"
                style={{}}
              >
                <div div className="grid-container-view-vitals">
                  <p className="font-style">Weight</p>
                  <p>{visitsDetails[0]?.weight} Kg</p>
                  <p className="font-style">BP Diastolic</p>
                  <p>{visitsDetails[0]?.bp_diastolic} mmHg</p>
                  <p className="font-style">GRBS</p>
                  <p>{visitsDetails[0]?.grbs}mg/ dL</p>
                  <p className="font-style">BMI</p>
                  <p>{visitsDetails[0]?.bmi}Kg/m*2</p>
                  <p className="font-style">BP Systolic</p>
                  <p>{visitsDetails[0]?.bp_systolic}mmHg</p>
                  <p className="font-style">Respiration</p>
                  <p>{visitsDetails[0]?.respiratory_rate} /min</p>
                </div>

                <div className="grid-container-view-vitals">
                  <p className="font-style">SpO2</p>
                  <p>{visitsDetails[0]?.spo2}%</p>
                  <p className="font-style">Height/Length</p>
                  <p>{visitsDetails[0]?.height}Cm</p>
                  <p className="font-style">Pulse</p>
                  <p>{visitsDetails[0]?.pulse}/min</p>
                  <p className="font-style">Temperature</p>
                  <p>{visitsDetails[0]?.temperature}</p>
                  <p className="font-style">BMI Status</p>
                  <p>{visitsDetails[0]?.bmi_status}</p>
                </div>
              </div>
            </div>

            <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <Form.Label className="font-style-bold">Other Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Specialization"
                  onChange={handleChange}
                  value={fieldValue.notes}
                />
              </Form.Group>
            </div>

            <hr />
            <button
              className="btn mb-3"
              style={{ backgroundColor: "#191BC3", color: "white" }}
            >
              sample Collections
            </button>
            <div className="d-flex justify-content-center align-items-center">
              <div className="date-container">
                <div
                  style={{
                    border: "1px solid #70707080",
                    padding: "5px 30px",
                    marginLeft: "auto",
                    borderRadius: "10px",
                    visibility: "hidden",
                  }}
                >
                  {moment(visitsDetails[0]?.record_time).format("DD MMMM YYYY")}
                </div>
              </div>
            </div>
            <div className="d-flex row justify-content-center">
              <div className=" d-flex flex-column col-lg-6 col-md-6 col-sm-12 text-center">
                <div className="d-flex justify-content-center ">
                  <p className="me-3">Blood Sample</p>
                  <Form.Check
                    //   disabled
                    inline
                    type={"radio"}
                    label={`yes`}
                    name="blood_sample"
                    id={`disabled-default-radio`}
                    value="yes"
                    checked={selectedBloodSample === "yes"}
                    onChange={handleBloodSample}
                  />
                  <Form.Check
                    //   disabled
                    inline
                    type={"radio"}
                    label={`No`}
                    id={`disabled-default-radio`}
                    value="no"
                    checked={selectedBloodSample === "no"}
                    onChange={handleBloodSample}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <p className="me-3">Urine Sample</p>
                  <Form.Check
                    //   disabled
                    inline
                    type={"radio"}
                    name="urine_sample"
                    label={`yes`}
                    id={`disabled-default-radio`}
                    value="yes"
                    checked={selectedUrineSample === "yes"}
                    onChange={handleUrineSample}
                  />
                  <Form.Check
                    //   disabled
                    inline
                    type={"radio"}
                    label={`No`}
                    id={`disabled-default-radio`}
                    value="no"
                    checked={selectedUrineSample === "no"}
                    onChange={handleUrineSample}
                  />
                </div>
              </div>
            </div>
            <hr class="hr" />
            <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <Form.Label className="font-style-bold">
                  Health Issues and symptoms
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Specialization"
                  onChange={handleChange}
                  name="health_issues"
                  value={fieldValue.health_issues}
                />
              </Form.Group>
            </div>
            <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <Form.Label className="font-style-bold">
                  Preliminary Diagnosis Assessment
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="write Here"
                  name="prelim_diagnosis"
                  onChange={handleChange}
                  value={fieldValue.prelim_diagnosis}
                />
              </Form.Group>
            </div>
            <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <Form.Label className="font-style-bold">
                  Nurse Advise
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="write Here"
                  name="nurse_advice"
                  onChange={handleChange}
                  value={fieldValue.nurse_advice}
                />
              </Form.Group>
            </div>
          </div>
        </>
      ) : action == "edit" ? (
        <form noValidate onSubmit={handleSubmit}>
          <>
            <div className="py-2 px-3" style={{ cursor: "pointer" }}>
              <span className="back-btn-row" onClick={() => setAction(null)}>
                <img src={backBtnIcon} alt="" />
                <span>Back</span>
              </span>
            </div>
            <div className="text-center">
              <div className="d-flex justify-content-center align-items-center">
                <div className="btn-container">
                  <button
                    className="btn mb-2"
                    style={{
                      backgroundColor: "#191BC3",
                      color: "white",
                      width: "200px",
                    }}
                  >
                    Vitals
                  </button>
                </div>
                <div className="date-container">
                  <div
                    style={{
                      border: "1px solid #70707080",
                      padding: "5px 30px",
                      marginLeft: "auto",
                      borderRadius: "10px",
                    }}
                  >
                    {moment(visitsDetails[0]?.record_time).format(
                      "DD MMMM YYYY"
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex row">
                <div
                  className="d-flex flex-row justify-content-center"
                  style={{}}
                >
                  <div div className="grid-container-view-vitals">
                    <p className="font-style">Weight</p>
                    <p>{visitsDetails[0]?.weight} Kg</p>
                    <p className="font-style">BP Diastolic</p>
                    <p>{visitsDetails[0]?.bp_diastolic} mmHg</p>
                    <p className="font-style">GRBS</p>
                    <p>{visitsDetails[0]?.grbs}mg/ dL</p>
                    <p className="font-style">BMI</p>
                    <p>{visitsDetails[0]?.bmi}Kg/m*2</p>
                    <p className="font-style">BP Systolic</p>
                    <p>{visitsDetails[0]?.bp_systolic}mmHg</p>
                    <p className="font-style">Respiration</p>
                    <p>{visitsDetails[0]?.respiratory_rate} /min</p>
                  </div>

                  <div className="grid-container-view-vitals">
                    <p className="font-style">SpO2</p>
                    <p>{visitsDetails[0]?.spo2}%</p>
                    <p className="font-style">Height/Length</p>
                    <p>{visitsDetails[0]?.height}Cm</p>
                    <p className="font-style">Pulse</p>
                    <p>{visitsDetails[0]?.pulse}/min</p>
                    <p className="font-style">Temperature</p>
                    <p>{visitsDetails[0]?.temperature}</p>
                    <p className="font-style">BMI Status</p>
                    <p>{visitsDetails[0]?.bmi_status}</p>
                  </div>
                </div>
              </div>

              <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                <Form.Group className="mb-3 " controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">
                    Other Notes
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Specialization"
                    name="notes"
                    onChange={handleChange}
                    value={fieldValue.notes}
                  />
                </Form.Group>
              </div>

              <hr />
              <button
                className="btn mb-3"
                style={{ backgroundColor: "#191BC3", color: "white" }}
              >
                sample Collections
              </button>
              <div className="d-flex justify-content-center align-items-center">
                <div className="date-container">
                  <div
                    style={{
                      border: "1px solid #70707080",
                      padding: "5px 30px",
                      marginLeft: "auto",
                      borderRadius: "10px",
                      visibility: "hidden",
                    }}
                  >
                    29 Apr 2023
                  </div>
                </div>
              </div>
              <div className="d-flex row justify-content-center">
                <div className=" d-flex flex-column col-lg-6 col-md-6 col-sm-12 text-center">
                  <div className="d-flex justify-content-center ">
                    <p className="me-3">Blood Sample</p>
                    <Form.Check
                      //   disabled
                      inline
                      type={"radio"}
                      label={`yes`}
                      name="blood_sample"
                      id={`disabled-default-radio`}
                      value="yes"
                      checked={selectedBloodSample === "yes"}
                      onChange={handleBloodSample}
                    />
                    <Form.Check
                      //   disabled
                      inline
                      type={"radio"}
                      label={`No`}
                      id={`disabled-default-radio`}
                      value="no"
                      checked={selectedBloodSample === "no"}
                      onChange={handleBloodSample}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <p className="me-3">Urine Sample</p>
                    <Form.Check
                      //   disabled
                      inline
                      type={"radio"}
                      name="urine_sample"
                      label={`yes`}
                      id={`disabled-default-radio`}
                      value="yes"
                      checked={selectedUrineSample === "yes"}
                      onChange={handleUrineSample}
                    />
                    <Form.Check
                      //   disabled
                      inline
                      type={"radio"}
                      label={`No`}
                      id={`disabled-default-radio`}
                      value="no"
                      checked={selectedUrineSample === "no"}
                      onChange={handleUrineSample}
                    />
                  </div>
                </div>
              </div>
              <hr class="hr" />
              <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                <Form.Group className="mb-3 " controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">
                    Health Issues and symptoms
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="health_issues"
                    placeholder="Specialization"
                    onChange={handleChange}
                    value={fieldValue.health_issues}
                  />
                </Form.Group>
              </div>
              <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                <Form.Group className="mb-3 " controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">
                    Preliminary Diagnosis Assessment
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="write Here"
                    name="prelim_diagnosis"
                    onChange={handleChange}
                    value={fieldValue.prelim_diagnosis}
                  />
                </Form.Group>
              </div>
              <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                <Form.Group className="mb-3 " controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">
                    Nurse Advise
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="write Here"
                    name="nurse_advice"
                    onChange={handleChange}
                    value={fieldValue.nurse_advice}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex flex-row">
              <button className="reset-btn-nhealth-save" type="submit">
                <b>Update</b>
              </button>
            </div>
          </>
        </form>
      ) : (
        <div>
          <div className="text-center">
            <button
              className="btn mb-2"
              style={{ backgroundColor: "#191BC3", color: "white" }}
            >
              Basic Health Data
            </button>
            <div className="d-flex row">
              <div className="col-lg-6 col-md-6 col-sm-12 text-start ">
                <Form.Group className="mb-3 " controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">Allergies</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Write Here"
                    name="allergies"
                    onChange={(e) => setAllergies(e.target.value)}
                    value={allergies}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">
                    Immunisation Details
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Write Here"
                    name="immunisation_details"
                    onChange={(e) => setImmunDetails(e.target.value)}
                    value={immuneDetails}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">Surgeries</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Write Here"
                    name="surgery_history"
                    onChange={(e) => setSurgeries(e.target.value)}
                    value={surgeries}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">
                    Family History and Hereditary
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Write Here"
                    name="family_health_history"
                    onChange={(e) => setFamilyHistory(e.target.value)}
                    value={familyHistory}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex row justify-content-between">
              <div className="d-flex row w-50 justify-content-between">
                <div className=" text-color col-lg-6 col-md-6 col-sm-12 text-start fs-5 fw-bold">
                  Addictions
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-end fs-5 fw-bold">
                  <button
                    className="btn mb-2"
                    style={{ backgroundColor: "#191BC3", color: "white" }}
                    onClick={() => {
                      setShowModal(true);
                      setAction("addiction-modal");
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="d-flex row w-50 justify-content-between">
                <div className="text-color col-lg-6 col-md-6 col-sm-12 text-start fs-5 fw-bold">
                  Chronic Diseases
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-end fs-5 fw-bold">
                  <button
                    className="btn mb-2"
                    style={{ backgroundColor: "#191BC3", color: "white" }}
                    onClick={() => {
                      setShowModal(true);
                      setAction("chronic-modal");
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div id="tabelInfo" className="d-flex row">
              <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                <table>
                  <tr>
                    <th>Sl.No</th>
                    <th>Addiction</th>
                    <th>Start Year</th>
                    <th>End Year</th>
                    <th>Remarks</th>
                    <th>Actions</th>
                  </tr>
                  {fieldData && fieldData?.map((items, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{items.addiction}</td>
                          <td>{format24HrDateOnly(items.start_year)}</td>
                          <td>{format24HrDateOnly(items.end_year)}</td>
                          <td>{items.remarks}</td>
                          <td>
                            <span
                              className="me-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowModal(true);
                                setAction("edit-addiction");
                                setIds(items.id);
                              }}
                            >
                              <img className="" src={edit}></img>
                            </span>
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleDelete(items.id, "addiction");
                              }}
                            >
                              <img className="" src={DeleteIcon}></img>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                <table>
                  <tr>
                    <th>Sl.No</th>
                    <th>Issue</th>
                    <th>Start Year</th>
                    <th>Remarks</th>
                    <th>Actions</th>
                  </tr>
                  {chronicData && chronicData?.map((items, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{items.name}</td>
                          <td>{format24HrDateOnly(items.start_year)}</td>
                          <td>{items.remarks}</td>
                          <td>
                            <span
                              className="me-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowModal(true);
                                setAction("edit-chronic");
                                setIds(items.id);
                              }}
                            >
                              <img className="" src={edit}></img>
                            </span>
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleDelete(items.id, "chronic");
                              }}
                            >
                              <img className="" src={DeleteIcon}></img>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start mt-4 gap-2">
            <Button
              style={{ padding: "10px 40px" }}
              variant="outline-primary"
              onClick={handleReset}
            >
              <b>Reset</b>
            </Button>
            <div>
              <Button
                className="loginButton reset-btn"
                variant="primary"
                // onClick={()=>console.log(' ==========>handleClick clicked ', )}
                onClick={handleClick}
              >
                Submit
              </Button>
            </div>
          </div>
          <hr class="hr" />
          <div className=" flex flex-row mb-3 mt-4 text-end">
            <PrimaryButton
              name="Add Health Info"
              img={<img src={AddIncon} />}
              className="py-2 px-3"
              style={{ color: "green" }}
              onClick={() => addHealthInfoHandler("Add")}
            />
          </div>

          <TableComp
            isSearchBar={false}
            isFilter={false}
            tableData={extractedData}
            thHeadData={thHeadData}
            patientVisits={patientVisits}
            navigateTo={navigateTo}
            setEditItems={setEditItems}
            editNavigateTo={() => editNavigateTo}
            setAction={setAction}
            totalRecords={totalRecords}
            page={page}
            setPage={setPage}
            data={extractedData}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setOffset={setOffset}
          />
          {action && action === "addiction-modal" && (
            <HealthInfoModal
              isHidden={showModal}
              onClose={closeModal}
              action={action}
              setFieldData={setFieldData}
              setChronicData={setChronicData}
            />
          )}
          {action && action === "chronic-modal" && (
            <HealthInfoModal
              isHidden={showModal}
              onClose={closeModal}
              action={action}
              setFieldData={setFieldData}
              setChronicData={setChronicData}
            />
          )}
          {action && action === "edit-addiction" && (
            <HealthInfoModal
              isHidden={showModal}
              onClose={closeModal}
              setFieldData={setFieldData}
              action={action}
              fieldData={fieldData}
              id={ids}
            />
          )}
          {action && action === "edit-chronic" && (
            <HealthInfoModal
              isHidden={showModal}
              onClose={closeModal}
              chronicData={chronicData}
              action={action}
              setChronicData={setChronicData}
              id={ids}
            />
          )}
          {
            <ConfirmationModal
              isHidden={showConfirmationModal}
              onClose={closeConfirmationModal}
              message={confirmatiomMsg}
            />
          }
        </div>
      )}
    </div>
  );
};

export default NHealthInfo;
