import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

import "../../../NursesvTwo/NurseTablePage/NurseHealthCamp/NurseGeneralInfTabsPage/NurseTabs/npharmacy.scss"

import { useSnackbar } from "notistack";
import Check from "../../../../Assets/icons/check.svg";
import Cross from "../../../../Assets/icons/cross.svg";

import TopBar from "../../../Doctorsvtwo/PatientTablePage/HealthCampPatient/Tabs/Components/MedicalDiagnosisTabComponents/TopBar";

const INITIAL_VALUES = {
  medicine: null,
  item_id: null,
  dosage: null,
  medicine_time: null,
  frequency: null,
  duration: "",
  item_qty: "",
  notes: "",
  medicineName: "",
  item_name: "",
  untracked_item_name: null,
  
};

function isEmpty(obj = {}) {
  return !Object.values(obj).some(
    (value) => !["", null, undefined].includes(value)
  );
}

const Pharmacy = (props) => {
  const {
    open,
    onTopBarClick,
    mainObject,
    healthCampId,
    visitId,
    id,
    setActiveTab,
    type = "View",
    pharmacyTableData,
  } = props;


  return (
    <div className="tabs-main-container" id="pharmacy-tab">
      <TopBar open={open} content="Pharmacy" onClick={onTopBarClick} />
      {open ? (
        <div className="lab-test py-4">
          <div id="npharmacy">
            <div className="pharmacy">
              <div className="dashboard-table-container box overflow-auto ">
                <table className="dashboard-table ">
                  <thead>
                    <tr>
                      <th>Medicine Name</th>
                      <th>Quantity</th>
                      <th>Distributed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {pharmacyTableData.map((item, i) => {
                        return (
                          <tr
                            // onClick={() => navigate("/home/pathologistView")}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{item.item_name}</td>
                            <td>{item.item_qty}</td>
                            <td>
                              {item.is_distributed ? (
                                <>
                                  <img alt="" src={Check}></img>
                                </>
                              ) : (
                                <>
                                  <img alt="" src={Cross}></img>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-content-end table-page me-5">
              {/* <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRecords}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </div>
          </div>
        </div>
      ) : null}
      {/* <PharmacyMedicineModal
        showModal={showModal}
        setShowModal={setShowModal}
      /> */}
    </div>
  );
};

export default Pharmacy;
