import React, { useState, useEffect } from "react";
import Layout from "../../LayoutComp/Layout";
import { useNavigate, useLocation } from "react-router";
import backBtnIcon from "../../../Assets/icons/back.svg";
import "./pathologistPatientDetails.scss";
import { useSelector } from "react-redux";
import redClose from "../../../Assets/icons/red-close-button.svg";
import StatusIcon from "../../../Assets/icons/Status.svg";
import UploadIcon from "../../../Assets/icons/upload-icon.svg";
import pdfImg from "../../../Assets/Images/prescription-pdf.png";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import EditIcon from "../../../Assets/icons/edit.svg";
import DeleteIcon from "../../../Assets/icons/delete-icon.svg";
import ViewIcon from "../../../Assets/icons/view-icon.svg";
import { readService } from "../../../Services/HttpService";
import { getMedicalReport } from "../../../Services/Pathologist";
import { TablePagination } from "@mui/material";
import CommonDeleteModal from "../../CommonDeleteModal/CommonDeleteModal";
import { format24HrDateOnly, formatDate } from "../../../Utils/DateUtils";
import LabReport from "../LabReport/LabReport";

const PathologistViewDetails = ({ sideNavState, setSideNavState }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { patientId } = useSelector((state) => state.patientId);
  const [generalInfo, setGeneralInfo] = useState(false);
  const [labReportsData, setLabReportsData] = useState([]);
  const [medicalReport, setMedicalReport] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLabReport, setShowLabReport] = useState(false);
  const [labId, setLabId] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);

  const closeModal = () => {
    setShowModal(!showModal);
  };

  const generalInfoHandler = () => {
    setGeneralInfo(true);
  };

  useEffect(() => {
    getMedicalTests();
    getAllLabReports();
  }, [location, page, pageSize]);

  const getAllLabReports = async () => {
    let params = {
      limit: pageSize,
      offset: offset,
    };
    let response = await readService(`patient/labreports/${patientId}`, params);
    if (response) {
      setTotalRecords(response.data.count);
      setLabReportsData(response.data.body);
    }
  };
  const getMedicalTests = async () => {
    let response = await getMedicalReport(patientId);
    if (response) {
      setMedicalReport(response.data);
    }
  };

  const handleChangeRowsPerPage = (e) => {
    setPageSize(parseInt(e.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = pageSize * pageIndex;
    setOffset(offSet);
  };

  const handleEditLabReport = (rowData) => {
    console.log("Clicked Edit for row:", rowData);
    navigate("/home/edit-report-view", {
      state: { editMode: true, reportData: rowData },
    });
  };



  return (
    <Layout
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
      hidenav={true}
    >
      {!generalInfo ? (
        <div>
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => navigate("/home/pathologistPatientDetail")}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>
          <div id="pathologist-view">
            <div className="pathol-view" style={{ display: "flex" }}>
              <div className="dashboard-table-container overflow-auto ">
                {medicalReport?.tests ? (
                  <table className="dashboard-table ">
                    <thead>
                      <tr>
                        <th>Test Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      <>
                        {medicalReport?.tests?.map((item, i) => {
                          return (
                            <tr
                              onClick={() => navigate("/home/pathologistView")}
                              style={{ cursor: "pointer" }}
                            >
                              <td>{item.name}</td>
                              <td style={{ pointerEvents: "none" }}>
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  onClick={generalInfoHandler}
                                >
                                  <p className="viewAll-patho px-2 py-1">
                                    {item.status ? (
                                      <img src={StatusIcon} alt="profile_img" />
                                    ) : (
                                      <img src={redClose} alt="profile_img" />
                                    )}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    </tbody>
                  </table>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    No record Found
                  </div>
                )}
              </div>

              <div>
                <div className="d-flex justify-content-end">
                  <PrimaryButton
                    name="Report"
                    img={<img src={UploadIcon} />}
                    className="hollow-button"
                    onClick={() => navigate("/home/upload-report-view")}
                  />
                </div>
                <h6>Description</h6>
                <div className="desc-box">
                  <p style={{ color: "black", padding: "10px" }}>
                    {medicalReport?.remarks}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <hr className="horizontal-line"></hr>
            </div>
            <div id="bottom-table-pathol" style={{ margin: "2%" }}>
              {labReportsData?.length ? (
                <table className=" " style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <th>Report Name</th>
                      <th>Report Type</th>
                      <th>Remarks</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {labReportsData?.map((item, i) => {
                      return (
                        <tr style={{ cursor: "pointer" }}>
                          <td>
                            <>
                              <img className="" src={pdfImg}></img>&nbsp;&nbsp;
                              {item.report_name}
                            </>
                          </td>
                          <td>{item.report_type}</td>
                          <td>{item.remarks || "-"}</td>
                          <td>{format24HrDateOnly(item.upload_date)}</td>
                          <td>
                            <span
                              className="me-1"
                              onClick={() => {
                                navigate("/home/labReportView", {
                                  state: {
                                    report_name: item.report_name,
                                    report_type: item.report_type,
                                    report_url: item.report,
                                  },
                                });
                              }}
                            >
                              <img className="" src={ViewIcon}></img>
                            </span>
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowModal(true);
                                setLabId(item.id);
                              }}
                            >
                              <img className="" src={DeleteIcon}></img>
                            </span>

                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEditLabReport(item)}
                            >
                              <img className="" src={EditIcon}></img>
                            </span>
                              </td>
                        </tr>
                      );
                    })}

                  </tbody>
                </table>
              ) : null}

              {labReportsData?.length === 0 && (
                <div className="warning-text-card">No result found!</div>
              )}
              {labReportsData?.length ? (
                <div className="flex justify-content-end table-page">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25]}
                    count={totalRecords}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => setGeneralInfo(false)}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>
        </div>
      )}
      {
        <CommonDeleteModal
          isHidden={showModal}
          onClose={closeModal}
          id={labId}
          comp="pathologist"
          getAllData={getAllLabReports}
        />
      }
    </Layout>
  );
};

export default PathologistViewDetails;
