import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Grid } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RenderSelect from "../RenderSelect/RenderSelect";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Form } from "react-bootstrap";
import {
  getAllAddictions,
  getChronicConditions,
} from "../../Services/PatientBasicHealthInfo";

export default function HealthInfoModal(props) {
  const { setFieldData, action, setChronicData, fieldData, chronicData, id } =
    props;
  const [addictions, setAddictions] = useState([]);
  const [chronicList, setChronicList] = useState([]);
  const [addictionName, setAddictionName] = useState("");
  const [chronicName, setChronicName] = useState("");
  const [fieldValue, setFieldValue] = useState({
    id: "",
    start_year: "",
    end_year: "",
    remarks: "",
    name: "",
  });

  useEffect(() => {
    const getAddictions = async () => {
      let data = await getAllAddictions();
      if (data) {
        setAddictions(data.data);
      }
    };
    const getChronic = async () => {
      let data = await getChronicConditions();
      if (data) {
        setChronicList(data.data);
      }
    };
    getAddictions();
    getChronic();
  }, []);

  // console.log("action is======================", action)
  useEffect(() => {
    if (id && action === "edit-addiction") {
      let data = fieldData?.find((item) => item.id === id);
      let obj = {}
      obj.id = data?.id
      if(data?.start_year)obj.start_year = data?.start_year 
      if(data?.end_year)obj.end_year = data?.end_year 
      if(data?.remarks)obj.remarks = data?.remarks
      if(data?.name)obj.name = data?.name
      setFieldValue({
        id: data?.id,
        start_year: data?.start_year ? new Date(data?.start_year) : null,
        end_year:data?.end_year ?  new Date(data?.end_year) : null,
        remarks: data?.remarks,
        name: data?.name,
      });
    } else {
      let data = chronicData?.find((item) => item.id === id);
      setFieldValue({
        id: data?.id,
        start_year: data?.start_year?  new Date(data?.start_year) : null,
        remarks: data?.remarks,
        name: data?.name,
      });
    }
  }, [action, id]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (
      (name === "id" && action !== "chronic-modal") ||
      action === "edit-addiction"
    ) {
      let addiction = addictions.find((item) => item.id === value);
      setAddictionName(addiction?.addiction);
    }
    if (
      (name === "id" && action === "chronic-modal") ||
      action === "edit-chronic"
    ) {
      let chronic = chronicList.find((item) => item.id === value);
      setChronicName(chronic?.name);
    }
    setFieldValue((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleStartYear = (date) => {
    setFieldValue((prevState) => ({ ...prevState, start_year: date }));
  };
  const handleEndYear = (date) => {
    setFieldValue((prevState) => ({ ...prevState, end_year: date }));
  };

  const handleSubmit = (e) => {
    let allData;
    let chronicDataVar;

    if (action === "edit-addiction") {
      // allData = [{ ...fieldValue, addiction: addictionName }];
      // setFieldData([...allData]);

      const editedData = {
        id: id,
        start_year: fieldValue.start_year ? fieldValue.start_year : null,
        end_year: fieldValue.end_year ? fieldValue.end_year : null,
        remarks: fieldValue.remarks,
        name: fieldValue.name,
        addiction: addictionName,
      };
      const updatedFieldData = fieldData.map((item) =>
        item.id === id ? editedData : item
      );
      setFieldData(updatedFieldData);
    } else if (action === "edit-chronic") {
      const editedData = {
        id: id,
        start_year: fieldValue.start_year ? fieldValue.start_year : null,
        end_year: fieldValue.end_year ? fieldValue.end_year : null,
        remarks: fieldValue.remarks,
        name: chronicName,
      };
      const updatedFieldData = chronicData?.map((item) =>
        item.id === id ? editedData : item
      );
      setChronicData(updatedFieldData);
    } else {
      if (action === "chronic-modal") {
        chronicDataVar = [{ ...fieldValue, name: chronicName }];
        setChronicData((prevData) => [...prevData, ...chronicDataVar]);
      } else {
        allData = [{ ...fieldValue, addiction: addictionName }];
        setFieldData((prevData) => [...prevData, ...allData]);
      }
    }

    // if (action === "chronic-modal") {
    //     chronicData = [{ ...fieldValue, name: chronicName }]
    //     setChronicData((prevData) => ([...prevData, ...chronicData]))
    // } else {
    //     allData = [{ ...fieldValue, addiction: addictionName }]
    //     setFieldData((prevData) => ([...prevData, ...allData]))
    // }
    if (action !== "edit-chronic" && action !== "edit-addiction") {
      setFieldValue({
        id: "",
        start_year: "",
        end_year: "",
        remarks: "",
      });
    }
    props.onClose();
  };

  const handleClose = () => {
    setFieldValue({
      id: "",
      start_year: "",
      end_year: "",
      remarks: "",
    });
    props.onClose();
  };
  const handleDisablebtnTwo = () => {
    // console.log("fieldValue.experience", fieldValue.experience)
    if (
      !fieldValue.id ||
      !fieldValue.start_year ||
      fieldValue.start_year == "Invalid Date" ||
      !fieldValue.end_year ||
      !fieldValue.remarks
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleDisablebtnOne = () => {
    // console.log("fieldValue.experience", fieldValue.experience)
    if (
      !fieldValue.id ||
      !fieldValue.start_year ||
      fieldValue.start_year == "Invalid Date" ||
      // !fieldValue.end_year ||
      !fieldValue.remarks
    ) {
      return true;
    } else {
      return false;
    }
  };
  console.log("fieldValue---->", fieldValue, chronicName);

  return (
    <Modal
      size="md"
      ClassName="healthInfo-modal"
      backdrop="static"
      show={props.isHidden}
      onHide={props.onClose}
      centered
    >
      <Modal.Header
        style={{
          backgroundColor: "#8484F6",
          color: "#ffff",
          fontSize: "20px",
        }}
        className="health-modal-header"
      >
        <b>Enter Details</b>
      </Modal.Header>
      <Modal.Body>
        <Grid container xs={12} display={"flex"} justifyContent={"center"}>
          {(action && action === "chronic-modal") ||
          action === "edit-chronic" ? (
            <Grid item xs={9.5}>
              <RenderSelect
                name="id"
                title="Issue"
                value={fieldValue.id}
                onChange={(e) => {
                  handleChange(e);
                }}
                options={chronicList}
                placeholder="Gender"
              />
            </Grid>
          ) : (
            <Grid item xs={9.5}>
              <RenderSelect
                name="id"
                title="Type Of Addiction"
                value={fieldValue.id}
                onChange={(e) => {
                  handleChange(e);
                }}
                options={addictions}
                placeholder="Gender"
              />
            </Grid>
          )}
          {(action && action === "chronic-modal") ||
          action === "edit-chronic" ? (
            <Grid
              container
              xs={24}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={9.5}>
                <p>Start Year</p>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    wrapperClassName="datepicker"
                    value={fieldValue.startyear}
                    onChange={handleStartYear}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              xs={12}
              spacing={2}
              display={"flex"}
              justifyContent={"center"}
            >
              <Grid item xs={5}>
                <p>Start Year</p>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    wrapperClassName="datepicker"
                    value={fieldValue.startyear}
                    onChange={handleStartYear}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={5}>
                <p>End Year</p>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    wrapperClassName="datepicker"
                    value={fieldValue.endyear}
                    onChange={handleEndYear}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          )}
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Remarks</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="remarks"
              placeholder="remarks"
              style={{ width: "380px" }}
              onChange={handleChange}
              value={fieldValue.remarks}
            />
          </Form.Group>
        </Grid>
        <div
          className="d-flex justify-content-start mt-4 gap-2"
          style={{ marginLeft: "43px" }}
        >
          <Button
            style={{ padding: "10px 40px" }}
            variant="outline-primary"
            onClick={handleClose}
          >
            <b>Reset</b>
          </Button>
          <div>
            <Button
              className="loginButton reset-btn"
              variant="primary"
              type="submit"
              onClick={handleSubmit}
              // disabled={
              //   action === "chronic-modal"
              //     ? handleDisablebtnOne()
              //     : handleDisablebtnTwo()
              // }
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
