import React, { useState } from "react";
import Layout from "../../LayoutComp/Layout";
import { useNavigate } from "react-router";
import backBtnIcon from "../../../Assets/icons/back.svg";
import "./medicineDetails.scss";
import { Row, Col } from "react-bootstrap";
import Table from "../../Patient/HealthCampPatient/View/Distributed Medicine/Table/Table";
import { useParams } from "react-router-dom";
import { readService, updateService } from "../../../Services/HttpService";
import { useEffect } from "react";
import { Autocomplete } from "@mui/material";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { updatePatientMedicine } from "../../../Services/Medicine";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
import { readHelthCampCoordinator } from "../../../Services/Coordinator";

const MedicineDetails = ({ sideNavState, setSideNavState }) => {
  const [itemName, setItemName] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const queryParameters = new URLSearchParams(window.location.search);
  const healthCampId = queryParameters.get("healthCampId");
  const [modalOpen, setModalOpen] = useState(false);

  const [generalInfo, setGeneralInfo] = useState(false);
  const [medicineDistributed, setMedicineDistributed] = useState([]);

  const [patientInfo, setPatientInfo] = useState({});

  const [item, setItem] = useState({});

  const generalInfoHandler = () => {
    navigate("/home/pathologistView");
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const getPatientGeneralInfo = async () => {
    const result = await readService(`patient/generalInfo/${id}`);
    setPatientInfo(result?.data);
    setItem(result?.data);
  };
  console.log("yarakad", data);

  const getMedicineDistributed = async () => {
    const result = await readService(`patient/medicines/${id}`);
    const dummyResult = result?.data.map((item, i) => {
      return { ...item, canEdit: !item.is_distributed };
    });
    setMedicineDistributed(dummyResult);
  };

  console.log(" ==========> medicineDistributed", medicineDistributed);

  useEffect(() => {
    getMedicineDistributed();
    getPatientGeneralInfo();
  }, [id]);

  const handleCheckBoxClick = (e, index) => {
    console.log(" ==========> event", e);
    const { value } = e.target;

    console.log(" ==========> value", value);

    const dummy = medicineDistributed.map((item, i) => {
      if (index === i) {
        return { ...item, is_distributed: !item?.is_distributed };
      } else {
        return item;
      }
    });
    setMedicineDistributed(dummy);
  };

  const handleSubmit = async () => {
    const result = await updateService(
      `patient/medicines/${id}`,
      {},
      {
        distribute_medicines: JSON.stringify(medicineDistributed),
        healthcamp_id: healthCampId,
      }
    );
    getMedicineDistributed();
    handleModalClose();
  };
  {
    console.log("ppp", patientInfo);
  }
  useEffect(() => {
    const storedItemName = sessionStorage.getItem("itemName");
    setItemName(storedItemName);
    console.log("testing-prajwal", storedItemName);
  }, []);
  return (
    <Layout
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
      hidenav={true}
    >
      <ConfirmModal
        open={modalOpen}
        onClose={handleModalClose}
        message="Are you sure?"
        onSubmit={handleSubmit}
      />
      {!generalInfo ? (
        <div>
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => navigate(-1)}
              // onClick={() => navigate("/home/parmacistView/100009")}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>

          <div id="parmacist-med">
            <h5>Medicine Details</h5>
            <div className="header-parm">
              <Row style={{ marginLeft: "15%" }}>
                <Col>
                  <b>Patient Name</b>
                  <div>{patientInfo?.full_name}</div>
                </Col>
                <Col style={{ fontSize: "17px" }}>
                  <b>Health Camp Name </b>
                  <div>{itemName}</div>
                </Col>
                <Col>
                  <b>Gender</b>
                  <div>{patientInfo?.gender}</div>
                </Col>
              </Row>
            </div>
            <div style={{ margin: "0px 5%" }}>
              {medicineDistributed && medicineDistributed?.length !== 0 ? (
                <div
                  id="pharmacy-diagnosis"
                  className="box overflow-auto pharmacy-table-overwrite margin-bottom"
                >
                  <table
                    className="dashboard-table border-collapse table-border"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Medicine</th>
                        <th>Dose</th>
                        <th>Timing</th>
                        <th>Frequency</th>
                        <th>Duration</th>
                        <th>No. of Units</th>
                        <th>Notes</th>
                        <th>Distributed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {medicineDistributed?.map((item, i) => {
                          return (
                            <tr
                              style={{ cursor: "pointer" }}
                              className="tr-class"
                            >
                              <td className="border-none">
                                {item?.item_name || "-"}
                              </td>
                              <td
                                className="border-none border-color"
                                style={{ border: "1px solid black" }}
                              >
                                {item?.dosage || "-"}
                              </td>
                              <td className="border-none">
                                {item.medicine_time || "-"}
                              </td>
                              <td className="border-none">
                                {item.frequency || "-"}
                              </td>
                              <td className="border-none">
                                {item?.duration || "-"}
                              </td>
                              <td className="border-none">
                                {item.item_qty || "-"}
                              </td>
                              <td className="border-none">
                                {item.notes || "-"}
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  disabled={!item.canEdit}
                                  checked={item?.is_distributed}
                                  onChange={(e) => handleCheckBoxClick(e, i)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div> No record found</div>
              )}
              <div className="submit-button-bottom top-spacing">
                <PrimaryButton name="Save" onClick={handleModalOpen} />
                {/* <PrimaryButton name="Save" onClick={handleSubmit} /> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => setGeneralInfo(false)}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default MedicineDetails;
