import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    campData:{}
};

export const campDataSlice = createSlice({
  name: "campData",
  initialState,
  reducers: {
    setCampData(state, action) {
      state.campData = action.payload;
    },
  },
});


export const { setCampData } = campDataSlice.actions;
export default campDataSlice.reducer;
