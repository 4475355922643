import React, { useState } from 'react'
import TableComp from '../../../../Table/TableComp';
import drImg from "../../../../../../Assets/Images/drImg.png"
import close from "../../../../../../Assets/icons/do-close.svg"
const Prescription = () => {
 const [isAction, setIsAction] = useState(true);
const tableData = [
  {
    PrescriptionMCH: "Prescription",
    Date: "7th Feb 2023",
    ActionMCHPrescription: "",
  },
  {
    PrescriptionMCH: "Prescription",
    Date: "7th Feb 2023",
    ActionMCHPrescription: "",
  },
  {
    PrescriptionMCH: "Prescription",
    Date: "7th Feb 2023",
    ActionMCHPrescription: "",
  },
  {
    PrescriptionMCH: "Prescription",
    Date: "7th Feb 2023",
    ActionMCHPrescription: "",
  },
  
];
  const navigateTo = () => {
    setIsAction(false);
  };
 return (
   <>
     {isAction ? (
       <TableComp
         isSearchBar={false}
         isFilter={false}
         tableData={tableData}
         navigateTo={() => navigateTo}
       />
     ) : (
       <>
         <div
           className="medical-prescription pt-2 pb-5"
           style={{ backgroundColor: "#FBFBFF" }}
         >
           <div className="text-end p-3" onClick={() => setIsAction(true)}>
             <img src={close}></img>
           </div>
           <div className="d-flex px-4 justify-content-between">
             <div className="d-flex">
               <img className="me-3" src={drImg}></img>
               <div className="doctor-info">
                 <p>Dr. Prashhant Gowda</p>
                 <p>MBBS MD (Heart Specialist)</p>
               </div>
             </div>

             <div>
               {
                 <>
                   <div className="status-recover d-flex justify-content-end align-items-center mb-3">
                     <p className="me-3">status</p>
                     <div
                       className="px-2 py-2"
                       style={{ backgroundColor: "#BAE5D1",borderRadius:'5px' }}
                     >
                       Patient Recovered
                     </div>
                   </div>
                 </>
               }
             </div>
           </div>
           <div
             className=" d-flex justify-content-between p-3 align-items-center mb-3 doctor-info-container"
             style={{ backgroundColor: "#FBFBFF", border: "" }}
           ></div>
           <div className="text-center">
             <p className="mb-2">Medical Prescription by (MCH Name) Center</p>
             <p>Hosur Road 4th main, Bangaore</p>
           </div>

           <div className="row mt-5">
             <div className="col-6 right-border ps-5">
               <div className="grid-container-two">
                 <p className="font-style">Patient Name</p>
                 <p></p>
                 <p className="font-style">Patient Age</p>
                 <p></p>
                 <p className="font-style">Date</p>
                 <p>
                   <span style={{ fontSize: "60px" }}></span>
                 </p>
               </div>
             </div>

             <div className="col-6 right-border ps-5">
               <div className="grid-container-two">
                 <p className="font-style">BP Level</p>
                 <p></p>
                 <p className="font-style">Sugar Level</p>
                 <p></p>
                 <p className="font-style">RBF Level</p>
                 <p></p>
               </div>
             </div>
             <p
               style={{
                 paddingLeft: "150px",
                 fontSize: "50px",
                 margin: "0",
                 lineHeight: "0.2em",
               }}
             >
               R<span style={{ fontSize: "30px" }}>x</span>
             </p>
             <div className="d-flex justify-content-center mt-4">
               <div className="col-6 right-border ps-5 mt-3">
                 <div className="grid-container-three">
                   <p className="font-style">DOLO 650</p>
                   <p>2 times after food</p>
                   <p className="font-style">Pan 40</p>
                   <p>2 times after food</p>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </>
     )}
   </>
 );
}

export default Prescription