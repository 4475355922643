import React, { useState } from "react";
import TopBar from "./TopBar";
import "./ReferredToMch.scss";
import PrimaryButton from "../../../../../../PrimaryButton/PrimaryButton";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import {
  readService,
  updateService,
} from "../../../../../../../Services/HttpService";
import { useSnackbar } from "notistack";
import { errorToast, successToast } from "../../../../../../../Utils/toasters";
import PopupDropdwon from "../../../../../../CommonPopupDropdown/PopupDropdown";
import UploadIcon from "../../../../../../../Assets/icons/upload-icon.svg";
import Table from "../../../../../../Patient/View/Table";

const ReferredToMch = (props) => {
  const {
    open,
    onTopBarClick,
    id,
    visitId,
    mainObject,
    doctorAdvice,
    activeTab,
    setActiveTab,
    type,
  } = props;
  const viewType = type === "View";

  const { enqueueSnackbar } = useSnackbar();

  const [imsData, setImsData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [showDropdown, setShowDropDown] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [offset, setOffset] = useState(0);
  const [medicalAdvice, setMedicalAdvice] = useState({
    advice: "Go to hospital",
    is_hospital_referred: "",
    hospital_id: "100001",
    refer_notes: "",
    prescription: "",
  });
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const selectedFilesArray = Array.from(fileList);
    setSelectedFiles(selectedFilesArray);
  };

  const handleFileSubmit = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`prescriptions`, file);
    });
    if (!selectedFiles?.length) {
      errorToast(enqueueSnackbar, "Please Select A File");
    } else {
      if (selectedFiles?.length <= 5 && selectedFiles?.length > 0) {
        try {
          const result = await updateService(
            `patient/diagnosis/${id}/${visitId}`,
            {},
            formData
          );
          successToast(enqueueSnackbar, "Document Uploaded successfully");
        } catch (error) {
          errorToast(enqueueSnackbar);
        }
      } else {
        errorToast(enqueueSnackbar);
      }
    }
  };

  const Options = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];

  useEffect(() => {
    setMedicalAdvice(doctorAdvice);
  }, [doctorAdvice]);

  const closeModal = () => {
    setShowDropDown(!showDropdown);
  };

  useEffect(() => {
    getImsHospitals();
  }, []);

  const getImsHospitals = async () => {
    const params = {
      limit: pageSize,
      offset: offset,
    };
    const result = await readService("imshospitals", params);
    setImsData(result.data.body);
    setTotalCount(result.data.count);
  };

  const setFieldValueHandler = (e) => {
    const { name, value } = e.target;

    setMedicalAdvice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    medicalAdvice.ims_id = selectedId;
    if (!viewType) {
      try {
        const result = await updateService(
          `patient/diagnosis/${id}/${visitId}`,
          {},
          {
            doctor_advice: JSON.stringify(medicalAdvice),
          }
        );
        successToast(enqueueSnackbar);
        setActiveTab(activeTab + 1);
      } catch (error) {
        errorToast(enqueueSnackbar);
      }
    }
  };

  return (
    <div id="referred-to-mch-tab">
      <div className="tabs-main-container">
        <div className="mb-3">
          <TopBar
            open={open}
            content="Medical Advice"
            onClick={onTopBarClick}
          />
        </div>
        {open ? (
          <div>
            <div className="row">
              <div
                className="col-lg-4 col-md-6 col-sm-12 mb-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="refer-to-mch-label">
                    Refer to MCH
                  </Form.Label>
                  <Form.Select
                    aria-label="select"
                    onChange={setFieldValueHandler}
                    name="is_hospital_referred"
                    value={medicalAdvice?.is_hospital_referred}
                    disabled={viewType}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <option>Refer To MCH</option>
                    {Options.length > 0 &&
                      Options?.map((item, index) => (
                        <option value={item.value} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label
                    className="refer-to-mch-label"
                    style={{ marginTop: 10 }}
                  >
                    MCH Name
                  </Form.Label>
                  {/* <div >Refer to MCH</div> */}
                  <div onClick={() => setShowDropDown(true)}>
                    <Form.Select
                      aria-label="select"
                      onChange={(e) => console.log("jkl")}
                      name="id"
                      value={selectedId}
                      disabled={viewType}
                      style={{ backgroundColor: "#fff" }}
                    >
                      <option>Select MCH</option>
                      {imsData.length > 0 &&
                        imsData?.map((item) => (
                          <option
                            style={{ visibility: "hidden" }}
                            value={item.id}
                            key={item.id}
                          >
                            {item.name}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </Form.Group>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12 mb-3">
                <Form.Group controlId="formBasicName">
                  <Form.Label className="refer-to-mch-label">
                    Reference Notes
                  </Form.Label>
                  <Form.Control
                    type=""
                    as="textarea"
                    rows={5}
                    placeholder="Reference Note"
                    name="refer_notes"
                    value={medicalAdvice?.refer_notes}
                    onChange={setFieldValueHandler}
                    disabled={viewType}
                    style={{ backgroundColor: "#fff" }}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                <Form.Group controlId="formBasicName">
                  <Form.Label className="refer-to-mch-label">
                    Prescription
                  </Form.Label>
                  <Form.Control
                    type=""
                    as="textarea"
                    rows={10}
                    placeholder="Prescription"
                    name="prescription"
                    value={medicalAdvice?.prescription}
                    onChange={setFieldValueHandler}
                    disabled={viewType}
                    style={{ backgroundColor: "#fff" }}
                  />
                </Form.Group>
              </div>
            </div>
            {!viewType ? (
              <div className=" flex flex-row text-end mb-3">
                <PrimaryButton
                  name="Save"
                  className="py-2 px-3"
                  style={{ color: "green" }}
                  onClick={handleSubmit}
                />
              </div>
            ) : null}
            <hr />
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="flex-row"
            >
              <div className=" flex flex-row text-end mb-3">
                <input
                  type="file"
                  label="Select File"
                  name="prescreption"
                  multiple
                  className="py-2 px-3"
                  onChange={handleFileChange}
                />
              </div>
              <div className=" flex flex-row text-end mb-3">
                <PrimaryButton
                  name="Upload Prescription"
                  img={<img src={UploadIcon} />}
                  className="py-2 px-3"
                  style={{ pointerEvents: "none" }}
                  onClick={handleFileSubmit}
                  // disabled={!selectedFiles?.length}
                />
              </div>
            </div>
            <Table
              id={id}
              visitId={visitId}
              errorToast={errorToast}
              enqueueSnackbar={enqueueSnackbar}
            />
          </div>
        ) : null}
      </div>
      {showDropdown && (
        <PopupDropdwon
          isHidden={showDropdown}
          onClose={closeModal}
          data={imsData}
          setSelectedId={setSelectedId}
          totalCount={totalCount}
          setImsData={setImsData}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          offset={offset}
          setOffset={setOffset}
          setTotalCount={setTotalCount}
        />
      )}
    </div>
  );
};

export default ReferredToMch;
