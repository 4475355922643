import React, { useEffect, useMemo, useState } from "react";
import "../../../Doctorsvtwo/PatientTablePage/HealthCampPatient/Tabs/Components/MedicalDiagnosisTabComponents/LabTests";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
// import { getMedicalTestsMetadata } from "../../../../../../../Services/Metadata/Metadata";
// import { updateService } from "../../../../../../../Services/HttpService";
// import PrimaryButton from "../../../../../../PrimaryButton/PrimaryButton";
import { useSnackbar } from "notistack";
import TopBar from "../../../Doctorsvtwo/PatientTablePage/HealthCampPatient/Tabs/Components/MedicalDiagnosisTabComponents/TopBar";

const LabTests = (props) => {
  const {
    open,
    onTopBarClick,
    labTest,
    id,
    visitId,
    activeTab,
    setActiveTab,
    type,
    labTestData,
  } = props;

  console.log(" ==========> labTestData", labTestData);

  const viewType = type === "View";

  const [remark, setRemark] = useState(labTest);

  console.log(" ==========> labTest 1234", labTest);
  console.log(" ==========> remark", remark);

  useEffect(() => {
    console.log(" ==========> labTest in useeffect", labTest);
    setRemark(labTest);
  }, [labTest]);

  const { enqueueSnackbar } = useSnackbar();

  const [metaData, setMetaData] = useState([]);
  const [finalResult, setFinalResult] = useState([]);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 48,
    height: 18,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 20,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(0px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(30px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#33cc11",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 14,
      height: 14,
      borderRadius: 20,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "red",
      boxSizing: "border-box",
    },
  }));

  const onStatusChange = (e, name) => {
    const upd_obj = finalResult?.map((obj) => {
      if (obj.name === name) {
        obj.status = Boolean(e.target.checked);
      }
      return obj;
    });

    setFinalResult(upd_obj);
  };

  const filteredData = useMemo(() => {
    const result = metaData.map((item, i) => {
      const { test_name } = item;
      const object = labTest?.tests?.find((i) => i.name === test_name);
      if (object) {
        return { name: test_name, status: object.status };
      } else {
        return { name: test_name, status: false };
      }
    });
    return result;
  }, [metaData, labTest]);

  useEffect(() => {
    setFinalResult(filteredData);
  }, [filteredData]);

  return (
    <div id="lab-test-tab">
      <div className="tabs-main-container">
        <TopBar open={open} content="Lab Tests" onClick={onTopBarClick} />
        {open ? (
          <div>
            <div
              className="lab-test py-4 box"
              style={{ display: "flex", gap: 20 }}
            >
              <div
                className="dashboard-table-container-medical-diagnosis box overflow-auto table lab-test-input-container"
                style={{ maxHeight: "70vh" }}
              >
                <table
                  className="dashboard-table border-collapse-lab-test labtest-patient"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Test Name</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {labTestData?.map((item, i) => {
                        const { name, status } = item;
                        return (
                          <tr style={{ cursor: "pointer" }}>
                            <td className="border-none-lab-test">{name}</td>
                            <td className="border-none-lab-test">
                              <div
                                className="d-flex justify-content-center align-items-center"
                                // onClick={generalInfoHandler}
                              >
                                <p className="viewAll-patho px-2 py-1">
                                  <AntSwitch
                                    // defaultChecked
                                    // disabled={viewType}
                                    checked={status}
                                    inputProps={{ "aria-label": "ant design" }}
                                    onChange={(e) => {
                                      if (!viewType) {
                                        onStatusChange(e, name);
                                      }
                                    }}
                                  />
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
              </div>
              <div className="box" style={{ height: "100%" }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    marginBottom: 20,
                  }}
                >
                  Add Description
                </p>
                <div className="lab-test-input-container desc-box">
                  <div className="px-3 py-4">
                    <textarea
                      name="Text1"
                      placeholder="description"
                      value={remark}
                      disabled={viewType}
                      onChange={(e) => setRemark(e.target.value)}
                      rows="16"
                      style={{ width: "100%", border: "none", outline: "none" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LabTests;
