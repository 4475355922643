import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userProfile: {}
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers(state, action) {
      state.userProfile = action.payload;
    }
  }
});

export const patientInfoSlice = createSlice({
  name: "patientInfo",
  initialState: { patientList: [] },
  reducers: {
    setPatientInfo(state, action) {
      state.patientList = action.payload;
    }
  }
});

export const patientId = createSlice({
  name: "patientId",
  initialState: { patientId: '' },
  reducers: {
    setPatientId(state, action) {
      state.patientId = action.payload;
    }
  }
});


export const patientFullName = createSlice({
  name: "patientFullName",
  initialState: { patientFullName: "" },
  reducers: {
    setPatientFullName(state, action) {
      state.patientFullName = action.payload;
    },
  },
});



export const { setUsers } = userSlice.actions;
export const { setPatientInfo } = patientInfoSlice.actions;
export const { setPatientId } = patientId.actions;
export const { setPatientFullName } = patientFullName.actions;


export const selectUsersState = (state) => state?.user?.userProfile;
export const selectPatientList = (state) => state?.patientInfo?.patientList;
export const selectedPatientId = (state) => state?.patientId?.patientId;

export default {
  user: userSlice.reducer,
  patientInfo: patientInfoSlice.reducer,
  patientId: patientId.reducer,
  patientFullName: patientFullName.reducer
};
