import { readService,updateService } from "../HttpService";

export const patientHealthCampList = (id, params) => {
  return readService(`patients/${id}`, params);
};

export const getPatientInfo=(id,params)=>{
    return readService(`patient/generalInfo/${id}`,params);
}

export const updatePatientInfo =(id,body)=>{
  return updateService(`patients/generalInfo/${id}`,{},body);
}

