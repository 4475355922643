import React, { createContext, useState } from "react"

export const  SideNavContext=createContext();
export const SideNavProvider = ({children})=>{
  const [sideNavState, setSideNavState] = useState(true);
    return (
        <SideNavContext.Provider value={{sideNavState,setSideNavState}}>
        {children}
        </SideNavContext.Provider>
    )

}