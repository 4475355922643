import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { DOCUMENT_OPTIONS, Ward } from "../../../Utils/constants";
import Layout from "../../LayoutComp/Layout";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import RenderInput from "../../RenderInput/RenderInput";
import RenderSelect from "../../RenderSelect/RenderSelect";
import "./CreateHospital.scss";
import { MCHUpdate, addMCH, readMCH } from "../../../Services/MCH/index";
import Doctors from "../../../Assets/Images/doctors.png";
import { useLocation, useNavigate } from "react-router-dom";
import { backBtnIcon } from "../../../Utils/ImageUtils";
import { Button } from "react-bootstrap";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";

import {
  getDistrictsByState,
  getPlacesByDistrict,
  getStates,
} from "../../../Services/Metadata/Metadata";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import { errorToast, successToast } from "../../../Utils/toasters";
import { enqueueSnackbar } from "notistack";
import { ContactValidator, pinCodeValidate } from "../../../Utils/Validators";

const CreateHospital = (props) => {
  const { sideNavState, setSideNavState } = props;
  const { userProfile } = useSelector((state) => state.user);
  console.log("userProfile", userProfile);
  // const initialValues = { name: "", document: "Aadhar", gender: "male" };
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [stateDropDown, setStateDropDown] = useState([]);
  const [distDrop, setDistDrop] = useState([]);
  const [placeDrop, setPlaceDrop] = useState([]);
  const [stateVal, setStateVal] = useState(null);
  const [distVal, setDistVal] = useState(null);
  const [placeVal, setPlaceVal] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [randomKey, setRandomKey] = useState(0);
  const [passMessage, setPassMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [pincodeMessage, setPinCodeMessage] = useState(null);

  const [error, setError] = useState({
    contact_number: false,
    password: false,
    pincode: false,

  });

  const location = useLocation();
  const handleChange = (event) => {
    // event.preventDefault()
    const { name, value } = event.target;
    console.log("nameee", name, value);
    if (name === "contact_number") {
      ContactValidator(setError, setMessage, value);
    }
    if (name == "pincode") {
      pinCodeValidate(setError, setPinCodeMessage, value);
    }
    if (name == "emergency_ward") {
      setInitialValues((prev) => ({
        ...prev,
        [name]: value == 1 ? true : false,
      }));
    }
    setInitialValues((prevMchObject) => ({
      ...prevMchObject,
      [name]: value,
    }));

    // event.preventDefault()
    if (event.target.name == "state_id") {
      setStateId(event.target.value);
    }
    if (event.target.name == "district_id") {
      setDistrictId(event.target.value);
    }
    if (event.target.name == "place_id") {
      setPlaceId(event.target.value);
    }
  };

  const navigate = useNavigate();
  const { state } = useLocation();
  const [initialValues, setInitialValues] = useState({
    name: "",
    contact_number: "",
    email: "",
    // state_id: stateId,
    // district_id: districtId,
    // place_id: placeId,
    pincode: "",
    no_of_beds: "",
    ims_id: userProfile.works_at,
    emergency_ward: "",
    icu_capacity: "",
    description: "",
  });
  useEffect(() => {
    setInitialValues((prevValues) => ({
      ...prevValues,
      state_id: stateId,
      district_id: districtId,
      place_id: placeId,
    }));
  }, [stateId, districtId, placeId]);
  const resetForm = () => {
    console.log("reset clicked");
    setInitialValues({
      name: "",
      contact_number: "",
      email: "",
      state_id: "",
      district_id: "",
      place_id: "",
      // state_id: stateId,
      // district_id: districtId,
      // place_id: placeId,
      pincode: "",
      no_of_beds: "",
      emergency_ward: "",
      icu_capacity: "",
      description: "",
    });
    setRandomKey(Math.random());
    setStateVal(null);
  };
  console.log("initialValuesss", initialValues);
  const handleSubmit = async (values) => {
    console.log("values------>", values);
    setShowSpinner(true);
    const body = {
      ...values,
    };
    console.log("body--->", body);
    if (location.state.action == "edit") {
      const response = await MCHUpdate(
        location.state.hospitalId,
        {},

        initialValues
      );
      if (response.status < 200 || response.status > 300) {
        errorToast(enqueueSnackbar, response.data.message);
      } else {
        resetForm();
        navigate("/home/hospital");
        successToast(enqueueSnackbar, response.data.message);
      }
    } else {
      const response = await addMCH(initialValues);
      if (response.status < 200 || response.status > 300) {
        let msg = response?.data?.errors.map((err) => err.msg);
        errorToast(enqueueSnackbar, msg?.join(""));
      } else {
        resetForm();
        navigate("/home/hospital");
        successToast(enqueueSnackbar, response.data.message);
      }
    }
    setShowSpinner(false);
  };

  useEffect(() => {
    getState();
  }, []);
  const getState = async () => {
    const states = await getStates();
    setStateDropDown(states.data);
  };
  useEffect(() => {
    if (stateId) getDistrict();
  }, [stateId]);
  const getDistrict = async () => {
    const district = await getDistrictsByState(stateId);
    setDistDrop(district.data);
  };
  useEffect(() => {
    if (districtId) getPlaceData();
  }, [districtId]);
  const getPlaceData = async () => {
    const place = await getPlacesByDistrict(districtId);
    setPlaceDrop(place.data);
  };

  useEffect(() => {
    if (location.state.action == "view" || location.state.action == "edit")
      getHospitalById();
  }, []);
  const getHospitalById = async () => {
    const hospital = await readMCH({ id: location.state.hospitalId });
    const val = hospital.data.body;

    setInitialValues({
      name: val[0].name,
      contact_number: val[0].contact_number,
      email: val[0].email,
      state_id: val[0].state_id,
      district_id: val[0].district_id,
      place_id: val[0].place_id,
      pincode: val[0].pincode,
      no_of_beds: val[0].no_of_beds,
      emergency_ward: val[0].emergency_ward == true ? "1" : "0",
      icu_capacity: val[0].icu_capacity,
      description: val[0].description,

      // update other fields accordingly
    });
    setStateVal(val[0].state_id);
    setDistVal(val[0].district_id);
    setPlaceVal(val[0].place_id);
    setStateId(val[0].state_id);
    setDistrictId(val[0].district_id);
    setPlaceId(val[0].place_id);
  };
  if (location.state.action == "view" || location.state.action == "edit") {
    if (stateVal == null || distVal == null || placeVal == null) {
      return null;
    }
  }
  const handleDisablebtn = () => {
    // for (let key in fieldValue){
    //   console.log("fieldVlueeee--->",fieldValue[key],key)

    // }
    if (
      error.contact_number == true ||
      initialValues.contact_number == "" ||
      !initialValues.name ||
      // || !initialValues.email
      !initialValues.pincode ||
      !initialValues.state_id ||
      !initialValues.district_id ||
      !initialValues.place_id ||
      error.pincode == true
      // || !initialValues.no_of_beds
      //  || !initialValues.emergency_ward
      //  || !initialValues.icu_capacity
    ) {
      return true;
    }
    return false;
  };
  error.contact_number == true ||
    console.log("initialvaue", initialValues, error);
  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        handleReset={resetForm}
      >
        {({ handleBlur, handleSubmit, values, handleReset }) => (
          <Form>
            <div style={{ padding: 30 }}>
              {/* <div className="hospital-form-heading">Add New MCH</div> */}
              <span
                className="back-btn-row"
                onClick={() => navigate("/home/hospital")}
              >
                <img src={backBtnIcon} alt="" />
                <span>Back</span>
              </span>
              <div className="hospital-form-heading">
                {state.action == "add"
                  ? "Add New"
                  : state.action == "edit"
                    ? "Edit"
                    : "View"}{" "}
                MCH
              </div>
              <div
                style={{
                  borderBottom: "2px solid #7070703C",
                  marginBottom: 40,
                }}
              >
                <div
                  style={{
                    paddingBottom: 10,
                    borderBottom: "2px solid red",
                    width: 160,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    marginBottom: -2,
                  }}
                >
                  MCH Information
                </div>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <RenderInput
                    type="text"
                    name="name"
                    value={initialValues.name}
                    onChange={handleChange}
                    title="MCH Name"
                    placeholder="MCH Name"
                    requiredprop={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderInput
                    name="contact_number"
                    value={initialValues.contact_number}
                    onChange={handleChange}
                    title="Contact Number"
                    placeholder="9737737377"
                    type="number"
                    className="no-spinners"
                    requiredprop={true}
                  />
                  {error.contact_number && (
                    <p className="text-danger">{message}</p>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <RenderInput
                    name="email"
                    value={initialValues.email}
                    onChange={handleChange}
                    title="Email Id"
                    placeholder="sample@gmail.com"
                  // requiredprop={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderSelect
                    name="state_id"
                    title="State"
                    placeholder="Select State"
                    // defaultValue={stateVal}
                    value={initialValues.state_id}
                    onChange={handleChange}
                    options={stateDropDown}
                    key={randomKey}
                    place="Karnataka"
                    requiredprop={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderSelect
                    name="district_id"
                    // defaultValue={distVal}
                    placeholder="Select District"
                    value={initialValues.district_id}
                    title="District"
                    onChange={handleChange}
                    options={distDrop}
                    key={randomKey}
                    requiredprop={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderSelect
                    name="place_id"
                    // defaultValue={placeVal}
                    value={initialValues.place_id}
                    onChange={handleChange}
                    title="Place"
                    options={placeDrop}
                    placeholder="Place"
                    key={randomKey}
                    requiredprop={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderInput
                    name="pincode"
                    onChange={handleChange}
                    value={initialValues.pincode}
                    title="PIN code"
                    placeholder="Pin Code"
                    requiredprop={true}
                  />
                  {error.pincode && (
                    <p className="text-danger">{pincodeMessage}</p>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <RenderInput
                    name="no_of_beds"
                    onChange={handleChange}
                    value={initialValues.no_of_beds}
                    title="NO. of Beds"
                    placeholder="50"
                    type="number"
                  // requiredprop={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderInput
                    name="icu_capacity"
                    type="number"
                    value={initialValues.icu_capacity}
                    onChange={handleChange}
                    title="ICU Capacity"
                    placeholder="Enter Capacity"
                  // requiredprop={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderSelect
                    name="emergency_ward"
                    onChange={handleChange}
                    value={initialValues.emergency_ward}
                    title="Emergency Ward"
                    options={Ward}
                  // requiredprop={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RenderInput
                    name="description"
                    value={initialValues.description}
                    onChange={handleChange}
                    title="Description"
                    placeholder="Description"
                    rows={5}
                    multiline
                  />
                </Grid>
              </Grid>
              {(location.state.action == "add" ||
                location.state.action == "edit") && (
                  <p className="text-danger note-text">
                    <span>
                      <LabelImportantIcon />
                    </span>
                    : Kindly complete all the required fields to submit the form.
                  </p>
                )}
              <Box className="button-pair">
                {state.action == "add" && (
                  <Button
                    className="reset-btn-mch"
                    variant=""
                    onClick={resetForm}
                  >
                    <b>Reset</b>
                  </Button>
                )}
                {/* {state.action=="a"} */}
                {state.action != "view" && (
                  // <button type="submit">hasd</button>
                  <PrimaryButton
                    type="submit"
                    disableprop={handleDisablebtn()}
                    name={state.action == "add" ? "Submit" : "Update"}
                    onClick={() => handleSubmit}
                    className="hollow-button"
                  />
                )}
              </Box>
            </div>
          </Form>
        )}
      </Formik>
      {showSpinner && <Spinner />}
    </Layout>
  );
};

export default CreateHospital;
