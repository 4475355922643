import React from "react";

const HealthCampCard = ({ bgImg, icon, value, name }) => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(../../assets/images/dashboard/${bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="py-4 px-2 rounded"
      >
        <div className="card-body">
          <div
            className="d-flex flex-column  justify-content-between"
            style={{ position: "relative" }}
          >
            <img src={icon} alt="" />

            <div
              className="d-flex align-items-center justify-content-center flex-column py-4"
              style={{ fontSize: "21px", color: "#fff", position: "absolute" }}
            >
              <p className="font-weight-bold" style={{ marginLeft: "20px" }}>
                {name}
              </p>
              <p style={{ textAlign: "left", width: "100%", paddingLeft: 20 }}>
                {value}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HealthCampCard;
