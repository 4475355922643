import { TablePagination } from "@mui/material";
import whitEye from "../../../../../../Assets/icons/view-white.svg";
import React from "react";
import StatusIcon from "../../../../../../Assets/icons/Status.svg";
import Button from "@mui/material/Button";

const Table = ({ type = "", generalInfoHandler }) => {
  const viewAll = [
    {
      "Sl/No": 1,
      "Patient Name": "Mr.Rahul Sharma",
      gender: "male",
      "Mobile No": "9882828288",
      Action: "view",
    },
    {
      "Sl/No": 2,
      "Patient Name": "Mr.Rahul Sharma",
      gender: "male",
      "Mobile No": "9882828288",
      Action: "view",
    },
  ];
  const patholistPatient = [
    {
      "Test Name": "Mr.Rahul Sharma",
      Status: "male",
      Action: "view",
    },
    {
      "Test Name": "Mr.Rahul Sharma",
      Status: "male",
      Action: "view",
    },
  ];
  const parmacistList = [
    {
      "Medicine Name": "Dolo 650",
      Quantity: "5",
      Distributed: "view",
    },
    {
      "Medicine Name": "Dolo 650",
      Quantity: "5",
      Distributed: "view",
    },
    {
      "Medicine Name": "Dolo 650",
      Quantity: "5",
      Distributed: "view",
    },
    {
      "Medicine Name": "Dolo 650",
      Quantity: "5",
      Distributed: "view",
    },
  ];
  const distributedMedicine = [
    {
      medicineName: "medicineName",
      quantity: "5",
    },
  ];
  const showData =
    type === "parmacist"
      ? parmacistList
      : type === "pathologistPatient"
      ? patholistPatient
      : type == "viewAllPatient"
      ? viewAll
      : distributedMedicine;

  const handleChangePage = () => {};
  const handleChangeRowsPerPage = () => {};

  const res =
    type === "parmacist"
      ? parmacistList.map((showData) => Object.keys(showData))
      : type === "pathologistPatient"
      ? patholistPatient.map((showData) => Object.keys(showData))
      : viewAll.map((showData) => Object.keys(showData));
  return (
    <div
      className="distributedmedicine"

      // style={{ padding: "10px", margin: "10px" }}
    >
      <div className="dashboard-table-container overflow-auto ">
        <table className="dashboard-table ">
          <thead>
            <tr>
              {res[0]?.map((val) => (
                <>
                  <th key={val}>{val}</th>
                </>
              ))}
            </tr>
          </thead>
          <tbody>
            {showData?.map((item, index) => (
              <>
                <tr key={index}>
                  {Object.keys(item).map((key) => (
                    <td key={key}>
                      {key == "Action" && type !== "pathologistPatient" ? (
                        <div
                          className="d-flex justify-content-center"
                          onClick={generalInfoHandler}
                        >
                          <p className="view-all px-2 py-1">
                            {item[key]}
                            <img src={whitEye}></img>
                          </p>
                        </div>
                      ) : key === "Distributed" && type === "parmacist" ? (
                        <div>
                          <input type="checkbox" />
                        </div>
                      ) : key == "Action" && type === "pathologistPatient" ? (
                        <Button variant="contained" component="label">
                          Upload
                          <input hidden accept="image/*" multiple type="file" />
                        </Button>
                      ) : key === "Status" ? (
                        <div>
                          <img src={StatusIcon} alt="profile_img" />
                        </div>
                      ) : (
                        item[key]
                      )}
                    </td>
                  ))}
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-content-end table-page">
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={100}
          page={3}
          onPageChange={handleChangePage}
          rowsPerPage={4}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default Table;
