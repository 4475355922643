import React, { useContext, useState, useEffect } from "react";
import Layout from "../../../../LayoutComp/Layout";
import { SideNavContext } from "../../../../SideNavContextAPI/SideNavContext";
import FullWidthTabs from "../../../../TabComp/FullWidthTabs";
// import HealthInfo from "../Tabs/HealthInfo";
// import Prescription from "../Tabs/Prescription";
// import LabTest from "../Tabs/LabTest";
// import Pharmacy from "../Tabs/Pharmacy";
// import MedicalReports from "../Tabs/MedicalReports";
import backBtnIcon from "../../../../../Assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import NGeneralInfoComp from "./NurseTabs/NGeneralInfoComp";
import NHealthInfo from "./NurseTabs/NHealthInfo";
import MedicalDiagnosis from "./NurseTabs/MedicalDiagnosis";
import NPrescription from "./NurseTabs/NPrescription";
import NPharmacy from "./NurseTabs/NPharmacy";
import NMedicalReports from "./NurseTabs/NMedicalReport";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPatientInfo } from "../../../../Redux/slices/userSlice";

const NurseGeneralInfoTab = ({ sideNavState, setSideNavState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localtion = useLocation();
  const patientInfo = localtion?.state?.patientInfo;
  const { patientList } = useSelector((state) => state.patientInfo);

  // console.log("patient id is=======================", patientList)
  useEffect(() => {
    dispatch(setPatientInfo(patientInfo));
  }, []);

  const childrenArry = [
    <NGeneralInfoComp patientInfo={patientList} />,
    <NHealthInfo patientInfo={patientList} />,
    // <MedicalDiagnosis patientInfo={patientList} />,
    // <NPrescription patientInfo={patientList} />,
    // <NPharmacy patientInfo={patientList} />,
    <NMedicalReports patientInfo={patientList} />,
  ];
  const tabstitle = [
    "General Information",
    "Health Information",
    // "Medical Diagnosis",
    // "Prescription",
    // "Pharmacy",
    "Medical report",
  ]
  return (
    <>
      <Layout
        hidenav={true}
        sideNavState={sideNavState}
        setSideNavState={setSideNavState}
      >
        <div className="py-2 px-3" style={{ cursor: "pointer" }}>
          <span
            className="back-btn-row"
            onClick={() => navigate("/home/nursepatienttab")}
          >
            <img src={backBtnIcon} alt="" />
            <span>Back</span>
          </span>
        </div>
        <FullWidthTabs
          section="nurses"
          tabstitle={tabstitle}
          DoctorsTabData={childrenArry}
        />
      </Layout>
    </>
  );
};

export default NurseGeneralInfoTab;
