import React from "react";
import Layout from "../../../../LayoutComp/Layout";
import Prescription from "./Tabs/Prescription";
import FullWidthTabs from "../../../../TabComp/FullWidthTabs";
import MedicalReports from "./Tabs/MedicalReports";
import Forum from "./Tabs/Forum";
import { useNavigate } from "react-router-dom";
import backBtnIcon from "../../../../../Assets/icons/back.svg";
const MCHTabsPage = ({ sideNavState, setSideNavState }) => {
  const navigate = useNavigate();
  const childrenArry = [
    <Prescription />,
    // <Pharmacy />,
    <MedicalReports />,

    <Forum />,
  ];
  const tabstitle = ["Prescription", "Medical report", "Forum"];
  const healthcampId = localStorage.getItem("healthCampid");
  return (
    <Layout
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
      hidenav={true}
    >
      <div className="py-2 px-3" style={{ cursor: "pointer" }}>
        <span
          className="back-btn-row"
          onClick={() => navigate(`/home/patienttabs/${healthcampId}`)}
        >
          <img src={backBtnIcon} alt="" />
          <span>Back</span>
        </span>
      </div>
      <FullWidthTabs tabstitle={tabstitle} DoctorsTabData={childrenArry} />
    </Layout>
  );
};

export default MCHTabsPage;
