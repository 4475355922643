import { TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import backBtnIcon from "../../Assets/icons/back.svg";
import "./pharmacyims.scss";
import Layout from "../LayoutComp/Layout";
import searchIcon from "../../Assets/icons/search_icon.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import filterIcon from "../../Assets/icons/filter_icon.svg";
import addIcon from "../../Assets/icons/addIcon.svg";
import blueeye from "../../Assets/icons/blue-eye.svg";
import PharmacyFilterModal from "./PharmacyFilterModal/PharmacyFilterModal";
import FilterModal from "../Modals/FilterModal/FilterModal";
import { readHelthCampCoordinator } from "../../Services/Coordinator";
import { getPharmacyInventory } from "../../Services/CoPharmacy";
import { getSlno } from "../../Utils/getSlno";
import { format24HrDateOnly, formatTimeHM } from "../../Utils/DateUtils";
import CommonFilterModal from "../CommonFilterModal/CommonFilterModal";
const PharmacyIms = ({ sideNavState, setSideNavState }) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = React.useState(0);
  const [action, setAction] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPageVal, setRowsPerPageVal] = useState(5);
  const [healthCampList, setHealthCampList] = useState([]);
  const [offSet, setOffset] = useState(0);
  const [inventories, setInventories] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [healthCampInfo, setHealthCampInfo] = useState({});
  const [filterDataObj, setFilterDataObj] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  const handleSearchClear = () => {
    setSearchText("");
    getHealthCampData(0, "");

    // getAllDoctorsDetails(offset, "");
  };
    const closeModal = () => {
      setShowModal(!showModal);
    };
  const searchHandler = () => {
    getHealthCampData(offSet, searchText);
  };
  const viewPageHandler = (item) => {
    setAction(true);
    setPage(0);
    setPageSize(5);
    setRowsPerPageVal(5);
    setOffset(0);
    localStorage.setItem("healthcampId", item.id);
    setHealthCampInfo(item);
    getInventory(item.id || localStorage.getItem("healthcampId"));
  };
  useEffect(() => {
    getInventory(localStorage.getItem("healthcampId"), 0, searchText);
  }, [rowsPerPageVal, pageSize]);
  const getInventory = async (id, offSet = 0, searchText = "") => {
    let params = {
      offSet: offSet,
      limit: rowsPerPageVal,
    };
    if (searchText) params = { ...params, name: searchText };

    const res = await getPharmacyInventory(id, params);
    setInventories(getSlno(res?.data?.body, offSet));
    setTotalRecords(res.data.count);
  };
  const getFilterObject = (param) => {
    setFilterDataObj(param);
  };
  useEffect(() => {
    getHealthCampData(0, searchText,filterDataObj);
  }, [rowsPerPageVal]);
  const getHealthCampData = async (offset = 0, searchText, filterText = {}) => {
    let params = {
      offset: offset,
      limit: rowsPerPageVal,
      ...filterText,
    };
    if (searchText) params = { ...params, name: searchText };
    if (filterText?.state && filterText?.state !== "DEFAULT");
      params = { ...params, state: filterText.state };
  if (filterText?.city && filterText?.city !== "DEFAULT")
      params = { ...params, city: filterText.city };
    const res = await readHelthCampCoordinator(params);
    setHealthCampList(getSlno(res.data.body, offset));
    setTotalRecords(res.data.count);
  };
  const handleChangeRowsPerPage = (e) => {
    setPageSize(e?.targte?.value);
    setRowsPerPageVal(parseInt(e.target.value, 10));
    // getDoctorsAll(offSet,searchText)
    //  getHospitals(offSet,searchText)
  };
  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPageVal * pageIndex;
    if (!action) {
      getHealthCampData(offSet, searchText);
    } else if (action) {
      getInventory(localStorage.getItem("healthcampId"), offSet, searchText);
      setOffset(offSet);
    }
  };
  return (
    <Layout
      setSideNavState={setSideNavState}
      sideNavState={sideNavState}
      //   id="npharmacy"
    >
      {action ? (
        <>
          <div id="pharmacy-view">
            <div className=" px-1 mb-2">
              <span
                className="back-btn"
                onClick={() => {
                  setAction(false);
                  setPage(0);
                  setPageSize(5);
                  setRowsPerPageVal(5);

                  setOffset(0);
                  getHealthCampData(0, searchText);
                }}
              >
                <img src={backBtnIcon} alt="" />

                <span>Back</span>
              </span>
            </div>
            <div className="d-flex flex-row header-container justify-content-between">
              <div className="d-flex flex-column">
                <p>Date</p>
                <p>
                  {" "}
                  <span>{format24HrDateOnly(healthCampInfo?.from_date)}</span>
                  {"   "}
                  <span>{format24HrDateOnly(healthCampInfo?.to_date)}</span>
                </p>
              </div>
              <div className="d-flex flex-column">
                <h4>{healthCampInfo.name}</h4>
              </div>
              <div className="d-flex flex-column text-end">
                <p>Time</p>
                <p>
                  <span>{formatTimeHM(healthCampInfo.start_time)}</span>-
                  <span>{formatTimeHM(healthCampInfo.end_time)}</span>
                </p>
              </div>
            </div>
            <div className="pharmacy">
              <div className="dashboard-table-container box overflow-auto ">
                <table className="dashboard-table ">
                  <thead>
                    <tr>
                      <th>SL No.</th>
                      <th>Medicine Name</th>
                      <th>Allotted</th>
                      <th>Distributed</th>
                      <th>Residue</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {inventories?.map((item, i) => {
                        return (
                          <tr
                            // onClick={() => navigate("/home/pathologistView")}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{item?.index}</td>
                            <td>{item?.item_name}</td>
                            <td>{item?.total_received}</td>
                            <td>{item?.total_distributed}</td>
                            <td>{item?.remaining}</td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-content-end table-page me-5">
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRecords}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPageVal}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div id="harmacy-ims">
            <p style={{ color: "#191BC3", fontWeight: "bold" }}>
              Upload Medicine
            </p>
            <div className="d-flex flex-column mx-0 mb-4  flex-md-row flex-lg-row flex-lg-row justify-content-start align-items-center align-items-md-end mt-2">
              <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
                <label className="label-input">
                  <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search by name"
                    onChange={handleSearchInput}
                    value={searchText}
                  />
                  {searchText && (
                    <button
                      className="search-btn-close"
                      onClick={handleSearchClear}
                    >
                      <img src={searchCloseIcon} alt="" width={"24px"} />
                    </button>
                  )}
                  <button className="search-btn" onClick={searchHandler}>
                    <img src={searchIcon} alt="" />
                    <span>Search</span>
                  </button>
                </label>
              </div>

              <div className="col-6 ms-3">
                <button
                  className="filter-btn"
                  onClick={() => setShowModal(true)}
                >
                  <span>Filter</span>
                  <img src={filterIcon} alt="" className="ps-2" />
                </button>
              </div>
            </div>
            <div className="pharmacy">
              <div className="dashboard-table-container box overflow-auto ">
                <table className="dashboard-table ">
                  {healthCampList?.length ? (
                    <>
                      <thead>
                        <tr>
                          <th>SL No.</th>
                          <th>MCH Name</th>
                          <th>Health Camp Name</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Time</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                    </>
                  ) : (
                    ""
                  )}

                  <tbody>
                    <>
                      {healthCampList?.map((item, i) => {
                        return (
                          <tr
                            // onClick={() => navigate("/home/pathologistView")}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{item?.index}</td>
                            <td>{item.hospital_id}</td>
                            <td>{item.name}</td>
                            <td>{format24HrDateOnly(item.from_date)}</td>
                            <td>{format24HrDateOnly(item.to_date)}</td>
                            <td>
                              <span>{formatTimeHM(item.start_time)}</span>-
                              <span>{formatTimeHM(item.end_time)}</span>
                            </td>
                            <td>
                              <img
                                alt=""
                                src={blueeye}
                                onClick={() => viewPageHandler(item)}
                              ></img>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
                {healthCampList?.length === 0 && (
                  <div className="warning-text-card">No Records Found!</div>
                )}
              </div>
            </div>
            <div className="flex justify-content-end table-page me-5">
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRecords}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPageVal}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
            {
              <CommonFilterModal
                isHidden={showModal}
                onClose={closeModal}
                getFilterObject={getFilterObject}
                comp="pharmacyims"
                filterDataObj={filterDataObj}
              />
            }
          </div>
        </>
      )}
    </Layout>
  );
};

export default PharmacyIms;
