import React, { useEffect, useState } from "react";
import "./Health.scss";
import { Form } from "react-bootstrap";
import { View, backBtnIcon, pdfImg } from "../../../../../Utils/ImageUtils";
import { TablePagination } from "@mui/material";
import {
  HealthBasicInfo,
  HealthInfoTab,
  PatientVitals,
  PatientVitalsIndividual,
  getPatientVitalList,
  getPatientVitals,
} from "../../../../../Services/ImsPatient/Patient";
import { getSlno } from "../../../../../Utils/getSlno";
import { Button } from "bootstrap";
import { format24HrDateOnly } from "../../../../../Utils/DateUtils";
import moment from "moment";

const HealtCenterPrePat = ({ patientId }) => {
  const [action, setAction] = useState(true);
  const [HealthInfoData, setHealthInfoData] = useState([]);
  const [basicHealthInfo, setBasicHealthInfo] = useState([]);
  const [chronicArray, setChronicArray] = useState([]);
  const [addiction, setAddiction] = useState([]);
  const [patientVitalsInfo, setPatientVitalsInfo] = useState([]);
  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowsPerPageVal, setRowsPerPageVal] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableWebital, setTableWebVital] = useState([]);
  const [patientVitalsIndivid, setPatientVitalsIndivid] = useState([]);

  useEffect(() => {
    // HealthInfo(23000004, offset);
    HealthInfo(patientId, offset);
  }, [rowsPerPageVal, page]);
  const HealthInfo = async (patientId, offset = 0) => {
    let params = {
      offset: offset,
      limit: rowsPerPageVal,
    };
    // const healthInfo = await HealthInfoTab(patientId, params);
    const getBasicInfo = await HealthBasicInfo(patientId);
    console.log("getBasicInfo", getBasicInfo, patientId);
    setBasicHealthInfo(getBasicInfo?.body?.data);
    console.log("chronicDiseases--->", getBasicInfo?.data?.chronicDiseases);
    setChronicArray(getBasicInfo?.data?.chronicDiseases);
    setAddiction(getBasicInfo?.data?.healthAddictions);
    console.log("offsetttttt", offset);
    // setHealthInfoData(getSlno(healthInfo.data.body,offset));
    // setTotalRecords(healthInfo.data.count)
    // console.log("healtInfo---->", healthInfo);
  };
  console.log("healthInfoData--->", HealthInfoData);
  const handleChangeRowsPerPage = (e) => {
    console.log("rows", e?.target?.value);
    setPageSize(e?.targte?.value);
    setRowsPerPageVal(parseInt(e.target.value, 10));
    // getDoctorsAll(offSet,searchText)
    //  getHospitals(offSet,searchText)
  };
  const handleChangePage = (event, newPage) => {
    console.log("newPage", newPage);
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPageVal * pageIndex;
    HealthInfo(offSet);
    setOffset(offSet);
  };

  const getPatientBasicHealthInfo = async (pid) => {
    setAction(true);
    setTotalRecords(0);
    setOffset(0);
    setRowsPerPageVal(5);
    setPage(1);
  };
  useEffect(() => {
    patientVisitVitalTable(patientId, offset);
  }, [rowsPerPageVal, page]);

  const patientVisitVitalTable = async (patientId, offset = 0) => {
    let params = {
      offset: offset,
      limit: rowsPerPageVal,
    };
    const patientVisitList = await getPatientVitalList(patientId, params);
    console.log("vital list", patientVisitList);

    setTableWebVital(getSlno(patientVisitList?.data?.body, offset));
    setTotalRecords(patientVisitList.data.count);
  };

  const getPatientVitals = async (patientId, visitId) => {
    console.log("getVitalssss");
    setAction(false);
    //  console.log("patientId", patientId, getBasicInfo);
    const getPatientVitalsInfo = await PatientVitalsIndividual(
      patientId,
      visitId,
      {}
    );
    console.log("getPatientVitalsInfo000", getPatientVitalsInfo);
    setPatientVitalsIndivid(getPatientVitalsInfo.data);

    // setPatientVitalsInfo()
  };

  console.log("chronicArray", patientVitalsIndivid);
  console.log("healthInfoData", HealthInfoData);
  const hanleHealthInfoBack = () => {
    setAction(false);
    setRowsPerPageVal(5);
    setPage(1);
    setOffset(0);
    patientVisitVitalTable(patientId, 0);
  };
  return (
    <div id="healthCenterPreferedPatient" className="text-center">
      {action ? (
        <>
          <div>
            <div className="text-center">
              <div className="d-flex justify-content-center align-items-center">
                <div className="py-2 px-3" style={{ cursor: "pointer" }}>
                  <span className="back-btn-row">
                    {/* <img src={backBtnIcon} alt="" /> */}
                    {/* <span>Back</span> */}
                  </span>
                </div>
                <div className="btn-container">
                  <button
                    className="btn mb-2"
                    style={{
                      backgroundColor: "#191BC3",
                      color: "white",
                      width: "200px",
                    }}
                  >
                    Basic Health Info
                  </button>
                </div>
                <div className="date-container">
                  <div
                    style={{
                      border: "1px solid #70707080",
                      padding: "5px 30px",
                      marginLeft: "auto",
                      borderRadius: "10px",
                    }}
                  >
                    {moment(patientVitalsIndivid[0]?.record_time).format(
                      "DD MMMM YYYY"
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex row">
                <div
                  className="d-flex flex-row justify-content-center my-4"
                  style={{}}
                >
                  <div div className="grid-container-view-patient">
                    <p className="font-style">Allergies</p>
                    <p>{basicHealthInfo?.allergies}</p>
                    <p className="font-style">surgery history</p>
                    <p>{basicHealthInfo?.surgery_history}</p>
                    <p className="font-style">Immunisation Details</p>
                    <p>{basicHealthInfo?.immunisation_details}</p>
                    <p className="font-style">Family History and Hereditary</p>
                    <p>{basicHealthInfo?.family_health_history}</p>
                  </div>
                </div>
              </div>
              <hr class="hr" />

              <div className="d-flex row justify-content-between">
                <div className="d-flex row w-50 justify-content-between">
                  <div className=" text-color col-lg-6 col-md-6 col-sm-12 text-start fs-5 fw-bold">
                    Addictions
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-12 text-end fs-5 fw-bold">
                    <button
                      className="btn mb-2"
                      style={{ backgroundColor: "#191BC3", color: "white" }}
                      onClick={() => {
                        // setShowModal(true);
                        setAction("addiction-modal");
                      }}
                    >
                      Add
                    </button>
                  </div> */}
                </div>
                {/* <div className="d-flex row w-50 justify-content-between"> */}
                <div className="d-flex mx-0 px-3 text-color col-lg-6 col-md-6 col-sm-12 text-start fs-5 fw-bold">
                  Chronic Diseases
                </div>

                {/* </div> */}
              </div>
              <div id="tabelInfo-patient" className="d-flex row">
                <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                  <table>
                    <tr>
                      <th>Sl.No00</th>
                      <th>Addiction</th>
                      <th>Start Year</th>
                      <th>End Year</th>
                      <th>Remarks</th>
                      {/* <th>Actions</th> */}
                    </tr>
                    {addiction?.map((items, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{items.addiction}</td>
                          <td>{format24HrDateOnly(items.start_year)}</td>
                          <td>{format24HrDateOnly(items.end_year)}</td>
                          <td>{items.remarks}</td>
                          {/* <td> */}
                          {/* sdasds */}
                          {/* <span
                                className="me-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setShowModal(true);
                                  setAction("edit-addiction");
                                  setIds(items.id);
                                }}
                              >
                                <img className="" src={edit}></img>
                              </span>
                              <span
                                className="ms-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleDelete(items.id, "addiction");
                                }}
                              >
                                <img className="" src={DeleteIcon}></img>
                              </span> */}
                          {/* </td> */}
                        </tr>
                      );
                    })}
                  </table>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                  <table>
                    <tr>
                      <th>Sl.No</th>
                      <th>Issue</th>
                      <th>Start Year</th>
                      <th>Remarks</th>
                    </tr>
                    {chronicArray?.map((items, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{items.name}</td>
                          <td>{format24HrDateOnly(items.start_year)}</td>
                          {/* <td>sdjsd</td> */}
                          <td>{items.remarks}</td>
                          {/* <td>
                              <span
                                className="me-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setShowModal(true);
                                  setAction("edit-chronic");
                                  setIds(items.id);
                                }}
                              >
                                <img className="" src={edit}></img>
                              </span>
                              <span
                                className="ms-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleDelete(items.id, "chronic");
                                }}
                              >
                                <img className="" src={DeleteIcon}></img>
                              </span>
                            </td> */}
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
              <hr class="hr" />

              <div className="text-center mt-4">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="btn-container">
                    <button
                      className="btn mb-2"
                      style={{
                        backgroundColor: "#191BC3",
                        color: "white",
                        width: "200px",
                      }}
                    >
                      Vitals
                    </button>
                  </div>
                  <div className="date-container">
                    <div
                      style={{
                        border: "1px solid #70707080",
                        padding: "5px 30px",
                        marginLeft: "auto",
                        borderRadius: "10px",
                      }}
                    >
                      {moment(patientVitalsIndivid[0]?.record_time).format(
                        "DD MMMM YYYY"
                      )}
                    </div>
                  </div>
                </div>

                {/* /////////////////////////  table for individual patient vital view  */}
                <div id="#Prescription">
                  <div
                    className="table-style"

                    // style={{ padding: "10px", margin: "10px" }}
                  >
                    <div
                      className="dashboard-table-container overflow-auto pb-3"
                      style={{ margin: "2%" }}
                    >
                      {/* <p
                className="pt-3"
                style={{
                  fontSize: "21px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                abc
              </p> */}
                      <table className="view-table ">
                        <thead>
                          <tr>
                            <th>SL No</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableWebital?.map((item, index) => {
                            return (
                              <tr
                                onClick={() =>
                                  getPatientVitals(
                                    item.patient_id,
                                    item.visit_id
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <td>
                                  {/* <img className="" src={pdfImg}></img> */}
                                  {item.index}
                                </td>
                                <td>{item.visit_time}</td>
                                <td>
                                  {" "}
                                  <img src={View} alt="" title="View" />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {/* {doctorList?.data?.doctors?.length === 0 && (
              <div className="warning-text-card">No Records Found!</div>
            )} */}
                      <div className="flex justify-content-end table-page">
                        <TablePagination
                          component="div"
                          rowsPerPageOptions={[5, 10, 25]}
                          count={totalRecords}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPageVal}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* ///////// Table end for patient visit */}
              </div>

              <hr class="hr" />
              {/* <div className=" flex flex-row mb-3 mt-4 text-end">
              <PrimaryButton
                name="Add Health Info"
                img={<img src={AddIncon} />}
                className="py-2 px-3"
                style={{ color: "green" }}
                onClick={() => addHealthInfoHandler("Add")}
              />
            </div> */}

              {/* <TableComp
              isSearchBar={false}
              isFilter={false}
              tableData={extractedData}
              thHeadData={thHeadData}
              patientVisits={patientVisits}
              navigateTo={navigateTo}
              setEditItems={setEditItems}
              editNavigateTo={() => editNavigateTo}
              setAction={setAction}
              totalRecords={totalRecords}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              setOffset={setOffset}
            /> */}
              {/* {action && action === "addiction-modal" && (
              <HealthInfoModal
                isHidden={showModal}
                onClose={closeModal}
                action={action}
                setFieldData={setFieldData}
                setChronicData={setChronicData}
              />
            )} */}
              {/* {action && action === "chronic-modal" && (
              <HealthInfoModal
                isHidden={showModal}
                onClose={closeModal}
                action={action}
                setFieldData={setFieldData}
                setChronicData={setChronicData}
              />
            )} */}
              {/* {action && action === "edit-addiction" && (
              <HealthInfoModal
                isHidden={showModal}
                onClose={closeModal}
                setFieldData={setFieldData}
                action={action}
                fieldData={fieldData}
                id={ids}
              />
            )} */}
              {/* {action && action === "edit-chronic" && (
              <HealthInfoModal
                isHidden={showModal}
                onClose={closeModal}
                chronicData={chronicData}
                action={action}
                setChronicData={setChronicData}
                id={ids}
              />
            )} */}
              {/* {
              <ConfirmationModal
                isHidden={showConfirmationModal}
                onClose={closeConfirmationModal}
                message={confirmatiomMsg}
              />
            } */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex row">
            <div className="py-2 px-3" style={{ cursor: "pointer" }}>
              <span className="back-btn-row" onClick={() => setAction(true)}>
                <img src={backBtnIcon} alt="" />
                <span>Back</span>
              </span>
            </div>
            <div className="d-flex flex-row justify-content-center" style={{}}>
              <div div className="grid-container-view-vitals">
                <p className="font-style">Weight</p>
                <p>{patientVitalsIndivid[0]?.weight}</p>
                <p className="font-style">BP Diastolic</p>
                <p>{patientVitalsIndivid[0]?.bp_diastolic}</p>
                <p className="font-style">GRBS</p>
                <p>{patientVitalsIndivid[0]?.grbs}</p>
                <p className="font-style">BMI</p>
                <p>{patientVitalsIndivid[0]?.bmi}</p>
                <p className="font-style">BP Systolic</p>
                <p>{patientVitalsIndivid[0]?.bp_systolic}</p>
                <p className="font-style">Respiration</p>
                <p>{patientVitalsIndivid[0]?.respiratory_rate}</p>
              </div>

              <div className="grid-container-view-vitals">
                <p className="font-style">SpO2</p>
                <p>{patientVitalsIndivid[0]?.spo2}</p>
                <p className="font-style">height/Length</p>
                <p>{patientVitalsIndivid[0]?.height}</p>
                <p className="font-style">Pulse</p>
                <p>{patientVitalsIndivid[0]?.pulse}</p>
                <p className="font-style">Temperature</p>
                <p>{patientVitalsIndivid[0]?.temperature}</p>
                <p className="font-style">BMI Status</p>
                <p>{patientVitalsIndivid[0]?.bmi_status}</p>
              </div>
            </div>
          </div>

          <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
            <Form.Group className="mb-3 " controlId="formBasicEmail">
              <Form.Label className="font-style-bold">Other Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="specialisation"
                // value={visitsDetails[0]?.notes}
              />
            </Form.Group>
          </div>

          <hr />
          <button
            className="btn mb-3"
            style={{ backgroundColor: "#191BC3", color: "white" }}
          >
            sample Collections
          </button>
          <div className="d-flex justify-content-center align-items-center">
            <div className="date-container">
              <div
                style={{
                  border: "1px solid #70707080",
                  padding: "5px 30px",
                  marginLeft: "auto",
                  borderRadius: "10px",
                  visibility: "hidden",
                }}
              >
                {moment(patientVitalsIndivid[0]?.record_time).format(
                  "DD MMMM YYYY"
                )}
              </div>
            </div>
          </div>
          <div className="d-flex row justify-content-center">
            <div className=" d-flex flex-column col-lg-6 col-md-6 col-sm-12 text-center">
              <div className="d-flex justify-content-center ">
                <p className="me-3">Blood Sample</p>
                <Form.Check
                  //   disabled
                  inline
                  type={"radio"}
                  label={`yes`}
                  name="blood_sample"
                  id={`disabled-default-radio`}
                  value="yes"
                  // checked={selectedBloodSample === "yes"}
                />
                <Form.Check
                  //   disabled
                  inline
                  type={"radio"}
                  label={`No`}
                  id={`disabled-default-radio`}
                  value="no"
                  // checked={selectedBloodSample === "no"}
                />
              </div>
              <div className="d-flex justify-content-center">
                <p className="me-3">Urine Sample</p>
                <Form.Check
                  //   disabled
                  inline
                  type={"radio"}
                  name="urine_sample"
                  label={`yes`}
                  id={`disabled-default-radio`}
                  value="yes"
                  // checked={selectedUrineSample === "yes"}
                />
                <Form.Check
                  //   disabled
                  inline
                  type={"radio"}
                  label={`No`}
                  id={`disabled-default-radio`}
                  value="no"
                  // checked={selectedUrineSample === "no"}
                />
              </div>
            </div>
          </div>
          <hr class="hr" />
          <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
            <Form.Group className="mb-3 " controlId="formBasicEmail">
              <Form.Label className="font-style-bold">
                Health Issues and symptoms
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="specialisation"
                name="health_issues"
                // value={visitsDetails[0]?.health_issues}
              />
            </Form.Group>
          </div>
          <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
            <Form.Group className="mb-3 " controlId="formBasicEmail">
              <Form.Label className="font-style-bold">
                Preliminary Diagnosis Assessment
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="write Here"
                name="prelim_diagnosis"
                // value={visitsDetails[0]?.prelim_diagnosis}
              />
            </Form.Group>
          </div>
          <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
            <Form.Group className="mb-3 " controlId="formBasicEmail">
              <Form.Label className="font-style-bold">Nurse Advise</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="write Here"
                name="nurse_advice"
                // value={visitsDetails[0]?.nurse_advice}
              />
            </Form.Group>
          </div>
        </>
      )}
    </div>
  );
};

export default HealtCenterPrePat;
