import React from "react";
import "./layout.scss";

const Layout = ({ sideNavState, children, hidenav }) => {
  return (
    <div
      id={hidenav ? "hidenav_container" : "layout_container"}
      style={{
        width: hidenav
          ? "100%"
          : sideNavState
          ? "calc(100% - 20%)"
          : "calc(100% - 10%)",
        marginLeft: hidenav ? "0%" : sideNavState ? "calc(100% - 80%)" : "10%",
        transition: "0.5s",
        position: "absolute",
      }}
    >
      <div className="py-4 px-2">{children}</div>
    </div>
  );
};

export default Layout;
