import React, { useEffect, useRef, useState } from "react";
import backBtnIcon from "../../Assets/icons/back.svg";
import view from "../../Assets/icons/view.svg";
import edit from "../../Assets/icons/edit.svg";
import ArrowUp from "../../Assets/icons/cloud-arrow-up.svg";
import "./bus.scss";
import close from "../../Assets/icons/close-rounded.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import searchIcon from "../../Assets/icons/search_icon.svg";
import { Button, Form } from "react-bootstrap";
import addIcon from "../../Assets/icons/addIcon.svg";
import { styled } from "@mui/material/styles";
import {
  Box,
  IconButton,
  Modal,
  TablePagination,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Layout from "../LayoutComp/Layout";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import busViewClose from "../../Assets/icons/bus-view-close.svg";
import remark from "../../Assets/Images/remark.png";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import viewwhite from "../../Assets/icons/view-white.svg";
import TableComp from "../NursesvTwo/NurseTableComp/TableComp";
import { createService, readService } from "../../Services/HttpService";
import { updateBus } from "../../Services/Bus";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { errorToast, successToast } from "../../Utils/toasters";
import { charLengthCheck } from "../../Utils/Validators";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  // bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  // pr: 2,
};

const initialState = {
  reg_no: "",
  driver_id: "",
  rc_front_url: null,
  rc_back_url: null,
};

const tableData = [
  {
    "Camp ID": "HVSM-01",
    "Camp Name": "Free Health Camp",
    State: "Uttarakhand",
    District: "dehrdun",
    place: "Haridwara",
    From: "7th March 2022",
    To: "12th March 2022",
    Time: "10:00AM-5:00AM",
  },
  {
    "Camp ID": "HVSM-01",
    "Camp Name": "Free Health Camp",
    State: "Uttarakhand",
    District: "dehrdun",
    place: "Haridwara",
    From: "7th March 2022",
    To: "12th March 2022",
    Time: "10:00AM-5:00AM",
  },
  {
    "Camp ID": "HVSM-01",
    "Camp Name": "Free Health Camp",
    State: "Uttarakhand",
    District: "dehrdun",
    place: "Haridwara",
    From: "7th March 2022",
    To: "12th March 2022",
    Time: "10:00AM-5:00AM",
  },
  {
    "Camp ID": "HVSM-01",
    "Camp Name": "Free Health Camp",
    State: "Uttarakhand",
    District: "dehrdun",
    place: "Haridwara",
    From: "7th March 2022",
    To: "12th March 2022",
    Time: "10:00AM-5:00AM",
  },
  {
    "Camp ID": "HVSM-01",
    "Camp Name": "Free Health Camp",
    State: "Uttarakhand",
    District: "dehrdun",
    place: "Haridwara",
    From: "7th March 2022",
    To: "12th March 2022",
    Time: "10:00AM-5:00AM",
  },
];

const SearchBarComp = ({
  searchText,
  handleSearchClear,
  handleSearchInput,
  handleSearchClick,
}) => {
  return (
    <label className="label-input">
      <input
        type="text"
        className="form-control search-input"
        placeholder="Search by name"
        onChange={handleSearchInput}
        value={searchText}
      />
      {searchText && (
        <button
          className="search-btn-close"
          onClick={handleSearchClear}
          style={{
            border: "none",
            position: "absolute",
            right: 90,
            margin: "auto",
            top: 4,
          }}
        >
          <img src={searchCloseIcon} alt="" width={"24px"} />
        </button>
      )}
      <button className="search-btn" onClick={handleSearchClick}>
        <img src={searchIcon} alt="" />
        <span>Search</span>
      </button>
    </label>
  );
};
const TransparentTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#5355D8",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    width: 130,
  },
}));

const SelectDriverModal = ({
  closeModal,
  showModal,
  setBusDetails,
  busDetails,
}) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState(0);
  const [allData, setAllData] = useState([]);

  const [page, setPage] = React.useState(0);

  const getAllBusDrivers = async (limit, offset, updateCount) => {
    const result = await readService(
      `busDrivers?limit=${limit}&offset=${offset}&name=${searchText}`
    );

    setAllData(result?.data?.body);
    if (updateCount) {
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    getAllBusDrivers(5, 0, 1);
  }, []);

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPage * pageIndex;
    getAllBusDrivers(rowsPerPage, offSet, 0);
  };

  const handleSearchClear = () => {
    setSearchText("");
  };
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getAllBusDrivers(event.target.value, 0, 1);
  };

  const handleSearchClick = async () => {
    getAllBusDrivers(rowsPerPage, 0, 1);
  };
  const handleDisablebtn = () => {
    if (!busDetails.reg_no) {
    }
  };

  return (
    <div>
      <Modal open={showModal} onClose={closeModal}>
        <Box sx={style}>
          <div id="pharmacy" style={{ backgroundColor: "#fff" }}>
            <div className="pharmacy">
              <div className="d-flex justify-content-between px-3 mb-3 align-items-center">
                <div
                  className="col-10 col-sm-18 mb-4 mb-md-0 mt-3   col-md-6 col-lg-5"
                  style={{ marginLeft: "20px" }}
                >
                  <SearchBarComp
                    searchText={searchText}
                    handleSearchClear={handleSearchClear}
                    handleSearchInput={handleSearchInput}
                    handleSearchClick={handleSearchClick}
                  />
                </div>
                <div
                  className="text-end "
                  onClick={closeModal}
                  style={{ cursor: "pointer" }}
                >
                  <img src={close} style={{ width: "25px" }} />
                </div>
              </div>
              <div
                className="dashboard-table-container box overflow-auto"
                style={{ maxHeight: "70vh" }}
              >
                {console.log(" ==========> allData", allData)}
                <table className="dashboard-table ">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Driver ID</th>
                      <th>Driver Name</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {allData?.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            // onClick={() => navigate("/home/pathologistView")}
                            style={{ cursor: "pointer" }}
                          >
                            {console.log(
                              " ==========> assigned to",
                              item?.assigned_to
                            )}
                            {console.log(
                              " ==========> flag",
                              !!item?.assigned_to
                            )}
                            <td>
                              <Form.Check
                                className="d-flex justify-content-end"
                                type="radio"
                                label=""
                                disabled={!!item?.assigned_to}
                                name="radioGroup"
                                id="option1"
                                onChange={(e) => {
                                  setBusDetails({
                                    ...busDetails,
                                    driver_id: item?.id,
                                  });
                                  closeModal();
                                }}
                              />
                            </td>
                            <td>{item?.id}</td>
                            <td>{item.name}</td>

                            <td>
                              <TransparentTooltip title="Driver is Assigned to other Bus">
                                <IconButton>
                                  {/* <DeleteIcon /> */}
                                  <img src={remark} />
                                </IconButton>
                              </TransparentTooltip>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
            {console.log(" ==========> allData.length", allData.length)}
            {allData.length ? (
              <div className="flex justify-content-end me-4">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                className="text-center d-flex justify-content-center
            align-items-center"
                style={{ minHeight: "10rem", fontSize: "24px" }}
              >
                <p>No Records Found!</p>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const Bus = ({ sideNavState, setSideNavState }) => {
  const { works_at } = useSelector((s) => s.user.userProfile);

  const [action, setAction] = useState(null);
  const [busDetails, setBusDetails] = useState(initialState);

  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageBack, setSelectedImageBack] = useState(null);

  const [page, setPage] = React.useState(0);
  const [allData, setAllData] = useState([]);
  const [busId, setBusId] = useState(0);

  const inputRef = useRef(null);

  const [count, setCount] = useState(0);
  const [lengthcheckMsg, setLengthcheckMsg] = useState(null);

  const [error, setError] = useState({
    lengthCheck: false,
  });
  const getAllBuses = async (limit, offset, updateCount) => {
    const result = await readService(
      `buses?limit=${limit}&offset=${offset}&reg_no=${searchText}`
    );

    setAllData(result?.data?.body);
    if (updateCount) {
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    console.log("busdetailReg", busDetails.reg_no);
    if (busDetails.reg_no !== "") {
      setError((prev) => ({
        ...prev,
        lengthCheck: false,
      }));
    }
  }, [action]);
  useEffect(() => {
    getAllBuses(5, 0, 1);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append(`ims_id`, works_at);

    const newArray = Object.keys(busDetails);

    newArray.map((item, i) => {
      formData.append(`${item}`, busDetails[item]);
    });

    if (action == "Add") {
      const response = await createService("buses", formData, {});
      if (response.status < 200 || response.status > 300) {
        errorToast(enqueueSnackbar, response.data);
      } else {
        successToast(enqueueSnackbar, "Created Successfully!!");
        setAction(null);
        getAllBuses(5, 0, 1);
        setPage(0);
      }
    }

    if (action == "Edit" && busId !== null) {
      const response = await updateBus(busId, formData, {});
      if (response.status < 200 || response.status > 300) {
        errorToast(enqueueSnackbar, response.data);
      } else {
        successToast(enqueueSnackbar);
        setAction(null);
        getAllBuses(5, 0, 1);
        setPage(0);
      }
    }

    // if (action == "Add") {
    //   const response = await createService("buses", formData, {});
    // }

    // setAction(null);
    // getAllBuses(5, 0, 1);
    // setPage(0);
  };
  const backButtonHandler = () => {
    setAction(null);
  };
  const handleReset = () => {
    setBusDetails(initialState);
  };
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  const handleSearchClear = () => {
    setSearchText("");
  };
  const searchHandler = async () => {
    getAllBuses(rowsPerPage, 0, 1);
  };
  const handleAction = (actionVal, bus_id, reg_no) => {
    setBusId(reg_no);

    const singleData = allData.find((s) => s.id === bus_id);

    let dummy = {};

    Object.keys(busDetails).map((item) => {
      dummy = { ...dummy, [item]: singleData[item] };
    });

    setBusDetails(dummy);
    setAction(actionVal);
  };
  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPage * pageIndex;
    getAllBuses(rowsPerPage, offSet, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getAllBuses(event.target.value, 0, 0);
  };

  const closeModal = () => {
    setShowModal(!showModal);
  };

  const setFieldValueHandler = (e) => {
    const { name, value } = e.target;
    if (name == "reg_no") {
      charLengthCheck(setError, setLengthcheckMsg, value, 4);
    }
    setBusDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDisablebtn = () => {
    if (!busDetails.reg_no || error.lengthCheck == true) {
      return true;
    } else {
      return false;
    }
  };
  const handleFileSelect = (event) => {
    const name = event.target.name;
    console.log("name--->", name);
    const file = event.target.files[0];
    if (name == "rc_front_url") {
      setSelectedImage(URL.createObjectURL(file));
    }
    if (name == "rc_back_url") {
      setSelectedImageBack(URL.createObjectURL(file));
    }
    setBusDetails((prevState) => ({
      ...prevState,
      [name]: file,
    }));
  };
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  return (
    <Layout
      id="doctorId"
      style={{ border: "none", boxShadow: "none" }}
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
    >
      {action == "Add" || action == "Edit" || action == "View" ? (
        <form onSubmit={handleSubmit}>
          <>
            <div className="add-doctor-form">
              {action != "View" && (
                <div className=" px-1">
                  <span
                    className="back-btn"
                    onClick={() => backButtonHandler()}
                  >
                    <img src={backBtnIcon} alt="" />
                    <span>Back</span>
                  </span>
                </div>
              )}
              {action == "View" && (
                <div className="d-flex justify-content-end">
                  <div className="d-flex justify-content-end me-4">
                    <PrimaryButton
                      name="Change Driver"
                      img={<img src={viewwhite} />}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal(true);
                      }}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-end me-4"
                    onClick={() => setAction("viewbushistory")}
                  >
                    <PrimaryButton
                      name="View Bus History"
                      img={<img src={viewwhite} />}
                    />
                  </div>
                </div>
              )}

              <div className="form-style px-3 py-2 ">
                <div className="d-flex flex-row justify-content-between py-2 mt-2 mb-2 ">
                  <div
                    className="px-2 py-2 "
                    style={{
                      background: "#EFEEFE",
                      color: "#1F21C4",
                      fontWeight: "bold",
                    }}
                  >
                    Add New
                  </div>
                  {action == "View" && (
                    <div onClick={() => backButtonHandler()}>
                      <img src={busViewClose}></img>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Bus Number<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Control
                        name="reg_no"
                        placeholder="Bus Number"
                        onChange={setFieldValueHandler}
                        value={busDetails.reg_no}
                        disabled={action === "View" || action === "Edit"}
                        style={{ backgroundColor: "#fff" }}
                      />
                      {error.lengthCheck && (
                        <p className="text-danger">{lengthcheckMsg}</p>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Select Driver (Optional)</Form.Label>
                      <Form.Control
                        type=""
                        placeholder="Select Driver"
                        name="driver_id"
                        onClick={() => setShowModal(true)}
                        value={busDetails.driver_id}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 d-flex align-items-center justify-content-center">
                    <div className="file-upload-bus">
                      <div
                        className="file-upload-inner-bus square border d-flex flex-column align-items-center justify-content-center"
                        onClick={() => {
                          if (action !== "View") inputRef1.current.click();
                        }}
                      >
                        <div>
                          <img
                            className="uploaded-img"
                            src={selectedImage || ArrowUp}
                            alt="arrow"
                          />
                          <input
                            name="rc_front_url"
                            ref={inputRef1}
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileSelect}

                          />
                        </div>
                        <p className="mt-2">Front photo of RC</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex align-items-center justify-content-center">
                    <div className="file-upload-bus">
                      <div
                        className="file-upload-inner-bus square border d-flex flex-column align-items-center justify-content-center"
                        onClick={() => {
                          if (action !== "View") inputRef2.current.click();
                        }}
                      >
                        <div>
                          <img
                            className="uploaded-img"
                            src={selectedImageBack || ArrowUp}
                            alt="arrow"
                          />
                          <input
                            name="rc_back_url"
                            ref={inputRef2}
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileSelect}

                          />
                        </div>
                        <p className="mt-2">Rear photo of RC</p>
                      </div>
                    </div>
                  </div>
                </div>
                {(action == "Add" || action == "Edit") && (
                  <p className="text-danger note-text">
                    <span>
                      <LabelImportantIcon />
                    </span>
                    : Kindly complete all the required fields to submit the
                    form.
                  </p>
                )}
              </div>
              {action !== "View" && (
                <div className="d-flex justify-content-start mt-3 ms-4 gap-2">
                  {action !== "Edit" && (
                    <Button
                      style={{ padding: "10px 40px" }}
                      variant="outline-primary"
                      onClick={handleReset}
                    >
                      <b>Reset</b>
                    </Button>
                  )}

                  <div>
                    <Button
                      className="loginButton reset-btn"
                      variant="primary"
                      type="submit"
                      disabled={handleDisablebtn()}
                    >
                      {action == "Edit" ? "Update" : "Save"}
                    </Button>
                  </div>
                </div>
              )}
            </div>

            {showModal && (
              <SelectDriverModal
                showModal={showModal}
                closeModal={closeModal}
                setBusDetails={setBusDetails}
                busDetails={busDetails}
              />
            )}
          </>
        </form>
      ) : action == "viewbushistory" ? (
        <>
          {action != "View" && (
            <div className=" px-1">
              <span className="back-btn" onClick={() => setAction("View")}>
                <img src={backBtnIcon} alt="" />

                <span>Back</span>
              </span>
            </div>
          )}
          <TableComp tableData={tableData} isSearchBar={true} isFilter={true} />
        </>
      ) : (
        <div id="doctorId" className="py-2 box-shadow-cls">
          <div className="box-border">
            <br />

            <div className="d-flex flex-column mx-0 mx-md-3  flex-md-row flex-lg-row flex-lg-row justify-content-between align-items-center align-items-md-end mt-4">
              <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
                <label className="label-input">
                  <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search by name"
                    onChange={handleSearchInput}
                    value={searchText}
                  />
                  {searchText && (
                    <button
                      className="search-btn-close"
                      onClick={handleSearchClear}
                    >
                      <img src={searchCloseIcon} alt="" width={"24px"} />
                    </button>
                  )}
                  <button className="search-btn" onClick={searchHandler}>
                    <img src={searchIcon} alt="" />
                    <span>Search</span>
                  </button>
                </label>
              </div>

              <div className="text-end">
                <button
                  className="add-btn"
                  onClick={() => {
                    setBusDetails(initialState);
                    setAction("Add");
                  }}
                >
                  <img src={addIcon} alt="" style={{ marginRight: "10px" }} />
                  Add New Bus
                </button>
              </div>
            </div>
            <div className="">
              <div className="patientdo" style={{ margin: "2%" }}>
                {allData.length ? (
                  <table className="" style={{ width: "100%", margin: 0 }}>
                    <thead>
                      <tr>
                        <th>Bus No</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allData?.map((item, index) => {
                        const { reg_no } = item;
                        return (
                          <tr key={index}>
                            <td>{reg_no}</td>

                            <td>
                              <span
                                onClick={() =>
                                  handleAction("View", item.id, item?.reg_no)
                                }
                                className="me-1"
                              >
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={view}
                                  alt=""
                                />
                              </span>
                              <span
                                className="ms-1"
                                onClick={() =>
                                  handleAction("Edit", item?.id, item?.reg_no)
                                }
                              >
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={edit}
                                  alt=""
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : null}

                {allData.length ? (
                  <div className="flex justify-content-end table-page">
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[5, 10, 25]}
                      count={count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                ) : (
                  <div
                    className="text-center d-flex justify-content-center
            align-items-center"
                    style={{ minHeight: "10rem", fontSize: "24px" }}
                  >
                    <p>No Records Found!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Bus;
