import React, { useState } from "react";
import Layout from "../../LayoutComp/Layout";
import "./parmacistView.scss";
import searchCloseIcon from "../../../Assets/icons/search-close-icon.png";
import searchIcon from "../../../Assets/icons/search_icon.svg";
import { useNavigate } from "react-router";
import whitEye from "../../../Assets/icons/view-white.svg";
import { useParams } from "react-router-dom";
import { readService } from "../../../Services/HttpService";
import { useEffect } from "react";
import { TablePagination } from "@mui/material";
import { backBtnIcon } from "../../../Utils/ImageUtils";

const ParmacistView = ({ sideNavState, setSideNavState }) => {
  const [searchText, setSearchText] = useState("");
  const params = useParams();

  const [allData, setAllData] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const navigate = useNavigate();
  const handleSearchClear = () => {
    setSearchText("");
  };
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    getPatients(5, 0, 1);
  }, []);

  const getPatients = async (limit, offset, updateCount) => {
    const result = await readService(
      `patients/${params?.id}?limit=${limit}&offset=${offset}&reg_no=${searchText}`
    );

    setAllData(result?.data?.body);
    if (updateCount) {
      setCount(result.data.count);
    }
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPage * pageIndex;
    getPatients(rowsPerPage, offSet, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getPatients(event.target.value, 0, 0);
  };
  return (
    <Layout
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
      hidenav={true}
    >
      <div id="parmacist">
        <div className="px-4">
          <span
            className="back-btn"
            onClick={() => navigate("/home/parmacist")}
          >
            <img src={backBtnIcon} alt="" />

            <span>Back</span>
          </span>
        </div>
        {allData?.length ? (
          <div className="d-flex flex-column  flex-md-row flex-lg-row flex-lg-row  align-items-center align-items-md-end pt-4">
            <div
              className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4"
              style={{ marginLeft: "20px" }}
            >
              <label className="label-input">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search by name"
                  onChange={handleSearchInput}
                  value={searchText}
                />
                {searchText && (
                  <button
                    className="search-btn-close"
                    onClick={handleSearchClear}
                  >
                    <img src={searchCloseIcon} alt="" width={"24px"} />
                  </button>
                )}

                <button
                  className="search-btn"
                  //    onClick={()=>searchHandler()}
                >
                  <img src={searchIcon} alt="" />
                  <span>Search</span>
                </button>
              </label>
            </div>
          </div>
        ) : null}

        <div className="patientdo" style={{ margin: "2%" }}>
          {allData?.length ? (
            <table className=" " style={{ width: "100%", margin: "0" }}>
              <thead>
                <tr>
                  <th>Sl No</th>
                  <th>Patient Name</th>
                  <th>Gender</th>
                  <th>Mobile No.</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allData?.map((item, i) => {
                  const { contact_number, gender, full_name, id } = item;
                  return (
                    <tr
                      onClick={() =>
                        navigate(
                          `/home/medicineDetails/${id}?healthCampId=${params?.id}`
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>
                        <>{i}</>
                      </td>
                      <td>{full_name}</td>
                      <td>{gender}</td>
                      <td>{contact_number}</td>
                      <td>
                        <div
                          className="d-flex justify-content-center"
                          // onClick={generalInfoHandler}
                        >
                          <p className="view-all px-2 py-1">
                            <img src={whitEye}></img>
                            {"View"}
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div
              style={{
                display: "flex",
                height: "30vh",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 24,
              }}
            >
              No Record Found
            </div>
          )}

          {allData?.length ? (
            <div className="flex justify-content-end table-page">
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default ParmacistView;
