import React, { useEffect } from "react";
import "./RenderSelect.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavItem } from "react-bootstrap";
import { Typography } from "@mui/material";

const GenderSelect = (props) => {
  const { value, options, name, onChange,requiredprop, placeholder, title, defaultValue } =
    props;
  //  useEffect(() => {
  //    if (options && options.length > 0 && !value) {
  //      onChange(name, options.id);
  //    }
  //  }, [options, name, onChange, value]);
  return (
    <>
      {title && (
        <Typography className="title-text">
          {title}
          {requiredprop && <span className="required-field">*</span>}
        </Typography>
      )}

      <Select
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        fullWidth
        size="small"
      >
        {options?.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.value}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default GenderSelect;
