import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./AlertDialogScreen.css";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

function AlertDialogScreen(props) {
  const { setShowSpinner, username, password } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShowSpinner(false);
    props.onClose();
  };

  async function signIn() {
    // console.log("called ", username, password)
    setShowSpinner(true);
    try {
      const user = await Auth.signIn(username, password);
      console.log("login successfull", user);
      if (user) {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          //   setNewPassword(true)
          const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword // the new password
            // OPTIONAL, the required attributes
            // {
            //   email: username,
            //   phone_number: '1234567890'
            // }
          )
            .then((user) => {
              // at this time the user is logged in if no MFA required

              setShowSpinner(false);
              navigate("/home/doctors");
              console.log(user);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          // other situation
          setShowSpinner(false);
          navigate("/home/doctors");
        }
      }
    } catch (error) {
      console.log(error);
      alert("user doesn't exists");
      setShowSpinner(false);
    }
  }

  return (
    <>
      <Modal
        className="alert-modal-container"
        show={props.isHidden}
        onHide={handleClose}
        centered
      >
        <div className="closeIcon">
          <IconButton>
            <CloseIcon style={{ color: "red" }} onClick={handleClose} />
          </IconButton>
        </div>
        <Modal.Body className="alert-modal-body">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Enter New Password</Form.Label>
            <Form.Control
              type=""
              placeholder="Enter new password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <div
          className="d-flex justify-content-center"
          style={{ marginBottom: "10px" }}
        >
          <Button
            className="loginButton"
            variant="primary"
            type="button"
            onClick={signIn}
          >
            Login
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default AlertDialogScreen;
