import React, { useEffect, useState } from "react";
import "./HealthCamp.scss";
import searchIcon from "../../Assets/icons/search_icon.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import filterIcon from "../../Assets/icons/filter_icon.svg";
import { useNavigate } from "react-router-dom";
import CommonFilterModal from "../CommonFilterModal/CommonFilterModal";
import view from "../../Assets/icons/view.svg";
import { readService } from "../../Services/HttpService";
import { TablePagination } from "@mui/material";
import Spinner from "../Spinner/Spinner";
import {
  getHealthCampByName,
  readHelthCampCoordinator,
} from "../../Services/Coordinator";
import { format24HrDateOnly } from "../../Utils/DateUtils";
import edit from "../../Assets/icons/edit.svg";

export default function HealthCamp({ sideNavState, setSideNavState }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [healthCampList, setHealthCampList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [offset, setOffset] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [hospitalId, setHospitalId] = useState();
  const [filterDataObj, setFilterDataObj] = useState({});

  // console.log("healthcamp list===================", healthCampList)

  useEffect(() => {
    if (searchText === "") {
      getHealthCampList(offset, searchText, filterDataObj);
    }
  }, [offset, searchText]);

  const getFilterObject = (param) => {
    setFilterDataObj(param);
  };
  console.log();

  useEffect(() => {
    getHealthCampList(offset, searchText, filterDataObj);
  }, [filterDataObj]);

  useEffect(() => {
    getHealthCampList(0, searchText, filterDataObj);
  }, []);

  const closeModal = () => {
    setShowModal(!showModal);
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = pageSize * pageIndex;
    getHealthCampList(offset, searchText.filterDataObj);
    setOffset(offset);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchClick = async () => {
    const params = {
      name: searchText,
    };
    try {
      const response = await getHealthCampByName(params);
      setHealthCampList(response.data.body);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleSearchClear = () => {
    setSearchText("");
    getHealthCampList(offset, "", filterDataObj);
  };

  const navigateToCreateHealthCamp = (items, actions) => {
    navigate("../createhealthcamp", {
      state: {
        hospitalId: hospitalId,
        actions: actions,
        healthCampId: items.id,
      },
    });
  };

  const onViewHealthCampClick = (items) => {
    navigate("../healthandwellness", {
      state: {
        campId: items._id,
        healthCampId: items?.id,
        healthCampList: items,
      },
    });
  };

  useEffect(() => {
    const readMyProfile = async () => {
      try {
        setShowSpinner(true);
        let response = await readService("./users/myprofile");
        if (response) {
          setHospitalId(response.data.works_at);
        }
      } catch (e) {
        console.log("Error while fetching Health camp list", e);
        setShowSpinner(false);
      }
    };
    readMyProfile();
  }, []);

  const getHealthCampList = async (offset, searchText, filterText = {}) => {
    let param = {
      offset: offset,
      limit: pageSize,
      ...filterText,
    };

    if (searchText) param = { ...param, searchText: searchText };

    if (filterText.state && filterText.state !== "DEFAULT")
      param = { ...param, state: filterText.state };

    if (filterText.city && filterText.city !== "DEFAULT")
      param = { ...param, city: filterText.city };

    try {
      setShowSpinner(true);
      let response = await readHelthCampCoordinator(param);
      if (response) {
        setHealthCampList(response.data.body);
        setTotalRecords(response.data.count);
        setOffset(response.data.offset);
        setShowSpinner(false);
      }
    } catch (e) {
      console.log("Error while fetching Health camp list", e);
      setShowSpinner(false);
    }
  };

  return (
    <div
      id="healthCamp_container"
      style={{
        width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
        marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
        transition: "0.5s",
        position: "absolute",
      }}
    >
      <div className="healthCamp-table-container">
        <div className="row mx-3 my-4">
          <div className="col-4">
            <label className="label-input">
              <input
                type="text"
                className="form-control search-input"
                placeholder="Search by name"
                onChange={handleSearchInput}
                value={searchText}
              />
              {searchText && (
                <button
                  className="search-btn-close"
                  onClick={handleSearchClear}
                >
                  <img src={searchCloseIcon} alt="" width={"24px"} />
                </button>
              )}
              <button className="search-btn" onClick={handleSearchClick}>
                <img src={searchIcon} alt="" />
                <span>Search</span>
              </button>
            </label>
          </div>
          <div className="col-3">
            <button className="filter-btn" onClick={() => setShowModal(true)}>
              <span>Filter</span>
              <img src={filterIcon} alt="" className="ps-2" />
            </button>
          </div>
          <div className="col-5 text-end">
            <button
              className="create-health-camp-btn"
              onClick={() => navigateToCreateHealthCamp("", "Create")}
            >
              Create Health Camp
            </button>
          </div>
        </div>

        <table className="healthcamp-table">
          {healthCampList?.length ? (
            <>
              <thead>
                <tr>
                  <th>Camp ID</th>
                  <th>Camp Name</th>
                  <th>State</th>
                  <th>District</th>
                  <th>City</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Time</th>
                  <th>Action</th>
                </tr>
              </thead>
            </>
          ) : (
            ""
          )}

          <tbody>
            {healthCampList &&
              healthCampList.map((items, index) => {
                return (
                  <tr>
                    <td>{items.id}</td>
                    <td>{items.name}</td>
                    <td>{items.state}</td>
                    <td>{items.district}</td>
                    <td>{items.place}</td>
                    <td>{format24HrDateOnly(items.from_date)}</td>
                    <td>{format24HrDateOnly(items.to_date)}</td>
                    <td>{items.start_time}</td>
                    <td>
                      <img
                        src={view}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => onViewHealthCampClick(items)}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <span
                        onClick={() =>
                          navigateToCreateHealthCamp(items, "Update")
                        }
                      >
                        <img style={{ cursor: "pointer" }} src={edit} alt="" />
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {healthCampList?.length === 0 && (
          <div className="warning-text-card">No Records Found!</div>
        )}
        <div className="flex justify-content-end table-page">
          {healthCampList?.length > 0 && (
            <TablePagination
              component="div"
              count={totalRecords}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[5, 10, 20, 40]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
      {
        <CommonFilterModal
          isHidden={showModal}
          onClose={closeModal}
          getFilterObject={getFilterObject}
          comp="HalthCamp"
          filterDataObj={filterDataObj}
        />
      }
      {showSpinner && <Spinner />}
    </div>
  );
}
