import React, { useRef, useState, useEffect } from "react";
import backBtnIcon from "../../Assets/icons/back.svg";
import close_icon from "../../Assets/icons/close-rounded.svg";
import Avatar from "../../Assets/Images/avatar.png";
import StatusIcon from "../../Assets/icons/Status.svg";
import view from "../../Assets/icons/view.svg";
import edit from "../../Assets/icons/edit.svg";
import "./coordinator.scss";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import searchIcon from "../../Assets/icons/search_icon.svg";
import { Button, Form, InputGroup } from "react-bootstrap";
import filterIcon from "../../Assets/icons/filter_icon.svg";
import addIcon from "../../Assets/icons/addIcon.svg";
import { TablePagination } from "@mui/material";
import Layout from "../LayoutComp/Layout";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";

import FilterModal from "../Modals/FilterModal/FilterModal";
// import { Formik, Field, ErrorMessage } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import {
  addCordinator,
  getCoordinatorById,
  getCoordinatorByName,
  readCordinator,
  updateCoordinator,
} from "../../Services/Coordinator";
import {
  ConvertYMD,
  format24HrDateOnly,
  formatDateFunc,
} from "../../Utils/DateUtils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  getBooldGroup,
  getDistrictsByState,
  getGenders,
  getPlacesByDistrict,
  getQualifications,
  getStates,
} from "../../Services/Metadata/Metadata";
import { readMCH } from "../../Services/MCH";
import ArrowUp from "../../Assets/icons/cloud-arrow-up.svg";
import { readService, updateService } from "../../Services/HttpService";
import CoordinatorFilterModal from "./CoordinatorFilter/CoordinatorFilterModal";
import CommonFilterModal from "../CommonFilterModal/CommonFilterModal";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { errorToast, successToast } from "../../Utils/toasters";
import { enqueueSnackbar } from "notistack";
import { ContactValidator, aadharNumber } from "../../Utils/Validators";

const CoPathologist = ({ sideNavState, setSideNavState }) => {
  const [action, setAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [date, setDateValue] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [qualification, setQualification] = useState(null);
  const [data, setData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [districtId, setDestrictId] = useState(null);
  const [placeId, setPlaceId] = useState(null);
  const [districtData, setDistrictData] = useState([]);
  const [placesData, setPlacesData] = useState([]);
  const [bloodGroup, setBloodGroup] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [qualificationData, setQualificationData] = useState([]);
  const [gender, setGender] = useState([]);
  const [offset, setOffset] = useState(0);
  const [filterDataObj, setFilterDataObj] = useState({});
  const [coordinateId, setCoordinatId] = useState(null);
  

  const { userProfile } = useSelector((state) => state.user);
  console.log("userProfile", userProfile);
  const [initialValues, setInitialValues] = useState({
    name: "",
    contact_number: "",
    state_id: "selected",
    district_id: "selected",
    place_id: "selected",
    blood_group: "select",
    dob: "",
    gender: "select",
    doc_type: "",
    doc_number: "",
    username: "",
    password: "",
    // works_at: userProfile.works_at,
    works_at: "select",
  });

  const inputRef = useRef(null);
  const [fileData, setFileData] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [mchSelect, setMchSelect] = useState(null);
  const [message, setMessage] = useState(null);
  const [aadharmessage, setAadharMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState({
    contact_number: false,
    password: false,
    aadhar: false,
  });
  const maxDate = new Date(2005, 0, 1);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const maxSize = Math.min(img.width, img.height);

          canvas.width = maxSize;
          canvas.height = maxSize;

          context.beginPath();
          context.arc(maxSize / 2, maxSize / 2, maxSize / 2, 0, 2 * Math.PI);
          context.closePath();
          context.clip();

          context.drawImage(img, 0, 0, maxSize, maxSize);

          canvas.toBlob((blob) => {
            setSelectedImage(file);
            setFileData(blob);
            setCroppedImage(new File([blob], file.name, { type: "image/png" }));
          }, "image/png");
        };
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    getAllCoordinator(0, searchText, filterDataObj);
  }, [pageSize, page, searchText, filterDataObj]);

  const getAllCoordinator = async (offSet = 0, searchText, filterText = {}) => {
    // console.log("caalled", offSet, searchText)
    // setShowSpinner(true);
    const params = {
      limit: pageSize,
      offset: offSet,
      searchText: searchText,
      ...filterText,
    };

    // if (searchText) params = { ...params, searchText: searchText };
    const { data } = await readCordinator(params);
    if (data) {
      setData(data.body);
      // setShowSpinner(false);
      setTotalRecords(data.count);
      setOffset(data.offset);
    }
  };

  useEffect(() => {
    const getAllHospitals = async () => {
      try {
        const response = await readService("imshospitals");
        console.log("response---->", response)
        setHospitals(response.data.body);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    getAllHospitals();
  }, [action, showModal]);
  useEffect(() => {
    const getGendersData = async () => {
      try {
        const response = await getGenders();
        setGender(response.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    const getQualification = async () => {
      try {
        const response = await getQualifications();
        setQualificationData(response.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    const getBooldGroupData = async () => {
      try {
        const response = await getBooldGroup();
        setBloodGroup(response.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    getGendersData();
    getQualification();
    getBooldGroupData();
  }, [action]);

  useEffect(() => {
    async function getData() {
      let state = await getStates();
      setStateData(state.data);
    }
    getData();
  }, []);

  const getFilterObject = (param) => {
    setFilterDataObj(param);
  };

  useEffect(() => {
    if (action === "Edit" || action === "View") {
      const getCoordinatorDataById = async () => {
        const params = {
          id: coordinateId,
        };
        try {
          const response = await getCoordinatorById(params);
          response.data.body?.map((item) =>
            setInitialValues({
              name: item.name,
              contact_number: item.contact_number,
              place_id: item.place_id,
              blood_group: item.blood_group,
              gender: item.gender,
              doc_type: item.doc_type,
              doc_number: item.doc_number,
              username: item.username,
              password: item.password,
            })
          );
          setMchSelect(response?.data?.body[0]?.works_at);
          setQualification(response?.data?.body[0]?.qualification);
          setStateId(response?.data?.body[0]?.state_id);
          setDestrictId(response?.data?.body[0]?.district_id);
          setPlaceId(response?.data?.body[0]?.place_id);
          setDateValue(new Date(response?.data?.body[0]?.dob));
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };
      getCoordinatorDataById();
    }
  }, [action, coordinateId]);

  const getDistrict = async (e) => {
    setStateId(e.target.value);
    setDestrictId("");
    let district = await getDistrictsByState(e.target.value);
    setDistrictData(district.data);
  };

  const getPlaces = async (e) => {
    setDestrictId(e.target.value);
    setPlaceId("");
    let places = await getPlacesByDistrict(e.target.value);
    setPlacesData(places.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.delete("image");
    formData.delete("qualification");
    formData.append("image", selectedImage);
    formData.append("dob", ConvertYMD(date));
    // const arr = [];
    // arr.push(qualification);
    formData.append(`qualification`, qualification);
    if (action !== "Edit") {
      try {
        const response = await addCordinator(formData);
        if (response.status < 200 || response.status > 300) {
          errorToast(enqueueSnackbar, response.data);
        } else {
          successToast(enqueueSnackbar, "Created Successfully!!");
          setPage(0);
          setAction(null);
        }
        console.log(response.data);
      } catch (err) {
        console.log(err);
      }
    }
    const response = await updateService(
      `/coordinators/${coordinateId}`,
      {},
      formData
    );
    if (response.status < 200 || response.status > 300) {
      let msg = response?.data?.errors.map((err) => err.msg);
      errorToast(enqueueSnackbar, msg.join(""));
    } else {
      setAction(null);
      handleReset();
      successToast(enqueueSnackbar, response.data.message);

      console.log(response.data);
    }
  };
  const backButtonHandler = () => {
    setAction(null);
    setInitialValues({
      name: "",
      contact_number: "",
      state_id: "select",
      district_id: "",
      place_id: "",
      blood_group: "select",
      dob: "",
      gender: "select",
      doc_type: "",
      doc_number: "",
      username: "",
      password: "",
      works_at: "select",
    });
    setQualification(null);
    setDateValue(null);
  };
  const handleReset = () => {
    setInitialValues({
      name: "",
      contact_number: "",
      state_id: "select",
      district_id: "",
      place_id: "",
      blood_group: "select",
      dob: "",
      gender: "select",
      doc_type: "",
      doc_number: "",
      username: "",
      password: "",
      qualification: "",
      works_at: "select",
    });
    setQualification(null);
    setDateValue(null);
  };
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  const handleSearchClear = () => {
    setSearchText("");
    getAllCoordinator(offset, "", filterDataObj);
  };
  const searchHandler = async () => {
    const params = {
      name: searchText,
    };
    try {
      const response = await getCoordinatorByName(params);
      setData(response.data.body);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleAction = (actionVal, id) => {
    setAction(actionVal);
    setCoordinatId(id);
  };
  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = pageSize * pageIndex;
    getAllCoordinator(offset, searchText, filterDataObj);
    setOffset(offset);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFieldChange = (event) => {
    if (event.target.name === "contact_number") {
      const value = event.target.value.slice(0, 12);
      // setFieldValue("doc_number", value);
    } else {
      const value = event.target.value.slice(0, 10);
      // setFieldValue("contact_number", value);
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "contact_number") {
      ContactValidator(setError, setMessage, value);
    }
    if (name == "doc_number") {
      aadharNumber(setError, setAadharMessage, value);
    }
    setInitialValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDate = (date) => {
    setDateValue(date);
  };

  const closeModal = () => {
    setShowModal(!showModal);
  };
  const handleDisablebtn = () => {
    if (
      error.contact_number == true ||
      initialValues.contact_number == "" ||
      initialValues.name == "" ||
      error.aadhar == true ||
      // !initialValues.state_id ||
      // !initialValues.district_id ||
      // !initialValues.place_id ||
      //  !fieldValue.email ||
      //  !fieldValue.dob ||
      !initialValues.gender ||
      !mchSelect ||
      //  !fieldValue.consult_fee ||
      //  !fieldValue.experience ||
      //  fieldValue?.specialisation[0]?.length === 0 ||
      //  fieldValue?.qualification[0]?.length === 0 ||
      !initialValues.username ||
      (action === "Add" && !initialValues.password)
      // || fieldValue.password.length < 8
    ) {
      return true;
    } else {
    }
  };
  return (
    <Layout
      id="doctorId"
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
    >
      {action == "Add" || action == "Edit" || action == "View" ? (
        <form noValidate onSubmit={handleSubmit}>
          <>
            <div className="add-doctor-form">
              {action != "View" && (
                <div className=" px-1">
                  <span
                    className="back-btn"
                    onClick={() => backButtonHandler()}
                  >
                    <img src={backBtnIcon} alt="" />

                    <span>Back</span>
                  </span>
                </div>
              )}

              <div className="form-style px-3 py-3">
                <div className="d-flex flex-row justify-content-between ">
                  <h5>
                    {action == "Add"
                      ? "Add"
                      : action == "Edit"
                        ? "Edit"
                        : "View"}{" "}
                    Co-Ordinator
                  </h5>
                  {action === "View" && (
                    <div>
                      <img
                        src={close_icon}
                        alt=""
                        onClick={() => setAction(null)}
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex row justify-content-start">
                  {/* <div className="col-lg-4 col-md-6 col-sm-12"> */}
                  {/* <div className="col-lg-4 col-sm-12 col-md-6 mr-70">
                      <div
                        className="img-upload d-flex flex-column align-items-center justify-content-center"
                        onClick={() => inputRef.current.click()}
                        style={{ cursor: "pointer" }}
                      >
                        {!croppedImage && (
                          <div className="file-upload square border d-flex flex-column align-items-center justify-content-center">
                            <div>
                              <img src={ArrowUp} alt="arrow" />
                              <input
                                ref={inputRef}
                                type="file"
                                name="image"
                                style={{ display: "none" }}
                                onClick={() => inputRef.current.click()}
                                onChange={handleImageUpload}
                                accept="image/png, image/jpeg"
                              />
                            </div>
                            <div className="upload-box d-flex align-items-center justify-content-center">
                              <p>Drop your image here</p>
                            </div>
                          </div>
                        )}
                        {croppedImage && (
                          <>
                            <input
                              ref={inputRef}
                              type="file"
                              name="image"
                              style={{ display: "none" }}
                              onClick={() => inputRef.current.click()}
                              onChange={handleImageUpload}
                              accept="image/png, image/jpeg"
                            />
                            <div style={{ width: "250px" }}>
                              <img
                                src={URL.createObjectURL(croppedImage)}
                                width={250}
                                height={250}
                                alt="Cropped"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div> */}
                  {/* <div className="d-flex row"> */}
                  <div className="fieldStyle col-lg-4 col-sm-12 col-md-6">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        Name<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={initialValues.name}
                        placeholder="Enter a name"
                        name="name"
                        onChange={onChangeHandler}
                      />
                    </Form.Group>
                  </div>
                  <div className="fieldStyle col-lg-4 col-sm-12 col-md-6">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        Contact Number<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        maxLength={10}
                        value={initialValues.contact_number}
                        onChange={onChangeHandler}
                        placeholder="Enter a phone number"
                        name="contact_number"
                      />
                      {error.contact_number && (
                        <p className="text-danger">{message}</p>
                      )}
                    </Form.Group>
                  </div>
                  <div className="fieldStyle col-lg-4 col-sm-12 col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={stateId}
                        defaultValue={"selected"}
                        onChange={getDistrict}
                        name="state_id"
                      >
                        <option selected="selected" value="">
                          -- Select State --
                        </option>
                        {stateData.length > 0 &&
                          stateData.map((state, index) => (
                            <option value={state.id} key={index}>
                              {state.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {console.log(" ==========> initialValues", initialValues)}
                  <div className="fieldStyle col-lg-4 col-sm-12 col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>District</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={districtId}
                        onChange={getPlaces}
                        name="district_id"
                        defaultValue={"selected"}
                      >
                        <option disabled selected="selected" value="">
                          -- Select District --
                        </option>
                        {districtData.length > 0 &&
                          districtData.map((district, index) => (
                            <option value={district.id} key={index}>
                              {district.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>

                  <div className="fieldStyle col-lg-4 col-sm-12 col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Place</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="place_id"
                        onChange={(e) => setPlaceId(e.target.value)}
                        value={placeId}
                      >
                        <option disabled selected="selected" value="">
                          -- Select Place --
                        </option>
                        {placesData?.length > 0 &&
                          placesData.map((place, index) => (
                            <option value={place.id} key={index}>
                              {place.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="fieldStyle col-lg-4 col-sm-12 col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Blood Group</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="blood_group"
                        onChange={onChangeHandler}
                        value={initialValues.blood_group}
                      >
                        <option disabled selected="selected" value="select">
                          -- Select Blood Group --
                        </option>
                        {bloodGroup?.length > 0 &&
                          bloodGroup.map((blood, index) => (
                            <option value={blood.group} key={index}>
                              {blood.group}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
                <div className="d-flex row justify-content-start">
                  <div
                    className="fieldStyle col-lg-4 col-md-6 col-sm-12 text-start date-picker-cls"
                  //   style={{ width: "30%" }}
                  >
                    <label htmlFor="dob">Date of Birth</label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        wrapperClassName="datepicker"
                        className="customDatePicker"
                        value={date}
                        onChange={handleDate}
                        disableFuture
                        maxDate={maxDate}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="fieldStyle col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Gender<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="gender"
                        onChange={onChangeHandler}
                        value={initialValues.gender}
                      >
                        <option disabled selected="selected" value={"select"}>
                          -- Select Gender --
                        </option>
                        {gender?.length > 0 &&
                          gender.map((gender, index) => (
                            <option value={gender.gender} key={index}>
                              {gender.gender}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div
                    className="fieldStyle col-lg-4 col-md-6 col-sm-12"
                    style={{ marginBottom: "20px" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Document Type</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="doc_type"
                        onChange={onChangeHandler}
                        value={initialValues.doc_type}
                      >
                        <option value="">Select</option>
                        {/* <option value="PAN Card">PAN Card</option> */}
                        <option value="AADHAR">Adhar Card</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div
                    className="fieldStyle col-lg-4 col-md-6 col-sm-12"
                  //   style={{ width: "30%" }}
                  >
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Doc Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter a doc number"
                        name="doc_number"
                        value={initialValues.doc_number}
                        onChange={onChangeHandler}
                      />
                      {error.aadhar && (
                        <p className="text-danger">{aadharmessage}</p>
                      )}
                    </Form.Group>
                  </div>
                  <div
                    className="fieldStyle col-lg-4 col-md-6 col-sm-12"
                  //   style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Qualification</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="qualification"
                        value={qualification}
                        onChange={(e) => setQualification(e.target.value)}
                      >
                        <option disabled selected="selected" value={"select"}>
                          -- Select Qualification --
                        </option>
                        {qualificationData?.length > 0 &&
                          qualificationData.map((qualification, index) => (
                            <option
                              value={qualification.degree}
                              key={qualification.id}
                            >
                              {qualification.degree}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {action == "Add" && (
                    <div
                      className="fieldStyle col-lg-4 col-md-6 col-sm-12"
                      style={{ marginBottom: "20px" }}
                    >
                      {/* <label htmlFor="state">Username</label>
                        <Field
                          type="text"
                          id="username"
                          name="username"
                          placeholder="Enter the username"
                          className="input-field"
                        /> */}

                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Username<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={initialValues.username}
                          onChange={onChangeHandler}
                          placeholder="Enter a username"
                          name="username"
                        />
                      </Form.Group>
                    </div>
                  )}

                  {action == "Add" && (
                    <div
                      className="fieldStyle col-lg-4 col-md-6 col-sm-12"
                    //   style={{ width: "30%" }}
                    >
                      {/* <label htmlFor="state">Password</label>
                        <Field
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Enter the paassword"
                          className="input-field"
                        /> */}
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Password<span className="required-field">*</span>
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={!showPassword ? "password" : "text"}
                            name="password"
                            placeholder="Enter a password"
                            onChange={onChangeHandler}
                            value={initialValues.password}
                          />
                          <div className="search-icon" onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </div>
                        </InputGroup>
                      </Form.Group>
                    </div>
                  )}

                  <div
                    className="fieldStyle col-lg-4 col-md-6 col-sm-12"
                  //   style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Select MCH<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="works_at"
                        value={mchSelect}
                        onChange={(e) => setMchSelect(e.target.value)}
                      >
                        <option disabled selected="selected" value="select">
                          -- Select MCH --
                        </option>
                        {hospitals?.length > 0 &&
                          hospitals?.map((hospital, index) => (
                            <option value={hospital.id} key={hospital.id}>
                              {hospital.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {(action == "Add" || action == "Edit") && (
                    <p className="text-danger note-text">
                      <span>
                        <LabelImportantIcon />
                      </span>
                      : Kindly complete all the required fields to submit the
                      form.
                    </p>
                  )}
                </div>
                {action !== "View" && (
                  <div className="d-flex justify-content-start mt-3 gap-2">
                    {action !== "Edit" && (
                      <Button
                        style={{ padding: "10px 40px" }}
                        //   className="reset-btn"
                        variant="outline-primary"
                        onClick={handleReset}
                      >
                        <b>Reset</b>
                      </Button>
                    )}

                    <div>
                      <Button
                        //   style={{ padding: "10px 40px" }}
                        className="loginButton reset-btn"
                        variant="primary"
                        type="submit"
                        disabled={handleDisablebtn()}
                      // onClick={handleClick}
                      >
                        {action == "Edit" ? "Update" : "Save"}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        </form>
      ) : (
        <div id="doctorId" className="py-2">
          <div
            className="d-flex flex-column flex-md-row flex-lg-row flex-lg-row justify-content-around align-items-center align-items-md-end mt-4"
            style={{ marginRight: "3rem", marginLeft: "3rem" }}
          >
            <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
              <label className="label-input">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search by name"
                  onChange={handleSearchInput}
                  value={searchText}
                />
                {searchText && (
                  <button
                    className="search-btn-close"
                    onClick={handleSearchClear}
                  >
                    <img src={searchCloseIcon} alt="" width={"24px"} />
                  </button>
                )}
                <button className="search-btn" onClick={searchHandler}>
                  <img src={searchIcon} alt="" />
                  <span>Search</span>
                </button>
              </label>
            </div>

            <div className="col-6">
              <button
                className="filter-btn ms-5"
                onClick={() => setShowModal(true)}
              >
                <span>Filter</span>
                <img src={filterIcon} alt="" className="ps-2" />
              </button>
            </div>

            <div className="text-end">
              <button
                className="add-btn"
                onClick={() => setAction("Add")}
                style={{ display: "flex", whiteSpace: "nowrap" }}
              >
                <img src={addIcon} alt="" style={{ marginRight: "10px" }} />
                Add new Co-Ordinator
              </button>
            </div>
          </div>
          <div
            className=""
          // style={{ padding: "10px", margin: "10px" }}
          >
            <div className="patientdo" style={{ margin: "2%" }}>
              <table className="" style={{ width: "100%", margin: 0 }}>
                {data?.length ? (
                  <>
                    <thead>
                      <tr>
                        <th>Co-Ordinator ID</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>MCH Name</th>
                        <th>State</th>
                        <th>District</th>
                        <th>Place</th>
                        {/* <th>Status</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                  </>
                ) : null}

                <tbody>
                  {data?.map((item, i) => {
                    return (
                      <tr>
                        <td>{item?.id}</td>
                        <td>
                          {/* <img
                            className="doctor-profile-img"
                            src={Avatar}
                            alt="profile_img"
                          /> */}
                          {item?.name}
                        </td>
                        <td>{item?.username}</td>
                        <td>{item?.works_at_name}</td>
                        <td>{item?.state}</td>
                        <td>{item?.district}</td>
                        <td>{item?.place}</td>
                        {/* <td>
                          <img src={StatusIcon} alt="profile_img" />
                        </td> */}
                        <td>
                          <span
                            onClick={() => handleAction("View", item?.id)}
                            className="me-1"
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={view}
                              alt=""
                            />
                          </span>
                          <span
                            className="ms-1"
                            onClick={() => handleAction("Edit", item?.id)}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={edit}
                              alt=""
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {data?.length === 0 && (
                <div className="warning-text-card">No Records Found!</div>
              )}
              <div className="flex justify-content-end table-page">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={totalRecords}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <CommonFilterModal
        isHidden={showModal}
        onClose={closeModal}
        getFilterObject={getFilterObject}
        comp="Cordinator"
        filterDataObj={filterDataObj}
        hospitals={hospitals}
      />
    </Layout>
  );
};

export default CoPathologist;
