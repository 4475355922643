import React, { useEffect, useState } from "react";
import Layout from "../../../LayoutComp/Layout";
import FullWidthTabs from "../../../TabComp/FullWidthTabs";
import GeneralInfoTab from "./GeneralInfoTab/GeneralInfoTab";
import HealtCenterPrePat from "./HealthCenterPrefPat/HealtCenterPrePat";
import Prescription from "./Prescription/Prescription";
import MedicalReport from "./MedicalReport/MedicalReport";
import DistributedMedicine from "./Distributed Medicine/DistributedMedicine";
import backBtnIcon from "../../../../Assets/icons/back.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { PatientGeneralInfo } from "../../../../Services/ImsPatient/Patient";
// import backBtnIcon from "../../Assets/icons/back.svg";
import "./view.scss";

const View = ({ sideNavState, setSideNavState }) => {
  const [patientInfo, setPatientInfo] = useState({});
  const location = useLocation();
  console.log("location--->", location);

  const childrenArry = [
    <GeneralInfoTab patientId={location?.state?.patientId?.id} />,
    <HealtCenterPrePat patientId={location?.state?.patientId?.id} />,
    <Prescription patientId={location?.state?.patientId?.id} />,
    <MedicalReport patientId={location?.state?.patientId?.id} />,
    // <DistributedMedicine />,
  ];
  const tabstitle = [
    "General Information",
    "Health Information",
    "Medical Diagnosis",
    "Medical report",
    // "Forum",
  ]

  const navigate = useNavigate();

  const backButtonHandler = () => {
    navigate(-1);
  };
  useEffect(() => {
    getPatientGeneralInfo();
  }, []);
  const getPatientGeneralInfo = async () => {
    const generalInfo = await PatientGeneralInfo(
      location?.state?.patientId?.id,
      {}
    );
    setPatientInfo(generalInfo.data);
  };
  console.log("patientInfo---->", patientInfo);

  return (
    <>
      <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
        <div style={{ margin: "0px 50px" }}>
          <span
            className="back-btn"
            onClick={() => backButtonHandler()}
            style={{ gap: 4, alignItems: "center", display: "flex" }}
          >
            <img src={backBtnIcon} alt="" />
            <span>Back</span>
          </span>
        </div>
        <div className="d-flex patient-info-header justify-content-between patient-info">
          <div className="d-flex ">
            <p>
              <span className="text-bold">Patient Name </span>:{" "}
              {patientInfo?.first_name}
            </p>
            <p className="ms-4">
              <span>Gender</span> : {patientInfo?.gender}
            </p>
            <p className="ms-4">
              <span>Age</span> : {patientInfo?.age}
            </p>
          </div>
          <div>
            <p>
              {" "}
              <span>Patient ID</span> : {patientInfo?.id}
            </p>
          </div>
        </div>
        <FullWidthTabs DoctorsTabData={childrenArry} tabstitle={tabstitle} />
      </Layout>
    </>
  );
};

export default View;
