import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import './CommonDeleteModal.scss'
import { useSelector } from 'react-redux';
import QuestionMark from "../../Assets/icons/questionMark.svg";
import { deleteLabReport } from '../../Services/Pathologist';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { errorToast, successToast } from '../../Utils/toasters';
import { enqueueSnackbar } from 'notistack';

export default function CommonDeleteModal(props) {
    const { id, comp, getAllData } = props;
    const { patientId } = useSelector(state => state.patientId)
    const [confirmatiomMsg, setConfirmationMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const closeFilterModal = () => {
        props.onClose();
    }

    const handleSubmit = async () => {
        if (comp === "pathologist") {
            try {
                let response = await deleteLabReport(patientId, id)
                if (response.status < 200 || response.status > 300) {
                    errorToast(enqueueSnackbar, response.data);
                  } else {
                    successToast(enqueueSnackbar, 'Lab Report deleted Successfully!')
                    setShowConfirmationModal(true);
                    getAllData();
                    props.onClose();
                  }
            } catch (err) {
                console.log(err)
            }
        }
    }

    const closeConfirmationModal = () => {
        setShowConfirmationModal(!showConfirmationModal);
    }

    return (
        <div>
            <Modal
                dialogClassName="delete-modal"
                backdrop="static"
                show={props.isHidden}
                onHide={props.onClose}
                centered
            >

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "25px" }} className="main-head">
                    <img src={QuestionMark} />
                </div>
                <div
                    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Modal.Body className="main-head">
                        <h2>Are You Sure Want to Delete<br></br> <span style={{ marginLeft: "122px" }}>This report?</span></h2>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="btn-card">
                            <button className="btn-apply" onClick={handleSubmit}>
                                Yes, Delete it!
                            </button>
                            <button className="btn-cancel" onClick={closeFilterModal}>
                                Cancel
                            </button>
                        </div>
                    </Modal.Footer>
                </div>
            </Modal>
            {
                <ConfirmationModal
                    isHidden={showConfirmationModal}
                    onClose={closeConfirmationModal}
                    message={confirmatiomMsg}
                />
            }
        </div>
    );
}
