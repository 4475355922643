import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "../../../../../Components/Doctorsvtwo/PatientTablePage/HealthCampPatient/Tabs/HealthInfo.scss";
import backBtnIcon from "../../../../../Assets/icons/back.svg";
import { useNavigate } from "react-router";
import "./HealthInfo.scss";
import moment from "moment";
import HealthInfoModal from "../../../../HealthInfoModal/HealthInfoModal";
import {
  getAllPatientVisits,
  getHealthBasicInfoData,
  getPatientsVisitsById,
} from "../../../../../Services/PatientBasicHealthInfo";
import ConfirmationModal from "../../../../ConfirmationModal/ConfirmationModal";
import { format24HrDateOnly, formatDate } from "../../../../../Utils/DateUtils";
import { useSelector } from "react-redux";
import TableComp from "../../../../NursesvTwo/NurseTableComp/TableComp";

const HealthInfo = (props) => {
  const [action, setAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { userProfile } = useSelector((state) => state.user);
  let { patientId } = useSelector((state) => state.patientId);
  const [patientVisits, setPatientVisits] = useState([]);
  const [allergies, setAllergies] = useState("");
  const [immuneDetails, setImmunDetails] = useState("");
  const [basicHealthInfoData, setBasicHealthInfoData] = useState("");
  const [surgeries, setSurgeries] = useState("");
  const [visitsDetails, setVisitsDetails] = useState([]);
  const [editItems, setEditItems] = useState({});
  const [familyHistory, setFamilyHistory] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [selectedBloodSample, setSelectedBloodSample] = useState("");
  const [selectedUrineSample, setSelectedUrineSample] = useState("");
  const [fieldValue, setFieldValue] = useState({
    weight: "",
    height: "",
    bp_systolic: "",
    bp_diastolic: "",
    pulse: "",
    respiratory_rate: "",
    grbs: "",
    temperature: "",
    spo2: "",
    notes: "",
    health_issues: "",
    prelim_diagnosis: "",
    nurse_advice: "",
  });

  // console.log("localtion is=====================", visitsDetails)

  useEffect(() => {
    const getPatientVisits = async () => {
      const params = {
        limit: rowsPerPage,
        offset: offset,
      };
      try {
        const response = await getAllPatientVisits(patientId, params);
        if (response) {
          setTotalRecords(response.data.count);
          setPatientVisits(response.data.body);
        }
      } catch (err) {
        // console.log("error is================", err)
      }
    };
    getPatientVisits();
  }, [rowsPerPage, page]);

  useEffect(() => {
    const getAllHealthBasicInfo = async () => {
      try {
        const response = await getHealthBasicInfoData(patientId);
        if (response) {
          setSelectedBloodSample(
            visitsDetails[0]?.blood_sample === true ? "yes" : "no"
          );
          setSelectedUrineSample(
            visitsDetails[0]?.urine_sample === true ? "yes" : "no"
          );
          setAllergies(response.data.allergies);
          setImmunDetails(response.data.immunisation_details);
          setSurgeries(response.data.surgery_history);
          setFamilyHistory(response.data.familyHistory);
          setBasicHealthInfoData(response.data);
        }
      } catch (err) {
        // console.log("error is================", err)
      }
    };
    getAllHealthBasicInfo();
  }, []);

  useEffect(() => {
    if (action === "view") {
      const getPatientVisitsById = async () => {
        try {
          const response = await getPatientsVisitsById(
            patientId,
            editItems?.visit_id
          );
          if (response) {
            setVisitsDetails(response.data);
          }
        } catch (err) {
          // console.log("error is================", err)
        }
      };
      getPatientVisitsById();
    }
  }, [editItems]);

  useEffect(() => {
    if (action === "view") {
      const getPatientVisitsById = async () => {
        try {
          const response = await getPatientsVisitsById(
            patientId,
            editItems?.visit_id
          );
          if (response) {
            setVisitsDetails(response.data);
          }
        } catch (err) {
          // console.log("error is================", err)
        }
      };
      getPatientVisitsById();
    }
  }, [editItems]);

  useEffect(() => {
    if (visitsDetails?.length !== 0 && action !== "Add" && action === "view") {
      setFieldValue({
        ...visitsDetails,
        weight: visitsDetails[0]?.weight,
        height: visitsDetails[0]?.height,
        bp_systolic: visitsDetails[0]?.bp_systolic,
        bp_diastolic: visitsDetails[0]?.bp_diastolic,
        pulse: visitsDetails[0]?.pulse,
        respiratory_rate: visitsDetails[0]?.respiratory_rate,
        grbs: visitsDetails[0]?.grbs,
        temperature: visitsDetails[0]?.temperature,
        spo2: visitsDetails[0]?.spo2,
        notes: visitsDetails[0]?.notes,
        health_issues: visitsDetails[0]?.health_issues,
        prelim_diagnosis: visitsDetails[0]?.prelim_diagnosis,
        nurse_advice: visitsDetails[0]?.nurse_advice,
      });
      setSelectedBloodSample(
        visitsDetails[0]?.blood_sample === true ? "yes" : "no"
      );
      setSelectedUrineSample(
        visitsDetails[0]?.urine_sample === true ? "yes" : "no"
      );
    }
  }, [editItems, action]);

  // console.log("patient info is===============", totalRecords)

  const navigateTo = () => {
    setAction("view");
  };
  const editNavigateTo = () => {
    setAction("edit");
  };

  const navigateBack = () => {
    navigate("/home/nursegeneralitp");
  };

  const thHeadData = ["S.No", "Date", "ActionNHealthInfo"];
  const extractedData = patientVisits?.map((obj, i) => {
    let index = i + 1;
    let ActionNHealthInfo = thHeadData[2];
    let { visit_time, visit_id } = obj;
    if (visit_time) {
      visit_time = format24HrDateOnly(visit_time);
    }
    return { index, visit_time, visit_id, ActionNHealthInfo };
  });

  return (
    <div>
      {action == "Add" ? (
        <>
          {" "}
          <form noValidate>
            <div id="nurseHealthInfo">
              <div className="py-2 px-3" style={{ cursor: "pointer" }}>
                <span className="back-btn-row" onClick={() => setAction(null)}>
                  <img src={backBtnIcon} alt="" />
                  <span>Back</span>
                </span>
              </div>
              <div className="text-center">
                <button
                  className="btn mb-2"
                  style={{ backgroundColor: "#191BC3", color: "white" }}
                >
                  Vitals
                </button>
                <div className="d-flex row">
                  <div className="col-lg-4 col-md-6 col-sm-12 text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">
                        Weight
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="weight"
                        name="weight"
                        value={fieldValue.weight}
                      />
                    </Form.Group>
                  </div>

                  <div className=" col-lg-4 col-md-6 col-sm-12 text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">
                        Height/Length
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="height"
                        name="height"
                        value={fieldValue.height}
                      />
                    </Form.Group>
                  </div>

                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">
                        Bp Systolic
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="by systolic"
                        name="bp_systolic"
                        value={fieldValue.bp_systolic}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">
                        Bp Diastolic
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="by diastolic"
                        name="bp_diastolic"
                        value={fieldValue.bp_diastolic}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">Pulse</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="pulse"
                        name="pulse"
                        value={fieldValue.pulse}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">
                        Respiratory Rate
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="respiratory rate"
                        name="respiratory_rate"
                        value={fieldValue.respiratory_rate}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">GRBS</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="grbs"
                        name="grbs"
                        value={fieldValue.grbs}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">
                        Temperature
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="temperature"
                        name="temperature"
                        value={fieldValue.temperature}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">SPo2</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="SPo2"
                        name="spo2"
                        value={fieldValue.spo2}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">BMI</Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="bmi"
                        name=""
                        value={""}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-4 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">
                        BMI Status
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="bmi status"
                        value={""}
                      />
                    </Form.Group>
                  </div>
                  <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      <Form.Label className="font-style-bold">
                        Other Notes
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="notes"
                        name="notes"
                        value={fieldValue.notes}
                      />
                    </Form.Group>
                  </div>
                </div>

                <hr class="hr" />
                <button
                  className="btn mb-3"
                  style={{ backgroundColor: "#191BC3", color: "white" }}
                >
                  sample Collections
                </button>
                <div className="d-flex row justify-content-center">
                  <div className=" d-flex flex-column col-lg-6 col-md-6 col-sm-12 text-center">
                    <div className="d-flex justify-content-center ">
                      <p className="me-3">Blood Sample</p>
                      <Form.Check
                        //   disabled
                        inline
                        type={"radio"}
                        label={`yes`}
                        name="blood_sample"
                        id={`disabled-default-radio`}
                        value="yes"
                        checked={selectedBloodSample === "yes"}
                      />
                      <Form.Check
                        //   disabled
                        inline
                        type={"radio"}
                        label={`No`}
                        id={`disabled-default-radio`}
                        value="no"
                        checked={selectedBloodSample === "no"}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <p className="me-3">Urine Sample</p>
                      <Form.Check
                        //   disabled
                        inline
                        type={"radio"}
                        name="urine_sample"
                        label={`yes`}
                        id={`disabled-default-radio`}
                        value="yes"
                        checked={selectedUrineSample === "yes"}
                      />
                      <Form.Check
                        //   disabled
                        inline
                        type={"radio"}
                        label={`No`}
                        id={`disabled-default-radio`}
                        value="no"
                        checked={selectedUrineSample === "no"}
                      />
                    </div>
                  </div>
                </div>
                <hr class="hr" />
                <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                  <Form.Group className="mb-3 " controlId="formBasicEmail">
                    <Form.Label className="font-style-bold">
                      Health Issues and symptoms
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="health issues and symptons"
                      name="health_issues"
                      value={fieldValue.health_issues}
                    />
                  </Form.Group>
                </div>
                <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                  <Form.Group className="mb-3 " controlId="formBasicEmail">
                    <Form.Label className="font-style-bold">
                      Preliminary Diagnosis Assessment
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="preliminary diagnosis"
                      name="prelim_diagnosis"
                      value={fieldValue.prelim_diagnosis}
                    />
                  </Form.Group>
                </div>
                <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
                  <Form.Group className="mb-3 " controlId="formBasicEmail">
                    <Form.Label className="font-style-bold">
                      Nurse Advise
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="nurse advise"
                      name="nurse_advice"
                      value={fieldValue.nurse_advice}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : action == "view" ? (
        <>
          <div className="py-2 px-3" style={{ cursor: "pointer" }}>
            <span className="back-btn-row" onClick={() => setAction(null)}>
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </span>
          </div>
          <div className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="btn-container">
                <button
                  className="btn mb-2"
                  style={{
                    backgroundColor: "#191BC3",
                    color: "white",
                    width: "200px",
                  }}
                >
                  Vitals
                </button>
              </div>
              <div className="date-container">
                <div
                  style={{
                    border: "1px solid #70707080",
                    padding: "5px 30px",
                    marginLeft: "auto",
                    borderRadius: "10px",
                  }}
                >
                  {moment(visitsDetails[0]?.record_time).format("DD MMMM YYYY")}
                </div>
              </div>
            </div>
            <div className="d-flex row">
              <div
                className="d-flex flex-row justify-content-center"
                style={{}}
              >
                <div div className="grid-container-view-vitals">
                  <p className="font-style">Weight</p>
                  <p>{visitsDetails[0]?.weight} Kg</p>
                  <p className="font-style">BP Diastolic</p>
                  <p>{visitsDetails[0]?.bp_diastolic} mmHg</p>
                  <p className="font-style">GRBS</p>
                  <p>{visitsDetails[0]?.grbs}mg/ dL</p>
                  <p className="font-style">BMI</p>
                  <p>{visitsDetails[0]?.bmi}Kg/m*2</p>
                  <p className="font-style">BP Systolic</p>
                  <p>{visitsDetails[0]?.bp_systolic}mmHg</p>
                  <p className="font-style">Respiration</p>
                  <p>{visitsDetails[0]?.respiratory_rate} /min</p>
                </div>

                <div className="grid-container-view-vitals">
                  <p className="font-style">SpO2</p>
                  <p>{visitsDetails[0]?.spo2}%</p>
                  <p className="font-style">Height/Length</p>
                  <p>{visitsDetails[0]?.height}Cm</p>
                  <p className="font-style">Pulse</p>
                  <p>{visitsDetails[0]?.pulse}/min</p>
                  <p className="font-style">Temperature</p>
                  <p>{visitsDetails[0]?.temperature}</p>
                  <p className="font-style">BMI Status</p>
                  <p>{visitsDetails[0]?.bmi_status}</p>
                </div>
              </div>
            </div>

            <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <Form.Label className="font-style-bold">Other Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Specialization"
                  value={visitsDetails[0]?.notes}
                />
              </Form.Group>
            </div>

            <hr />
            <button
              className="btn mb-3"
              style={{ backgroundColor: "#191BC3", color: "white" }}
            >
              sample Collections
            </button>
            <div className="d-flex justify-content-center align-items-center">
              <div className="date-container">
                <div
                  style={{
                    border: "1px solid #70707080",
                    padding: "5px 30px",
                    marginLeft: "auto",
                    borderRadius: "10px",
                    visibility: "hidden",
                  }}
                >
                  {moment(visitsDetails[0]?.record_time).format("DD MMMM YYYY")}
                </div>
              </div>
            </div>
            <div className="d-flex row justify-content-center">
              <div className=" d-flex flex-column col-lg-6 col-md-6 col-sm-12 text-center">
                <div className="d-flex justify-content-center ">
                  <p className="me-3">Blood Sample</p>
                  <Form.Check
                    //   disabled
                    inline
                    type={"radio"}
                    label={`yes`}
                    name="blood_sample"
                    id={`disabled-default-radio`}
                    value="yes"
                    checked={selectedBloodSample === "yes"}
                  />
                  <Form.Check
                    //   disabled
                    inline
                    type={"radio"}
                    label={`No`}
                    id={`disabled-default-radio`}
                    value="no"
                    checked={selectedBloodSample === "no"}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <p className="me-3">Urine Sample</p>
                  <Form.Check
                    //   disabled
                    inline
                    type={"radio"}
                    name="urine_sample"
                    label={`yes`}
                    id={`disabled-default-radio`}
                    value="yes"
                    checked={selectedUrineSample === "yes"}
                  />
                  <Form.Check
                    //   disabled
                    inline
                    type={"radio"}
                    label={`No`}
                    id={`disabled-default-radio`}
                    value="no"
                    checked={selectedUrineSample === "no"}
                  />
                </div>
              </div>
            </div>
            <hr class="hr" />
            <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <Form.Label className="font-style-bold">
                  Health Issues and symptoms
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Specialization"
                  name="health_issues"
                  value={visitsDetails[0]?.health_issues}
                />
              </Form.Group>
            </div>
            <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <Form.Label className="font-style-bold">
                  Preliminary Diagnosis Assessment
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="write Here"
                  name="prelim_diagnosis"
                  value={visitsDetails[0]?.prelim_diagnosis}
                />
              </Form.Group>
            </div>
            <div className="  col-lg-12 col-md-6 col-sm-12  text-start">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <Form.Label className="font-style-bold">
                  Nurse Advise
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="write Here"
                  name="nurse_advice"
                  value={visitsDetails[0]?.nurse_advice}
                />
              </Form.Group>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="text-center">
            <button
              className="btn mb-2"
              style={{ backgroundColor: "#191BC3", color: "white" }}
            >
              Basic Health Data
            </button>
            <div className="d-flex row">
              <div className="col-lg-6 col-md-6 col-sm-12 text-start ">
                <Form.Group className="mb-3 " controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">Allergies</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Write Here"
                    name="allergies"
                    value={allergies}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">
                    Immunisation Details
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Write Here"
                    name="immunisation_details"
                    value={immuneDetails}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">Surgeries</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Write Here"
                    name="surgery_history"
                    value={surgeries}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="font-style-bold">
                    Family History and Hereditary
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Write Here"
                    name="family_health_history"
                    value={familyHistory}
                  />
                </Form.Group>
              </div>
            </div>
            {basicHealthInfoData &&
            basicHealthInfoData?.healthAddictions?.length ? (
              <div>
                <div className="d-flex row justify-content-between">
                  <div className="d-flex row w-50 justify-content-between">
                    <div className=" text-color col-lg-6 col-md-6 col-sm-12 text-start fs-5 fw-bold">
                      Addictions
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-sm-12 text-end fs-5 fw-bold">
                    <button
                      className="btn mb-2"
                      style={{ backgroundColor: "#191BC3", color: "white" }}
                      onClick={() => {
                        setShowModal(true);
                        setAction("addiction-modal");
                      }}
                    >
                      Add
                    </button>
                  </div> */}
                  </div>
                  <div className="d-flex row w-50 justify-content-between">
                    <div className="text-color col-lg-6 col-md-6 col-sm-12 text-start fs-5 fw-bold">
                      Chronic Diseases
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-sm-12 text-end fs-5 fw-bold">
                    <button
                      className="btn mb-2"
                      style={{ backgroundColor: "#191BC3", color: "white" }}
                      onClick={() => {
                        setShowModal(true);
                        setAction("chronic-modal");
                      }}
                    >
                      Add
                    </button>
                  </div> */}
                  </div>
                </div>
                <div id="tabelInfo" className="d-flex row">
                  <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                    <table>
                      <tr>
                        <th>S.No</th>
                        <th>Addiction</th>
                        <th>Start Year</th>
                        <th>End Year</th>
                        <th>Remarks</th>
                      </tr>
                      {basicHealthInfoData &&
                        basicHealthInfoData?.healthAddictions?.map(
                          (items, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{items.addiction}</td>
                                <td>{formatDate(items.start_year)}</td>
                                <td>{formatDate(items.end_year)}</td>
                                <td>{items.remarks}</td>
                              </tr>
                            );
                          }
                        )}
                    </table>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 text-start">
                    <table>
                      <tr>
                        <th>S.No</th>
                        <th>Issue</th>
                        <th>Start Year</th>
                        <th>Remarks</th>
                      </tr>
                      {basicHealthInfoData &&
                        basicHealthInfoData?.chronicDiseases?.map(
                          (items, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{items.name}</td>
                                <td>{format24HrDateOnly(items.start_year)}</td>
                                <td>{items.remarks}</td>
                              </tr>
                            );
                          }
                        )}
                    </table>
                  </div>
                </div>
              </div>
            ) : undefined}
          </div>

          {extractedData && extractedData?.length && (
            <>
              <hr class="hr" />

              <TableComp
                isSearchBar={false}
                isFilter={false}
                rowShouldNot={true}
                tableData={extractedData}
                thHeadData={thHeadData}
                patientVisits={patientVisits}
                navigateTo={navigateTo}
                setEditItems={setEditItems}
                editNavigateTo={() => editNavigateTo}
                setAction={setAction}
                totalRecords={totalRecords}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                setOffset={setOffset}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default HealthInfo;
