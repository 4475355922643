import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";
import { formatTimeHM } from "../../../Utils/DateUtils";
import { cropName } from "../../../Utils/Utility";
{
  /* <table className="dashboard-table">
<thead>
  <tr className="dashbord-table-header"> */
}
{
  /* <th>Camp Id</th> */
}
{
  /* <th>Clinical Advice</th>
    <th>Number of Patients</th> */
}
{
  /* <th>Venue</th> */
}
{
  /* </tr>
</thead>
<tbody> */
}
{
  /* // </tbody>{data?.map((item, i) => {  */
}
{
  /* // const { name, value } = item;
    // return (
      // <tr>
      //   <td>{name}</td>
      //   <td>{value}</td>
      // </tr>
    // );
  // })} */
}

{
  /* <tr>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td> */
}
{
  /* <b>Total number of patients advised</b>
    </td>
    <td>
      <b>119</b>
    </td>
  </tr>
</tbody> */
}
{
  /* </table> */
}

const PrescriptionCategory = (props) => {
  // const {
  //   data = [],
  //   imsData2,
  //   setShowDropDown2,
  //   healthCampIdRecentPatients,
  // } = props;

  // const data = [1, 2, 3, 4, 5];

  const data = [
    { name: "Prescribed for glasses", value: 769 },
    { name: "Cataract Surgery", value: 371 },
    { name: "Retina Surgery", value: 12 },
    { name: "Cornea Transplant", value: 12 },
    { name: "Occulo Plasty Surgery", value: 12 },
    { name: "Pterygium Surgery", value: 7 },
    { name: "Squint Surgery", value: 4 },
    { name: "Glaucoma Surgery", value: 3 },
  ];

  return (
    <div id="prescription-category" style={{ height: "100%" }}>
      <div style={{ height: "100%" }}>
        <div
          className="dashboard-table-container overflow-auto pb-3"
          style={{ margin: "2%", height: "98%" }}
        >
          <div style={{ display: "flex", margin: "10px 0px" }}>
            <p
              className="col-lg-6 col-md-6 col-sm-12"
              style={{
                fontSize: "21px",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              Prescription category
            </p>
          </div>

          {data?.length ? (
            <div>
              <div className="heading-row single-row">
                <div>Clinical Advice</div>
                <div>Number of Patients</div>
              </div>
              {data.map((item) => {
                const { name, value } = item;
                return (
                  <div className="single-row">
                    <div>{name}</div>
                    <div>{value}</div>
                  </div>
                );
              })}
              <div className="single-row footer-row">
                <div>Total number of patients advised</div>
                <div>1191</div>
              </div>
              {/* Total number of patients advised */}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // height: "30vh",
                minHeight: 365,
              }}
            >
              <div>No data found</div>
            </div>
          )}
          <div className="flex justify-content-end table-page"></div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionCategory;
