import React, { useState } from "react";
import close from "../../../../../../Assets/icons/prescription-close.svg";
import TableComp from "../../../../Table/TableComp";
const tableData = [
  {
    "Report Name": "Prescription",
    "Report Type": "Report Name Here",
    Date: "7th Feb 2023",
    ActionMedical: "",
  },
  {
    "Report Name": "Prescription",
    "Report Type": "Report Name Here",
    Date: "7th Feb 2023",
    ActionMedical: "",
  },
  {
    "Report Name": "Prescription",
    "Report Type": "Report Name Here",
    Date: "7th Feb 2023",
    ActionMedical: "",
  },
  {
    "Report Name": "Prescription",
    "Report Type": "Report Name Here",
    Date: "7th Feb 2023",
    ActionMedical: "",
  },
];
const MedicalReports = () => {
  const [isAction, setIsAction] = useState(true);
  const navigateTo = () => {
    console.log("hello111");
    setIsAction(false);
  };
  return (
    <>
      {isAction ? (
        <TableComp
          isSearchBar={false}
          isFilter={false}
          tableData={tableData}
          navigateTo={() => navigateTo}
        />
      ) : (
        <>
          <div
            className=" d-flex justify-content-between p-3 align-items-center mb-3 doctor-info-container"
            style={{ backgroundColor: "#FBFBFF", border: "" }}
          >
            <div className="" style={{ fontSize: "24px", fontWeight: "bold" }}>
              <p>Report Name Here</p>
            </div>
            <div className="" style={{ fontSize: "24px", fontWeight: "bold" }}>
              <p>Report Type Here</p>
            </div>
            <div style={{ cursor: "pointer" }}>
              <img
                src={close}
                onClick={() => setIsAction(true)}
                style={{ cursor: "pointer" }}
              ></img>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MedicalReports;
