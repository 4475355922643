import React, { useEffect, useMemo, useRef, useState } from "react";
import ProfileIconBlack from "../../../Assets/Images/dataop-user-form.png";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../LayoutComp/Layout";
import { Button, Form, InputGroup } from "react-bootstrap";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import InputFieldModal from "./Modal/InputFieldModal";
import fingerIcon from "../../../Assets/icons/fingerprint.svg";
import loadingIcon from "../../../Assets/icons/loading-png-icon.png";
import "./addPatient.scss";
import { Box, ButtonBase, Modal, Popover, Typography } from "@mui/material";
import groupImg from "../../../Assets/Images/group-do-img.png";
import doclose from "../../../Assets/icons/do-close.svg";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { EditIcon, DeleteIcon, backBtnIcon } from "../../../Utils/ImageUtils";
import {
  getAddictions,
  getGenders,
  getMeritalStatus,
  getRelationship,
} from "../../../Services/Metadata/Metadata";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { createService } from "../../../Services/HttpService";
import { useDispatch, useSelector } from "react-redux";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {ConvertYMD,formatDatePatient,} from "../../../Utils/DateUtils";
import { useSnackbar } from "notistack";
import { errorToast, successToast } from "../../../Utils/toasters";
import {
  getDistrictData,
  getPlaceData,
  getStateData,
} from "../../../Services/MCH";
import { setPatientFullName } from "../../Redux/slices/userSlice";
import {
  ContactValidator,
  aadharNumber,
  pinCodeValidate,
} from "../../../Utils/Validators";
import { updatePatientInfo, getPatientInfo } from "../../../Services/AddPatient/AddPatient";



const style = {
  position: "absolute",
  top: "80%",
  left: "70%",
  transform: "translate(-100%, -100%)",
  //  width: 400,
  bgcolor: "background.paper",
  margin: "0",
  padding: "0",
};
const saveModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddFamilystyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  padding: "30px",
  borderRadius: "10px",
};

const AddFamilymembersModal = ({
  familyModal,
  setFamilyModal,
  handleContentChange,
  setFamilyMembersData,
  familyMembersData,
  addictionData,
  genderData,
  relationshipData,
  setFamilyMemberArray,
  familyMemberArray,
  memberEditId,
  setMemberEditId,
  memberAction,
  setMemberAction,
  setUpdated,
  memberDeleteId,
  fieldValues,
  setIsEdit,
  isEdit,
}) => {
  //
  const [newaddr, setNewaddr] = useState(null);
  const [aadharmessage, setAadharMessage] = useState(null);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    console.log("nameee", name);
    setNewaddr(value);
  };

  const [error, setError] = useState({
    contact_number: false,
    password: false,
  });
  //

  const [count, setCount] = useState(1);
  const [tempMembers, setTempMembers] = useState([]);
  const handleDateChange = (date) => {
    //  const formattedDate = date.toISOString().substring(0, 10);
    //  // setFormatedDateState(formattedDate);
    //  console.log("formattedDate", formattedDate);
    setFamilyMembersData((prevState) => ({
      ...prevState,
      dob: date,
    }));
  };
  const bankAccount = [
    { name: "Available", value: "Available" },
    { name: "Not Available", value: "Not Available" },
  ];
  const setFieldValue = (e) => {
    // if(Object(fieldValues).values)
    const { name, value } = e.target;
    if (name == "aadhar") {
      aadharNumber(setError, setAadharMessage, value);
    }
    if (name == "addiction") {
      const arr = [];
      arr.push(value);
      setFamilyMembersData((prev) => ({
        ...prev,
        addiction: arr,
      }));
    } else {
      setFamilyMembersData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    if (isEdit) {
      //  if(familyMemberArray.){

      //  }
      const getSelectedRow = familyMemberArray.find(
        (val) => val?.id == memberEditId
      );
      setFamilyMembersData({
        id: getSelectedRow?.id,
        aadhar: getSelectedRow?.aadhar,
        addiction: getSelectedRow?.addiction,
        dob: new Date(getSelectedRow?.dob),
        family_member_name: getSelectedRow?.family_member_name,
        gender: getSelectedRow?.gender,
        relationship: getSelectedRow?.relationship,
        bank_account: getSelectedRow?.bank_account,
      });
    }
  }, [memberEditId, isEdit]);


  useEffect(() => {
    if (memberAction == "Add") {
      setFamilyMembersData({
        family_member_name: "",
        aadhar: "",
        addiction: "",
        dob: "",
        family_member_name: "",
        gender: "",
        relationship: "",
        bank_account: "",
      });
    }
  }, [memberAction]);

  console.log("familyMemberArray is====================", familyMemberArray);

  useEffect(() => {
    if (memberDeleteId !== null) {
      const getSelectedRow = familyMemberArray.filter(
        (val) => val.id != memberDeleteId
      );
      setFamilyMemberArray(getSelectedRow);
    }
  }, [memberDeleteId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (memberAction == "Edit") {
      const updatedArray = familyMemberArray?.map((item) => {
        if (item.id === memberEditId) {
          // Create a new object with updated values
          const updatedItem = { ...item, ...familyMembersData };
          updatedItem.dob = formatDatePatient(updatedItem.dob);
          return updatedItem;
        }
        return item; // Return the original object for other items in the array
      });
      setFamilyMemberArray(updatedArray);
      setMemberEditId(null);
      // setFamilyMembersData({})
      setFamilyModal(false);
      setUpdated(true);
    }
    //  }else{
    if (memberAction == "Add") {
      const data = {
        ...familyMembersData,
        dob: ConvertYMD(familyMembersData.dob),
        id: count,
      };
      setFamilyMemberArray([...familyMemberArray, data]);
      setCount(count + 1);
      setMemberEditId(null);
      setFamilyMembersData({});
      setFamilyModal(false);
      setUpdated(true);
    }
  };

  useEffect(() => {
    if (familyMembersData.aadhar == "") {
      setError((prev) => ({
        ...prev,
        aadhar: false,
      }));
    }
  }, [familyMembersData.aadhar]);


  const familyMemberButton = () => {
    if (
      !familyMembersData.family_member_name ||
      !familyMembersData.relationship ||
      error.aadhar == true
      // && familyMembersData.gender && familyMembersData.dob && familyMembersData.addiction && familyMembersData?.aadhar && familyMembersData.bank_account
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Modal
      open={familyModal}
      onClose={() => {
        setFamilyModal(false);
        setFamilyMembersData({});
        setMemberEditId(null);
        setIsEdit(false);
        //  setFamilyMembersData({});
      }}
    >
      <Box sx={AddFamilystyle}>
        <form onSubmit={handleSubmit}>
          <div className="text-end">
            <img
              alt=""
              src={doclose}
              onClick={() => {
                //  setFamilyMembersData({});
                setFamilyModal(false);
                setFamilyMembersData({});
                setMemberEditId(null);
                setIsEdit(false);
              }}
              style={{ width: "20px", cursor: "pointer" }}
            ></img>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group className="mb-3" co0ntrolId="formBasicEmail">
                <Form.Label>
                  Name of the Family Member
                  <span className="required-field">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="family_member_name"
                  placeholder="Enter Family Member Name"
                  onChange={setFieldValue}
                  value={familyMembersData.family_member_name}
                  required
                />
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Relationship<span className="required-field">*</span>
                </Form.Label>
                <Form.Select
                  onChange={setFieldValue}
                  aria-label="Default select example"
                  name="relationship"
                  value={familyMembersData.relationship}
                  required
                >
                  <option selected="selected">Select Relationship</option>
                  {relationshipData?.map((relation, index) => (
                    <option
                      name="relationship"
                      value={relation.relationship}
                      key={index}
                    >
                      {relation.relationship}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group className="mb-3" co0ntrolId="formBasicEmail">
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  onChange={setFieldValue}
                  aria-label="Default select example"
                  name="gender"
                  value={familyMembersData.gender}
                >
                  <option selected="selected">Select Gender</option>
                  {genderData?.map((gen, index) => (
                    <option name="gender" value={gen.gender} key={index}>
                      {gen.gender}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4 date-pick date-input-add-nurse">
              <Form.Group className="mb-3" co0ntrolId="formBasicEmail">
                <Form.Label>Date of Birth</Form.Label>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className="customDatePicker"
                    wrapperClassName="datepicker"
                    value={familyMembersData.dob}
                    onChange={handleDateChange}
                  />
                </LocalizationProvider>
                {/* <DatePicker
                   name="dob"
                   selected={
                     familyMembersData.dob
                       ? new Date(familyMembersData.dob)
                       : null
                   }
                   onChange={handleDateChange}
                   wrapperClassName="datepicker"
                   //  defaultValue={dayjs(familyMembersData.dob)}
                 /> */}
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group required className="mb-3" controlId="formBasicEmail">
                <Form.Label>Addiction</Form.Label>
                <Form.Select
                  onChange={setFieldValue}
                  aria-label="Default select example"
                  name="addiction"
                  value={familyMembersData.addiction}
                >
                  <option selected="selected">Select Addiction</option>
                  {addictionData?.map((add, index) => (
                    <option name="addiction" value={add.addiction} key={index}>
                      {add.addiction}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Aadhar Card</Form.Label>
                <Form.Control
                  type="number"
                  name="aadhar"
                  placeholder="Aadhar Card"
                  onChange={setFieldValue}
                  value={familyMembersData.aadhar}
                />

                {error.aadhar && <p className="text-danger">{aadharmessage}</p>}
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Bank Account
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Select
                  aria-label="select"
                  name="bank_account"
                  value={familyMembersData.bank_account}
                  onChange={setFieldValue}
                >
                  <option selected={"selected"}>Select</option>
                  {bankAccount.map((item, index) => (
                    <option name="bank_account" value={item.value} key={index}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          </div>
          <div className="col-5 d-flex justify-content-start">
            <div className="d-flex row justify-content-start align-items-center mt-3 gap-2">
              <div className="btn-card">
                {!isEdit ? (
                  <button
                    type="submit"
                    className="btn-save me-2"
                    disabled={familyMemberButton()}
                    onClick={() => {
                      // setMemberEditId(null);
                      // setMemberAction('Add')
                      setIsEdit(false);
                    }}
                  // onClick={() => setIsAddNewPatient(false)}
                  >
                    {"Save"}
                    {/* {state.action === "Edit" ? "Update" : "Save"} */}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-save me-2"
                    disabled={familyMemberButton()}
                    onClick={() => {
                      // setFamilyModal(false);
                      // setMemberAction("Add")
                      // setMemberEditId(null);
                      setIsEdit(false);
                    }}
                  // onClick={() => setIsAddNewPatient(false)}
                  >
                    {"Update"}
                    {/* {state.action === "Edit" ? "Update" : "Save"} */}
                  </button>
                )}

                <button
                  type="button"
                  className="btn-reset"
                  onClick={() => {
                    // setTempMembers(familyMembersData)
                    setIsEdit(false);
                    setFamilyMembersData({
                      id: "",
                      aadhar: "",
                      addiction: "",
                      dob: "",
                      family_member_name: "",
                      gender: "",
                      relationship: "",
                    });
                  }}
                // style={{
                //   display: state.action === "Edit" ? "none" : undefined,
                // }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

const AddPatientForm = ({ sideNavState, setSideNavState }) => {

  const { state } = useLocation();
  const [patientEdit, setPatientEdit] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [familyModal, setFamilyModal] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [stream, setStream] = useState(null);
  const [hideIcon, setHideIcon] = useState(true);
  const [hideCanvas, setHideCanvas] = useState(false);
  const [modalOnSave, setModalOnSave] = useState(false);
  const [relationshipData, setRelationshipData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [addictionData, setAddictionData] = useState([]);
  const [memberEditId, setMemberEditId] = useState(null);
  const [memberAction, setMemberAction] = useState(null);
  const [familyMemberArray, setFamilyMemberArray] = useState([]);
  const [meritalData, setMeritalData] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [memberDeleteId, setMemberDeleteId] = useState(null);
  const [image, setImage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { healthCampid } = useSelector((state) => state.name);
  const [patientId, setPatientID] = useState("");
  const [stateDropDown, setStateDropDown] = useState([]);
  const [distDrop, setDistDrop] = useState([]);
  const [placeDrop, setPlaceDrop] = useState([]);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [message, setMessage] = useState(null);
  const [aadharmessage, setAadharMessage] = useState(null);

  const [error, setError] = useState({
    contact_number: false,
    password: false,
    aadhar: false,
    pincode: false,
  });

  var patientObj = {
    first_name: "",
    middle_name: "",
    last_name: "",
    full_name: "",
    gender: "",
    contact_number: "",
    // doc_type: "",
    // doc_number: "",
    ayush_card: "",
    occupation: "",
    farm_technique: "",
    annual_income: "",
    other_skills: "",
    family_count: "",
    earning_count: "",
    marital_status: "",
    spouse_occupation: "",
    children_count: "",
    aadhar: "",
    bank_account: null,
    biometric: null,
    image: "",
    healthcamp_id: healthCampid,
    family_members: "",
    dob: "",
    state_id: "",
    district_id: "",
    place_id: "",
    village: "",
    address: "",
    pincode: "",
    panchayat_ward: "",
    camp_patient_id: ""
  }

  const { campData } = useSelector((state) => state.campData);

  const [familyMembersData, setFamilyMembersData] = useState({
    id: "",
    aadhar: "",
    addiction: "",
    dob: "",
    family_member_name: "",
    gender: "",
    relationship: "",
    bank_account: "",
  });

  const [fieldValues, setFieldValues] = useState({});
  const [ayushCardNumber,setAyushNumber] = useState("");

  useEffect(() => {
    if (state) {
      setPatientEdit(true)
      getfamilyData(state?.id)
      let patientData = {
        id: state?.id,
        first_name: state?.first_name ?? "",
        middle_name: state?.middle_name ?? "",
        last_name: state?.last_name ?? "",
        full_name: state?.full_name ?? "",
        gender: state?.gender ?? "",
        contact_number: state?.contact_number ?? "",
        // doc_type: "",
        // doc_number: "",
        occupation: state?.occupation ?? "",
        farm_technique: state?.farm_technique ?? "",
        annual_income: state?.annual_income ?? "",
        other_skills: state?.other_skills ?? "",
        family_count: state?.family_count ?? "",
        earning_count: state?.earning_count ?? "",
        marital_status: state?.marital_status ?? "",
        spouse_occupation: state?.spouse_occupation ?? "",
        children_count: state?.children_count ?? "",
        aadhar: state?.aadhar ?? "",
        bank_account: state?.bank_account ?? null,
        biometric: state?.biometric ?? null,
        image: state?.image ?? "",
        healthcamp_id: healthCampid,
        family_members: state?.family_members ?? "",
        dob: state?.dob ? new Date(state?.dob) : "",
        state_id: state?.state_id ? state.state_id : campData?.state_id,
        district_id: state?.district_id ? state.district_id : campData?.district_id,
        place_id: state?.place_id ? state.place_id : campData?.place_id,
        village: state?.village ?? "",
        address: state?.address ?? "",
        pincode: state?.pincode ?? "",
        panchayat_ward: state?.panchayat_ward ?? "",
        camp_patient_id: state?.camp_patient_id ?? ""
      }

      if(state?.ayush_card){
        if(state?.ayush_card !== "Available" && state?.ayush_card !== "Not Available"){
          patientData.ayush_card = "Available"
            setAyushNumber(state?.ayush_card)
            setShowPopup(true)
        }else{
          patientData.ayush_card = state?.ayush_card
          if(state?.ayush_card === "Available")setShowPopup(true)
        }
      }
      setFieldValues(patientData)
      getState(true);
    } else {
      setFieldValues(patientObj)
      getState(false);
    }


    const getAddictionData = async () => {
      const addiction = await getAddictions();
      setAddictionData(addiction.data);
    };
    const getGenderData = async () => {
      const genders = await getGenders();
      setGenderData(genders.data);
    };

    const getRelationshipData = async () => {
      const relation = await getRelationship();
      setRelationshipData(relation.data);
    };
    const getMeritalData = async () => {
      const relation = await getMeritalStatus();
      setMeritalData(relation.data);
    };

    getMeritalData();
    getRelationshipData();
    getGenderData();
    getAddictionData();

  }, []);

  const getfamilyData = async (patientId) => {
    let patiendData = await getPatientInfo(patientId, {})
    if (patiendData.status === 200) {
      if(patiendData.data?.familyDetails){
        setFamilyMemberArray(patiendData.data?.familyDetails)
        setFieldValues((prev) => ({
          ...prev,
          family_members: JSON.stringify(patiendData.data?.familyDetails)
        }));
      }
    }
  }


  const getState = async (isEdit) => {
    const states = await getStateData();
    if (campData && !isEdit) {
      let obj = { ...fieldValues }
      obj.state_id = campData?.state_id
      obj.district_id = campData?.district_id
      obj.place_id = campData?.place_id
      // obj.pincode = campData?.pincode
      setFieldValues(obj)
    }
    setStateDropDown(states.data);
  };

  useEffect(() => {
    if (fieldValues.state_id) getDistrict();
  }, [fieldValues.state_id]);

  const getDistrict = async () => {
    const district = await getDistrictData(fieldValues.state_id);
    setDistDrop(district.data);
  };

  useEffect(() => {
    if (fieldValues.district_id) getPlace();
  }, [fieldValues.district_id]);

  const getPlace = async () => {
    const place = await getPlaceData(fieldValues.district_id);
    setPlaceDrop(place.data);
  };

  useEffect(() => {
    const updatedArray = familyMemberArray.map((item) => {
      let obj = {}
      if (item?.aadhar) obj.aadhar = item.aadhar
      if (item?.addiction) obj.addiction = item.addiction
      if (item?.dob) obj.dob = item.dob
      if (item?.gender) obj.gender = item.gender
      if (item?.bank_account) obj.bank_account = item.bank_account
      obj.relationship = item.relationship
      obj.family_member_name = item.family_member_name
      const { id, ...rest } = obj;
      return rest;
    });
    setFieldValues((prev) => ({
      ...prev,
      family_members: JSON.stringify(updatedArray)
    }));
    setUpdated(false);
  }, [updated]);


  //  const [modalContent,setModalContent]=useState('')
  const [clickCount, setClickCount] = useState(0);
  let videoRef = useRef(null);
  let photoRef = useRef(null);
  const handleClickCam = async () => {
    if (!videoOpen) {
      setVideoOpen(true);

      setHideCanvas(true);
      setHideIcon(false);
      const stream = await navigator?.mediaDevices?.getUserMedia({
        video: true,
      });
      setStream(stream);
    } else {
      videoHandleClose();
    }
  };

  if (stream && videoRef.current) {
    videoRef.current.srcObject = stream;
  }
  const videoHandleClose = () => {
    setVideoOpen(false);
    if (videoRef.current?.srcObject) {
      videoRef?.current?.srcObject
        ?.getTracks()
        ?.forEach((track) => track.stop());
    }
    setStream(null);
    if (!image) {
      setHideIcon(true);
      setHideCanvas(false);
    }
  };

  const takePicture = () => {
    let width = 200;
    let height = 200;
    let photo = photoRef?.current;
    let video = videoRef?.current;
    photo.width = width;
    photo.height = height;
    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, photo.width, photo.height);
    const capturedImageBase64 = photo.toDataURL();
    setHideIcon(false);
    const byteCharacters = atob(capturedImageBase64.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "image/png" });
    const fileOptions = {
      type: blob.type,
      lastModified: new Date().getTime(),
      name: "drImg.png",
      size: blob.size,
    };

    const fileData = new File([blob], "drImg.png", fileOptions);
    setCapturedImage(fileData);
    if (fileData) {
      setVideoOpen(false);
      if (videoRef.current?.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
      setStream(null);
      //  setHideIcon(false);
      //  setHideCanvas(true);
    }
    // setVideoOpen(false);

    if (!fileOptions) {
      width = 0;
      height = 0;
    }
  };


  useEffect(() => {
    if (capturedImage !== null) {
      setImage(capturedImage);
      // videoHandleClose()
    }
  }, [capturedImage]);


  const saveHandleClose = () => {
    // setSpinner(true)
    setModalOnSave(false);
    setFieldValues({
      first_name: "",
      middle_name: "",
      last_name: "",
      full_name: "",
      gender: "",
      contact_number: "",
      // doc_type: "",
      // doc_number: "",
      ayush_card: "",
      occupation: "",
      farm_technique: "",
      annual_income: "",
      other_skills: "",
      family_count: "",
      earning_count: "",
      marital_status: "",
      spouse_occupation: "",
      children_count: "",
      aadhar: "",
      bank_account: "",
      biometric: null,
      image: "",
      healthcamp_id: "",
      family_members: "",
      state_id: "",
      district_id: "",
      place_id: "",
      village: "",
      pincode: "",
      address: "",
      dob: "",
      ayush_card: "",
      biometric: null,
      panchayat_ward: "",
      camp_patient_id: ""
    });
    setFamilyMemberArray([]);
    setFamilyMembersData({
      id: "",
      aadhar: "",
      addiction: "",
      dob: "",
      family_member_name: "",
      gender: "",
      relationship: "",
    });
    setImage("");
    setPatientID("");
    setModalOnSave(false);
    naviate("/home/allPatientsPage");
  };

  const getModalContent = () => {
    switch (clickCount) {
      case 0:
        return {
          title: "Biometric Authentication",
          subTitle: "Please Place Your Finger On the Scanner",
          icon: fingerIcon,
          bottomData: "100%",
          bottomDataTwo: "Scanning Completed",
        };

      case 1:
        return {
          title: "Biometric",
          subTitle: "Verify Biometric",
          icon: loadingIcon,
          bottomData: "",
          bottomDataTwo: "",
        };
      case 2:
        return {
          title: "Verified",
          subTitle: "",
          icon: "",
          bottomData: "",
          bottomDataTwo: "",
        };
      default:
        return {
          title: "Bio Authentication",
          subTitle: "Please Place Your Finger On the Scanner",
          icon: loadingIcon,
          bottomData: "",
          bottomDataTwo: "",
        };
    }
  };

  const content = getModalContent();
  const handleContentChange = () => {
    if (clickCount < 2) {
      setClickCount(clickCount + 1);
    } else {
      handleClose();
      setClickCount(0);
    }
  };

  const popoveropen = Boolean(anchorEl);
  const id = popoveropen ? "simple-popover" : undefined;

  const closeFamilyModal = () => {
    setFamilyModal(false);
    setIsEdit(false);
  };


  const [showPopup, setShowPopup] = useState(false); // State to control the visibility of the popup
  const [pincodeMessage, setPinCodeMessage] = useState(null);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "contact_number") {
      ContactValidator(setError, setMessage, value);
    }
    if (name == "aadhar") {
      aadharNumber(setError, setAadharMessage, value);
    }
    if (name == "pincode") {
      pinCodeValidate(setError, setPinCodeMessage, value);
    }
    if (name == "ayush_card") {
      if (value == "Available") {
        setShowPopup(true);
      } else {
        setShowPopup(false);
      }
    }
    setFieldValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    
  };

  const setAyushVal = (e) => {
    setAyushNumber(e.target.value)
  };

  const fullName = useMemo(() => {
    if(state && !fieldValues?.first_name ) {
      return state?.full_name
    } else {
      const { first_name, middle_name, last_name } = fieldValues;
      const formattedName = `${first_name}${middle_name ? ` ${middle_name}` : ""
        }${last_name ? ` ${last_name}` : ""}`;
      return formattedName !== "undefined" ? formattedName.trim() : ""
    }

  }, [fieldValues.first_name, fieldValues.last_name, fieldValues.middle_name]);

  const formSubmit = async (e) => {
    e.preventDefault();
    // YYYY-MM-DD
    const formData = new FormData();
    formData.append("first_name", fieldValues.first_name);
    if (fieldValues.middle_name) formData.append("middle_name", fieldValues.middle_name);
    if (fieldValues.last_name) formData.append("last_name", fieldValues.last_name);
    formData.append("full_name", fullName);
    formData.append("gender", fieldValues.gender);
    formData.append("dob", ConvertYMD(fieldValues.dob));
    formData.append("contact_number", fieldValues.contact_number);
    if(ayushCardNumber.length > 0 && fieldValues.ayush_card === "Available"){
      formData.append("ayush_card",ayushCardNumber);
    }
    else {
      formData.append("ayush_card",fieldValues.ayush_card);
    }
    if (fieldValues.occupation) formData.append("occupation", fieldValues.occupation);
    if (fieldValues.farm_technique) formData.append("farm_technique", fieldValues.farm_technique);
    if (fieldValues.annual_income) formData.append("annual_income", fieldValues.annual_income);
    if (fieldValues.other_skills) formData.append("other_skills", fieldValues.other_skills);
    if (fieldValues.family_count) formData.append("family_count", fieldValues.family_count);
    if (fieldValues.earning_count) formData.append("earning_count", fieldValues.earning_count);
    formData.append("marital_status", fieldValues.marital_status);
    if (fieldValues.spouse_occupation) formData.append("spouse_occupation", fieldValues.spouse_occupation);
    if (fieldValues.children_count) formData.append("children_count", fieldValues.children_count);
    formData.append("aadhar", fieldValues.aadhar);

    formData.append("bank_account", fieldValues.bank_account);
    formData.append("state_id", fieldValues.state_id);
    formData.append("place_id", fieldValues.place_id);
    formData.append("district_id", fieldValues.district_id);
    formData.append("village", fieldValues.village);
    if (fieldValues.pincode) formData.append("pincode", fieldValues.pincode);
    formData.append("address", fieldValues.address);
    formData.append("biometric", fieldValues.biometric);
    if (fieldValues.panchayat_ward) formData.append("panchayat_ward", fieldValues.panchayat_ward)
    if (fieldValues.camp_patient_id) formData.append("camp_patient_id", fieldValues.camp_patient_id)
    formData.append("image", image);
    formData.append("healthcamp_id", localStorage.getItem("healthCampid", healthCampid));
    if(fieldValues.family_members) {
      console.log("Members data is"+JSON.stringify(fieldValues.family_members))
      console.log("FFrom state"+JSON.stringify(familyMemberArray))
      formData.append("family_members", fieldValues.family_members);
    }
    // try {

    if (patientEdit) {
      let resp = await updatePatientInfo(fieldValues?.id,formData)
      if (resp.status === 500) {
        errorToast(enqueueSnackbar, "Please check the data you entered and try again");
      }
      else if (resp.status < 201 || resp.status > 300) {
        let msg = resp?.data?.errors.map((item) => item.msg);
        errorToast(enqueueSnackbar, msg?.join(""));
        // ResetForm();
        setFamilyMemberArray([]);
      } else {
        successToast(enqueueSnackbar, resp.data.message);
        setModalOnSave(true);
      }
      setPatientID(resp?.data?.body?.patientId);
    } else {
      const resp = await createService("patients/generalinfo", formData, {}, enqueueSnackbar);
      if (resp.status === 500) {
        errorToast(enqueueSnackbar, "Please check the data you entered and try again");
      }
      else if (resp.status < 201 || resp.status > 300) {
        let msg = resp?.data?.errors.map((item) => item.msg);
        errorToast(enqueueSnackbar, msg?.join(""));
        // ResetForm();
        setFamilyMemberArray([]);
      } else {
        successToast(enqueueSnackbar, resp.data.message);
        setModalOnSave(true);
      }
      setPatientID(resp?.data?.body?.patientId);

    }


    // } catch (err) {
    // errorToast(enqueueSnackbar, "");
    // }
  };

  const ResetForm = () => {
    setFieldValues({
      first_name: "",
      middle_name: "",
      last_name: "",
      full_name: "",
      gender: "",
      contact_number: "",
      // doc_type: "",
      // doc_number: "",
      ayush_card: "",
      occupation: "",
      farm_technique: "",
      annual_income: "",
      other_skills: "",
      family_count: "",
      earning_count: "",
      marital_status: "",
      spouse_occupation: "",
      children_count: "",
      aadhar: "",
      bank_account: "",
      biometric: "",
      image: "",
      dob: "",
      // healthcamp_id: "",
      family_members: "",
      address: "",
      pincode: "",
      village: "",
      state_id: "",
      place_id: "",
      district_id: "",
      panchayat_ward: "",
      camp_patient_id: ""
    });
    setShowPopup(false);
    setFamilyMemberArray([]);
  };

  const naviate = useNavigate();

  const disabledHandler = () => {
    if (
      !fieldValues.first_name ||
      !fieldValues.dob ||
      !fieldValues.gender ||
      !fieldValues.ayush_card ||
      !fieldValues.marital_status ||
      !fieldValues.address ||
      !fieldValues.village ||
      !fieldValues.state_id ||
      !fieldValues.district_id ||
      !fieldValues.place_id ||
      // !fieldValues.bank_account ||
      !fieldValues.aadhar ||
      error.contact_number == true ||
      !fieldValues.contact_number ||
      error.aadhar == true
      // || // error.pincode == true

    ) {
      return true; // Enable the button
    } else {
      return false; // Disable the button
    }
  };


  const handleDateChange = (date) => {
    //  const formattedDate = date.toISOString().substring(0, 10);
    //  // setFormatedDateState(formattedDate);
    //  console.log("formattedDate", formattedDate);
    setFieldValues((prevState) => ({
      ...prevState,
      dob: date,
    }));
  };


  return (
    <>
      <Layout
        sideNavState={sideNavState}
        setSideNavState={setSideNavState}
        hidenav={true}
      >
        <div className=" px-1">
          <span
            className="back-btn"
            onClick={() => naviate("/home/allPatientsPage")}
          >
            <img src={backBtnIcon} alt="" />
          </span>
        </div>
        <form onSubmit={formSubmit}>
          <div className="patient-general-info">
            <div className="d-flex flex-row justify-content-between ">
              <h5>General Information</h5>
            </div>
            <div className="d-flex row justify-content-start">
              <div className="d-flex flex-row">
                <div className="col-lg-2 col-sm-12 col-md-6 d-flex justify-content-start align-items-center">
                  <div
                    className=" row  d-flex flex-column align-items-center justify-content-start"
                  // onClick={() => inputRef.current.click()}
                  >
                    {/* <video ref={videoRef} autoPlay /> */}
                    {/* {photoRef.current != null ? ( */}

                    {hideCanvas && (
                      <canvas ref={photoRef} onClick={handleClickCam}></canvas>
                    )}
                    {/* ) : ( */}
                    {hideIcon && (
                      <div style={{ width: "100%" }}>
                        <img
                          alt=""
                          src={ProfileIconBlack}
                          onClick={handleClickCam}
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#B7B7B7",
                            textAlign: "center",
                          }}
                        >
                          <span>Take a photo</span>
                          <span> with your webcam</span>
                        </p>
                      </div>
                    )}

                    {/* )} */}
                  </div>
                </div>
                {/* <div className="d-flex row  justify-content-start"> */}
                <div style={{ width: "100%" }}>
                  <div className="d-flex row">
                    <div className="col-lg-4 col-sm-12 col-md-6">
                      <Form.Group co0ntrolId="formBasicEmail">
                        <Form.Label>
                          First Name<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          name="first_name"
                          type="text"
                          placeholder="Enter Name"
                          onChange={handleFieldChange}
                          value={fieldValues.first_name}
                          required
                        // value={phone}
                        />
                      </Form.Group>
                    </div>
                    <div
                      className="col-lg-4 col-sm-12 col-md-6"
                    // style={{ width: "33%" }}
                    >
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Middle Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="middle_name"
                          placeholder="Middle Name"
                          onChange={handleFieldChange}
                          value={fieldValues.middle_name}
                        />
                      </Form.Group>
                    </div>
                    <div
                      className=" col-lg-4 col-sm-12 col-md-6"
                    // style={{ width: "33%" }}
                    >
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="last_name"
                          placeholder="Last name"
                          onChange={handleFieldChange}
                          value={fieldValues.last_name}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex row">
                    <div
                      className="col-lg-4 col-md-6 col-sm-12"
                    // style={{ width: "42.5%" }}
                    >
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Full Name </Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          name="full_name"
                          placeholder="Enter Full Name"
                          onChange={handleFieldChange}
                          value={fullName}
                        />
                      </Form.Group>
                    </div>
                    <div className="d-flex flex-column date-pick col-lg-4 col-md-6 col-sm-12 ">
                      {/* <Form.Group className="mb-3"> */}
                      <Form.Label>
                        Date of Birth<span className="required-field">*</span>
                      </Form.Label>
                      {/* <DatePicker
                        name="dob"
                        selected={
                          familyMembersData.dob
                            ? new Date(familyMembersData.dob)
                            : null
                        }
                        onChange={(e) => handleDateChange(e)}
                        wrapperClassName="datepicker"
                        //  defaultValue={dayjs(familyMembersData.dob)}
                      /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          wrapperClassName="datepicker"
                          value={fieldValues.dob}
                          onChange={handleDateChange}
                          placeholder="Date of Birth"
                          style={{ backgroundColor: "#fff" }}
                          className="customDatePicker"
                          required
                        />
                      </LocalizationProvider>

                      {/* </Form.Group> */}
                    </div>
                    <div
                      className="col-lg-4 col-md-6 col-sm-12"
                    // style={{ width: "42.5%" }}
                    >
                      <Form.Group className="mb-3" co0ntrolId="formBasicEmail">
                        <Form.Label>
                          Gender<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          onChange={handleFieldChange}
                          aria-label="Default select example"
                          name="gender"
                          value={fieldValues.gender}
                        >
                          <option selected="selected">Select Gender</option>
                          {genderData?.map((gen, index) => (
                            <option
                              name="gender"
                              value={gen.gender}
                              key={index}
                            >
                              {gen.gender}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex row justify-content-start">
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Label>
                  Mobile Number<span className="required-field">*</span>
                </Form.Label>
                <InputGroup
                  className="mb-3 flex flex-row"
                  controlId="formBasicEmail"
                >
                  <InputGroup.Text id="">91 </InputGroup.Text>

                  <Form.Control
                    placeholder="Mobile Number"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    type="number"
                    name="contact_number"
                    value={fieldValues.contact_number}
                    onChange={handleFieldChange}
                    className="no-spinners"
                  />
                </InputGroup>
                {error.contact_number && (
                  <p className="text-danger">{message}</p>
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Ayushman Card<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="select"
                    name="ayush_card"
                    value={fieldValues.ayush_card}
                    onChange={handleFieldChange}
                  >
                    <option>Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                  </Form.Select>
                </Form.Group>
                {showPopup && (
                  <div>
                    <Form.Label>Ayushman Details</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Additional Information"
                      onChange={setAyushVal}
                      value={ayushCardNumber}
                    // Add value and other necessary props as needed
                    />
                    {/* Render other components or UI elements for the popup */}
                  </div>
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Occupation</Form.Label>
                  <Form.Control
                    type="text"
                    name="occupation"
                    value={fieldValues.occupation}
                    placeholder="Occupation"
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </div>
              {/* <div
                className="col-lg-4 col-md-6 col-sm-12"
                // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Technique of Farming</Form.Label>
                  <Form.Control
                    type="text"
                    name="farm_technique"
                    placeholder="Farming Technique"
                    onChange={handleFieldChange}
                    value={fieldValues.farm_technique}
                  />
                </Form.Group>
              </div> */}
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Annual Income</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Annual Income"
                    onChange={handleFieldChange}
                    value={fieldValues.annual_income}
                    name="annual_income"
                  />
                </Form.Group>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Other Skills</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Other Skills"
                    onChange={handleFieldChange}
                    name="other_skills"
                    value={fieldValues.other_skills}
                  />
                </Form.Group>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Total Family Member's</Form.Label>
                  <Form.Control
                    type="number"
                    name="family_count"
                    placeholder="Total Family Count"
                    onChange={handleFieldChange}
                    value={fieldValues.family_count}
                  />
                </Form.Group>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Total Earning Members</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Earning Count"
                    name="earning_count"
                    onChange={handleFieldChange}
                    value={fieldValues.earning_count}
                  />
                </Form.Group>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" co0ntrolId="formBasicEmail">
                  <Form.Label>
                    Marital Status<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Select
                    onChange={handleFieldChange}
                    aria-label="Default select example"
                    name="marital_status"
                    value={fieldValues.marital_status}
                    required
                  >
                    <option selected="selected">Select Marital Status</option>
                    {meritalData?.map((gen, index) => (
                      <option name="marital_status" value={gen.status} key={index}>
                        {gen.status}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              {fieldValues.marital_status !== "Single" ? (
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Spouse Occupation</Form.Label>
                    <Form.Control
                      type="text"
                      name="spouse_occupation"
                      placeholder="Spouse Occupation"
                      onChange={handleFieldChange}
                      value={fieldValues.spouse_occupation}
                    />
                  </Form.Group>
                </div>
              ) : undefined}
              {fieldValues.marital_status !== "Single" ? (
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Total Children</Form.Label>
                    <Form.Control
                      type="number"
                      name="children_count"
                      placeholder="Total Children"
                      onChange={handleFieldChange}
                      value={fieldValues.children_count}
                    />
                  </Form.Group>
                </div>
              ) : undefined}
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Aadhar Card<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="aadhar"
                    placeholder="Aadhar Card"
                    onChange={handleFieldChange}
                    value={fieldValues.aadhar}
                    required
                  />

                  {error.aadhar && (
                    <p className="text-danger">{aadharmessage}</p>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    State<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Select
                    onChange={handleFieldChange}
                    aria-label="Default select example"
                    name="state_id"
                    value={fieldValues.state_id}
                    required
                  >
                    <option selected="selected">Select State</option>
                    {stateDropDown?.length > 0 &&
                      stateDropDown?.map((state, index) => (
                        <option name="state_id" value={state.id} key={index}>
                          {state.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>
                    District<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Select
                    onChange={handleFieldChange}
                    aria-label="Default select example"
                    name="district_id"
                    value={fieldValues.district_id}
                  >
                    <option selected="selected">Select Discrict</option>
                    {distDrop?.length > 0 &&
                      distDrop?.map((state, index) => (
                        <option value={state.id} key={index}>
                          {state.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>
                    Place<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Select
                    onChange={handleFieldChange}
                    aria-label="Default select example"
                    name="place_id"
                    value={fieldValues.place_id}
                  >
                    <option selected="selected">Select Place</option>
                    {placeDrop?.length > 0 &&
                      placeDrop?.map((state, index) => (
                        <option value={state.id} key={index}>
                          {state.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Pincode
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="pincode"
                    placeholder="Pin code"
                    onChange={handleFieldChange}
                    value={fieldValues.pincode}
                  />
                  {error.pincode && (
                    <p className="text-danger">{pincodeMessage}</p>
                  )}
                </Form.Group>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Village<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="village"
                    placeholder="Village"
                    onChange={handleFieldChange}
                    value={fieldValues.village}
                  />
                </Form.Group>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Gram Panchayat / Ward No.
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="panchayat_ward"
                    placeholder="Gram Panchayat / Ward No."
                    onChange={handleFieldChange}
                    value={fieldValues.panchayat_ward}
                  />
                </Form.Group>
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Address<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Address"
                    onChange={handleFieldChange}
                    value={fieldValues.address}
                  />
                </Form.Group>
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Camp Reg No.
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="camp_patient_id"
                    placeholder="Camp registration number"
                    onChange={handleFieldChange}
                    value={fieldValues.camp_patient_id}
                  />
                </Form.Group>
              </div>



              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Bank Account</Form.Label>
                  <Form.Select
                    aria-label="select"
                    name="bank_account"
                    value={fieldValues.bank_account}
                    onChange={handleFieldChange}
                  >
                    <option>Select Bank Account</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                  </Form.Select>
                </Form.Group>
              </div>


              <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Label>Patient Biometric</Form.Label>
                <InputGroup className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    readOnly
                    aria-label="Amount (to the nearest dollar)"
                  // name="biometric" // Add the name attribute
                  // value={fieldValues?.name}
                  // onChange={handleFieldChange}
                  />
                  <InputGroup.Text
                    style={{
                      backgroundColor: "transparent",
                      borderLeft: "none",
                    }}
                    onClick={() => setOpen(true)}
                  >
                    <FingerprintIcon />
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <hr></hr>
            <div className="row d-flex">
              <div className="row d-flex mt-3">
                {/* <hr className="mt-3" style={{ width: "90%" }}></hr> */}
                <div className="text-end mb-3">
                  <Button
                    name="Add family mmbers"
                    className="add-family-member"
                    onClick={() => {
                      setMemberAction("Add");
                      setFamilyModal(true);
                      setShowTable(true);
                    }}
                    disabled={disabledHandler()}
                  >
                    Add Family Member (Optional)
                  </Button>
                </div>
                {/* <h4>sdsds</h4> */}
                {/* ////////////////////// */}

                <div id="doctorId">
                  <div className="patientdo" style={{ margin: "2%" }}>
                    <table className="" style={{ width: "100%", margin: 0 }}>
                      {familyMemberArray.length > 0 && (
                        <thead>
                          <tr>
                            <th>Family Member Name</th>
                            <th>Relationship</th>
                            <th>Gender</th>
                            <th>DOB</th>
                            <th>Addiction</th>
                            <th>Aadhar</th>
                            <th>Bank Account</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                      )}

                      <tbody>
                        {familyMemberArray?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item?.family_member_name}</td>
                              <td>{item?.relationship}</td>
                              <td>{item?.gender}</td>
                              <td>{item?.dob}</td>
                              <td>{item?.addiction}</td>
                              <td>{item?.aadhar}</td>
                              <td>{item?.bank_account}</td>
                              <td
                                onClick={() => {
                                  setMemberEditId(item.id);
                                  setMemberAction("Edit");
                                  setFamilyModal(true);
                                  setIsEdit(true);
                                }}
                              >
                                <img
                                  alt=""
                                  className="patient-edit-icon"
                                  src={EditIcon}
                                />
                              </td>
                              <td
                                onClick={() => {
                                  setMemberDeleteId(item.id);
                                }}
                              >
                                <img
                                  alt=""
                                  className="patient-delete-icon"
                                  src={DeleteIcon}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="btn-card mt-3">
            <button
              type="button"
              className="btn-reset me-2"
              // disabled={hasValues}
              onClick={ResetForm}
            // style={{
            //   display: state.action === "Edit" ? "none" : undefined,
            // }}
            >
              Reset
            </button>
            <button
              type="submit"
              className="btn-save"
              disabled={disabledHandler()}
            // onClick={() => setModalOnSave(true)}
            >
              {patientEdit ? "Update" : "Save"}
            </button>
          </div>
        </form>

        {
          <AddFamilymembersModal
            familyModal={familyModal}
            setFamilyModal={setFamilyModal}
            closeFamilyModal={closeFamilyModal}
            setFamilyMembersData={setFamilyMembersData}
            familyMembersData={familyMembersData}
            addictionData={addictionData}
            genderData={genderData}
            relationshipData={relationshipData}
            setFamilyMemberArray={setFamilyMemberArray}
            familyMemberArray={familyMemberArray}
            setMemberEditId={setMemberEditId}
            setMemberAction={setMemberAction}
            memberAction={memberAction}
            memberEditId={memberEditId}
            setUpdated={setUpdated}
            memberDeleteId={memberDeleteId}
            fieldValues={fieldValues}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
          />
        }

        {
          <InputFieldModal
            open={open}
            handleClose={handleClose}
            handleContentChange={handleContentChange}
            content={content}
          />
        }
        {
          <div className="webcam-container">
            <Modal
              open={videoOpen}
              onClose={videoHandleClose}
            // onClick={handleContentChange}
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                {/* <div className="text-end mr-2" onClick={videoHandleClose}>
                  <img alt="" src={doclose} style={{ cursor: "pointer" }}></img>
                </div> */}
                <div className="webcam-box">
                  <video ref={videoRef} autoPlay />
                  <div className="webcam-btn">
                    <CenterFocusWeakIcon
                      onClick={takePicture}
                      style={{ fontSize: "40px", cursor: "pointer" }}
                    />
                  </div>
                </div>

                {/* <button
                  className="btn btn-primary"
                  style={{ width: "650px" }}
                  onClick={takePicture}
                >
                  Take Selfie
                </button> */}
              </Box>
            </Modal>
          </div>
        }
        {
          <div className="webcam-container">
            <Modal
              open={modalOnSave}
              onClose={saveHandleClose}
            // onClick={handleContentChange}
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
            >
              <Box sx={saveModalStyle}>
                <div className="relative">
                  <img src={groupImg}></img>
                  <div style={{ position: "absolute", top: "20%" }}>
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Patient Name:
                    </p>
                    <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                      <div>
                        {fieldValues.first_name} {fieldValues.middle_name}{" "}
                        {fieldValues.last_name}
                      </div>
                    </p>
                  </div>
                  <div style={{ position: "absolute", bottom: "10%" }}>
                    <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                      {patientId}
                    </p>
                  </div>
                </div>
                <img
                  alt=""
                  onClick={() => saveHandleClose()}
                  style={{ position: "absolute", top: "2%", right: "2%" }}
                  src={doclose}
                ></img>
              </Box>
            </Modal>
          </div>
        }
      </Layout>
    </>
  );
};

export default AddPatientForm;
