import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "../../../Assets/icons/modal-close-icon.svg";
import searchIcon from "../../../Assets/icons/search_icon.svg";
import searchCloseIcon from "../../../Assets/icons/search-close-icon.png";
import "./HealthCampDropDown.scss";
import { readService } from "../../../Services/HttpService";
import { TablePagination } from "@mui/material";
import Spinner from "../../../Components/Spinner/Spinner";

export default function HealthCampDropDown(props) {
  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    setOffset,
    offset,
    setTotalCount,
  } = props;
  const [showSpinner, setShowSpinner] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedImsData, setSelectedImsData] = useState(props.data);
  const [selectedOption, setSelectedOption] = useState(null);

  // console.log("selected doctor is===================", props.itemsData)
  useEffect(() => {
    if (searchValue === "") getAllImsData(0, 5,"");
  }, [searchValue]);
  

  useEffect(() => {
    setSelectedImsData(props.data);
  }, [props.data]);

  const onCloseClick = () => {
    props.onClose();
    setSearchValue("");
  };

  const handleSearchTextEnter = (event) => {
    setSearchValue(event.target.value);
  };

  const onSearchClick = async () => {
    const params = {
      name: searchValue,
    };
    try {
      const response = await readService("healthCamps", params);
      // const response = await readService("imshospitals", params);
      props.setImsData(response.data.body);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const clearSearchValue = () => {
    setSearchValue("");
    getAllImsData(offset,0, "");
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = pageSize * pageIndex;
    getAllImsData(offset, pageSize, searchValue);
    setOffset(offset);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    let nePageSize = event.target.value
    setPage(0);
    getAllImsData(0,nePageSize,searchValue);
  };

  const selectedDoctorApply = () => {
    props.setSelectedId(selectedOption);
    props.onClose();
  };

  const getAllImsData = async (offset = 0, newPageSize, searchValue) => {
    try {
      setShowSpinner(true);
      const params = {
        offset: offset,
        limit: newPageSize,
        searchText: searchValue,
      };
      const response = await readService("healthCamps", params);
      // const response = await readService("imshospitals", params);
      props.setImsData(response?.data?.body);
      setTotalCount(response?.data?.count);
      setShowSpinner(false);
      setOffset(response?.data?.offset);
    } catch (e) {
      console.log("Error while fetching Doctor list", e);
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    getAllImsData(0, 5, searchValue);
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div id="health-camp-popup">
      <Modal
        size="lg"
        dialogClassName="common-popup"
        backdrop="static"
        show={props.isHidden}
        onHide={props.onClose}
        centered
      >
        <Modal.Header className="assign-modal-header">
          <img src={closeIcon} alt="" onClick={onCloseClick} />
        </Modal.Header>
        <Modal.Body>
          <div className="row align-items-center">
            <div className="col-6">
              <label className="label-input">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search by Health Camp name"
                  value={searchValue}
                  onChange={handleSearchTextEnter}
                />
                {searchValue && (
                  <button
                    className="search-btn-close"
                    onClick={clearSearchValue}
                  >
                    <img src={searchCloseIcon} alt="" width={"24px"} />
                  </button>
                )}
                <button className="search-btn" onClick={onSearchClick}>
                  <img src={searchIcon} alt="" />
                  <span>Search</span>
                </button>
              </label>
            </div>
            <div className="col-2 text-end">
              <button className="apply-btn" onClick={selectedDoctorApply}>
                Apply
              </button>
            </div>
          </div>
          <div className="table-container">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>SL.No</th>
                  <th>Health Camp Id</th>
                  <th>Health Camp Name</th>
                </tr>
              </thead>
              <tbody>
                {props.data &&
                  props.data.map((item, index) => {
                    const slNo = index + 1 + page * pageSize; 
                    return (
                      <tr>
                        <td>
                          <input
                            type="radio"
                            onChange={handleOptionChange}
                            value={item.id}
                            checked={selectedOption === item.id}
                          />
                        </td>
                        <td>{slNo}</td>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="flex justify-content-end table-page">
              <TablePagination
                component="div"
                count={props.totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[5, 10, 20, 40]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Modal.Body>
        {showSpinner && <Spinner />}
      </Modal>
    </div>
  );
}
