import { useSnackbar } from "notistack";

export const successToast = (enqueueSnackbar, message) => {
  enqueueSnackbar(message || "Updated Successfully!!!", {
    variant: "success",
    autoHideDuration: 4000,
  });
};

export const errorToast = (enqueueSnackbar, message) => {
  enqueueSnackbar(message || "Something Went Wrong, Please Try Again!!!", {
    variant: "error",
    autoHideDuration: 4000,
  });
};
