import React, { useEffect, useState } from "react";
import filterIcon from "../../Assets/icons/filter_icon_black.svg";
import CrossIcon from "../../Assets/icons/cross_black_icon.svg";
import "./FilterModal.scss";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { getGenders } from "../../Services/Metadata/Metadata";

const AGE_GROUP = [
  { age: "1-10" },
  { age: "11-25" },
  { age: "26-40" },
  { age: "41-60" },
  { age: "60-120" },
];

const FilterModal = (props) => {
  const {
    onClose,
    setAgeUpper,
    setAgeLower,
    setGender,
    genderDrop,
    onFilterSubmit,
  } = props;

  console.log(" ==========> genderDrop", genderDrop);

  const [values, setValues] = React.useState({
    age: "",
    lower: 0,
    upper: 100,
    gender: "",
    bloodGroup: "",
    ageLabel: "",
  });

  const options = [
    { name: "one", value: 1 },
    { name: "two", value: 2 },
    { name: "three", value: 3 },
    { name: "four", value: 4 },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "age") {
      const [upper, lower] = value.split("-");
      const dummy = {
        ...values,
        lower: parseInt(lower),
        upper: parseInt(upper),
        ageLabel: value,
      };
      setValues(dummy);
    } else {
      const dummy = { ...values, [name]: value };
      setValues(dummy);
    }
  };

  const handleSubmit = () => {
    setGender(values.gender);
    setAgeLower(values.upper);
    setAgeUpper(values.lower);
    // onFilterSubmit();
    onClose();
  };

  return (
    <div id="health-and-wellness-filter">
      <div className="top-container">
        <div className="top-title-container">
          <img src={filterIcon} alt="" />
          <div className="top-name">Filter</div>
        </div>
        <div>
          <img
            src={CrossIcon}
            alt="logo"
            className="cross-icon"
            onClick={onClose}
          />
        </div>
      </div>
      <div className="bottom-main-container">
        <div className="select-pair">
          <div>Age Group</div>
          <div>
            <select
              className="select-input"
              placeholder="Select District"
              defaultValue={"DEFAULT"}
              name="age"
              onChange={handleChange}
              value={values.ageLabel}
            >
              <option value={{ upper: 100, lower: 0 }}>Select Age</option>
              {AGE_GROUP.length > 0 &&
                AGE_GROUP.map((option, index) => (
                  <option value={option.age} key={index}>
                    {option.age} Years
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="select-pair">
          <div>Gender</div>
          <div>
            <select
              className="select-input"
              placeholder="Select District"
              defaultValue={"DEFAULT"}
              name="gender"
              onChange={handleChange}
              value={values?.gender}
            >
              <option value="">Gender</option>
              {genderDrop.length > 0 &&
                genderDrop.map((option, index) => (
                  <option value={option.gender} key={index}>
                    {option.gender}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {/* <div className="select-pair">
          <div>Blood Sugar Level</div>
          <div>
            <select
              className="select-input"
              placeholder="Select District"
              defaultValue={"DEFAULT"}
              name="bloodSugarLevel"
              onChange={handleChange}
              value={values?.bloodSugarLevel}
            >
              <option value="DEFAULT" disabled>
                Select Blood Sugar level
              </option>
              {options.length > 0 &&
                options.map((option, index) => (
                  <option value={option.value} key={index}>
                    {option.name}
                  </option>
                ))}
            </select>
          </div>
        </div> */}

        {/* <div className="select-pair">
          <div>Blood Group</div>
          <div>
            <select
              className="select-input"
              placeholder="Select District"
              defaultValue={"DEFAULT"}
              name="bloodGroup"
              onChange={handleChange}
              value={values?.bloodGroup}
            >
              <option value="" disabled>
                Select Blood group
              </option>
              {options.length > 0 &&
                options.map((option, index) => (
                  <option value={option.value} key={index}>
                    {option.name}
                  </option>
                ))}
            </select>
          </div>
        </div> */}
      </div>
      <div className="bottom-button-pair">
        <PrimaryButton
          name="Cancel"
          className="cancel-button"
          onClick={onClose}
        />
        <PrimaryButton name="Apply" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default FilterModal;
