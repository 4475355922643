import React from "react";
import { Form } from "react-bootstrap";
import { cropName } from "../../../Utils/Utility";
import "./HealthCampDropDown.scss";

const RecentAdmittedPatients = (props) => {
  const {
    data = [],
    imsData2,
    setShowDropDown2,
    healthCampIdRecentPatients,
  } = props;

  // console.log(" ==========> data in recent ", data);

  return (
    <div id="recent-admitted-patients">
      <div>
        <div
          className="dashboard-table-container overflow-auto pb-3"
          style={{ margin: "2%" }}
        >
          <div style={{ display: "flex", margin: "10px 0px" }}>
            <p
              className="col-lg-6 col-md-6 col-sm-12"
              style={{
                fontSize: "21px",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              Recent Added Patients
            </p>
            <div
              className="col-lg-5 col-md-6 col-sm-12"
              style={{ marginRight: 10 }}
            >
              <Form.Group controlId="formBasicEmail">
                <div onClick={() => setShowDropDown2(true)}>
                  <Form.Select
                    aria-label="select"
                    onChange={(e) => console.log("jkl")}
                    name="id"
                    value={healthCampIdRecentPatients}
                    style={{ backgroundColor: "#fff" }}
                    onTouchStart={() => console.log("world")}
                  >
                    <option>Select Health Camp</option>
                    {imsData2.length > 0 &&
                      imsData2?.map((item) => (
                        <option
                          style={{ visibility: "hidden" }}
                          value={item.id}
                          key={item.id}
                        >
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
          </div>

          {data?.length ? (
            <table className="dashboard-table">
              <thead>
                <tr className="dashbord-table-header">
                  <th>Patient Name</th>
                  <th>Gender</th>
                  <th>Age</th>
                  <th>Camp Id</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, i) => {
                  const { age, full_name, gender, healthcamp_id } = item;
                  return (
                    <tr>
                      <td>{cropName(full_name)}</td>
                      <td>{gender}</td>
                      <td>{age}</td>
                      <td>{healthcamp_id}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // height: "30vh",
                minHeight: 365,
              }}
            >
              <div>No data found</div>
            </div>
          )}

          <div className="flex justify-content-end table-page"></div>
        </div>
      </div>
    </div>
  );
};

export default RecentAdmittedPatients;
