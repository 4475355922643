import moment from "moment";
import React from "react";
import { formatTimeHM } from "../../../Utils/DateUtils";
import { cropName } from "../../../Utils/Utility";

const UpcomingHealthCamps = (props) => {
  const { data = [] } = props;

  return (
    <div id="recent-health-camps">
      <div>
        <div
          className="dashboard-table-container overflow-auto pb-3"
          style={{ margin: "2%" }}
        >
          <p
            className="pt-3"
            style={{
              fontSize: "21px",
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Recent Health Camps
          </p>
          <table className="dashboard-table ">
            <thead>
              <tr className="dashbord-table-header">
                {/* <th>Camp Id</th> */}
                <th>Camp Name</th>
                <th>From</th>
                <th>To</th>
                <th>Timings</th>
                {/* <th>Venue</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, i) => {
                const {
                  hospital_id,
                  name,
                  from_date,
                  to_date,
                  start_time,
                  end_time,
                } = item;
                return (
                  <tr>
                    {/* <td>{hospital_id}</td> */}
                    <td>{cropName(name)}</td>
                    <td>{moment(from_date).format("DD MMM YYYY")}</td>
                    <td>{moment(to_date).format("DD MMM YYYY")}</td>
                    <td>
                      {formatTimeHM(start_time)}-{formatTimeHM(end_time)}
                    </td>
                    {/* <td>weiwewieuwi</td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-content-end table-page"></div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingHealthCamps;
