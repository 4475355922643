import { createService, readService, updateService } from "../HttpService";

export const addPatientBasicHealthInfo = (id, body) => {
  return createService(`patient/healthInfo/${id}`, body);
};

export const getAllAddictions = (params) => {
  return readService("metadata/addictions", params);
};
export const getChronicConditions = (params) => {
  return readService("metadata/chronicConditions", params);
};

export const getAllPatientVisits = (id, params) => {
  return readService(`patient/visits/${id}`, params);
};
export const getPatientsVisitsById = (id,visitId, params) => {
  return readService(`patient/healthInfo/${id}/${visitId}`, params);
};
export const getHealthBasicInfoData = (id) => {
  return readService(`patient/healthBasicInfo/${id}`);
};

export const createBasicHealthData = (id, formData) => {
  return updateService(`patient/healthBasicInfo/${id}`, {}, formData);
};

export const updatePatientBasicHealthInfo = (id, formData) => {
  return updateService(`patient/healthBasicInfo/${id}`, {}, formData);
};
