import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AccessDenied from "../Accessdenied/AccessDenied";

export const ProtectedRoute = (props) => {
  const { element } = props;
  const { sideNavState, setSideNavState } = element.props;
  const [modules, setModules] = useState([]);
  const [roleState, setRoleState] = useState(null);
  const [modulesLoaded, setModulesLoaded] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const localtion = useLocation();
  useEffect(() => {
    const rolee = localStorage.getItem("role");
    if (rolee) {
      setRoleState(localStorage.getItem("role"));
    }
    if (rolee == "admins") {
      setModules([
        "dashboard",
        "doctors",
        "patient",
        "nurses",
        "hospital",
        "nurses",
        "healthcamp",
        "data-operator",
        "admin",
        "health-survey",
        "addnewpatient",
        "patientcrud",
        "viewAllPatient",
        "createhealthcamp ",
      ]);
    } else if (rolee == "doctors") {
      setModules([
        "doctorsindex",
        "patienttabs",
        "doctorsgeneralitp",
        "doctorsmchtabs",
      ]);
    } else if (rolee == "data-operators") {
      setModules(["dataoprpat", "addnewpatientform"]);
    } else if (rolee == "pharmacists") {
      setModules(["parmacist"]);
    } else if (rolee == "nurses") {
      setModules([
        "nurseindex",
        "nursepatienttab",
        "nursegeneralitp",
        "nursenmch",
      ]);
    } else if (rolee == "mch-admins") {
      setModules([
        "dashboard",
        "doctors",
        "patient",
        "hospital",
        "nurses",
        "healthcamp",
        "data-operator",
        "admin",
        "health-survey",
        "pathologist-co-ordinator",
        "pharmacist-co-ordinator",
      ]);
    } else if (rolee == "ims-admins") {
      setModules([
        "dashboard",
        "doctors",
        "patient",
        "hospital",
        "nurses",
        "healthcamp",
        "data-operator",
        "admin",
        "health-survey",
        "co-ordinator",
        "pharmacy-ims",
        "bus-info",
        "bus-driver",
      ]);
    }
  }, []);
  useEffect(() => {
    if (modules != []) {
      const isAllowed = modules.some((val) =>
        val.includes(localtion.pathname.split("/")[2])
      );
      setAllowed(isAllowed);
    }
  }, [modules]);
  useEffect(() => {
    if (modules.length > 0) {
      setModulesLoaded(true);
    }
  }, [modules]);

  if (!modulesLoaded) {
    return null;
  }
  return allowed ? (
    element
  ) : (
    <AccessDenied
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
    />
  );
};
