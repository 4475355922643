import React, { useEffect, useMemo, useRef, useState } from "react";
import ProfileIconBlack from "../../../Assets/Images/dataop-user-form.png";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../LayoutComp/Layout";
import { Button, Form, InputGroup } from "react-bootstrap";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import InputFieldModal from "./Modal/InputFieldModal";
import fingerIcon from "../../../Assets/icons/fingerprint.svg";
import loadingIcon from "../../../Assets/icons/loading-png-icon.png";
import "./addPatient.scss";

import { Box, ButtonBase, Modal, Popover, Typography } from "@mui/material";
import groupImg from "../../../Assets/Images/group-do-img.png";
import doclose from "../../../Assets/icons/do-close.svg";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { EditIcon, DeleteIcon, backBtnIcon } from "../../../Utils/ImageUtils";
import {
  getAddictions,
  getGenders,
  getMeritalStatus,
  getRelationship,
} from "../../../Services/Metadata/Metadata";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import { createService } from "../../../Services/HttpService";
import { useDispatch, useSelector } from "react-redux";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  ConvertYMD,
  formatDate,
  formatDatePatient,
} from "../../../Utils/DateUtils";
import { useSnackbar } from "notistack";
import { errorToast, successToast } from "../../../Utils/toasters";
import {
  getDistrictData,
  getPlaceData,
  getStateData,
} from "../../../Services/MCH";
import { setPatientFullName } from "../../Redux/slices/userSlice";
import {
  ContactValidator,
  aadharNumber,
  pinCodeValidate,
} from "../../../Utils/Validators";
import { ref } from "yup";
const style = {
  position: "absolute",
  top: "80%",
  left: "70%",
  transform: "translate(-100%, -100%)",
  //  width: 400,
  bgcolor: "background.paper",
  margin: "0",
  padding: "0",
};
const saveModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


function QuickRegistration({ sideNavState, setSideNavState }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [familyModal, setFamilyModal] = useState(false);
    const [videoOpen, setVideoOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [stream, setStream] = useState(null);
    const [hideIcon, setHideIcon] = useState(true);
    const [hideCanvas, setHideCanvas] = useState(false);
    const [modalOnSave, setModalOnSave] = useState(false);
    const [relationshipData, setRelationshipData] = useState([]);
    const [genderData, setGenderData] = useState([]);
    const [addictionData, setAddictionData] = useState([]);
    const [memberEditId, setMemberEditId] = useState(null);
    const [memberAction, setMemberAction] = useState(null);
    const [familyMemberArray, setFamilyMemberArray] = useState([]);
    const [meritalData, setMeritalData] = useState([]);
    const [updated, setUpdated] = useState(false);
    const [memberDeleteId, setMemberDeleteId] = useState(null);
    const [image, setImage] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { healthCampid } = useSelector((state) => state.name);
    const [patientId, setPatientID] = useState("");
  
    const [stateDropDown, setStateDropDown] = useState([]);
    const [distDrop, setDistDrop] = useState([]);
    const [placeDrop, setPlaceDrop] = useState([]);
  
    const dispatch = useDispatch();
    const [fullNamee, setFullNamee] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [count, setCount] = useState(1);
    const [capturedImage, setCapturedImage] = useState(null);
  
    const [showTable, setShowTable] = useState(false);
    const [message, setMessage] = useState(null);
    const [aadharmessage, setAadharMessage] = useState(null);
    const [error, setError] = useState({
      contact_number: false,
      password: false,
      aadhar: false,
      pincode: false,
    });
  
    const { campData } = useSelector((state) => state.campData);
  
    const [familyMembersData, setFamilyMembersData] = useState({
      id: "",
      aadhar: "",
      addiction: "",
      dob: "",
      family_member_name: "",
      gender: "",
      relationship: "",
      bank_account: "",
    });
  
    const [fieldValues, setFieldValues] = useState({
      // first_name: "",
      // middle_name: "",
      // last_name: "",
      full_name: "",
      gender: "",
      contact_number: "",
      // doc_type: "",
      // doc_number: "",
      ayush_card: "",
      occupation: "",
      farm_technique: "",
      annual_income: "",
      other_skills: "",
      family_count: "",
      earning_count: "",
      marital_status: "",
      spouse_occupation: "",
      children_count: "",
      aadhar: "",
      bank_account: null,
      biometric: null,
      image: "",
      healthcamp_id: healthCampid,
      family_members: "",
      dob: "",
      state_id: "",
      district_id: "",
      place_id: "",
      village: "",
      address: "",
      pincode: "",
      panchayat_ward:"",
      camp_patient_id:""
    });
  
    useEffect(() => {
      getState();
    }, []);
  
  
    const getState = async () => {
      const states = await getStateData();
      if(campData) {
        let obj = {...fieldValues}
        obj.state_id = campData?.state_id
        obj.district_id = campData?.district_id
        obj.place_id = campData?.place_id
        // obj.pincode = campData?.pincode
        setFieldValues(obj)
      }
      setStateDropDown(states.data);
    };
  
    useEffect(() => {
      if (fieldValues.state_id) getDistrict();
    }, [fieldValues.state_id]);
  
    const getDistrict = async () => {
      const district = await getDistrictData(fieldValues.state_id);
      setDistDrop(district.data);
    };
  
    useEffect(() => {
      if (fieldValues.district_id) getPlace();
    }, [fieldValues.district_id]);
  
    const getPlace = async () => {
      const place = await getPlaceData(fieldValues.district_id);
      setPlaceDrop(place.data);
    };
  
    useEffect(() => {
      console.log("Before Updated array is"+JSON.stringify(familyMemberArray))
      const updatedArray = familyMemberArray.map((item) => {
        let obj = {}
        if(item?.aadhar.length > 0)obj.aadhar = item.aadhar
        if(item?.addiction.length > 0)obj.addiction = item.addiction
        if(item?.dob.length > 0)obj.dob = item.dob
        if(item?.gender.length > 0)obj.gender = item.gender
        if(item?.bank_account.length > 0)obj.bank_account = item.bank_account
        obj.relationship = item.relationship
        obj.family_member_name = item.family_member_name
        const { id, ...rest } = obj;
        return rest;
      });
  console.log("Updated array is"+JSON.stringify(updatedArray))
      setFieldValues((prev) => ({
        ...prev,
        family_members: JSON.stringify(updatedArray),
      }));
      setUpdated(false);
    }, [updated]);
  
    useEffect(() => {
      const getAddictionData = async () => {
        const addiction = await getAddictions();
        setAddictionData(addiction.data);
      };
      const getGenderData = async () => {
        const genders = await getGenders();
        setGenderData(genders.data);
      };
  
      const getRelationshipData = async () => {
        const relation = await getRelationship();
        setRelationshipData(relation.data);
      };
      const getMeritalData = async () => {
        const relation = await getMeritalStatus();
        setMeritalData(relation.data);
      };
  
      getMeritalData();
      getRelationshipData();
      getGenderData();
      getAddictionData();
    }, []);
  
    //  const [modalContent,setModalContent]=useState('')
    const [clickCount, setClickCount] = useState(0);
    let videoRef = useRef(null);
    let photoRef = useRef(null);
    const handleClickCam = async () => {
      if (!videoOpen) {
        setVideoOpen(true);
  
        setHideCanvas(true);
        setHideIcon(false);
        const stream = await navigator?.mediaDevices?.getUserMedia({
          video: true,
        });
        setStream(stream);
      } else {
        videoHandleClose();
      }
    };
  
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
    const videoHandleClose = () => {
      setVideoOpen(false);
      if (videoRef.current?.srcObject) {
        videoRef?.current?.srcObject
          ?.getTracks()
          ?.forEach((track) => track.stop());
      }
      setStream(null);
      if (!image) {
        setHideIcon(true);
        setHideCanvas(false);
      }
    };
  
    const takePicture = () => {
      let width = 200;
      let height = 200;
      let photo = photoRef?.current;
      let video = videoRef?.current;
      photo.width = width;
      photo.height = height;
      let ctx = photo.getContext("2d");
      ctx.drawImage(video, 0, 0, photo.width, photo.height);
      const capturedImageBase64 = photo.toDataURL();
      setHideIcon(false);
      const byteCharacters = atob(capturedImageBase64.split(",")[1]);
      const byteArrays = [];
  
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
  
      const blob = new Blob(byteArrays, { type: "image/png" });
      const fileOptions = {
        type: blob.type,
        lastModified: new Date().getTime(),
        name: "drImg.png",
        size: blob.size,
      };
  
      const fileData = new File([blob], "drImg.png", fileOptions);
      setCapturedImage(fileData);
      if (fileData) {
        setVideoOpen(false);
        if (videoRef.current?.srcObject) {
          videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        }
        setStream(null);
        //  setHideIcon(false);
        //  setHideCanvas(true);
      }
      // setVideoOpen(false);
  
      if (!fileOptions) {
        width = 0;
        height = 0;
      }
    };
    useEffect(() => {
      if (capturedImage !== null) {
        setImage(capturedImage);
        // videoHandleClose()
      }
    }, [capturedImage]);
    const saveHandleClose = () => {
      // setSpinner(true)
      setModalOnSave(false);
      setFieldValues({
        full_name: "",
        gender: "",
        contact_number: "",
        aadhar: "",
        image: "",
        healthcamp_id: "",
        state_id: "",
        district_id: "",
        place_id: "",
        village: "",
        pincode: "",
        address: "",
        dob: "",
        // ayush_card: "",
        // biometric: null,
        panchayat_ward:"",
        camp_patient_id:""
      });
      setFamilyMemberArray([]);
      setFamilyMembersData({
        id: "",
        aadhar: "",
        addiction: "",
        dob: "",
        family_member_name: "",
        gender: "",
        relationship: "",
      });
      setImage("");
      setPatientID("");
      setModalOnSave(false);
      naviate("/home/allPatientsPage");
    };
  
    const getModalContent = () => {
      switch (clickCount) {
        case 0:
          return {
            title: "Biometric Authentication",
            subTitle: "Please Place Your Finger On the Scanner",
            icon: fingerIcon,
            bottomData: "100%",
            bottomDataTwo: "Scanning Completed",
          };
  
        case 1:
          return {
            title: "Biometric",
            subTitle: "Verify Biometric",
            icon: loadingIcon,
            bottomData: "",
            bottomDataTwo: "",
          };
        case 2:
          return {
            title: "Verified",
            subTitle: "",
            icon: "",
            bottomData: "",
            bottomDataTwo: "",
          };
        default:
          return {
            title: "Bio Authentication",
            subTitle: "Please Place Your Finger On the Scanner",
            icon: loadingIcon,
            bottomData: "",
            bottomDataTwo: "",
          };
      }
    };
  
    const content = getModalContent();
    const handleContentChange = () => {
      if (clickCount < 2) {
        setClickCount(clickCount + 1);
      } else {
        handleClose();
        setClickCount(0);
      }
    };
    const popoverHandleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const popoverhandleClose = () => {
      setAnchorEl(null);
    };
    const popoveropen = Boolean(anchorEl);
  
    const id = popoveropen ? "simple-popover" : undefined;
    const closeFamilyModal = () => {
      setFamilyModal(false);
      setIsEdit(false);
    };
    const [showPopup, setShowPopup] = useState(false); // State to control the visibility of the popup
    const [pincodeMessage, setPinCodeMessage] = useState(null);
  
    const handleFieldChange = (e) => {
      const { name, value } = e.target;
      if (name === "contact_number") {
        ContactValidator(setError, setMessage, value);
      }
      if (name == "aadhar") {
        aadharNumber(setError, setAadharMessage, value);
      }
      if (name == "pincode") {
        pinCodeValidate(setError, setPinCodeMessage, value);
      }
      if (name == "ayush_card") {
        if (value == "Available") {
          setShowPopup(true);
        } else {
          setShowPopup(false);
        }
      }
      setFieldValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      // if (name == "full_name") {
      //   setFieldValues(value);
      // }

    };
  
    
  
   
  
    const formSubmit = async (e) => {
      e.preventDefault();
      // YYYY-MM-DD
      const formData = new FormData();
      formData.append("full_name", fieldValues.full_name);
      formData.append("gender", fieldValues.gender);
      formData.append("dob", ConvertYMD(fieldValues.dob));
      formData.append("contact_number", fieldValues.contact_number);
      formData.append("aadhar", fieldValues.aadhar);
      formData.append("state_id", fieldValues.state_id);
      formData.append("place_id", fieldValues.place_id);
      formData.append("district_id", fieldValues.district_id);
      formData.append("village", fieldValues.village);
      formData.append("pincode", fieldValues.pincode);
      formData.append("address", fieldValues.address);
      formData.append("biometric", fieldValues.biometric);
      formData.append("panchayat_ward",fieldValues.panchayat_ward)
      formData.append("camp_patient_id",fieldValues.camp_patient_id)

      formData.append(
        "healthcamp_id",
        localStorage.getItem("healthCampid", healthCampid)
      );

      console.log("API Request Data:", formData);

      // formData.append("image", image);
      // try {
      const resp = await createService(
        "patients/generalinfoLite",
        formData,
        {},
        enqueueSnackbar
      );
     
      if (resp.status === 201) {
        successToast(enqueueSnackbar, resp.data.message);
        setModalOnSave(true);
        // ResetForm();
      }
      else if (resp.status === 500) {
        errorToast(enqueueSnackbar, "Please check the data you entered and try again");
      }
     else  if (resp.status < 201 || resp.status > 300) {
        let msg = resp?.data?.errors.map((item) => item.msg);
        errorToast(enqueueSnackbar, msg?.join(""));
        // ResetForm();
        setFamilyMemberArray([]);
      } else {
        errorToast(enqueueSnackbar, "Failed to add the patient. Please try agen");
      }
      setPatientID(resp?.data?.body?.patientId);
  
      // } catch (err) {
      // errorToast(enqueueSnackbar, "");
      // }

      console.log("Api testing", formData);
    };


    const ResetForm = () => {
      setFieldValues({
        full_name: "",
        gender: "",
        contact_number: "",
        ayush_card: "",
        aadhar: "",
        image: "",
        dob: "",
        address: "",
        pincode: "",
        village: "",
        state_id: "",
        place_id: "",
        district_id: "",
        panchayat_ward:"",
        camp_patient_id:""
      });
      setShowPopup(false);
      setFamilyMemberArray([]);
    };
  
    const naviate = useNavigate();

    const disabledHandler = () => {
      console.log("submited_data", fieldValues)
      if (
        !fieldValues.full_name ||
        !fieldValues.dob ||
        !fieldValues.gender ||
        !fieldValues.address ||
        // !fieldValues.village ||
        !fieldValues.state_id ||
        !fieldValues.district_id ||
        !fieldValues.place_id ||
        // !fieldValues.bank_account ||

        !fieldValues.aadhar ||
        error.contact_number == true ||
        !fieldValues.contact_number ||
        error.aadhar == true 
        // || // error.pincode == true
       
      ) {
        return true; // Enable the button
      } else {
        return false; // Disable the button
      }


    };
  console.log(disabledHandler());

    const handleDateChange = (date) => {
      //  const formattedDate = date.toISOString().substring(0, 10);
      //  // setFormatedDateState(formattedDate);
      //  console.log("formattedDate", formattedDate);
      setFieldValues((prevState) => ({
        ...prevState,
        dob: date,
      }));
    };
  
  
    return (
      <>
        <Layout
          sideNavState={sideNavState}
          setSideNavState={setSideNavState}
          hidenav={true}
        >
          <div className=" px-1">
            <span
              className="back-btn"
              onClick={() => naviate("/home/allPatientsPage")}
            >
              <img src={backBtnIcon} alt="" />
            </span>
          </div>
          <form onSubmit={formSubmit}>
            <div className="patient-general-info">
              <div className="d-flex flex-row justify-content-between ">
                <h5>General Information</h5>
              </div>
              <div className="d-flex row justify-content-start">
                <div className="d-flex flex-row">
                  {/* <div className="d-flex row  justify-content-start"> */}
                  <div style={{ width: "100%" }}>
                    
                    <div className="d-flex row">
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                      // style={{ width: "42.5%" }}
                      >
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="full_name"
                            placeholder="Enter Full Name"
                            onChange={handleFieldChange}
                            value={fieldValues.full_name}
                          />
                        </Form.Group>
                      </div>
                      <div className="d-flex flex-column date-pick col-lg-4 col-md-6 col-sm-12 ">
                        {/* <Form.Group className="mb-3"> */}
                        <Form.Label>
                          Date of Birth<span className="required-field">*</span>
                        </Form.Label>
                        {/* <DatePicker
                          name="dob"
                          selected={
                            familyMembersData.dob
                              ? new Date(familyMembersData.dob)
                              : null
                          }
                          onChange={(e) => handleDateChange(e)}
                          wrapperClassName="datepicker"
                          //  defaultValue={dayjs(familyMembersData.dob)}
                        /> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            wrapperClassName="datepicker"
                            value={fieldValues.dob}
                            onChange={handleDateChange}
                            placeholder="Date of Birth"
                            style={{ backgroundColor: "#fff" }}
                            className="customDatePicker"
                            required
                          />
                        </LocalizationProvider>
  
                        {/* </Form.Group> */}
                      </div>
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                      // style={{ width: "42.5%" }}
                      >
                        <Form.Group className="mb-3" co0ntrolId="formBasicEmail">
                          <Form.Label>
                            Gender<span className="required-field">*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={handleFieldChange}
                            aria-label="Default select example"
                            name="gender"
                            value={fieldValues.gender}
                          >
                            <option selected="selected">Select Gender</option>
                            {genderData?.map((gen, index) => (
                              <option
                                name="gender"
                                value={gen.gender}
                                key={index}
                              >
                                {gen.gender}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex row justify-content-start">
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                // style={{ width: "30%" }}
                >
                  <Form.Label>
                    Mobile Number<span className="required-field">*</span>
                  </Form.Label>
                  <InputGroup
                    className="mb-3 flex flex-row"
                    controlId="formBasicEmail"
                  >
                    <InputGroup.Text id="">91 </InputGroup.Text>
  
                    <Form.Control
                      placeholder="Mobile Number"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      type="number"
                      name="contact_number"
                      value={fieldValues.contact_number}
                      onChange={handleFieldChange}
                      className="no-spinners"
                    />
                  </InputGroup>
                  {error.contact_number && (
                    <p className="text-danger">{message}</p>
                  )}
                </div>
              
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Aadhar Card<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="aadhar"
                      placeholder="Aadhar Card"
                      onChange={handleFieldChange}
                      value={fieldValues.aadhar}
                      required
                    />
  
                    {error.aadhar && (
                      <p className="text-danger">{aadharmessage}</p>
                    )}
                  </Form.Group>
                </div>
                <div
                className="col-lg-4 col-md-6 col-sm-12"
              // style={{ width: "30%" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Address<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Address"
                    onChange={handleFieldChange}
                    value={fieldValues.address}
                  />
                </Form.Group>
              </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      State<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={handleFieldChange}
                      aria-label="Default select example"
                      name="state_id"
                      value={fieldValues.state_id}
                      required
                    >
                      <option selected="selected">Select State</option>
                      {stateDropDown?.length > 0 &&
                        stateDropDown?.map((state, index) => (
                          <option name="state_id" value={state.id} key={index}>
                            {state.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      District<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={handleFieldChange}
                      aria-label="Default select example"
                      name="district_id"
                      value={fieldValues.district_id}
                    >
                      <option selected="selected">Select Discrict</option>
                      {distDrop?.length > 0 &&
                        distDrop?.map((state, index) => (
                          <option value={state.id} key={index}>
                            {state.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      Place<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={handleFieldChange}
                      aria-label="Default select example"
                      name="place_id"
                      value={fieldValues.place_id}
                    >
                      <option selected="selected">Select Place</option>
                      {placeDrop?.length > 0 &&
                        placeDrop?.map((state, index) => (
                          <option value={state.id} key={index}>
                            {state.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Pincode
                      {/* <span className="required-field">*</span> */}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="pincode"
                      placeholder="Pin code"
                      onChange={handleFieldChange}
                      value={fieldValues.pincode}
                    />
                    {error.pincode && (
                      <p className="text-danger">{pincodeMessage}</p>
                    )}
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Village
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="village"
                      placeholder="Village"
                      onChange={handleFieldChange}
                      value={fieldValues.village}
                    />
                  </Form.Group>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                    Gram Panchayat / Ward No. 
                    {/* <span className="required-field">*</span> */}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="panchayat_ward"
                      placeholder="Gram Panchayat / Ward No."
                      onChange={handleFieldChange}
                      value={fieldValues.panchayat_ward}
                    />
                  </Form.Group>
                </div>
  
  
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                // style={{ width: "30%" }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Camp Reg No.
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="camp_patient_id"
                      placeholder="Camp registration number"
                      onChange={handleFieldChange}
                      value={fieldValues.camp_patient_id}
                    />
                  </Form.Group>
                </div>
  
              </div>
              
            </div>
  
            <div className="btn-card mt-3">
              <button
                type="button"
                className="btn-reset me-2"
                // disabled={hasValues}
                onClick={ResetForm}
              // style={{
              //   display: state.action === "Edit" ? "none" : undefined,
              // }}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn-save"
                disabled={disabledHandler()}
  
                onClick={() => setModalOnSave(true)}
              >
                Save
                {/* {state.action === "Edit" ? "Update" : "Save"} */}
              </button>
            </div>
          </form>
{/*   
          {
            <AddFamilymembersModal
              familyModal={familyModal}
              setFamilyModal={setFamilyModal}
              closeFamilyModal={closeFamilyModal}
              setFamilyMembersData={setFamilyMembersData}
              familyMembersData={familyMembersData}
              addictionData={addictionData}
              genderData={genderData}
              relationshipData={relationshipData}
              setFamilyMemberArray={setFamilyMemberArray}
              familyMemberArray={familyMemberArray}
              setMemberEditId={setMemberEditId}
              setMemberAction={setMemberAction}
              memberAction={memberAction}
              memberEditId={memberEditId}
              setUpdated={setUpdated}
              memberDeleteId={memberDeleteId}
              fieldValues={fieldValues}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
            />
          } */}
  
          {
            <InputFieldModal
              open={open}
              handleClose={handleClose}
              handleContentChange={handleContentChange}
              content={content}
            />
          }
          {
            <div className="webcam-container">
              <Modal
                open={videoOpen}
                onClose={videoHandleClose}
              // onClick={handleContentChange}
              // aria-labelledby="modal-modal-title"
              // aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  {/* <div className="text-end mr-2" onClick={videoHandleClose}>
                    <img alt="" src={doclose} style={{ cursor: "pointer" }}></img>
                  </div> */}
                  <div className="webcam-box">
                    <video ref={videoRef} autoPlay />
                    <div className="webcam-btn">
                      <CenterFocusWeakIcon
                        onClick={takePicture}
                        style={{ fontSize: "40px", cursor: "pointer" }}
                      />
                    </div>
                  </div>
  
                  {/* <button
                    className="btn btn-primary"
                    style={{ width: "650px" }}
                    onClick={takePicture}
                  >
                    Take Selfie
                  </button> */}
                </Box>
              </Modal>
            </div>
          }
          {
            <div className="webcam-container">
              <Modal
                open={modalOnSave}
                onClose={saveHandleClose}
              // onClick={handleContentChange}
              // aria-labelledby="modal-modal-title"
              // aria-describedby="modal-modal-description"
              >
                <Box sx={saveModalStyle}>
                  <div className="relative">
                    <img src={groupImg}></img>
                    <div style={{ position: "absolute", top: "20%" }}>
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Patient Name:
                      </p>
                      <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                        <div>
                          {fieldValues.full_name} 
                        </div>
                      </p>
                    </div>
                    <div style={{ position: "absolute", bottom: "10%" }}>
                      <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {patientId}
                      </p>
                    </div>
                  </div>
                  <img
                    alt=""
                    onClick={() => saveHandleClose()}
                    style={{ position: "absolute", top: "2%", right: "2%" }}
                    src={doclose}
                  ></img>
                </Box>
              </Modal>
            </div>
          }
        </Layout>
      </>
    );
}

export default QuickRegistration