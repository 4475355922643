import React, { useEffect, useState } from "react";
import firstAid from "../../Assets/icons/dashboardIcons/first-aid.svg";
import PathologistIcon from "../../Assets/icons/dashboardIcons/dashboard-pathologist.svg";
import PharmacistIcon from "../../Assets/icons/dashboardIcons/pharmacist-dashboard-icon.svg";
import maidsvgrepo from "../../Assets/icons/dashboardIcons/maids-svgrepo-com.svg";
import nurse from "../../Assets/icons/dashboardIcons/nurse-svgrepo-com.svg";
import wheelchair from "../../Assets/icons/dashboardIcons/wheelchair.svg";
import Layout from "../../Components/LayoutComp/Layout";
import DbCard from "../../Components/DashboardCards/dbCard";
import { PieChart } from "../../Components/PieChart/PieChart";
import { readService } from "../../Services/HttpService";
import { Form } from "react-bootstrap";
import UpcomingHealthCamps from "./components/UpComingHealthCamps";
import RecentAdmittedPatients from "./components/RecentAdmittedPatients";
import HealthCampDropDown from "./components/HealthCampDropDown";
import "./dashboard.scss";
import PrescriptionCategory from "./components/PrescriptionCategory";

const STATIC_PIE_CHART_DATA = [
  ["Prescribed for glasses", 769],
  ["Cataract Surgery", 371],
  ["Retina Surgery", 13],
  ["Cornea Transplant", 12],
  ["Occulo Plasty Surgery", 12],
  ["Pterygium Surgery", 7],
  ["Squint Surgery", 4],
  ["Glaucoma Surgery", 3],
];

const cardData = [
  {
    icon: wheelchair,
    bgImg: "patients.png",
    name: "Patients",
    count: 2825,
    label: "patients_count",
  },
  {
    icon: firstAid,
    bgImg: "doctors.png",
    name: "Doctors",
    count: 8,
    label: "doctors_count",
  },
  {
    icon: nurse,
    bgImg: "total-nurses.png",
    name: "Nurse",
    count: 10,
    label: "nurses_count",
  },
  {
    icon: maidsvgrepo,
    bgImg: "asha-worker.png",
    name: "Data operator",
    count: 8,
    label: "dataoperators_count",
  },
  {
    icon: PathologistIcon,
    bgImg: "pathologist-background.png",
    name: "Pathologist",
    count: 1,
    label: "pathologists_count",
  },
  {
    icon: PharmacistIcon,
    bgImg: "pharmacist-dashboard-background.png",
    name: "Pharmacist",
    count: 1,
    label: "pharmacists_count",
  },
];

const Dashboard = ({ setSideNavState, sideNavState }) => {
  const [countObject, setCountObject] = useState({});
  const [genderPercentage, setGenderPercentage] = useState([]);
  const [patientByAgeData, setPatientByAgeData] = useState([]);

  const [healthCampIdGender, setHealthCampIdGender] = useState("");
  const [healthCampIdAge, setHealthCampIdAge] = useState("");
  const [showDropdown, setShowDropDown] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [popUpPage, setPopUpPage] = useState(0);
  const [imsData, setImsData] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [offset, setOffset] = useState(5);
  const [upcomingHealthCamps, setUpcomingHealthCamps] = useState([]);
  const closeModalDropDown = () => {
    setShowDropDown(!showDropdown);
  };

  const [healthCampIdRecentPatients, setHealthCampIdRecentPatients] =
    useState("");

  const [showDropdown2, setShowDropDown2] = useState(false);
  const [totalCount2, setTotalCount2] = useState(0);
  const [popUpPage2, setPopUpPage2] = useState(0);
  const [imsData2, setImsData2] = useState([]);
  const [pageSize2, setPageSize2] = useState(5);
  const [offset2, setOffset2] = useState(5);
  const [recentAddedPatients, setRecentAddedPatients] = useState([]);

  const closeModalDropDown2 = () => {
    setShowDropDown2(!showDropdown2);
  };

  const [showDropdown3, setShowDropDown3] = useState(false);
  const [totalCount3, setTotalCount3] = useState(0);
  const [popUpPage3, setPopUpPage3] = useState(0);
  const [imsData3, setImsData3] = useState([]);
  const [pageSize3, setPageSize3] = useState(5);
  const [offset3, setOffset3] = useState(5);
  // const [recentAddedPatients, setRecentAddedPatients] = useState([]);

  const closeModalDropDown3 = () => {
    setShowDropDown3(!showDropdown3);
  };

  const handleSelect = (e) => {
    console.log(e.target.value);
  };

  const getAddedPatients = async () => {
    const result = await readService(
      `reports/patients?healthCampId=${healthCampIdRecentPatients}&limit=5&offset=0`,
      {}
    );

    if (result?.data) {
      setRecentAddedPatients(result?.data?.body);
    }
  };

  useEffect(() => {
    getAddedPatients();
  }, [healthCampIdRecentPatients]);

  useEffect(() => {
    getImsHospitals();
  }, []);

  const getImsHospitals = async () => {
    const params = {
      limit: 10,
      offset: 0,
    };
    const result = await readService("imshospitals", params);
    setImsData(result.data.body);
    setTotalCount(result.data.count);
  };

  const getUpcomingHealthCamps = async () => {
    const result = await readService(
      "reports/healthcamps?orderBy=DESC&limit=5&offset=0",
      {}
    );

    if (result?.data) {
      setUpcomingHealthCamps(result?.data?.body);
    }
  };

  useEffect(() => {
    getEntitiesCount();
    getUpcomingHealthCamps();
  }, []);

  useEffect(() => {
    getGenderPercentage();
  }, [healthCampIdGender]);

  const getEntitiesCount = async () => {
    const result = await readService("reports/entitiesCount", {});

    setCountObject(result?.data?.[0]);
  };

  const getGenderPercentage = async () => {
    const result = await readService(
      `reports/patientsByGender?healthCampId=${healthCampIdGender}`,
      {}
    );

    const dummy = await formatGenderData(result?.data?.[0]);

    setGenderPercentage(dummy);
  };

  const formatGenderData = (object) => {
    let dummyData = [];

    Object.keys(object).map((item) => {
      const array = item.split("_");
      const label = array?.[0].charAt(0).toUpperCase() + array?.[0].slice(1);
      let value = 0;
      if (object?.[item]) {
        value = object?.[item];
      }
      if (value) {
        dummyData = [...dummyData, [label, value]];
      }
    });

    return dummyData;
  };

  useEffect(() => {
    getPatientByAge();
  }, [healthCampIdAge]);

  const getPatientByAge = async () => {
    const result = await readService(
      `reports/patientsByAgeRange?healthCampId=${healthCampIdAge}`,
      {}
    );

    const dummy = await formatAgeData(result?.data?.[0]);

    setPatientByAgeData(dummy);
  };

  const formatAgeData = (object) => {
    let dummyData = [];

    Object.keys(object).map((item) => {
      let first = "";
      let last = "";
      const array = item.split("_");
      first = array[1];
      last = array[3];
      if (array.includes("below")) {
        first = "1";
        last = array[array.length - 1];
      }
      if (array.includes("above")) {
        first = array[array.length - 1];
        last = "1";
      }
      if (array.includes("unavailable")) {
        first = "Other";
        last = "";
      }

      // if (value) {
      //   dummyData = [...dummyData, [label, value]];
      // }
      let value = 0;
      if (object?.[item]) {
        value = object?.[item];
      }
      if (value) {
        dummyData = [...dummyData, [first + (last ? "-" : "") + last, value]];
        // dummyData = [...dummyData, [label, value]];
      }
    });

    return dummyData;
  };

  return (
    <>
      <div className="dashboard">
        <Layout setSideNavState={setSideNavState} sideNavState={sideNavState}>
          <div
            style={{
              overflow: "scroll",
              flexDirection: "row",
              display: "flex",
              gap: 10,
            }}
          >
            {cardData?.map((val) => {
              return (
                <div className="col-md-6 col-lg-3 col-12 mb-md-2 mb-2 mb-lg-0">
                  <DbCard
                    bgImg={val.bgImg}
                    icon={val.icon}
                    name={val.name}
                    count={countObject?.[val?.label]}
                    // count={val.count}
                  />
                </div>
              );
            })}
          </div>
          {/* <div className="row">
            <div className="col-md-12 col-lg-6">
              <PrescriptionCategory />
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="d-flex flex-column graph-border px-2 py-3 dashboard-table-style">
                <div className="d-flex flex-row justify-content-between ">
                  <div className="">
                    <p style={{ fontSize: "21px", fontWeight: "bold" }}>
                      Patients by prescription category
                    </p>
                  </div>
                </div>
                <PieChart data={STATIC_PIE_CHART_DATA} />
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <RecentAdmittedPatients
                data={recentAddedPatients}
                imsData2={imsData2}
                setShowDropDown2={setShowDropDown2}
                healthCampIdRecentPatients={healthCampIdRecentPatients}
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="d-flex flex-column graph-border px-2 py-3 dashboard-table-style">
                <div className="d-flex flex-row justify-content-between ">
                  <div className="">
                    <p style={{ fontSize: "21px", fontWeight: "bold" }}>
                      Patients Visited By Age Group
                    </p>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12">
                    <Form.Group controlId="formBasicEmail">
                      <div onClick={() => setShowDropDown3(true)}>
                        <Form.Select
                          aria-label="select"
                          onChange={(e) => console.log("jkl")}
                          name="id"
                          value={healthCampIdAge}
                          // disabled={true}
                          style={{ backgroundColor: "#fff" }}
                        >
                          <option>Select Health Camp</option>
                          {imsData3.length > 0 &&
                            imsData3?.map((item) => (
                              <option
                                style={{ visibility: "hidden" }}
                                value={item.id}
                                key={item.id}
                              >
                                {item.name}
                              </option>
                            ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <PieChart data={patientByAgeData} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-6">
              <UpcomingHealthCamps data={upcomingHealthCamps} />
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="d-flex flex-column graph-border px-2 py-3">
                <div className="d-flex flex-row justify-content-between ">
                  <div className="">
                    <p style={{ fontSize: "21px", fontWeight: "bold" }}>
                      Patient Visit By Gender
                    </p>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12">
                    <Form.Group controlId="formBasicEmail">
                      <div onClick={() => setShowDropDown(true)}>
                        <Form.Select
                          aria-label="select"
                          onChange={(e) => console.log("jkl")}
                          name="id"
                          value={healthCampIdGender}
                          // disabled={true}
                          style={{ backgroundColor: "#fff", width: "100%" }}
                        >
                          <option>Select Health Camp</option>
                          {imsData?.length > 0 &&
                            imsData?.map((item) => (
                              <option
                                style={{ visibility: "hidden" }}
                                value={item.id}
                                key={item.id}
                              >
                                {item.name}
                              </option>
                            ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {showDropdown && (
                  <HealthCampDropDown
                    isHidden={showDropdown}
                    onClose={closeModalDropDown}
                    data={imsData}
                    setSelectedId={setHealthCampIdGender}
                    totalCount={totalCount}
                    setImsData={setImsData}
                    page={popUpPage}
                    pageSize={pageSize}
                    setPage={setPopUpPage}
                    setPageSize={setPageSize}
                    offset={offset}
                    setOffset={setOffset}
                    setTotalCount={setTotalCount}
                  />
                )}
                {showDropdown2 && (
                  <HealthCampDropDown
                    isHidden={showDropdown2}
                    onClose={closeModalDropDown2}
                    data={imsData2}
                    setSelectedId={setHealthCampIdRecentPatients}
                    totalCount={totalCount2}
                    setImsData={setImsData2}
                    page={popUpPage2}
                    pageSize={pageSize2}
                    setPage={setPopUpPage2}
                    setPageSize={setPageSize2}
                    offset={offset2}
                    setOffset={setOffset2}
                    setTotalCount={setTotalCount2}
                  />
                )}
                {showDropdown3 && (
                  <HealthCampDropDown
                    isHidden={showDropdown3}
                    onClose={closeModalDropDown3}
                    data={imsData3}
                    setSelectedId={setHealthCampIdAge}
                    totalCount={totalCount3}
                    setImsData={setImsData3}
                    page={popUpPage3}
                    pageSize={pageSize3}
                    setPage={setPopUpPage3}
                    setPageSize={setPageSize3}
                    offset={offset3}
                    setOffset={setOffset3}
                    setTotalCount={setTotalCount3}
                  />
                )}
                <PieChart data={genderPercentage} />
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </>
  );
};

export default Dashboard;
