import React, { useState, useEffect, useRef } from "react";
import searchIcon from "../../Assets/icons/search_icon.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import { TablePagination } from "@mui/material";
// import {
//     createService,
//     readService,
//     updateService,
// } from "../../Services/HttpService";
import Spinner from "../Spinner/Spinner";
import { useLocation } from "react-router-dom";

const HealthCampView = ({ sideNavState, ...props }) => {
  const location = useLocation();
  const { data, columns } = location.state;

  console.log(" ==========> data in health camp", data);
  const [page, setPage] = React.useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  // const [funcCall, setFuncCall] = useState("")
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [doctorList, setDoctorList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [offset, setOffset] = useState(0);
  // const inputRef = useRef(null);

  const handleSearchInput = () => {};
  const handleSearchClear = () => {};

  const searchHandler = () => {};

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = pageSize * pageIndex;
    // getAllDoctorsDetails(offset, searchText);
    setOffset(offset);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="doct-comp">
      <div
        id="healthCamp_container"
        style={{
          width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
          marginLeft: sideNavState ? "calc(100% - 80%)" : "0%",
          transition: "0.5s",
          position: "absolute",
        }}
      >
        <div className="d-flex flex-column  flex-md-row flex-lg-row flex-lg-row justify-content-between align-items-center align-items-md-end mt-4">
          <div
            className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4"
            style={{ marginLeft: "20px" }}
          >
            <label className="label-input">
              <input
                type="text"
                className="form-control search-input"
                placeholder="Search by Doctor's name"
                onChange={handleSearchInput}
                value={searchText}
              />
              {searchText && (
                <button
                  className="search-btn-close"
                  onClick={handleSearchClear}
                >
                  <img src={searchCloseIcon} alt="" width={"24px"} />
                </button>
              )}

              <button className="search-btn" onClick={searchHandler}>
                <img src={searchIcon} alt="" />
                <span>Search</span>
              </button>
            </label>
          </div>
        </div>
        <div
          className="table-style"
          // style={{ padding: "10px", margin: "10px" }}
        >
          <div
            className="doctor-table-container overflow-auto"
            style={{ margin: "2%" }}
          >
            <table className="doctor-table">
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th key={column.field}>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    {columns.map((column) => (
                      <td key={column.field}>{row[column.field]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {doctorList?.data?.doctors?.length === 0 && (
              <div className="warning-text-card">No Records Found!</div>
            )}
            <div className="flex justify-content-end table-page">
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRecords}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
      {showSpinner && <Spinner />}
    </div>
  );
};

export default HealthCampView;
