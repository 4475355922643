import React from "react";
import { Modal } from "react-bootstrap";
import { DatePicker } from "@mui/x-date-pickers";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";

const FilterModal = (props) => {
  const { visible, onClose, onSubmit, children, setStateDropDown, setFilterDistrictDrop, setPlaceDrop  } = props;

  const closeFilterModal = () => {
    onClose();
    // setStateDropDown([]);
    // setFilterDistrictDrop([]);
    // setPlaceDrop([]);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Modal
      dialogClassName="filter-modal"
      backdrop="static"
      show={visible}
      onHide={onClose}
      centered
    >
      <Modal.Header className="filter-modal-header">Filter</Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <div className="btn-card">
          <button className="btn-cancel" onClick={closeFilterModal}>
            Cancel
          </button>
          <button className="btn-apply" onClick={handleSubmit}>
            Apply
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
