import React, { useState } from "react";
import TopBar from "./TopBar";
import { Autocomplete, TextField } from "@mui/material";
import PrimaryButton from "../../../../../../PrimaryButton/PrimaryButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import { ReactComponent as Dustbin } from "../../../../../../../Assets/icons/dustbin.svg";
import {
  getDoctorPharmacyDose,
  getDoctorPharmacyFrequency,
  getDoctorPharmacyWhen,
} from "../../../../../../../Services/Metadata/Metadata";
import {
  readService,
  updateService,
} from "../../../../../../../Services/HttpService";
import { errorToast, successToast } from "../../../../../../../Utils/toasters";
import { useSnackbar } from "notistack";
import Check from "../../../../../../../Assets/icons/check.svg";
import Cross from "../../../../../../../Assets/icons/cross.svg";

import "./Pharmacy.scss";

const INITIAL_VALUES = {
  medicine: null,
  item_id: null,
  dosage: null,
  medicine_time: null,
  frequency: null,
  duration: "",
  item_qty: "",
  notes: "",
  medicineName: "",
  item_name: "",
  untracked_item_name: null,
};

function isEmpty(obj = {}) {
  return !Object.values(obj).some(
    (value) => !["", null, undefined].includes(value)
  );
}

const Pharmacy = (props) => {
  const {
    open,
    onTopBarClick,
    mainObject,
    healthCampId,
    visitId,
    id,
    setActiveTab,
    type = "View",
  } = props;

  const [search, setSearch] = useState("");
  const [age, setAge] = React.useState("");
  const [mainData, setMainData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [doseDrop, setDoseDrop] = useState([]);
  const [frequencyDrop, setFrequencyDrop] = useState([]);
  const [whenDrop, setWhenDrop] = useState([]);
  const [medicineOptions, setMedicineOptions] = useState([]);

  useEffect(() => {
    getMedicines();
  }, [search]);

  useEffect(() => {
    setMainData(mainObject?.pharmacy);
  }, [mainObject]);

  useEffect(() => {
    if ((mainData?.length === 0 || !mainData) && type !== "View") {
      setMainData([INITIAL_VALUES]);
    } else if (!isEmpty(mainData?.[mainData?.length - 1]) && type !== "View") {
      setMainData((v = []) => [...v, INITIAL_VALUES]);
    }
  }, [mainData]);

  useEffect(() => {
    getDoses();
    getFrequency();
    getWhen();
  }, []);

  const getMedicines = async () => {
    const result = await readService(
      `pharmacy/${healthCampId}?limit=5&item_name=${search}`
    );
    const filterData = result?.data?.body.map((item) => {
      return { label: item?.item_name, id: item?.id };
    });

    setMedicineOptions(filterData);
  };

  const getDoses = async () => {
    const result = await getDoctorPharmacyDose();
    setDoseDrop(result?.data);
  };

  const getFrequency = async () => {
    const result = await getDoctorPharmacyFrequency();
    setFrequencyDrop(result?.data);
  };

  const getWhen = async () => {
    const result = await getDoctorPharmacyWhen();
    setWhenDrop(result?.data);
  };

  const handleChangeAutocomplete = (event, value, index) => {
    const newObject = mainData[index];
    const newResult = {
      ...newObject,
      item_id: value?.id,
      medicineName: value?.label,
    };
    const result = mainData?.map((item, i) => {
      if (index === i) {
        return newResult;
      }
      return item;
    });
    setMainData(result);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const selectedTiming = e.target.value;
    console.log("prajwal-testing", selectedTiming);

    const newObject = mainData[index];
    const newResult = { ...newObject, [name]: value };
    const result = mainData?.map((item, i) => {
      if (index === i) {
        return newResult;
      }
      return item;
    });
    setMainData(result);
  };

  const handleDelete = (index, item) => {
    if (!item?.id && !isEmpty(item)) {
      const newArray = mainData?.map((item, i) => {
        if (index !== i) {
          return item;
        }
      });
      const final = newArray.filter((s) => s);
      setMainData(final);
    }
  };

  const handleInputFiledChange = (e, i) => {
    const { name, value } = e.target;

    const result = mainData?.map((item, index) => {
      if (index === i) {
        return { ...item, [name]: value };
      } else {
        return item;
      }
    });
    setMainData(result);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleSubmit = async () => {
    const finalResult = mainData?.map((item, i) => {
      if (i === mainData.length - 1) {
        return null;
      } else {
        const object = {
          dosage: item.dosage ? item.dosage : null,
          medicine_time: item.medicine_time ? item.medicine_time : null,
          duration: item.duration ? item.duration : null,
          frequency: item.frequency ? item.frequency : null,
          item_qty: item.item_qty ? parseInt(item.item_qty) : 0,
          notes: item.notes ? item.notes : null,
          item_id: parseInt(item.item_id) ? parseInt(item.item_id) : null,
          is_distributed: false,
          untracked_item_name: null,
        };
        if (item?.id) {
          return {
            ...object,
            id: item.id,
          };
        } else {
          return object;
        }
      }
    });
    const filteredData = finalResult?.filter((s) => s);

    try {
      const result = await updateService(
        `patient/diagnosis/${id}/${visitId}`,
        {},
        {
          pharmacy: JSON.stringify(filteredData),
        }
      );
      successToast(enqueueSnackbar);
      setActiveTab(0);
    } catch (error) {
      errorToast(enqueueSnackbar);
    }
  };

  return (
    <div className="tabs-main-container" id="pharmacy-tab">
      <TopBar open={open} content="Pharmacy" onClick={onTopBarClick} />
      {open ? (
        <div className="lab-test py-4">
          {mainData && mainData?.length !== 0 ? (
            <div
              id="pharmacy-diagnosis"
              className="box overflow-auto pharmacy-table-overwrite margin-bottom"
            >
              <table
                className="dashboard-table border-collapse table-border"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Medicine</th>
                    <th>Dose</th>
                    <th>Timing</th>
                    <th>Frequency</th>
                    <th>Duration</th>
                    <th>No. of Units</th>
                    <th>Notes</th>
                    <th>{type === "View" ? "Distributed" : "Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {mainData?.map((item, i) => {
                      return (
                        <tr style={{ cursor: "pointer" }} className="tr-class">
                          <td
                            className="border-none"
                            // style={{ border: "1px solid black" }}
                          >
                            {type === "View" ? (
                              item?.item_name || "-"
                            ) : (
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                onChange={(e, value) =>
                                  handleChangeAutocomplete(e, value, i)
                                }
                                value={item?.item_name}
                                options={medicineOptions}
                                sx={{
                                  width: 200,
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          border: "none",
                                        },
                                        "&:hover fieldset": {
                                          border: "none",
                                        },
                                        "&.Mui-focused fieldset": {
                                          border: "none",
                                        },
                                      },
                                    }}
                                    placeholder="Medicine"
                                    style={{ border: "none" }}
                                    name="medicine"
                                    onChange={(e) => handleSearch(e)}
                                    // value={item?.medicine}
                                  />
                                )}
                              />
                            )}
                          </td>
                          <td
                            className="border-none border-color"
                            style={{ border: "1px solid black" }}
                          >
                            {/* <FormControl
                            sx={{
                              width: "100%",
                              height: "100%",
                            }}
                          > */}
                            {type === "View" ? (
                              item?.dosage || "-"
                            ) : (
                              <Select
                                name="dosage"
                                onChange={(e) => handleChange(e, i)}
                                displayEmpty
                                value={item?.dosage}
                                variant="standard"
                                disableUnderline
                                inputProps={{ "aria-label": "Without label" }}
                                sx={{
                                  // height: "100%",
                                  backgroundColor: "#fff",
                                  height: "100%",
                                  "& .MuiSelect-root": {
                                    "&:focus": {
                                      backgroundColor: "red",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value={null}>
                                  <em>Select dose</em>
                                </MenuItem>
                                {doseDrop?.map((item, i) => {
                                  return (
                                    <MenuItem value={item.dosage}>
                                      {item.dosage}
                                    </MenuItem>
                                  );
                                })}
                                {/* <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem> */}
                              </Select>
                            )}

                            {/* </FormControl> */}
                          </td>
                          <td className="border-none">
                            {type === "View" ? (
                              item?.medicine_time || "-"
                            ) : (
                              <Select
                                name="medicine_time"
                                onChange={(e) => handleChange(e, i)}
                                displayEmpty
                                variant="standard"
                                disableUnderline
                                value={item?.medicine_time}
                                inputProps={{ "aria-label": "Without label" }}
                                sx={{
                                  backgroundColor: "#fff",
                                  height: "100%",
                                  "& .MuiSelect-root": {
                                    "&:focus": {
                                      backgroundColor: "red",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value={null}>
                                  <em>Select Time</em>
                                </MenuItem>
                                {whenDrop?.map((item, i) => (
                                  <MenuItem key={i} value={item.medicine_time}>
                                    {item.medicine_time}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </td>

                          <td className="border-none">
                            {/* <FormControl
                            sx={{
                              width: "100%",
                              height: "100%",
                            }}
                          > */}
                            {type === "View" ? (
                              item.frequency || "-"
                            ) : (
                              <Select
                                value={item.frequency}
                                name="frequency"
                                onChange={(e) => handleChange(e, i)}
                                displayEmpty
                                variant="standard"
                                disableUnderline
                                inputProps={{ "aria-label": "Without label" }}
                                sx={{
                                  // height: "100%",
                                  backgroundColor: "#fff",
                                  height: "100%",
                                  "& .MuiSelect-root": {
                                    "&:focus": {
                                      backgroundColor: "red",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value={null}>
                                  <em>Select frequency</em>
                                </MenuItem>
                                {frequencyDrop?.map((item, i) => {
                                  return (
                                    <MenuItem value={item.frequency}>
                                      {item.frequency}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            )}

                            {/* </FormControl> */}
                          </td>
                          <td className="border-none">
                            {type === "View" ? (
                              age || "-"
                            ) : (
                              <Select
                                value={age}
                                name="duration"
                                onChange={(e) => handleChange(e, i)}
                                displayEmpty
                                variant="standard"
                                disableUnderline
                                inputProps={{ "aria-label": "Without label" }}
                                sx={{
                                  // height: "100%",
                                  backgroundColor: "#fff",
                                  height: "100%",
                                  "& .MuiSelect-root": {
                                    "&:focus": {
                                      backgroundColor: "red",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>

                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                              </Select>
                            )}

                            {/* </FormControl> */}
                          </td>
                          <td className="border-none">
                            {type === "View" ? (
                              item.item_qty || "-"
                            ) : (
                              <TextField
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      border: "none",
                                    },
                                    "&:hover fieldset": {
                                      border: "none",
                                    },
                                    "&.Mui-focused fieldset": {
                                      border: "none",
                                    },
                                  },
                                  width: "100%",
                                }}
                                placeholder="Enter Units"
                                type="number"
                                style={{ border: "none" }}
                                name="item_qty"
                                value={item.item_qty}
                                onChange={(e) => handleInputFiledChange(e, i)}
                              />
                            )}
                          </td>
                          <td className="border-none">
                            {type === "View" ? (
                              item.notes || "-"
                            ) : (
                              <TextField
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      border: "none",
                                    },
                                    "&:hover fieldset": {
                                      border: "none",
                                    },
                                    "&.Mui-focused fieldset": {
                                      border: "none",
                                    },
                                  },
                                  width: "100%",
                                }}
                                placeholder="Enter Notes"
                                style={{ border: "none" }}
                                name="notes"
                                value={item.notes}
                                onChange={(e) => handleInputFiledChange(e, i)}
                              />
                            )}
                          </td>
                          <td>
                            {type === "View" ? (
                              item?.is_distributed ? (
                                <img src={Check} alt="logo" />
                              ) : (
                                <img src={Cross} alt="logo" />
                              )
                            ) : (
                              <Dustbin
                                color={!item?.id ? "#e22020" : null}
                                onClick={() => handleDelete(i, item)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                </tbody>
              </table>
            </div>
          ) : (
            <div> No record found</div>
          )}

          {type !== "View" ? (
            <div className="submit-button-bottom top-spacing">
              <PrimaryButton name="Save" onClick={handleSubmit} />
            </div>
          ) : null}
        </div>
      ) : null}
      {/* <PharmacyMedicineModal
        showModal={showModal}
        setShowModal={setShowModal}
      /> */}
    </div>
  );
};

export default Pharmacy;
