import React from "react";
import "./nforum.scss";
import shareIcon from "../../../../../../../../Assets/Images/shareIcon.svg";
import userIcon from "../../../../../../../../Assets/Images/forum-user-img.png";
const NForum = () => {
  return (
    <div className="forum">
      <div className="d-flex align-items-center  justify-content-between user-profile-container">
        <p className="forum-text">Forum</p>
        <div className="d-flex justify-content-between align-items-center  ">
          <div className="dot me-2"></div>
          <img src={userIcon} />
          <div className="d-flex flex-column ms-3 profile-class">
            <p>Doctor</p>
            <p>Dr.Manjunath</p>
          </div>
        </div>
      </div>
      <div className="message-container ">
        <div className="px-3 d-flex align-items-center message-box justify-content-between ">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ flexGrow: 1 }}
          >
            <img src={userIcon} />
            <div class="line"></div>
            <input className="input" placeholder="Type your message here" />
          </div>

          <div className="d-flex justify-content-between align-items-center  ">
            <div className="d-flex flex-column ms-3 ">
              <img src={shareIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NForum;
