import React from "react";
import "./SearchInput.css";
import searchIcon from "../../Assets/icons/search_icon.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";

const SearchInput = (props) => {
  const { onChange, searchText, onSearchClear, onSearchClick } = props;

  return (
    <div
      className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4"
      style={{ width: 300 }}
    >
      <label className="label-input">
        <input
          type="text"
          className="form-control search-input"
          placeholder="Search by name"
          onChange={onChange}
          value={searchText}
        />
        {searchText && (
          <button className="search-btn-close" onClick={onSearchClear}>
            <img src={searchCloseIcon} alt="" width={"24px"} />
          </button>
        )}

        <button className="search-btn" onClick={onSearchClick}>
          <img src={searchIcon} alt="" />
          <span>Search</span>
        </button>
      </label>
    </div>
  );
};

export default SearchInput;
