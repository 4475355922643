import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

export const data2 = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7],
];

export const options = {
  title: "",
};

export function PieChart(props) {
  const { data } = props;

  const [formattedData, setFormattedData] = React.useState([]);

  const [showChart, setShowChart] = useState(true);

  useEffect(() => {
    console.log(" ==========> data", data);
    if (data) {
      const dummy = [["Task", "Hours per Day"], ...data];
      setFormattedData(dummy);
    }
  }, [data]);

  console.log(" ==========> formattedData", formattedData);

  if (formattedData?.length) {
    return (
      <Chart
        chartType="PieChart"
        data={formattedData}
        options={options}
        width={"100%"}
        height={"370px"}
      />
    );
  }
}
