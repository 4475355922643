import React, { useEffect, useRef, useState } from "react";
import backBtnIcon from "../../Assets/icons/back.svg";
import close_icon from "../../Assets/icons/close-rounded.svg";
import StatusIcon from "../../Assets/icons/Status.svg";
import view from "../../Assets/icons/view.svg";
import edit from "../../Assets/icons/edit.svg";
import coavatar from "../../Assets/Images/coavatar.png";
import "./copathologist.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import searchIcon from "../../Assets/icons/search_icon.svg";
import { Button, Form, InputGroup } from "react-bootstrap";
import filterIcon from "../../Assets/icons/filter_icon.svg";
import addIcon from "../../Assets/icons/addIcon.svg";
import { TablePagination } from "@mui/material";
import Layout from "../LayoutComp/Layout";
import FilterModal from "../Modals/FilterModal/FilterModal";
import CoPathologistFilterModal from "../CoPathologistFilterModal/CoPathologistFilterModal";
import { createService, readService } from "../../Services/HttpService";
import {
  getGenders,
  getBooldGroup,
  getQualifications,
} from "../../Services/Metadata/Metadata";
import { updatePathologist } from "../../Services/Pathologist";
import moment from "moment";
import { errorToast } from "../../Utils/toasters";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import PopupDropdwon from "../CommonPopupDropdown/PopupDropdown";
import QualificationSelect from "../RenderSelect/QualificationSelect";
import { ContactValidator, aadharNumber } from "../../Utils/Validators";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { ConvertYMD } from "../../Utils/DateUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const initialState = {
  name: "",
  contact_number: "",
  state_id: "",
  place_id: "",
  district_id: "",
  blood_group: "",
  dob: moment(Date.now()).format("YYYY_MM_DD"), //YYYY-MM-DD
  gender: "",
  doc_type: "",
  doc_number: "",
  qualification: "",
  username: "",
  password: "",
  // works_at: "100002",
};

const CoPathologist = ({ sideNavState, setSideNavState }) => {
  // const { works_at } = useSelector((s) => s.user.userProfile);
  const { works_at } = useSelector((s) => s.user.userProfile);
  const userType = useSelector((s) => s.user.userProfile.userGroup);
  const isMchAdmin = userType === "mch-admins";

  const [fieldValue, setFieldValue] = useState(initialState);
  const [placeDrop, setPlaceDrop] = useState([]);
  const [bloodDrop, setBloodDrop] = useState([]);
  const [genderDrop, setGenderDrop] = useState([]);
  const [action, setAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const [allData, setAllData] = useState([]);
  const [count, setCount] = useState(0);
  const [showDropdown, setShowDropDown] = useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [page, setPage] = React.useState(0);
  const [stateDropDown, setStateDropDown] = useState([]);
  const [distDrop, setDistDrop] = useState([]);
  const [pathologistId, setPathologistId] = useState(null);
  const [filterDistrictDrop, setFilterDistrictDrop] = useState([]);
  const [filterPlaceDrop, setFilterPlaceDrop] = useState([]);
  const [imsData, setImsData] = useState([]);
  const [popUpPage, setPopUpPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [offset, setOffset] = useState(5);
  const [selectedId, setSelectedId] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [qualificationDD, setQualificationDD] = useState([]);

  const [filterState, setFilterState] = useState("");
  const [filterDistrict, setFilterDistrict] = useState("");
  const [filterPlace, setFilterPlace] = useState("");
  const [passMessage, setPassMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [aadharmessage, setAadharMessage] = useState(null);

  const [error, setError] = useState({
    contact_number: false,
    password: false,
    aadhar: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  const getAllPathologists = async (limit, offset, updateCount) => {
    const state1 = stateDropDown.find((s) => s.id === filterState)?.id || "";
    const district1 =
      filterDistrictDrop.find((s) => s.id === filterDistrict)?.id || "";
    const place1 = filterPlaceDrop.find((s) => s.id === filterPlace)?.id || "";

    const result = await readService(
      `pathologists?state_id=${state1}&limit=${limit}&offset=${offset}&district_id=${district1}&place_id=${place1}&name=${searchText}`
    );

    setAllData(result?.data?.body);
    if (updateCount) {
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    getImsHospitals();
    getQualification();
  }, []);

  const getQualification = async () => {
    const special = await getQualifications();
    setQualificationDD(special.data);
  };

  const getImsHospitals = async () => {
    const params = {
      limit: 10,
      offset: 0,
    };
    const result = await readService("imshospitals", params);
    console.log("resulttt--->", result);
    setImsData(result.data.body);
    setTotalCount(result.data.count);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // formData.append(`works_at`, works_at);
    console.log(" ==========> selectedId", selectedId);
    if (isMchAdmin) {
      formData.append(`works_at`, works_at);
    } else {
      formData.append(`works_at`, selectedId);
    }

    const newArray = Object.keys(fieldValue);
    newArray.map((item, i) => {
      if (item == "dob") {
        formData.append(`${item}`, ConvertYMD(fieldValue[item]));
      } else {
        formData.append(`${item}`, fieldValue[item]);
      }
    });

    if (action == "Add") {
      const response = await createService("pathologists", formData, {});

      if (response.status < 200 || response.status > 300) {
        errorToast(enqueueSnackbar, response.data);
      } else {
        setAction(null);
        getAllPathologists(5, 0, 1);
        setPage(0);
      }
    }

    if (action == "Edit" && pathologistId !== null) {
      const response = await updatePathologist(pathologistId, formData, {});

      if (
        response.status < 200 ||
        response.status > 300
        // response.includes("Error")
      ) {
        errorToast(enqueueSnackbar, response.data);
      } else {
        setAction(null);
        getAllPathologists(5, 0, 1);
        setPage(0);
      }
    }
  };

  useEffect(() => {
    getState();
    getBloodGroupData();
    getGenderData();
    getAllPathologists(rowsPerPage, 0, 1);
  }, []);

  const getGenderData = async () => {
    const result = await getGenders();
    setGenderDrop(result.data);
  };

  const getState = async () => {
    const states = await readService("metadata/states");
    setStateDropDown(states.data);
  };

  const getBloodGroupData = async () => {
    const bloodGroups = await getBooldGroup();
    setBloodDrop(bloodGroups?.data);
  };

  useEffect(() => {
    if (fieldValue.district_id) getPlaceData();
  }, [fieldValue.district_id]);

  const getPlaceData = async () => {
    const place = await readService(
      `metadata/places/${fieldValue.district_id}`
    );
    setPlaceDrop(place.data);
  };

  useEffect(() => {
    if (fieldValue.state_id) getDistrict();
  }, [fieldValue.state_id]);

  const getDistrict = async () => {
    const district = await readService(
      `metadata/districts/${fieldValue?.state_id}`
    );
    setDistDrop(district.data);
  };

  useEffect(() => {
    if (filterState) getFilterDistrict();
  }, [filterState]);
  const getFilterDistrict = async () => {
    const district = await readService(`metadata/districts/${filterState}`);
    setFilterDistrictDrop(district.data);
  };

  useEffect(() => {
    if (filterDistrict) getFilterPlaces();
  }, [filterDistrict]);
  const getFilterPlaces = async () => {
    const place = await readService(`metadata/places/${filterDistrict}`);
    setFilterPlaceDrop(place.data);
  };

  const inputRef = useRef(null);

  const backButtonHandler = () => {
    setAction(null);
  };
  const closeModalDropDown = () => {
    setShowDropDown(!showDropdown);
  };
  const handleReset = () => {
    setFieldValue(initialState);
  };
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  const handleSearchClear = () => {
    setSearchText("");
  };
  const searchHandler = async () => {
    getAllPathologists(rowsPerPage, 0, 0);
  };

  const handleAction = (actionVal, path_id) => {
    setPathologistId(path_id);

    const singleData = allData.find((s) => s.id === path_id);

    console.log("fieldValue.dob", singleData);
    let dummy = {};
    Object.keys(fieldValue).map((item) => {
      if (item !== "dob") {
        dummy = { ...dummy, [item]: singleData[item] };
      } else {
        dummy = { ...dummy, dob: new Date(singleData[item]) };
      }
    });

    setFieldValue(dummy);
    setAction(actionVal);
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPage * pageIndex;
    getAllPathologists(rowsPerPage, offSet, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getAllPathologists(event.target.value, 0, 0);
  };

  const handleFilterClose = () => {
    setShowModal(false);
  };

  const setFieldValueHandler = (e) => {
    const { name, value } = e.target;
    if (name === "contact_number") {
      ContactValidator(setError, setMessage, value);
    }
    if (name == "doc_number") {
      aadharNumber(setError, setAadharMessage, value);
    }
    setFieldValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (fieldValue.doc_number == "") {
      setError((prev) => ({
        ...prev,
        aadhar: false,
      }));
    }
  }, [fieldValue.doc_number]);

  const handleDisablebtn = () => {
    if (
      error.contact_number == true ||
      fieldValue.contact_number == "" ||
      error.aadhar == true ||
      fieldValue.name == "" ||
      !fieldValue.gender ||
      (!isMchAdmin ? !selectedId : false) ||
      !fieldValue.username ||
      (action === "Add" && !fieldValue.password)
    ) {
      return true;
    } else {
      return false;
    }
  };
  console.log("field");
  const handleDateChange = (date) => {
    setFieldValue((prevState) => ({
      ...prevState,
      dob: date,
    }));
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Layout
      id="doctorId"
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
    >
      {action == "Add" || action == "Edit" || action == "View" ? (
        <form onSubmit={handleSubmit}>
          <>
            <div className="add-doctor-form">
              {action != "View" && (
                <div className=" px-1">
                  <span
                    className="back-btn"
                    onClick={() => backButtonHandler()}
                  >
                    <img src={backBtnIcon} alt="" />

                    <span>Back</span>
                  </span>
                </div>
              )}

              <div className="form-style px-3 py-3">
                <div className="d-flex flex-row justify-content-between ">
                  <h5>
                    {action == "Add"
                      ? "Add"
                      : action == "Edit"
                        ? "Edit"
                        : "View"}{" "}
                    Pathologist
                  </h5>
                  {action === "View" && (
                    <div>
                      <img
                        src={close_icon}
                        alt=""
                        onClick={() => setAction(null)}
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex row justify-content-start">
                  {/* <div className="d-flex flex-row"> */}
                  {/* <div className="col-lg-4 col-sm-12 col-md-6 mr-70">
                      <div
                        className="img-upload d-flex flex-column align-items-center justify-content-center"
                        onClick={() => inputRef.current.click()}
                      >
                        <div style={{ width: "250px" }}>
                          <img style={{ width: "100%" }} src={coavatar} />
                        </div>
                      </div>
                    </div> */}
                  {/* <div className="d-flex row"> */}
                  <div className="col-lg-4 col-sm-12 col-md-6">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        Name<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Control
                        name="name"
                        value={fieldValue.name}
                        placeholder="Enter Your name"
                        onChange={setFieldValueHandler}
                        disabled={action === "View"}
                        // className="background-color"
                        style={{ backgroundColor: "#fff" }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Contact Number
                        <span className="required-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="contact_number"
                        placeholder="Contact Number"
                        onChange={setFieldValueHandler}
                        value={fieldValue.contact_number}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                        className="no-spinners"
                      />
                      {error.contact_number && (
                        <p className="text-danger">{message}</p>
                      )}
                    </Form.Group>
                  </div>
                  <div className=" col-lg-4 col-sm-12 col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        onChange={setFieldValueHandler}
                        aria-label="Default select example"
                        name="state_id"
                        value={fieldValue.state_id}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      >
                        <option>Select State</option>
                        {stateDropDown?.length > 0 &&
                          stateDropDown?.map((state, index) => (
                            <option
                              name="state_id"
                              value={state.id}
                              key={index}
                            >
                              {state.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>District</Form.Label>
                      <Form.Select
                        aria-label="select"
                        onChange={setFieldValueHandler}
                        name="district_id"
                        value={fieldValue.district_id}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      >
                        <option>Select District</option>
                        {distDrop?.length > 0 &&
                          distDrop?.map((district, index) => (
                            <option
                              name="state_id"
                              value={district.id}
                              key={index}
                            >
                              {district.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Place</Form.Label>
                      <Form.Select
                        aria-label="select"
                        onChange={setFieldValueHandler}
                        name="place_id"
                        value={fieldValue.place_id}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      >
                        <option>Select Place</option>
                        {placeDrop?.length > 0 &&
                          placeDrop?.map((place, index) => (
                            <option value={place.id} key={index}>
                              {place.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Blood Group</Form.Label>
                      <Form.Select
                        aria-label="select"
                        onChange={setFieldValueHandler}
                        name="blood_group"
                        value={fieldValue.blood_group}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      >
                        <option>Select Blood Group</option>
                        {bloodDrop?.length > 0 &&
                          bloodDrop?.map((blood, index) => (
                            <option value={blood.group} key={index}>
                              {blood.group}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
                <div className="d-flex row justify-content-start">
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 date-input-add-nurse"
                  //   style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicName">
                      {console.log(" ==========> fieldValue", fieldValue)}
                      <Form.Label>Date Of Birth</Form.Label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="customDatePicker"
                          wrapperClassName="datepicker"
                          value={fieldValue.dob}
                          onChange={handleDateChange}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Gender<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="select"
                        onChange={setFieldValueHandler}
                        name="gender"
                        value={fieldValue.gender}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      >
                        <option>Select Gender</option>
                        {genderDrop?.length > 0 &&
                          genderDrop?.map(({ gender }, index) => (
                            <option value={gender} key={index}>
                              {gender}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {console.log(" ==========> fieldValue 1234", fieldValue)}
                  <div
                    className="col-lg-4 col-md-6 col-sm-12"
                  //   style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Document type</Form.Label>
                      <Form.Select
                        onChange={setFieldValueHandler}
                        name="doc_type"
                        aria-label="select"
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                        value={fieldValue.doc_type}
                      >
                        <option>Select Document</option>
                        <option value="Aadhar">Aadhar Card</option>
                        {/* <option value="PAN Card">Pan Card</option> */}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 col-sm-12"
                  //   style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Document No</Form.Label>
                      <Form.Control
                        type="number"
                        name="doc_number"
                        placeholder="Document number"
                        onChange={setFieldValueHandler}
                        value={fieldValue.doc_number}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      />
                      {error.aadhar && (
                        <p className="text-danger">{aadharmessage}</p>
                      )}
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 col-sm-12"
                  //   style={{ width: "30%" }}
                  >
                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Qualification</Form.Label>
                      <Form.Control
                        name="qualification"
                        placeholder="Qualification"
                        onChange={setFieldValueHandler}
                        value={fieldValue.qualification}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      />
                    </Form.Group> */}

                    {/* prajwal Comment select qualification code-start */}

                    {/* 
                    <QualificationSelect
                      name="qualification"
                      defaultValue={fieldValue.qualification}
                      value={fieldValue?.qualification}
                      onChange={setFieldValueHandler}
                      title="Qualification"
                      placeholder="Qualification" // Update the placeholder value here
                      options={qualificationDD}
                      optType="qualification"
                    /> */}

                    {/* prajwal Comment select qualification code-start*/}

                    {/* adding the code select qualification -start */}

                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Qualification</Form.Label>
                        <Form.Select
                          name="qualification"
                          defaultValue={fieldValue.qualification}
                          value={fieldValue.qualification}
                          onChange={setFieldValueHandler}
                          title="Qualification"
                          placeholder="Select Qualifications"
                          options={qualificationDD}
                          optType="qualification"
                          disabled={action === "View"}
                        >
                          <option selected="selected">
                            Select Qualification
                          </option>
                          {qualificationDD?.length > 0 &&
                            qualificationDD?.map((state, index) => (
                              <option value={state.degree} key={index}>
                                {state.degree}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </div>

                    {/* adding the code select qualification -start*/}
                  </div>
                  {console.log(" ==========> fieldValue 121212", fieldValue)}
                  {action == "Add" && (
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Username<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          name="username"
                          placeholder="User Name"
                          onChange={setFieldValueHandler}
                          value={fieldValue.username}
                          disabled={action === "View"}
                          style={{ backgroundColor: "#fff" }}
                        />
                      </Form.Group>
                    </div>
                  )}

                  {action == "Add" && (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12"
                    //   style={{ width: "30%" }}
                    >
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Password<span className="required-field">*</span>
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={!showPassword ? "password" : "text"}
                            name="password"
                            placeholder="Password"
                            onChange={setFieldValueHandler}
                            value={fieldValue.password}
                            disabled={action === "View"}
                            style={{ backgroundColor: "#fff" }}
                          />
                          <div className="search-icon" onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </div>
                        </InputGroup>
                      </Form.Group>
                    </div>
                  )}
                  {!isMchAdmin ? (
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label
                          className="refer-to-mch-label"
                          style={{ marginTop: 10 }}
                        >
                          MCH Name
                        </Form.Label>
                        {/* <div >Refer to MCH</div> */}
                        <div onClick={() => setShowDropDown(true)}>
                          <Form.Select
                            aria-label="select"
                            onChange={(e) => console.log("jkl")}
                            name="id"
                            value={selectedId}
                            disabled={action === "View"}
                            style={{ backgroundColor: "#fff" }}
                          >
                            <option>Select MCH</option>
                            {imsData.length > 0 &&
                              imsData?.map((item) => (
                                <option
                                  style={{ visibility: "hidden" }}
                                  value={item.id}
                                  key={item.id}
                                >
                                  {item.name}
                                </option>
                              ))}
                          </Form.Select>
                        </div>
                      </Form.Group>
                    </div>
                  ) : null}

                  {(action == "Add" || action == "Edit") && (
                    <p className="text-danger note-text">
                      <span>
                        <LabelImportantIcon />
                      </span>
                      : Kindly complete all the required fields to submit the
                      form.
                    </p>
                  )}
                </div>
                {action !== "View" && (
                  <div className="d-flex justify-content-start mt-3 gap-2">
                    {action !== "Edit" && (
                      <Button
                        style={{ padding: "10px 40px" }}
                        //   className="reset-btn"
                        variant="outline-primary"
                        onClick={handleReset}
                      >
                        <b>Reset</b>
                      </Button>
                    )}

                    <div>
                      <Button
                        className="loginButton reset-btn"
                        variant="primary"
                        type="submit"
                        disabled={handleDisablebtn()}
                      >
                        {action == "Edit" ? "Update" : "Save"}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        </form>
      ) : (
        <div id="doctorId" className="py-2">
          <div className="d-flex flex-column mx-0 mx-md-3  flex-md-row flex-lg-row flex-lg-row justify-content-between align-items-center align-items-md-end mt-4">
            <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
              <label className="label-input">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search by name"
                  onChange={handleSearchInput}
                  value={searchText}
                />
                {searchText && (
                  <button
                    className="search-btn-close"
                    onClick={handleSearchClear}
                  >
                    <img src={searchCloseIcon} alt="" width={"24px"} />
                  </button>
                )}
                <button className="search-btn" onClick={searchHandler}>
                  <img src={searchIcon} alt="" />
                  <span>Search</span>
                </button>
              </label>
            </div>

            <div className="col-6">
              <button
                className="filter-btn ms-5"
                onClick={() => setShowModal(true)}
              >
                <span>Filter</span>
                <img src={filterIcon} alt="" className="ps-2" />
              </button>
            </div>

            <div className="text-end">
              <button
                className="add-btn"
                onClick={() => {
                  setFieldValue(initialState);
                  setAction("Add");
                }}
              >
                <img src={addIcon} alt="" style={{ marginRight: "10px" }} />
                Add Pathologist
              </button>
            </div>
          </div>
          <div className="">
            <div className="patientdo" style={{ margin: "2%" }}>
              <table className="" style={{ width: "100%", margin: 0 }}>
                {allData?.length ? (
                  <>
                    <thead>
                      <tr>
                        <th>Pathologist ID</th>
                        <th>Pathologist Name</th>
                        <th>Username</th>
                        <th>State</th>
                        <th>District</th>
                        <th>Place</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                  </>
                ) : (
                  ""
                )}

                <tbody>
                  {allData?.length
                    ? allData?.map((item, index) => {
                      const { id, name, username, state, district, place } =
                        item || {};
                      return (
                        <tr key={index}>
                          <td>{id}</td>
                          <td>{name}</td>
                          <td>{username}</td>
                          <td>{state}</td>
                          <td>{district}</td>
                          <td>{place}</td>

                          <td>
                            <span
                              onClick={() => handleAction("View", item.id)}
                              className="me-1"
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                src={view}
                                alt=""
                              />
                            </span>
                            <span
                              className="ms-1"
                              onClick={() => handleAction("Edit", item.id)}
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                src={edit}
                                alt=""
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })
                    : null}
                </tbody>
              </table>

              {allData?.length ? (
                <div className="flex justify-content-end table-page">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25]}
                    count={count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <div
                  className="text-center d-flex justify-content-center
            align-items-center"
                  style={{ minHeight: "10rem", fontSize: "24px" }}
                >
                  <p>No Records Found!</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {showDropdown && (
        <PopupDropdwon
          isHidden={showDropdown}
          onClose={closeModalDropDown}
          data={imsData}
          setSelectedId={setSelectedId}
          totalCount={totalCount}
          setImsData={setImsData}
          page={popUpPage}
          pageSize={pageSize}
          setPage={setPopUpPage}
          setPageSize={setPageSize}
          offset={offset}
          setOffset={setOffset}
          setTotalCount={setTotalCount}
        />
      )}
      <FilterModal
        visible={showModal}
        onClose={handleFilterClose}
        onSubmit={() => {
          getAllPathologists(rowsPerPage, 0, 1);
          handleFilterClose();
        }}
      // setStateDropDown={setStateDropDown}
      // setFilterDistrictDrop={setFilterDistrictDrop}
      // setPlaceDrop={setFilterPlaceDrop}
      >
        <CoPathologistFilterModal
          filterState={filterState}
          setFilterState={setFilterState}
          stateDropDown={stateDropDown}
          filterDistrictDrop={filterDistrictDrop}
          filterDistrict={filterDistrict}
          setFilterDistrict={setFilterDistrict}
          filterPlaceDrop={filterPlaceDrop}
          filterPlace={filterPlace}
          setFilterPlace={setFilterPlace}
        />
      </FilterModal>
      {/* {showSpinner && <Spinner />} */}
    </Layout>
  );
};

export default CoPathologist;
