import React from "react";
import "./CustomTable.css";
import edit from "../../Assets/icons/edit.svg";
import view from "../../Assets/icons/view.svg";

// const rows = [
//   {
//     displayName: "displayName",
//     _id: "_id",
//     gender: "gender",
//     phone: "Phone",
//   },
//   {
//     displayName: "displayName",
//     _id: "_id",
//     gender: "gender",
//     phone: "Phone",
//   },
//   {
//     displayName: "displayName",
//     _id: "_id",
//     gender: "gender",
//     phone: "Phone",
//   },
//   {
//     displayName: "displayName",
//     _id: "_id",
//     gender: "gender",
//     phone: "Phone",
//   },
//   {
//     displayName: "displayName",
//     _id: "_id",
//     gender: "gender",
//     phone: "Phone",
//   },
// ];

// const RowHeaders = [
//   "Admin Id",
//   "Admin Name",
//   "Contact No.",
//   "State",
//   "City",
//   "Status",
//   "Actions",
// ];

const CustomTable = (props) => {
  const { Row, data, Headings } = props;
  console.log("row",Row)

  return (
    <table className=" " style={{ width: "100%", margin: "0" }}>
      {data.length?<thead>
        <tr>
          {Headings.map((item, i) => (
            <th>{item}</th>
          ))}
        </tr>
      </thead>:""}
      
      <tbody>
        {data.map((item, i) => {
          return <Row data={item} type={"data1"} />;
        })}
      </tbody>
    </table>
  );
};

export default CustomTable;
