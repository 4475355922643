import React, { useContext, useEffect, useState } from "react";
import { SideNavContext } from "../../../SideNavContextAPI/SideNavContext";
import { useMemo } from "react";
import { TablePagination } from "@mui/material";
import whitEye from "../../../../Assets/icons/view-white.svg";
import { useNavigate } from "react-router-dom";
import { setPatientId, setPatientInfo } from "../../../Redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { patientHealthCampList } from "../../../../Services/AddPatient/AddPatient";
import Filter from "../../../Filter/Filter";

const HealthCP = (props) => {
  const { sideNavState, setSideNavState } = useContext(SideNavContext);
  const dispatch = useDispatch();
  const { healthCampId } = props;
  const navigate = useNavigate();
  const [allData, setAllData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [offset, setOffset] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedSearchOption, setSelectedSearchOption] = useState("name");
  const [selectedPlaceholder, setPlaceholder] = useState("Search by Name")

  const newAllData = useMemo(() => {
    const result = allData?.map((item, index) => {
      return {
        "Sl No": index + 1,
        "Patient Name": item?.full_name,
        Gender: item?.gender,
        "Mobile No": item?.contact_number,
        ActionHealthCamp: "view",
      };
    });
    return result;
  }, [allData]);


  const handleViewClick = (id, item) => {
    dispatch(setPatientId(id));
    dispatch(setPatientInfo(item));
    navigate(`/home/doctorsgeneralitp/${id}?healthCampId=${healthCampId}`);
  };

  const getPatientDetails = async (offset = 0, searchText) => {
    let params = {
      limit: pageSize,
      offset: offset,
    };
    if (searchText) params = { ...params, [selectedSearchOption]: searchText };
    let patient = await patientHealthCampList(healthCampId, params);
    if (patient) {
      setCount(patient.data.count);
      setAllData(patient.data.body);
    }
  };

  useEffect(() => {
    getPatientDetails(0, searchText);
  }, [pageSize]);

  useEffect(() => {
    getPatientDetails(offset, searchText);
  }, []);


  const handleSearchClear = () => {
    setSearchText("");
    getPatientDetails(0, "");
  };

  useEffect(() => {
    if (searchText === "") getPatientDetails(offset, searchText);
  }, [searchText]);

  const searchHandler = () => {
    getPatientDetails(offset, searchText);
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  /**Pagination page change handle */
  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = pageSize * pageIndex;
    getPatientDetails(offset, searchText);
    setOffset(offset);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateFilter = (value, label) => {
    handleSearchClear()
    setSelectedSearchOption(value)
    if (label) setPlaceholder(label)
  }

  return (
    <div>
      <div className="patientdo" style={{ margin: "2%" }}>
        <Filter
          selectedSearchOption={selectedSearchOption}
          updateFilter={updateFilter}
          selectedPlaceholder={selectedPlaceholder}
          handleSearchInput={handleSearchInput}
          searchText={searchText}
          handleSearchClear={handleSearchClear}
          searchHandler={searchHandler}
        />
        {allData?.length ? (
          <table className="" style={{ width: "100%", margin: 0 }}>
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Patient Name</th>
                <th>Gender</th>
                <th>Mobile No.</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allData?.map((item, index) => {
                const { full_name, gender, contact_number } = item;
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{full_name}</td>
                    <td>{gender}</td>
                    <td>{contact_number}</td>
                    <td>
                      <div
                        style={{ justifyContent: "center", display: "flex" }}
                        onClick={() => handleViewClick(item?.id, item)}
                      >
                        <p className="view-all px-2 py-1">
                          view
                          <img src={whitEye}></img>
                        </p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
        {allData?.length ? (
          <div className="flex justify-content-end table-page">
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <div className="text-center d-flex justify-content-center align-items-center" style={{ minHeight: "10rem", fontSize: "24px" }} >
            <p>No Records Found!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HealthCP;
