import React, { useState } from "react";
import ChangeEmailIcon from "../../../Assets/Images/Profile/change-email.svg";
// import PhoneIcon from "../../../Assets/Images/Profile/phoneicon.svg";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';

const ChangeEmail = () => {
  const [email, setEmail] = useState('');
  const [newPhoneNumber, setNewEmail] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [updateNumber, setUpdateNumber] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [otp, setOtp] = useState(['', '', '', '']);

  const handleOtpChange = (index, value) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);
  };


  const handleSendOTP = async (e) => {
    e.preventDefault();
    let phoneNumberExists;
    try {
      const user = await Auth.currentAuthenticatedUser();
      phoneNumberExists = user.attributes.phone_number;
    } catch (error) {
      console.log('Error getting current user:', error);
    }
    // if (phoneNumberExists) {
      try {
        await Auth.verifyCurrentUserAttribute('phone_number', email);
        setOtpSent(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
    // }
  };

  const handleVerifyPhoneNumber = async () => {
    let allOtp = otp.join('')
    try {
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', allOtp);
      setEmail('');
      // setVerificationCode('');
      setNewEmail('');
      setUpdateNumber(true)
      setOtpSent(false);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleUpdatePhoneNumber = async () => {
    try {
      await Auth.updateUserAttributes({
        phone_number: newPhoneNumber,
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  // console.log("otp is==============================", otp)


  return (
    <>
      {!otpSent ? (
        <form noValidate onSubmit={handleSendOTP} >
          <div className="row d-flex ">
            <div className="col-5">
              <div></div>
            </div>
            <div className="col-6">
              <div className="side email-tab">
                <div className="profile-tab-title-email">
                  <img src={ChangeEmailIcon} className="tab-icons-email"></img> &nbsp;&nbsp;
                  <h1 className="tab-name" style={{marginBottom:"-10px"}}>Change Email</h1>
                </div>
              </div>
              <div className="side-content">
                <div className="form-input">
                  <div>
                    <label className="from-lable">Current Email</label>
                    <input
                      type="number"
                      className="input-fild"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <button
                    type="submit"
                    className="button-e"
                  // onClick={() => setOtpSent(true)}
                  >
                    Send OTP
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : updateNumber ?
        <form noValidate onSubmit={handleUpdatePhoneNumber} >
          <div className="row d-flex ">
            <div className="col-5">
              <div></div>
            </div>
            <div className="col-6">
              <div className="side">
                <div className="profile-tab-titele">
                  <img src={ChangeEmailIcon} className="tab-icons"></img>
                  <h1 className="tab-name">Change Phone</h1>
                </div>
              </div>
              <div className="side-content">
                <div className="form-input">
                  <div>
                    <label className="from-lable">Ente new Phone Number</label>
                    <input
                      type="number"
                      className="input-fild"
                      value={newPhoneNumber}
                      onChange={(e) => setNewEmail(e.target.value)} />
                  </div>
                  <button
                    type="submit"
                    className="button-e"
                    onClick={() => setOtpSent(true)}
                  >
                    Update Number
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form> :
        <>
          <form noValidate onSubmit={handleVerifyPhoneNumber}>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <div className="row d-flex ">
              <div className="col-5">
                <div></div>
              </div>

              <div className="col-6">
                <div className="side">
                  <div className="profile-tab-titele">
                    <img src={ChangeEmailIcon} className="tab-icons-m"></img>
                    <h1 className="tab-name">Change Phone</h1>
                  </div>
                </div>
                <div className="side-content">
                  <div className="otp-section">
                    <h1 className="otp-titel">Verification Code</h1>
                    <p className="enetr-otp">Enter OTP</p>
                    <div className="otp-input">
                      <input
                        className="form-Otp"
                        maxlength="1"
                        value={otp[0]}
                        onChange={(e) => handleOtpChange(0, e.target.value)}>
                      </input>
                      <input
                        className="form-Otp"
                        maxlength="1"
                        value={otp[1]}
                        onChange={(e) => handleOtpChange(1, e.target.value)}>
                      </input>
                      <input
                        className="form-Otp"
                        maxlength="1"
                        value={otp[2]}
                        onChange={(e) => handleOtpChange(2, e.target.value)}>
                      </input>
                      <input
                        className="form-Otp"
                        maxlength="1"
                        value={otp[3]}
                        onChange={(e) => handleOtpChange(3, e.target.value)}>
                      </input>
                    </div>
                    <p className="contetn-text">If you don’t received code!</p>
                    <span className="span-titel">Resend</span>
                    <button type="submit" className="button-otp">
                      <Link>
                        <span className="bt-names">Verify</span>
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      }
    </>
  );
};
export default ChangeEmail;

