import * as React from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import './fullWidth.scss';
import { useSelector } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs(props) {
  const { width = "100%", marginLeft = "10px", section } = props;
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { patientList } = useSelector((state) => state.patientInfo)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box id="tabs-style" sx={{ padding: "0px", margin: "0px", marginTop: "10px" }}>
      <Box sx={{ margin: "0px 0px 0px 0px" }}>
        {section === "nurses" &&
          <Box style={{ display: "flex", justifyContent: "space-between", padding: "10px", backgroundColor: "#7C7C7C1A", marginBottom: "15px", border: "1px solid #191BC3" }}>
            <Box><b>Patient Name:</b> {patientList[0]?.full_name}</Box>
            <Box><b>Gender:</b> {patientList[0]?.gender}</Box>
            <Box><b>Age:</b> {patientList[0]?.age}</Box>
            <Box className={'patient'}><b>Patient ID:</b> {patientList[0]?.id}</Box>
          </Box>}
        <AppBar position="static">
          <Tabs className="table_hedding"
            style={{ backgroundColor: "#F2F2FE", color: "black", }}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
          >
            {props.tabstitle.map((val, index) => {
              return <Tab label={val} {...a11yProps(index)} />;
            })}
          </Tabs>
        </AppBar>
      </Box>
      <div>
        {props.DoctorsTabData.map((child, index) => {
          return (
            <TabPanel value={value} index={index} dir={theme.direction}>
              {child}
            </TabPanel>
          );
        })}
      </div>
    </Box>
  );
}
