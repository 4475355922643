import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  healthCampid: ""
};

export const healthCampSlice = createSlice({
  name: "healthCamp",
  initialState,
  reducers: {
    setHealthCamp(state, action) {
      state.healthCampid = action.payload;
    },
  },
});



export const { setHealthCamp } = healthCampSlice.actions;
export const selectHealthCampState = (state) => state?.healthCamp?.healthCampid;
export default healthCampSlice.reducer;
