import { Avatar, Box, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { DOCUMENT_OPTIONS, GENDER_OPTIONS } from "../../../Utils/constants";
import HollowButton from "../../HollowButton/HollowButton";
import Layout from "../../LayoutComp/Layout";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import RenderInput from "../../RenderInput/RenderInput";
import RenderSelect from "../../RenderSelect/RenderSelect";
import "./CreateSurvey.scss";
import Doctors from "../../../Assets/Images/doctors.png";

const SinglePair = (props) => {
  const { label, value } = props;

  return (
    <div className="single-pair-container">
      <div className="single-pair-label">{label}:</div>
      <div className="single-pair-value">{value}</div>
    </div>
  );
};

const CreateSurvey = (props) => {
  const { sideNavState, setSideNavState } = props;

  const initialValues = { name: "", document: "Aadhar", gender: "male" };

  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      <div style={{ padding: 20 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={"center"}
          className="create-survey-header-container"
        >
          <Grid item xs={8}>
            <div className="create-survey-heading">
              Free Health And Wellness Day Camp
            </div>
          </Grid>
          <Grid item xs={4}>
            <SinglePair label="Date" value="15th March 2022 Sunday" />
          </Grid>
          <Grid item xs={8}>
            <SinglePair
              label="Venue"
              value="Vimlalaya hospital, near to huskur gate, Haridwar"
            />
          </Grid>
          <Grid item xs={4}>
            <SinglePair label="Timings" value="10AM To 5:00PM" />
          </Grid>
        </Grid>
      </div>

      <div className="create-survey-image-container">
        <img src={Doctors} alt="doctors" />
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => console.log(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values, handleReset }) => (
          <div style={{ padding: 30 }}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <RenderSelect
                  name="state"
                  title="Select State"
                  value={values.state}
                  onChange={handleChange}
                  options={DOCUMENT_OPTIONS}
                />
              </Grid>
              <Grid item xs={4}>
                <RenderSelect
                  name="district"
                  title="Select District"
                  value={values.district}
                  onChange={handleChange}
                  options={DOCUMENT_OPTIONS}
                />
              </Grid>
              <Grid item xs={4}>
                <RenderSelect
                  name="city"
                  title="Select City"
                  value={values.city}
                  onChange={handleChange}
                  options={DOCUMENT_OPTIONS}
                />
              </Grid>
              <Grid item xs={4}>
                <RenderInput
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  title="Title Of the Health Camp"
                  placeholder="Title"
                />
              </Grid>
              <Grid item xs={4}>
                <RenderInput
                  name="venue"
                  value={values.venue}
                  onChange={handleChange}
                  title="Venue"
                  placeholder="Venue"
                />
              </Grid>
            </Grid>
            <Box className="button-pair">
              <PrimaryButton
                name="Create Survey"
                onClick={() => console.log(" ==========> button pressed")}
                className="hollow-button"
              />
            </Box>
          </div>
        )}
      </Formik>
    </Layout>
  );
};

export default CreateSurvey;
