import { createService, readService, updateService } from "../HttpService";

export const readDataOperator = (params) => {
  return readService("dataOperators", params);
};

export const addDataOper = (body) => {
  return createService("dataOperators", body);
};

export const updateDataOperator = (id, param, body) => {
  return updateService(
    `dataOperators/${id}`,
    param,

    body
  );
};