import React from "react";

const DbCard = ({ bgImg, icon, name, count }) => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(../../assets/images/dashboard/${bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="py-4 px-2 rounded"
      >
        <div className="card-body">
          <div className="d-flex flex-row justify-content-around">
            <img src={icon} alt="" />

            <div
              style={{
                fontSize: "21px",
                color: "#1E5074",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p className="font-weight-bold">{name}</p>
              <p>{count}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DbCard;
