import React, { useState } from "react";
import generalInfoProfile from "../../../Assets/Images/Profile/male-icon.svg";
import EditProfileIcon from "../../../Assets/Images/Profile/edituser.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getBooldGroup, getDistrictsByState, getPlacesByDistrict, getQualifications, getStates } from "../../../Services/Metadata/Metadata";
import { updateProfile } from "../../../Services/UserProfile/UserProfile";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import { enqueueSnackbar } from "notistack";
import { errorToast, successToast } from "../../../Utils/toasters";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ConvertYMD, formatDate } from "../../../Utils/DateUtils";

const ProfilePage = () => {
  const [active, setActive] = useState(false);
  const { userProfile } = useSelector(state => state?.user)
  const [qualificationData, setQualificationData] = useState([])
  const [stateId, setStateId] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [placesData, setPlacesData] = useState([]);
  const [districtId, setDestrictId] = useState('');
  const [bloodGroup, setBloodGroup] = useState([]);
  const [confirmatiomMsg, setConfirmationMsg] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  // const [selectedCity, setSelectedCity] = useState('');

  const [fieldValue, setFieldValue] = useState({
    name: userProfile.name,
    contact_number: userProfile.contact_number,
    dob: new Date(userProfile.dob),
    qualification: userProfile.qualification,
    blood_group: userProfile.blood_group,
    state_id: userProfile.state_id,
    district_id: userProfile.district_id,
    place_id: userProfile.place_id,
  })


  // console.log("getQualification(userProfile.qualification)===", userProfile.qualification)
  const closeConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  }

  useEffect(() => {
    getBooldGroupData()
  }, []);

  const getBooldGroupData = async () => {
    try {
      const response = await getBooldGroup();
      setBloodGroup(response.data);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  // useEffect(() => {
  //   setStateId(userProfile.state_id);
  //   setDestrictId(userProfile.district_id);
  //   setFieldValue((prevState) => ({
  //     ...prevState,
  //     blood_group: userProfile.blood_group
  //   }))
  // }, [userProfile]);

  useEffect(() => {
    const getStateList = async () => {
      try {
        let response = await getStates()
        setStateList(response.data);
      } catch (e) {
        console.log("Error while fetching state list", e);
      }
    }
    const getQualification = async () => {
      try {
        const response = await getQualifications();
        setQualificationData(response.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    getQualification()
    getStateList()
  }, [])

  useEffect(() => {
    if (fieldValue.state_id) getDistrict(fieldValue.state_id);
  }, [fieldValue.state_id]);

  useEffect(() => {
    if (fieldValue.district_id) getPlaces(fieldValue.district_id);
  }, [fieldValue.district_id]);

  const getPlaces = async (value) => {
    setDestrictId(value);
    let places = await getPlacesByDistrict(value);
    setPlacesData(places.data);
  };

  const getDistrict = async (value) => {
    setStateId(value);
    let district = await getDistrictsByState(value);
    setDistrictData(district.data);
  };

  const handleDateChange = (date) => {
    setFieldValue((prevData) => ({
      ...prevData,
      dob: date
    }))
    // const datePattern = /^\d{0,2}\/\d{0,2}\/\d{0,4}$/;


  }

  const onChangeHandler = (e) => {
    // console.log("ckicke", e.target.value)
    const name = e.target.name;
    const value = e.target.value;
    setFieldValue((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    formData.append("dob", ConvertYMD(fieldValue?.dob))
    try {
      let response = await updateProfile(formData)
      if (response.status < 200 || response.status > 300) {
        errorToast(enqueueSnackbar, response.data);
      } else {
        successToast(enqueueSnackbar, 'Updated Successfully!!')
        setActive(!active)
      }

    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <div className="row d-flex ">
        <div className="col-5">
          <div></div>
        </div>

        <div className="col-7">
          <div className="profile-side">
            <div className="profile-tab-titele">
              <img src={generalInfoProfile} className="tab-icons"></img>
              <h1 className="tab-name">Admin Profile</h1>
            </div>

            <button type="btn" className="edit-btn">
              <Link onClick={() => setActive(!active)}>
                <img src={EditProfileIcon} className="p-edit-icon"></img>
                <span className="bt-names">Edit</span>
              </Link>
            </button>
          </div>
          <div className="profile-side-content">
            <div>
              <div className="">
                <form className="container" noValidate onSubmit={handleSubmit}>
                  <div className="m-4 mt-5">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="input-lable">Name</label>
                            <input
                              className="form-control"
                              placeholder="Richa Sharma"
                              value={fieldValue.name}
                              name="name"
                              onChange={onChangeHandler}
                            ></input>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="input-lable">
                              Contact Number{" "}
                            </label>
                            <input
                              type="number"
                              name="contact_number"
                              className="form-control no-spinners"
                              placeholder="9746756452"
                              value={fieldValue.contact_number}
                              onChange={onChangeHandler}
                            ></input>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="input-lable">State </label>
                            <select
                              className="form-control"
                              onChange={onChangeHandler}
                              value={fieldValue.state_id}
                              name="state_id"
                              disabled={!active}
                            >
                              <option>Select State</option>
                              {stateList &&
                                stateList.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="input-lable">District </label>
                            <select
                              className="form-control"
                              placeholder="Select District"
                              defaultValue={"default"}
                              value={fieldValue.district_id}
                              name="district_id"
                              disabled={!active}
                              onChange={onChangeHandler}
                            >
                              <option>Select District</option>
                              {districtData?.length > 0 && districtData.map((district, index) => (
                                <option value={district.id} key={index} >{district.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group" style={{ marginTop: "33px" }}>
                            <label className="input-lable">Place </label>
                            <select
                              className="form-control"
                              placeholder="Select City"
                              defaultValue={"DEFAULT"}
                              name="place_id"
                              disabled={!active}
                              onChange={onChangeHandler}
                              value={fieldValue.place_id}
                            >
                              <option>Select Place</option>
                              {placesData &&
                                placesData.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="fieldStyle col-lg-12 col-md-6 col-sm-12 text-start date-picker-cls">
                            <label className="input-lable">
                              DOB
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                style={{ marginBottom: "20px" }}
                                wrapperClassName="datepicker"
                                value={fieldValue.dob}
                                onChange={handleDateChange}
                              />
                            </LocalizationProvider>
                            {/* <input
                              type="date"
                              className="form-control"
                              placeholder="10th"
                              value={fieldValue.dob}
                              onChange={handleDateChange}
                              name="dob"
                            ></input> */}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="input-lable">
                              {" "}
                              Blood Group{" "}
                            </label>
                            <select className="form-control"
                              value={fieldValue.blood_group}
                              name="blood_group"
                              onChange={onChangeHandler}
                            >
                              <option value=''>Select blood group</option>
                              {bloodGroup?.length > 0 && bloodGroup.map((blood, index) => (
                                <option value={blood.group} key={index} >{blood.group}</option>
                              ))}
                            </select>
                          </div>
                        </div>

                  
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="input-lable">
                              {" "}
                              Qualification{" "}
                            </label>
                            <select className="form-control"
                              value={fieldValue.qualification}
                              name="qualification"
                              disabled={!active}
                              onChange={onChangeHandler}
                            >
                              <option value=''>Select Qualification</option>
                              {qualificationData?.length > 0 && qualificationData.map((qualif, index) => (
                                <option value={qualif.degree} key={qualif.id} >{qualif.degree}</option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {active && <div className="col-lg-6">
                          <div className="form-group" style={{ marginTop: "20px" }}>
                            <button className="update-btn">Update</button>
                          </div>
                        </div>}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        <ConfirmationModal
          isHidden={showConfirmationModal}
          onClose={closeConfirmationModal}
          message={confirmatiomMsg}
        />
      }
    </>
  );
};
export default ProfilePage;
