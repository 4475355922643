import React from 'react'
import Layout from '../../LayoutComp/Layout'
import FullWidthTabs from '../../TabComp/FullWidthTabs';
import Prescription from '../HealthCampPatient/View/Prescription/Prescription';
import MedicalReport from '../HealthCampPatient/View/MedicalReport/MedicalReport';

const HealthCenterView = ({ sideNavState,setSideNavState}) => {
         const childrenArry = [<Prescription/>, <MedicalReport/>
           
         ];
          const tabstitle = ["Prescription", "Medical report"];

  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      <FullWidthTabs DoctorsTabData={childrenArry} tabstitle={tabstitle} />
    </Layout>
  );
}

export default HealthCenterView