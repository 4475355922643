import React, { useEffect, useState } from "react";
import "./Hospital.scss";
import Layout from "../LayoutComp/Layout";
import AddButton from "../AddButton/AddButton";
import SearchInput from "../SearchInput/SearchInput";
import CustomTable from "../CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import { TablePagination } from "@mui/material";
import view from "../../Assets/icons/view.svg";
import edit from "../../Assets/icons/edit.svg";
import filterIcon from "../../Assets/icons/filter_icon.svg";
import { readMCH } from "../../Services/MCH";
import CommonFilterModal from "../CommonFilterModal/CommonFilterModal";
import { useSelector } from "react-redux";

const SingleRow = (props) => {
  const navigate = useNavigate();

  const { data } = props;

  return (
    <tr style={{ cursor: "pointer" }}>
      <td>{data.id}</td>
      <td>
        <span>{data.name}</span>
      </td>
      <td>{data.state}</td>
      <td>{data.district}</td>
      <td>{data.place}</td>

      {/* <td>
        <img src={StatusIcon} alt="logo" />
      </td> */}
      <td>
        <span
          className="edit-icon"
          onClick={() =>
            navigate("/home/hospital/edit", {
              state: {
                action: "edit",
                hospitalId: data.id,
              },
            })
          }
        >
          <img src={edit} alt="" />
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span
          onClick={() =>
            navigate("/home/hospital/view", {
              state: {
                action: "view",
                hospitalId: data.id,
              },
            })
          }
        >
          <img src={view} alt="" />
        </span>
      </td>
    </tr>
  );
};

const HOSPITAL_HEADING = [
  "MCH Id",
  "MCH Name",
  "State",
  "District",
  "Place",
  // "Status",
  "Action",
];

const Hospital = (props) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [showModal, setShowModal] = useState(false);
  const [tdata, setTData] = useState([]);
  const [filterDataObj, setFilterDataObj] = useState({});
  const { sideNavState, setSideNavState } = props;
  const [offSet, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowsPerPageVal, setRowsPerPageVal] = useState(5);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(null);

  const { userGroup } = useSelector((s) => s.user.userProfile);
  console.log(" ==========>userGroup ", userGroup);

  const handleSearchClear = () => {
    setSearchText("");
    getHospitals(offSet, "");
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
    //  getHospitals(offSet, searchText);
  };
  const searchHandler = () => {
    getHospitals(offSet, searchText);
  };

  const handleAddHospital = () => {
    navigate("/home/hospital/add-new", {
      state: {
        action: "add",
      },
    });
  };
  
  const closeModal = () => {
    setShowModal(!showModal);
  };
  const getFilterObject = (param) => {
    setFilterDataObj(param);
  };
  useEffect(() => {
    getHospitals(offSet, searchText, filterDataObj);
  }, [filterDataObj]);
  useEffect(() => {
    const getHospital = async () => {
      const hospitalCount = await readMCH();
    };
    getHospital();
  }, []);

  useEffect(() => {
    if (searchText == "") {
      getHospitals(0, "", filterDataObj);
    }
  }, [searchText]);

  useEffect(() => {
    getHospitals(offSet, searchText, filterDataObj);
  }, [rowsPerPageVal]);
  const getHospitals = async (
    offSet = 0,
    searchText = "",
    filterDataObj = {}
  ) => {
    let params = {
      offset: offSet,
      limit: rowsPerPageVal,
      ...filterDataObj,
    };
    if (searchText) params = { ...params, name: searchText };
    if (filterDataObj?.state && filterDataObj?.state !== "DEFAULT")
      params = { ...params, state: filterDataObj.state };

    if (filterDataObj?.city && filterDataObj?.city !== "DEFAULT")
      params = { ...params, city: filterDataObj.city };
    const res = await readMCH(params);
    setTData(res.data.body);
    setTotalCount(res?.data?.count);
  };
  const handleChangeRowsPerPage = (e) => {
    setPageSize(e?.targte?.value);
    setRowsPerPageVal(parseInt(e.target.value, 10));
    console.log("valuee", rowsPerPageVal);
    //  getHospitals(offSet,searchText)
  };
  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPageVal * pageIndex;
    getHospitals(offSet, searchText);

    setOffset(offSet);
  };

  const checkPermission = () => {
    console.log("UG is"+userGroup)
    return userGroup === "ims-admins" || userGroup === "admins";
  };

  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      <div id="hospital" style={{ padding: 20, border: "1px solid #00000029" }}>
        <div className="main-container">
          <div
            className="me-4"
            style={{ display: "flex", alignItems: "center", gap: 30 }}
          >
            <SearchInput
              searchText={searchText}
              onChange={handleSearchInput}
              onSearchClear={handleSearchClear}
              onSearchClick={searchHandler}
            />
          </div>
          <div className="col-6">
            <button className="filter-btn" onClick={() => setShowModal(true)}>
              <span>Filter</span>
              <img src={filterIcon} alt="" className="ps-2" />
            </button>
          </div>
          {checkPermission() ? (
            <div>
              <AddButton name="Add MCH" onClick={handleAddHospital} />
            </div>
          ) : null}
        </div>

        <div className="patientdo">
          <CustomTable
            Row={SingleRow}
            data={tdata}
            Headings={HOSPITAL_HEADING}
          />
          {tdata?.length === 0 && (
            <div className="warning-text-card">No Records Found!</div>
          )}
          <div className="flex justify-content-end table-page">
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPageVal}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
      {
        <CommonFilterModal
          isHidden={showModal}
          onClose={closeModal}
          getFilterObject={getFilterObject}
          filterDataObj={filterDataObj}
          comp="mch"
        />
      }
    </Layout>
  );
};

export default Hospital;
