import React from "react";
import { useState } from "react";
import { Auth } from 'aws-amplify';
import PasswordIcon from "../../../Assets/Images/Profile/password.svg";
import eye from "../../../Assets/Images/Profile/password-hide.svg";
import eyeOff from "../../../Assets/Images/Profile/password-hide.svg";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";

const ChangePassword = () => {
  const [type, setType] = useState("password");
  const navigate = useNavigate()

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmatiomMsg, setConfirmationMsg] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
}

  const handleChangePassword = async (e) => {
    e.preventDefault();

    try {
      await Auth.currentAuthenticatedUser().then((user) => {
        return Auth.changePassword(user, currentPassword, newPassword);
      });
      setConfirmationMsg('Password changed successfully!');
      setShowConfirmationModal(true);
      navigate('/login')
      console.log('Password changed successfully');
    } catch (message) {
      alert('username or password does not match')
    }
  };
  return (
    <>
      <div className="row d-flex ">
        <div className="col-5">
          <div></div>
        </div>
        <div className="col-6">
          <div className="side">
            <div className="profile-tab-titele">
              <img src={PasswordIcon} className="tab-icons"></img>
              <h1 className="tab-name">Change Password</h1>
            </div>
          </div>
          <div className="side-content-changePass">
            <form noValidate onSubmit={handleChangePassword}>
              <div className="form-input">
                <div>
                  <label className="from-lable">Current Password</label>
                  <input
                    type={type}
                    className="input-fild"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  <span onClick={handleToggle}>
                    <img src={eyeOff} className="eyeOff"></img>
                  </span>
                </div>
                <div>
                  <label className="from-lable">New Password</label>
                  <input
                    type={type}
                    className="input-fild"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}

                  />
                  <span onClick={handleToggle}>
                    <img src={eyeOff} className="eyeOff"></img>
                  </span>
                </div>
                <p className="c-pass-titele">
                  Password Pattern (8 characters minimum, At least 1 Uppercase,1
                  Lower case , 1 Number and 1 Special character(ex:@))
                </p>
                <button type="submit" className="button">
                  Change Password
                </button>
              </div>
              {
                <ConfirmationModal
                    isHidden={showConfirmationModal}
                    onClose={closeConfirmationModal}
                    message={confirmatiomMsg}
                />
            }
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
