import React, { useState } from "react";
import "./loginStyle.scss";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { NavLink as RouterLink, Link } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import AlertDialogScreen from "../AlertDialogScreen/AlertDialogScreen";
import { getUserRole } from "../../Services/HttpService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSelect } from "react-select-search";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showSpinner, setShowSpinner] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [newPassword, setNewPassword] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  console.log(" ==========> showPassword outside", showPassword);

  const toggleShowPassword = () => {
    console.log(" ==========> showPassword on press", showPassword);
    if (showPassword) {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
    setShowPassword(!showPassword);
  };

  const usernameHandler = (e) => {
    setUsername(e.target.value);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const roleToRoute = {
    admins: "/home/dashboard",
    "data-operators": "/home/dataoprpat",
    pharmacists: "/home/parmacist",
    nurses: "/home/nurseindex",
    doctors: "/home/doctorsindex",
    "lab-technicians": "/home/pathologistpatient",
    "mch-admins": "/home/dashboard",
    "ims-admins": "/home/dashboard",
  };

  async function signIn() {
    setShowSpinner(true);
    try {
      const user = await Auth.signIn(username, password);
      console.log("login successfull", user);
      if (user) {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setNewPassword(true);
        } else {
          getUserRole()
            .then((role) => {
              console.log("role is===========", role);
              if (role) {
                if (role !== undefined) {
                  localStorage.setItem("role", role);
                  //need to remoe this once log out
                }
                if (role in roleToRoute) {
                  navigate(roleToRoute[role], { state: { role } });
                }
                setShowSpinner(false);
              }
            })
            .catch((error) => {
              setShowSpinner(false);
              console.log("login successfull", error);
            });
        }
      }
    } catch (error) {
      console.log(error);
      alert("user doesn't exists");
      setShowSpinner(false);
    }
  }
  const closeModal = () => {
    setNewPassword(false);
  };

  const handleEnter = async(event) =>{
    console.log("e code"+event.keyCode)
    if(event.keyCode === 13){
        if(username.length > 0 && password.length > 0){
          await signIn()
        }
     }
  }

  return (
    <div className="d-flex justify-content-start flex-column formContainer">
      <div className="loginHeading">Hello</div>
      <div className="loginHeading">Welcome Back</div>
      <div className="mt-4">
        <Form className="form">
          <Row>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="formLabel">Username</Form.Label>
              <Form.Control
                className="formControl"
                onChange={usernameHandler}
                type="email"
                placeholder="Enter Username"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label className="formLabel">Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={!showPassword ? "password" : "text"}
                  onChange={passwordHandler}
                  value={password}
                  className="formControl"
                  placeholder="Password"
                  onKeyDown={handleEnter}
                />
                <div className="search-icon" onClick={toggleShowPassword}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </div>
              </InputGroup>
            </Form.Group>
          </Row>
          <div className="d-flex justify-content-around flex-wrap">
            <div>
              <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check type="checkbox" label="Remember Device" />
              </Form.Group>
            </div>
            <Link className="forgotPassword" to="/forgot-password">
              Forgot Password?
            </Link>
          </div>
          <div className="d-flex justify-content-center ">
            <Button
              className="loginButton"
              variant="primary"
              type="button"
              onClick={signIn}
            >
              Login
            </Button>
          </div>
        </Form>
      </div>
      {
        <AlertDialogScreen
          username={username}
          password={password}
          setShowSpinner={setShowSpinner}
          isHidden={newPassword}
          onClose={closeModal}
        />
      }
      {showSpinner && <Spinner />}
    </div>
  );
}

export default Login;
