import React from "react";
import "./PrimaryButton.css";

const PrimaryButton = (props) => {
  const {
    onClick,
    name,
    className,
    img,
    disableprop,
    disabled = false,
  } = props;

  return (
    <button
      type="submit"
      disabled={disableprop}
      className={`${className} add-btn`}
      onClick={onClick}
    // disabled={disabled}
    >
      {img ? img : ""}&nbsp;{name}
    </button>
  );
};

export default PrimaryButton;
