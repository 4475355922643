import React, { useEffect } from "react";
import userProfileIcon from "../../Assets/icons/userProfilePic.png";
import arrowDownIcon from "../../Assets/icons/arrowDownIcon.svg";
import SignOutIcon from "../../Assets/icons/logout.svg";
import SettingIcon from "../../Assets/icons/settings-gear-icon.svg";
// import SignOutIcon from "../../Assets/icons/sign-out-white.svg";
import headerCoachIcon from "../../Assets/icons/healthCoachIcon.png";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import {
  getProfile,
  userProfile,
} from "../../Services/UserProfile/UserProfile";
import { useDispatch, useSelector } from "react-redux";
import { setUsers } from "../Redux/slices/userSlice";
import { Dropdown } from "react-bootstrap";
import { Auth } from "aws-amplify";
import DefaultImage from "../../Assets/Images/defaultProfile.png";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => state.user);

  useEffect(() => {
    profile();
  }, []);

  const [imageError, setImageError] = React.useState(false);

  const profile = async () => {
    const res = await getProfile();
    // console.log("ressss-->", res);
    dispatch(setUsers(res.data));
  };

  const handleLogoutClicked = async () => {
    try {
      let response = await Auth.signOut({ global: true });
       localStorage.setItem("selectedNav","");


      navigate("/login");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  function handleClick() {
    navigate("/home/profile");
  }

  const handleSettingsClicked = () => {
    navigate("/home/profile");
  };

  return (
    <div className="header-container">
      <div className="header-row row mx-0">
        <div className="col-6 text-start header-coach-card">
          <div>
            <img src={headerCoachIcon} alt="" />
          </div>
          <p style={{ color: "#191BC3" }}>
            Health <span style={{ color: "#0CA1E6" }}>Camp</span>
          </p>
        </div>
        <div className="col-6 text-end">
          <div className="userProfileCard">
            <img
              style={{ cursor: "pointer", width: 50, height: 50 }}
              src={imageError ? DefaultImage : DefaultImage}
              alt=""
              onError={() => {
                setImageError(true);
              }}
              onClick={handleClick}
            />
            {console.log(" ==========> userProfile", userProfile)}
            <div className="text-start">
              <p className="profile-name">{userProfile?.name}</p>
              <p className="profile-role">{userProfile?.userGroup}</p>
            </div>
            {/* <img src={arrowDownIcon} alt="" /> */}
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className="dropdown-profile">
                <img
                  src={arrowDownIcon}
                  alt="^"
                  width="20px"
                  height="20px"
                  style={{ margin: "0px !important" }}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={"dropdown-profile-menu admin-menu-hgts"}
                style={{ width: "100px", border: "1px dashed #707070" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // border: "1px dashed #000",
                  }}
                >
                  <btn
                    className="btn-blue1 cursor-pointer d-flex-img ml-5"
                    onClick={handleSettingsClicked}
                  >
                    <img
                      src={SettingIcon}
                      className="dp-profile-img"
                      alt="logout"
                    />
                    <span className="dp-profile-text">Settings</span>
                  </btn>
                  <btn
                    className="btn-blue1 cursor-pointer d-flex-img ml-5"
                    onClick={handleLogoutClicked}
                  >
                    <img
                      src={SignOutIcon}
                      className="dp-profile-img"
                      alt="logout"
                    />
                    <span className="dp-profile-text">Logout</span>
                  </btn>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}
