import React from 'react'
import Table from '../Table';
const Prescription = ({patientId}) => {
  return (
    <>
      <Table patientId={patientId} />
    </>
  );
}

export default Prescription