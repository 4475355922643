import React, { useContext } from "react";
// import HealthInfo from "../Tabs/HealthInfo";
// import Prescription from "../Tabs/Prescription";
// import LabTest from "../Tabs/LabTest";
// import Pharmacy from "../Tabs/Pharmacy";
// import MedicalReports from "../Tabs/MedicalReports";
import backBtnIcon from "../../../../../../../Assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import NGeneralInfoComp from "../../NurseTabs/NGeneralInfoComp";
import FullWidthTabs from "../../../../../../TabComp/FullWidthTabs";
import Layout from "../../../../../../LayoutComp/Layout";
import NMCHPrescription from "./Tabs/NMCHPrescription";
import NMCHMedicalReports from "./Tabs/NMCHMedicalReports";
import NForum from "./Tabs/NForum";

const NMCHTabs = ({ sideNavState, setSideNavState }) => {
  //   const { sideNavState, setSideNavState } = useContext(SideNavContext);
  const navigate = useNavigate();

  const childrenArry = [
    // <NMCHPrescription />,
    <NMCHMedicalReports/>,
    <NForum/>

    // <Prescription />,
    // <LabTest />,
    // <Pharmacy />,
    // <MedicalReports />,
  ];
  const tabstitle = [
    // "Prescription",
    "Medical report",
    "Forum"
    
  ]
  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState} hidenav={true}>
      <div className="py-2 px-3" style={{ cursor: "pointer" }}>
        <span
          className="back-btn-row"
          onClick={() => navigate("/home/nursepatienttab")}
        >
          <img src={backBtnIcon} alt="" />
          <span>Back</span>
        </span>
      </div>
      <FullWidthTabs tabstitle={tabstitle} DoctorsTabData={childrenArry} />
    </Layout>
  );
};

export default NMCHTabs;
