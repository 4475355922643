import axios from "axios";
import { Auth, Storage } from "aws-amplify";


const baseUrl = "https://demo.healthcoach.org.in/api/";

axios.interceptors.request.use(
  async (config) => {
    const res = await Auth.currentSession();
    const accessToken = res.getAccessToken();
    const jwt = accessToken.getJwtToken();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: "Bearer " + jwt,
      },
    };
  },
  (error) => Promise.reject(error)
);

const decodeJWTToken = async (token) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export async function createService(endpoint, body = {}, params = {}) {
  return axios
    .post(baseUrl + endpoint, body, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`create data error is${JSON.stringify(error)}`);
      if (error.response) {
        console.log(error.response);
        console.log("server responded");
        return error.response;
      }
    });
}

export async function createServiceCoordinator(
  endpoint,
  requestData = {},
  formData = {},
  params = {}
) {
  const config = {
    params: params,
    headers: {
      "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
    },
  };

  // Append the formData to the requestData object
  const mergedData = { ...requestData, ...formData };

  return axios
    .post(baseUrl + endpoint, mergedData, config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
}

export async function readService(endpoint, params = {}) {
  return axios
    .get(baseUrl + endpoint, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function readByKey(endpoint, key) {
  return axios
    .get(baseUrl + endpoint + `/${key}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function updateService(endpoint, params = {}, body) {
  return axios
    .put(baseUrl + endpoint, body, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteService(endpoint, params = {}) {
  return axios
    .delete(baseUrl + endpoint, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function fileUploadService(directory, entityId, file) {
  return new Promise((resolve, reject) => {
    try {
      var extension = file.name.split(".").pop();
      Storage.put(
        directory + "/" + entityId + "/" + entityId + "." + extension,
        file,
        {
          level: "private",
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          },
        }
      )
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

//   export async function SignOut() {
//     const history = useHistory();
//     Auth.signOut()
//       .then(() => {
//         history.push("/login");
//       })
//       .catch((err) => {});
//   }

export async function getIdentityId() {
  return Auth.currentUserCredentials().then((res) => {
    return res.identityId;
  });
}

export async function getUserRole() {
  const res = await Auth.currentSession();
  const accessToken = res.getIdToken();
  const jwt = accessToken.getJwtToken();
  const userRole = await decodeJWTToken(jwt);
  return userRole["cognito:groups"][0];
}

export async function getMentorId() {
  const res = await Auth.currentSession();
  const accessToken = res.getIdToken();
  const jwt = accessToken.getJwtToken();
  const mentorId = await decodeJWTToken(jwt);
  return mentorId["custom:id"];
}

export async function getLoginDetails() {
  try {
    const res = await Auth.currentSession();
    const accessToken = res.getIdToken();
    const jwt = accessToken.getJwtToken();
    const loginDetails = await decodeJWTToken(jwt);
    return loginDetails;
  } catch {
    return false;
  }
}
