import React, { useState, useEffect, useRef } from "react";
import { Button, InputGroup } from "react-bootstrap";
import Avatar from "../../Assets/Images/avatar.png";
import StatusIcon from "../../Assets/icons/Status.svg";
import ArrowUp from "../../Assets/icons/cloud-arrow-up.svg";
import "./doctors.scss";
import backBtnIcon from "../../Assets/icons/back.svg";
import close_icon from "../../Assets/icons/close-rounded.svg";
import view from "../../Assets/icons/view.svg";
import edit from "../../Assets/icons/edit.svg";
import searchIcon from "../../Assets/icons/search_icon.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import Form from "react-bootstrap/Form";
import { Alert, AlertTitle, Snackbar, TablePagination } from "@mui/material";
import { addIcon, filterIcon } from "../../Utils/ImageUtils";
import {
  createService,
  readService,
  updateService,
} from "../../Services/HttpService";
import Spinner from "../Spinner/Spinner";
import Layout from "../LayoutComp/Layout";
import FullWidthTabs from "../TabComp/FullWidthTabs";
import RegisteredDoctors from "./RegisteredDoctors";
import CommonFilterModal from "../CommonFilterModal/CommonFilterModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { readDoctors, readMCH, updateDoctor } from "../../Services/MCH";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { errorToast, successToast } from "../../Utils/toasters";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  ContactValidator,
  charLengthCheck,
  emailValidator,
  passwordValidator,
} from "../../Utils/Validators";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ConvertYMD } from "../../Utils/DateUtils";
import PopupDropdwon from "../CommonPopupDropdown/PopupDropdown";
import { getGenders } from "../../Services/Metadata/Metadata";

const DoctorsTabData = ({ sideNavState, setSideNavState }) => {
  const [action, setAction] = useState(null);
  const [page, setPage] = React.useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowsPerPageVal, setRowsPerPageVal] = useState(5);
  const { userProfile } = useSelector((state) => state.user);

  const [fieldValue, setFieldValue] = useState({
    name: "",
    contact_number: "",
    state_id: "",
    place_id: "",
    district_id: "",
    email: "",
    dob: null,
    gender: "",
    consult_fee: "",
    experience: "",
    qualification: [],
    specialisation: [],
    biography: "",
    image: "",
    password: "",
    username: "",
    ims_id: "",
    works_at: userProfile?.works_at,
  });

  const [stateDropDown, setStateDropDown] = useState([]);
  const [distDrop, setDistDrop] = useState([]);
  const [placeDrop, setPlaceDrop] = useState([]);
  const [specialDD, setSpecialDD] = useState([]);
  const [qualificationDD, setQualificationDD] = useState([]);
  const [imsData, setImsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [showDropdown, setShowDropDown] = useState(false);
  const [popUpPage, setPopUpPage] = useState(0);

  useEffect(() => {
    getState();
    getImsHospitals();
  }, []);

  const getImsHospitals = async () => {
    const params = {
      limit: rowsPerPage,
      offset: offset,
    };
    const result = await readService("imshospitals", params);
    setImsData(result.data.body);
    setTotalCount(result.data.count);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getState = async () => {
    const states = await readService("metadata/states");
    setStateDropDown(states.data);
  };

  useEffect(() => {
    if (fieldValue.state_id) getDistrict();
  }, [fieldValue.state_id]);

  const getDistrict = async () => {
    const district = await readService(
      `metadata/districts/${fieldValue.state_id}`
    );
    setDistDrop(district.data);
  };

  useEffect(() => {
    if (fieldValue.district_id) getPlaceData();
  }, [fieldValue.district_id]);
  const getPlaceData = async () => {
    const place = await readService(
      `metadata/places/${fieldValue.district_id}`
    );
    setPlaceDrop(place.data);
  };

  useEffect(() => {
    const specialisation = async () => {
      const special = await readService("metadata/specializations");
      console.log("special", special);
      setSpecialDD(special.data);
    };
    specialisation();
    const qualification = async () => {
      const special = await readService("metadata/qualifications");
      console.log("special", special);
      setQualificationDD(special.data);
    };
    qualification();
    const getGenderData = async () => {
      const result = await getGenders();
      setGenderDrop(result.data);
    };
    getGenderData();

  }, []);

  const [allDoctors, setAllDoctors] = useState([]);
  const [allDoctrosForSingle, SetAllDoctrosForSingle] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [genderDrop, setGenderDrop] = useState([]);

  const [doctorId, setDoctorId] = useState(null);
  const inputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [filterDataObj, setFilterDataObj] = useState({});
  const [formatedDateState, setFormatedDateState] = useState("");
  const [passMessage, setPassMessage] = useState(null);
  const [emailMsg, setEmailMsg] = useState(null);
  const [message, setMessage] = useState(null);
  const [charLengthMsg, setCharLengthMsg] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (action === "Add") setSelectedId(null);
    if (userProfile?.userGroup === "mch-admins")
      setSelectedId(userProfile?.works_at);
  }, []);

  const [error, setError] = useState({
    contact_number: false,
    password: false,
    email: false,
    name: false,
    username: false,
  });
  const handleAction = async (actionVal, doctid) => {
    console.log("doctid---->", doctid);
    if (doctid == null) {
      setAction(actionVal);
      return;
    }
    setDoctorId(doctid);
    if ((actionVal == "Edit" || actionVal == "View") && doctid !== null) {
      const singleDoc = await readService("doctors", { id: doctid });
      console.log("singleDoc", singleDoc, doctid);
      const {
        name,
        state_id,
        contact_number,
        place_id,
        district_id,
        email,
        dob,
        gender,
        consult_fee,
        experience,
        qualification,
        specialisation,
        biography,
        username,
        password,
        works_at,
      } = singleDoc?.data?.body[0];
      setSelectedId(singleDoc?.data?.body[0]?.works_at);
      console.log("new Date(dob)", works_at);
      setFieldValue({
        ...fieldValue,
        name,
        state_id,
        contact_number: contact_number,
        place_id,
        district_id,
        email,
        dob: new Date(dob),
        gender,
        consult_fee,
        experience,
        qualification,
        specialisation,
        biography,
        username,
        password,
      });
      // setFields(singleDoc);
    }
    // if (actionVal == "Edit" && doct_id !== null) {
    //   const singleDoc = getSingleDoctor(doct_id);
    //   setFields(singleDoc);
    // }
    setAction(actionVal);
  };
  console.log("fieldVaaaaa", fieldValue);

  const closeModal = () => {
    setShowModal(!showModal);
  };
  const searchHandler = async () => {
    getDoctorsAll(offset, searchText);
  };
  const formReset = () => {
    setFieldValue({
      name: "",
      contact_number: "",
      state_id: "",
      place_id: "",
      district_id: "",
      email: "",
      dob: "",
      gender: "",
      consult_fee: "",
      experience: "",
      qualification: [],
      specialisation: [],
      biography: "",
      image: "",
      password: "",
      username: "",
    });
  };

  const handleSubmit = async (e) => {
    setShowSpinner(true);

    e.preventDefault();

    const formData = new FormData();
    formData.append("image", fieldValue.image);
    formData.append("name", fieldValue.name);
    formData.append("state_id", fieldValue.state_id);
    formData.append("place_id", fieldValue.place_id);
    formData.append("district_id", fieldValue.district_id);
    formData.append("email", fieldValue.email || "");
    formData.append("contact_number", fieldValue.contact_number);
    formData.append("dob", ConvertYMD(fieldValue.dob));

    formData.append("gender", fieldValue.gender);
    formData.append("consult_fee", fieldValue.consult_fee || 0);
    formData.append("experience", fieldValue.experience || 0);
    fieldValue?.specialisation?.forEach((value, index) => {
      formData.append(`specialisation[${index}]`, value);
    });
    fieldValue?.qualification?.forEach((value, index) => {
      formData.append(`qualification[${index}]`, value);
    });
    formData.append("username", fieldValue.username);
    formData.append("password", fieldValue.password);

    formData.append("biography", fieldValue.biography);
    formData.append("works_at", userProfile?.works_at);

    const formDataObject = {};
    for (let pair of formData.entries()) {
      console.log((formDataObject[pair[0]] = pair[1]));
    }
    console.log("formData", formDataObject);
    if (action == "Add") {
      const response = await createService("doctors", formData, {});
      if (response.status < 200 || response.status > 300) {
        let msg = response?.data?.errors?.map(
          (item) => item.msg + " " + item.param
        );
        errorToast(enqueueSnackbar, msg.join(""));
      } else {
        successToast(enqueueSnackbar, "Created Successfully!!");
        formReset();
        setAction(null);
        getDoctorsAll();
      }
    }

    if (action == "Edit" && doctorId !== null) {
      const response = await updateDoctor(doctorId, formData, {});
      if (response.status < 200 || response.status > 300) {
        errorToast(enqueueSnackbar, response.data);
      } else {
        successToast(enqueueSnackbar);
        formReset();
        setAction(null);
      }
    }
    setShowSpinner(false);
  };
  const handleReset = () => {
    formReset();
  };
  const backButtonHandler = () => {
    setAction(null);
    formReset();
    setSelectedId(null);
  };
  console.log("actionValll", action);
  const handleSearchClear = () => {
    setSearchText("");
    getDoctorsAll(0, "");
  };

  useEffect(() => {
    if (fieldValue.email == "") {
      setError((prev) => ({
        ...prev,
        email: false,
      }));
    }
  }, [fieldValue.email, fieldValue.name]);

  const setFieldValueHandler = (e) => {
    const { name, value } = e.target;

    if (name === "contact_number") {
      ContactValidator(setError, setMessage, value);
    }
    if (name === "password") {
      passwordValidator(setError, setPassMessage, value, 8);
    }
    // if (name === "email") {
    //   emailValidator(setError, setEmailMsg, value);
    // }
    if (name === "name") {
      charLengthCheck(setError, setCharLengthMsg, value, 3, "name");
    }
    if (name === "username") {
      charLengthCheck(setError, setCharLengthMsg, value, 3, "username");
    }
    setFieldValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const QuaValueHandler = (e) => {
    let arr = [];
    arr.push(e.target.value);
    setFieldValue((prev) => ({
      ...prev,
      qualification: arr,
    }));
  };

  const SpaValueHandler = (e) => {
    let arr = [];
    arr.push(e.target.value);
    setFieldValue((prev) => ({
      ...prev,
      specialisation: arr,
    }));
  };

  const handleDateChange = (date) => {
    setFieldValue((prevState) => ({
      ...prevState,
      dob: date,
    }));
  };

  useEffect(() => {
    const getDoctorsAll = async () => {
      const allDoctors = await readService("doctors", {});
      SetAllDoctrosForSingle(allDoctors.data);
    };

    getDoctorsAll();
  }, []);

  const getFilterObject = (param) => {
    setFilterDataObj(param);
  };

  useEffect(() => {
    if (searchText == "") {
      getDoctorsAll(0, "", filterDataObj);
    }
  }, [searchText]);

  useEffect(() => {
    getDoctorsAll(0, searchText, filterDataObj);
  }, [rowsPerPageVal, pageSize, filterDataObj]);

  const getDoctorsAll = async (offset = 0, searchText, filterDataObj = {}) => {
    let params = {
      offset: offset,
      limit: rowsPerPageVal,
      ...filterDataObj,
    };
    if (searchText) params = { ...params, name: searchText };
    if (filterDataObj?.state && filterDataObj?.state !== "DEFAULT")
      params = { ...params, state: filterDataObj.state };
    if (filterDataObj?.city && filterDataObj?.city !== "DEFAULT")
      params = { ...params, city: filterDataObj.city };
    const res = await readDoctors(params);
    console.log("res", res);
    setAllDoctors(res.data.body);
    setTotalRecords(res.data.count);
  };

  const handleChangeRowsPerPage = (e) => {
    setPageSize(e?.targte?.value);
    setRowsPerPageVal(parseInt(e.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    console.log("newPage", newPage);
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPageVal * pageIndex;
    getDoctorsAll(offSet, searchText);
    setOffset(offSet);
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  const closeButtonHandler = () => {
    setAction(null);
    formReset();
  };

  const handleDisablebtn = () => {
    // console.log("fieldValue.experience", fieldValue.experience)
    if (
      error.contact_number == true ||
      error.email == true ||
      error.name == true ||
      error.username == true ||
      fieldValue.contact_number == "" ||
      fieldValue.name == "" ||
      !fieldValue.state_id ||
      !fieldValue.district_id ||
      !fieldValue.place_id ||
      //  !fieldValue.email ||
      //  !fieldValue.dob ||
      !fieldValue.gender ||
      //  !fieldValue.consult_fee ||
      //  !fieldValue.experience ||
      //  fieldValue?.specialisation[0]?.length === 0 ||
      //  fieldValue?.qualification[0]?.length === 0 ||
      !fieldValue.username ||
      (action === "Add" && (!fieldValue.password || error.password == true))
    ) {
      return true;
    } else {
    }
  };

  const disableFutureDates = (date) => {
    return date > new Date();
  };

  const closeModalDropDown = () => {
    setShowDropDown(!showDropdown);
  };

  const handleDropdown = () => {
    if (action !== "View" && userProfile?.userGroup !== "mch-admins")
      setShowDropDown(true);
  };

  return (
    <div className="doct-comp">
      {action == "Add" || action == "Edit" || action == "View" ? (
        <form onSubmit={handleSubmit} enctype="multipart/form-data">
          <div
            className="pt-1 mobile-class"
            style={{
              marginLeft: sideNavState ? "calc(100% - 80%)" : "0",
              transition: "0.5s",
              position: "absolute",
              top: "12%",
            }}
          >
            <div className="add-doctor-form">
              {action != "View" && (
                <div className=" px-1">
                  <span
                    className="back-btn"
                    onClick={() => backButtonHandler()}
                  >
                    <img src={backBtnIcon} alt="" />

                    <span>Back</span>
                  </span>
                </div>
              )}

              <div className="form-style px-3 py-3">
                <div className="d-flex flex-row justify-content-between ">
                  <h5>Doctor's Profile</h5>
                  {action === "View" && (
                    <div>
                      <img
                        src={close_icon}
                        alt=""
                        onClick={() => closeButtonHandler()}
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex row justify-content-start">
                  <div className="d-flex flex-row">
                    {/* <div className="col-lg-3 col-sm-12 col-md-6 mr-70">
                      <div
                        className="file-upload square border d-flex flex-column align-items-center justify-content-center"
                        onClick={() => inputRef.current.click()}
                      >
                        <div>
                          <img src={ArrowUp} alt="arrow" />
                          <input
                            ref={inputRef}
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileSelect}
                          />
                        </div>
                        <div className="upload-box d-flex align-items-center justify-content-center">
                          <p>Drop your image here</p>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="d-flex row  justify-content-start"> */}
                    <div className="d-flex row justify-content-start">
                      {/* <div className="d-flex row"> */}
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                      // style={{ width: "42.5%" }}
                      >
                        {console.log(" ==========> fieldValue", fieldValue)}
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            Doctor Name
                            <span className="required-field">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Enter Doctor Name"
                            onChange={setFieldValueHandler}
                            value={fieldValue.name}
                          />
                          {error.name && (
                            <p className="text-danger">{charLengthMsg}</p>
                          )}
                        </Form.Group>
                      </div>
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                      // style={{ width: "42.5%" }}
                      >
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicEmail"
                          Control={false}
                        >
                          <Form.Label>
                            Mobile Number
                            <span className="required-field">*</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="contact_number"
                            placeholder="Enter Mobile Number"
                            onChange={setFieldValueHandler}
                            value={fieldValue.contact_number}
                            className="no-spinners"
                          />
                          {error.contact_number && (
                            <p className="text-danger">{message}</p>
                          )}
                        </Form.Group>
                      </div>
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                      // style={{ width: "42.5%" }}
                      >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            State<span className="required-field">*</span>
                          </Form.Label>
                          <Form.Select
                            onChange={setFieldValueHandler}
                            aria-label="Default select example"
                            name="state_id"
                            value={fieldValue.state_id}
                          >
                            <option selected="selected">Select State</option>
                            {stateDropDown?.length > 0 &&
                              stateDropDown?.map((state, index) => (
                                <option
                                  name="state_id"
                                  value={state.id}
                                  key={index}
                                >
                                  {state.name}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                      // style={{ width: "42.5%" }}
                      >
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>
                            District<span className="required-field">*</span>
                          </Form.Label>
                          <Form.Select
                            onChange={setFieldValueHandler}
                            aria-label="Default select example"
                            name="district_id"
                            value={fieldValue.district_id}
                          >
                            <option selected="selected">Select District</option>
                            {distDrop?.length > 0 &&
                              distDrop?.map((state, index) => (
                                <option value={state.id} key={index}>
                                  {state.name}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                      // style={{ width: "42.5%" }}
                      >
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>
                            City<span className="required-field">*</span>
                          </Form.Label>
                          <Form.Select
                            onChange={setFieldValueHandler}
                            aria-label="Default select example"
                            name="place_id"
                            value={fieldValue.place_id}
                          >
                            <option selected="selected">Select Place</option>
                            {placeDrop?.length > 0 &&
                              placeDrop?.map((state, index) => (
                                <option value={state.id} key={index}>
                                  {state.name}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>

                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                      // style={{ width: "42.5%" }}
                      >
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="emil"
                            name="email"
                            placeholder="Email"
                            onChange={setFieldValueHandler}
                            value={fieldValue.email}
                          />
                          {error.email && (
                            <p className="text-danger">{emailMsg}</p>
                          )}
                        </Form.Group>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="d-flex row justify-content-start">
                  <div className="col-lg-4 col-md-6 col-sm-12 date-input-add-doctor">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Date of Birth</Form.Label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="customDatePicker"
                          wrapperClassName="datepicker"
                          value={fieldValue.dob}
                          onChange={handleDateChange}
                          sx={{ width: "100%" }}
                          shouldDisableDate={disableFutureDates}
                        />
                      </LocalizationProvider>
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Gender<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Select
                        value={fieldValue.gender}
                        name="gender"
                        onChange={setFieldValueHandler}
                        aria-label="select"
                      >
                        <option selected="selected">Select Gender</option>
                        {genderDrop?.length > 0 &&
                          genderDrop?.map(({ gender }, index) => (
                            <option value={gender} key={index}>
                              {gender}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Consultation Fees</Form.Label>
                      <Form.Control
                        name="consult_fee"
                        type="number"
                        placeholder="Consultation Fees"
                        onChange={setFieldValueHandler}
                        value={fieldValue.consult_fee}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Experience</Form.Label>
                      <Form.Control
                        name="experience"
                        type="number"
                        placeholder="Experience"
                        onChange={setFieldValueHandler}
                        value={fieldValue.experience}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Specialisation</Form.Label>
                      <Form.Select
                        value={fieldValue.specialisation}
                        aria-label="Default select example"
                        name="specialisation"
                        onChange={SpaValueHandler}
                      >
                        <option selected="selected">
                          Select Specialisation
                        </option>
                        {specialDD?.length > 0 &&
                          specialDD?.map((state, index) => (
                            <option value={state.specialization} key={index}>
                              {state.specialization}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Qualification</Form.Label>
                      <Form.Select
                        value={fieldValue.qualification}
                        name="qualification"
                        onChange={QuaValueHandler}
                        disabled={action === "View"}
                        aria-label="Default select example"
                      >
                        <option selected="selected">
                          Select Qualification
                        </option>
                        {qualificationDD?.length > 0 &&
                          qualificationDD?.map((state, index) => (
                            <option value={state.degree} key={index}>
                              {state.degree}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {action == "Add" && (
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          User Name <span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          name="username"
                          type="text"
                          placeholder="Username"
                          onChange={setFieldValueHandler}
                          value={fieldValue.username}
                        />
                      </Form.Group>
                      {error.username && (
                        <p className="text-danger">{charLengthMsg}</p>
                      )}
                    </div>
                  )}

                  {action == "Add" && (
                    <div className="col-lg-4 col-md-6 col-sm-12 ">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Password <span className="required-field">*</span>
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={!showPassword ? "password" : "text"}
                            name="password"
                            placeholder="Password"
                            onChange={setFieldValueHandler}
                            value={fieldValue.password}
                          />
                          <div className="search-icon" onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </div>
                        </InputGroup>
                      </Form.Group>
                      {error.password && (
                        <p className="text-danger">{passMessage}</p>
                      )}
                    </div>
                  )}

                  {userProfile.userGroup === "ims-admins" && (
                    <div className="col-lg-4 col-md-6 col-sm-12 ">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="refer-to-mch-label">
                          Select MCH
                        </Form.Label>
                        {/* <div >Refer to MCH</div> */}
                        <div onClick={handleDropdown}>
                          <Form.Select
                            aria-label="select"
                            onChange={(e) => console.log("jkl")}
                            name="id"
                            value={selectedId}
                            disabled={
                              action === "View" &&
                              userProfile?.userGroup !== "mch-admins"
                            }
                            style={{ backgroundColor: "#fff" }}
                          >
                            <option>Select MCH</option>
                            {imsData?.length > 0 &&
                              imsData?.map((item) => (
                                <option
                                  style={{ display: "none" }}
                                  value={item.id}
                                  key={item.id}
                                >
                                  {item.name}
                                </option>
                              ))}
                          </Form.Select>
                        </div>
                      </Form.Group>
                    </div>
                  )}

                  {showDropdown && (
                    <PopupDropdwon
                      isHidden={showDropdown}
                      onClose={closeModalDropDown}
                      data={imsData}
                      setSelectedId={setSelectedId}
                      totalCount={totalCount}
                      setImsData={setImsData}
                      page={popUpPage}
                      pageSize={pageSize}
                      setPage={setPopUpPage}
                      setPageSize={setPageSize}
                      offset={offset}
                      setOffset={setOffset}
                      setTotalCount={setTotalCount}
                    />
                  )}
                  {/* <div className="col-lg-11 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontWeight: 600 }}>
                        <b>Biography</b>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="specialisation"
                        onChange={setFieldValueHandler}
                        value={specialisation}
                      />
                    </Form.Group>
                  </div> */}
                </div>
                {(action == "Add" || action == "Edit") && (
                  <p className="text-danger note-text">
                    <span>
                      <LabelImportantIcon />
                    </span>
                    : Kindly complete all the required fields to submit the
                    form.
                  </p>
                )}
              </div>
              {action !== "View" && (
                <div className="d-flex justify-content-start mt-3 gap-2">
                  {action !== "Edit" && (
                    <Button
                      className="reset-btn-mch ms-3"
                      variant="outline-primary"
                      onClick={handleReset}
                    >
                      <b>Reset</b>
                    </Button>
                  )}

                  <div>
                    <Button
                      className="loginButton reset-btn"
                      variant="primary"
                      type="submit"
                      // onClick={handleClick}

                      disabled={handleDisablebtn()}
                    >
                      {action == "Edit" ? "Update" : "Save"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      ) : (
        <div
          id="healthCamp_container"
          style={{
            width: sideNavState ? "calc(97%)" : "calc(97%)",
            marginLeft: sideNavState ? "calc(100% - 100%)" : "0%",
            transition: "0.5s",
            position: "absolute",
          }}
        >
          <div className="table-style">
            <div
              className="doctor-table-container overflow-auto"
              style={{ margin: "0" }}
            >
              <div
                className="d-flex flex-column  flex-md-row flex-lg-row flex-lg-row justify-content-between align-items-center align-items-md-end mt-4"
                style={{ margin: "0px 2%" }}
              >
                <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
                  <label className="label-input">
                    <input
                      type="text"
                      className="form-control search-input"
                      placeholder="Search by name"
                      onChange={handleSearchInput}
                      value={searchText}
                    />
                    {searchText && (
                      <button
                        className="search-btn-close"
                        onClick={handleSearchClear}
                      >
                        <img src={searchCloseIcon} alt="" width={"24px"} />
                      </button>
                    )}
                    <button className="search-btn" onClick={searchHandler}>
                      <img src={searchIcon} alt="" />
                      <span>Search</span>
                    </button>
                  </label>
                </div>

                <div className="col-5 ms-5">
                  <button
                    className="filter-btn"
                    onClick={() => setShowModal(true)}
                  >
                    <span>Filter</span>
                    <img src={filterIcon} alt="" className="ps-2" />
                  </button>
                </div>

                <div className="text-end">
                  <button className="add-btn" onClick={() => setAction("Add")}>
                    <img src={addIcon} alt="" style={{ marginRight: "10px" }} />
                    Add Doctor
                  </button>
                </div>
              </div>
              <br />

              <table className="doctor-table">
                {allDoctors.length ? (
                  <thead>
                    <tr>
                      <th>Doctor’s ID</th>
                      <th>Doctor’s Name</th>
                      <th>Username</th>
                      <th>State</th>
                      <th>District</th>
                      <th>Place</th>
                      {/* <th>Status</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                ) : (
                  ""
                )}

                <tbody>
                  {allDoctors?.map((item, i) => {
                    return (
                      <tr>
                        <td>{item.id}</td>
                        <td>
                          {/* <img
                            className="doctor-profile-img"
                            src={Avatar}
                            alt="profile_img"
                          />{" "} */}
                          {item.name}
                        </td>
                        <td>{item.username}</td>
                        <td>{item.state}</td>
                        <td>{item.district}</td>
                        <td>{item.place}</td>

                        {/* <td>
                          <img src={StatusIcon} alt="profile_img" />
                        </td> */}
                        <td>
                          <span
                            onClick={() => handleAction("View", item.id)}
                            className="me-1"
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={view}
                              alt=""
                            />
                          </span>
                          <span
                            className="ms-1"
                            onClick={() => handleAction("Edit", item.id)}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={edit}
                              alt=""
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {allDoctors?.length === 0 && (
                <div className="warning-text-card">No Records Found!</div>
              )}
              <div className="flex justify-content-end table-page">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={totalRecords}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPageVal}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>

          {
            <CommonFilterModal
              isHidden={showModal}
              onClose={closeModal}
              comp="doctor"
              getFilterObject={getFilterObject}
              setFilterDataObj={setFilterDataObj}
              filterDataObj={filterDataObj}
            />
          }
        </div>
      )}
      {showSpinner && <Spinner />}
    </div>
  );
};

const Doctors = ({ sideNavState, setSideNavState }) => {
  return (
    <>
      <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
        <DoctorsTabData />
      </Layout>
    </>
  );
};

export default Doctors;
