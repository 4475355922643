import React, { useState } from "react";
import "./Admin.scss";
import Layout from "../LayoutComp/Layout";
import searchIcon from "../../Assets/icons/search_icon.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import addIcon from "../../Assets/icons/addIcon.svg";
import AddButton from "../AddButton/AddButton";
import SearchInput from "../SearchInput/SearchInput";
import CustomTable from "../CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import { TablePagination } from "@mui/material";
import StatusIcon from "../../Assets/icons/Status.svg";
import view from "../../Assets/icons/view.svg";
import edit from "../../Assets/icons/edit.svg";
import Avatar from "../../Assets/Images/avatar.png";

const Admin = (props) => {
  const { sideNavState, setSideNavState } = props;

  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const handleSearchClear = () => {
    setSearchText("");
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  const searchHandler = async () => {
    console.log(" ==========> handle search called");
  };

  const handleAddNewAdmin = () => {
    console.log("pressed add new");
    navigate("/home/admin/add-new");
    // navigate("/admin/add-new");
  };

  const ADMIN_HEADING = [
    "Admin Id",
    "Admin Name",
    "Contact No.",
    "State",
    "City",
    "Status",
    "Action",
  ];

  const SingleRow = (props) => {
    const { data, type } = props;

    return (
      <tr
        // onClick={() => console.log(" ==========> pressed")}
        style={{ cursor: "pointer" }}
      >
        <td>2204</td>
        <td>
          {/* <span>
            <img className="admin-profile-img" src={Avatar} alt="profile_img" />
          </span> */}
          <span>Priyanka Singhal</span>
        </td>
        <td>9191919191</td>
        <td>Maharashtra</td>
        <td>Ahmedabad</td>
        <td>
          <img src={StatusIcon} alt="status" />
        </td>
        <td>
          <span
            onClick={() => console.log(" ==========>pressed ")}
            className="me-1"
          >
            <img style={{ cursor: "pointer" }} src={view} alt="" />
          </span>
          <span
            className="ms-1"
            onClick={() => console.log(" ==========>pressed ")}
          >
            <img style={{ cursor: "pointer" }} src={edit} alt="" />
          </span>
        </td>
      </tr>
    );
  };

  const data1 = [
    { name: "rinku", gender: "Male" },
    { name: "Ravi", gender: "Female" },
    { name: "Ravi", gender: "Female" },
    { name: "Ravi", gender: "Female" },
    { name: "Ravi", gender: "Female" },
    { name: "Ravi", gender: "Female" },
    { name: "Ravi", gender: "Female" },
  ];

  const data2 = [
    { fullName: "rinku Poonia", class: "first", role: "role1" },
    { fullName: "Ravi Poonia", class: "second", role: "role2" },
  ];

  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      <div id="admin" style={{ padding: 20, border: "1px solid #00000029" }}>
        <div className="main-container">
          <SearchInput
            searchText={searchText}
            onChange={handleSearchInput}
            onSearchClear={handleSearchClear}
            onSearchClick={searchHandler}
          />
          <AddButton name="Add Admin" onClick={handleAddNewAdmin} />
        </div>
        <div className="patientdo">
          <CustomTable Row={SingleRow} data={data1} Headings={ADMIN_HEADING} />
          <div className="flex justify-content-end table-page">
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={100}
              page={1}
              onPageChange={() => console.log(" ==========>hello ")}
              rowsPerPage={10}
              onRowsPerPageChange={() => console.log(" ==========> hello")}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Admin;
