import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { readService } from "../../Services/HttpService";
import "./PatientFilterModal.scss";
import { DatePicker } from "@mui/x-date-pickers";

const Select = ({ options, onChange }) => {
  return (
    <select className="select-input" onChange={onChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default function PatientFiterModal({
  isHidden,
  onClose,
  selectOptions = [],
}) {
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  console.log("selectOptions", selectOptions);
  const [state, district, city] = selectOptions;
  console.log("state", state);
  //   useEffect(() => {
  //     getStateList();
  //   }, []);

  const closeFilterModal = () => {
    onClose();
    // setSelectedState("DEFAULT");
    // setSelectedCity("DEFAULT");
    // props.setFilterDataObj({});
  };

  const handleStateChange = (event) => {
    // setSelectedState(event.target.value);
    // setCityList([]);
    // getCityList(event.target.value);
    // setSelectedCity("DEFAULT");
  };

  const handleCityChange = (event) => {
    // setSelectedCity(event.target.value);
  };

  //   const getStateList = async () => {
  //     try {
  //       let params = {
  //         addressCategory: "state",
  //         countryName: "India",
  //       };
  //       let response = await readService("/health/location", params);
  //       setStateList(response.data);
  //     } catch (e) {
  //       console.log("Error while fetching state list", e);
  //     }
  //   };

  //   const getCityList = async (selectedState) => {
  //     try {
  //       let params = {
  //         addressCategory: "city",
  //         countryName: "India",
  //         stateName: selectedState,
  //       };
  //       let response = await readService("/health/location", params);
  //       setCityList(response.data);
  //     } catch (e) {
  //       console.log("Error while fetching city list", e);
  //     }
  //   };

  const applyFilterValues = () => {
    // let filterObj = {};
    // if (selectedState) {
    //   filterObj = { ...filterObj, state: selectedState };
    // }
    // if (selectedCity) {
    //   filterObj = { ...filterObj, city: selectedCity };
    // }
    // if (selectedCity || selectedState) {
    //   props.setFilterDataObj(filterObj);
    //   props.onClose();
    // }
  };

  return (
    <Modal
      dialogClassName="filter-modal"
      backdrop="static"
      show={isHidden}
      onHide={onClose}
      centered
    >
      <Modal.Header className="filter-modal-header">Filter</Modal.Header>
      <Modal.Body>
        <div className="filter-row">
          <p>{state?.state}</p>
          <Select options={state?.opt} onChange={handleStateChange} />
        </div>
        <div className="filter-row">
          <p>{district?.district}</p>
          <Select options={district?.opt} onChange={handleStateChange} />
        </div>
        <div className="filter-row">
          <p>{city?.city}</p>
          <Select options={city?.opt} onChange={handleStateChange} />
        </div>

        <div className="filter-row">
          <p>From Date</p>
          <DatePicker wrapperClassName="datepicker" />
        </div>
        <div className="filter-row">
          <p>To Date</p>
          <DatePicker wrapperClassName="datepicker" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-card">
          <button className="btn-cancel" onClick={closeFilterModal}>
            Cancel
          </button>
          <button className="btn-apply" onClick={applyFilterValues}>
            Apply
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
