import React from "react";
import "./RenderInput.css";
import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const RenderInput = (props) => {
  const {
    value,
    name,
    onChange,
    title,
    placeholder,
    error,
    type,
    preventDefault,
    requiredprop = false,
    toggleShowPassword,
    showPassword,
    fromPassword
  } = props;
  return (
    <Box>
      <Typography className="title-text">
        {title}
        {requiredprop && <span className="required-field">*</span>}
      </Typography>
      <TextField
        {...props}
        value={value}
        onChange={onChange}
        fullWidth
        size="small"
        InputProps={{
          endAdornment: (
            fromPassword && showPassword ?
              <InputAdornment position="end" >
                <IconButton onClick={toggleShowPassword}>
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
              : fromPassword && !showPassword ?
              <InputAdornment position="end" >
                <IconButton onClick={toggleShowPassword}>
                  <VisibilityOffIcon />
                </IconButton>
              </InputAdornment> : ""
          ),
        }}
      />
      {error && <div className="error-text">{error}</div>}
    </Box>
  );
};

export default RenderInput;
