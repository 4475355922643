
import React, { useState, useEffect } from "react";
// import "./Patient.scss";
import searchIcon from "../../../../Assets/icons/search_icon.svg";
import searchCloseIcon from "../../../../Assets/icons/search-close-icon.png";
import filterIcon from "../../../../Assets/icons/filter_icon.svg";
import view from "../../../../Assets/icons/patient-view-blue.svg";
import { useLocation, useNavigate } from "react-router";
import { readService } from "../../../../Services/HttpService";
import Spinner from "../../../Spinner/Spinner";
import { TablePagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPatientId, setPatientInfo } from "../../../Redux/slices/userSlice";
// import HealthCampViewCord from "./HealthCampView";



// const MCHReferredPat = () => {
//   return <TableComp tableData={tableData} isSearchBar={true} />;
// }

// export default MCHReferredPat


const MCHReferredPat = ({sideNavState, campId}) => {
  const [patientDetails, setPatientDetails] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    getPatientDetails(0, searchText);
  }, [pageSize]);

  const searchHandler = async () => {
    getPatientDetails(offset, searchText);
  };

  const getPatientDetails = async (offSet = 0, searchText) => {
    setShowSpinner(true);
    let params = {
      offset: offSet,
      limit: pageSize,
      isMCHReferred: true
    };
    if (searchText) params = { ...params, searchText: searchText };

    const data = await readService(`patients/${campId}`, params);

    if (data) {
      setShowSpinner(false);
      setPatientDetails(data.data.body);
      setTotalRecords(data.data.count);
      setOffset(data.data.offset);
    }
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = pageSize * pageIndex;
    getPatientDetails(offset, searchText);
    setOffset(offset);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchClear = () => {
    setSearchText("");
    getPatientDetails(offset, "");
  };

  const handleViewClick = (id, item) => {
    dispatch(setPatientId(id));
    dispatch(setPatientInfo(item));
    navigate(`/home/doctorsgeneralitp/${id}?healthCampId=${campId}`);
  };
  return (
    <div
      id="patient_container_healthcamp"
    >
      <div className="d-flex flex-column mx-0 mx-md-3  flex-md-row flex-lg-row flex-lg-row justify-content-between align-items-center align-items-md-end mt-4">
        <div style={{marginTop:"20px"}} className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
          <label className="label-input">
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search by name"
              onChange={handleSearchInput}
              value={searchText}
            />
            {searchText && (
              <button className="search-btn-close" onClick={handleSearchClear}>
                <img src={searchCloseIcon} alt="" width={"24px"} />
              </button>
            )}
            <button className="search-btn" onClick={searchHandler}>
              <img src={searchIcon} alt="" />
              <span>Search</span>
            </button>
          </label>
        </div>
        <div className="col-6 ms-4">
          <button className="filter-btn" onClick={() => setShowModal(true)}>
            <span>Filter</span>
            <img src={filterIcon} alt="" className="ps-2" />
          </button>
        </div>
      </div>

      <div>
        <br />
        <table className="healthcamp-table">
          {patientDetails?.data?.patients.length !== 0 && <thead>
            <tr>
              <th>Patient ID</th>
              <th>Patient Name</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Action</th>
            </tr>
          </thead>}
          <tbody>
            {patientDetails?.map((item) => {
              return (
                <tr>
                  <td>{item.patient_id}</td>
                  <td>{item.patient_name}</td>
                  <td>{item.gender}</td>
                  <td>{item.patient_age}</td>
                  <td>
                    <span
                      className="me-1"
                      onClick={() => handleViewClick(item?.patient_id, item)}
                    >
                      <img src={view} alt="" />
                    </span>
                    <span
                      className="ms-1"
                    // onClick={() =>
                    //   navigateToPatientCreateViewEdit(item._id, "Edit", item)
                    // }
                    >
                      <p style={{ color: "#5355D8" }}>view</p>
                      {/* <img src={edit} alt="" /> */}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {patientDetails?.data?.patients.length === 0 && (
          <div className="warning-text-card">No Records Found!</div>
        )}
        <div className="flex justify-content-end patient-table-page">
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {
        // <PatientFiterModal
        //   isHidden={showModal}
        //   onClose={closeModal}
        //   // setFilterDataObj={setFilterDataObj}
        // />
      }
      {showSpinner && <Spinner />}
    </div>
  );
}
export default MCHReferredPat;