import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import RenderSelect from "../../RenderSelect/RenderSelect";

const STATE_OPTIONS = [{ label: "STATE", value: "state" }];

const SingleRow = (props) => {
  const { name, content } = props;

  return (
    <div className="filter-row" style={{ alignItems: "center" }}>
      <p style={{ margin: 0 }}>{name}</p>
      {content}
    </div>
  );
};

const Select = (props) => {
  const {} = props;

  return (
    <select
      className="select-input"
      placeholder="Select Health Camp"
      // defaultValue={"DEFAULT"}
      // onChange={handleStateChange}
      // value={selectedState}
    >
      <option value="DEFAULT">Select Health Camp</option>
      <option value="1">Health Camp one</option>
      <option value="2">Health Camp two</option>
    </select>
  );
};

const HealthFilterModal = () => {
  return (
    <div className="health-filter-modal">
      <SingleRow name="Select State" content={<Select />} />
      <SingleRow name="Select District" content={<Select />} />
      <SingleRow name="Select City" content={<Select />} />
      <SingleRow
        name="From"
        content={<DatePicker wrapperClassName="datepicker" />}
      />
      <SingleRow
        name="To"
        content={<DatePicker wrapperClassName="datepicker" />}
      />
    </div>
  );
};

export default HealthFilterModal;
