import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import closeIcon from '../../Assets/icons/modal-close-icon.svg';
import searchIcon from '../../Assets/icons/search_icon.svg';
import searchCloseIcon from '../../Assets/icons/search-close-icon.png';
import './AssignDoctorModal.scss';
import { readService } from '../../Services/HttpService';
import { TablePagination } from '@mui/material';
import Spinner from '../Spinner/Spinner';
import { getAllDoctorsByName } from '../../Services/Coordinator';

export default function AssignDoctorModal(props) {

    const [doctorList, setDoctorList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [showSpinner, setShowSpinner] = useState(false)
    const [searchValue, setSearchValue] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState('DEFAULT');
    const [offset, setOffset] = useState(0);
    const [selectedDoctorList, setSelectedDoctorList] = useState(props.selectedDoctor);

    /** Fetch all doctors list om every page load */
    useEffect(() => {
        if (searchValue === '')
            getAllDoctors(0);
    }, [searchValue]);

    /** Code to keep selected in sync with both doctor assign modal and 
        create health camp selected doctor chips */
    useEffect(() => {
        setSelectedDoctorList(props.selectedDoctor)
    }, [props.selectedDoctor]);

    /** to close the modal */
    const onCloseClick = () => {
        props.onClose();
        setSearchValue('');
    }

    /** set the search text entered */
    const handleSearchTextEnter = (event) => {
        setSearchValue(event.target.value)
    }

    /** Search button click */
    const onSearchClick = async () => {
        const params = {
            name: searchValue
        }
        try {
            const response = await getAllDoctorsByName(params);
            setDoctorList(response.data.body);
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    }

    /** on search text clear, call default API*/
    const clearSearchValue = () => {
        setSearchValue('');
        getAllDoctors(offset, '');
    }

    /**Pagination page change handle */
    const handleChangePage = (event, newPage) => {
        let pageIndex = newPage;
        setPage(newPage);
        let offset = pageSize * pageIndex;
        getAllDoctors(offset, searchValue)
        setOffset(offset);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    /** Handle select and de-select of doctors */
    const onDoctorCheckBoxClick = (event, item) => {
        let tempDoctorList = [...selectedDoctorList];
        if (event.target.checked) {
            if (tempDoctorList.length === 0) {
                tempDoctorList.push(item)
            } else {
                let findDuplicate = tempDoctorList.filter((ele) => ele.id === item.id)
                if (findDuplicate.length === 0) {
                    tempDoctorList.push(item)
                }
            }
            setSelectedDoctorList(tempDoctorList);
        } else {
            let index = tempDoctorList.findIndex((ele) => ele.id === item.id)
            if (index !== -1) {
                tempDoctorList.splice(index, 1);
                setSelectedDoctorList(tempDoctorList);
            }
        }
    }

    /** Send the selected doctors list to create
        health camp form page */
    const selectedDoctorApply = () => {
        props.setSelectedDoctor(selectedDoctorList);
        
        props.onClose();
    }

    /** Update the table checkbox based on selected doctors list 
       from  health camp form page*/
    const getCheckBoxStatus = (item) => {
        let index = selectedDoctorList.findIndex((ele) => ele.id === item.id)
        if (index !== -1) {
            return true;
        } else return false;
    }

    /**API call to get all doctors list*/
    const getAllDoctors = async (offset = 0, searchValue) => {
        console.log(searchValue)
        try {
            setShowSpinner(true);
            const params = {
                offset: offset,
                limit: pageSize,
                searchText: searchValue
            };
            const response = await readService("/doctors", params);
            setDoctorList(response?.data?.body)
            setTotalRecords(response?.data?.count)
            setShowSpinner(false)
            setOffset(response?.data?.offset)
        } catch (e) {
            console.log('Error while fetching Doctor list', e);
            setShowSpinner(false)
        }
    }

    const handleSelectedDoctor = async (e) => {
        setSelectedDoctor(e.target.value)
            const params = {
                name: e.target.value
            }
            try {
                const response = await getAllDoctorsByName(params);
                setDoctorList(response.data.body);
            } catch (error) {
                console.log('Error fetching data:', error);
            }
    }

    return (
        <Modal size="lg" dialogClassName="assign-modal" backdrop="static" show={props.isHidden} onHide={props.onClose} centered>
            <Modal.Header className="assign-modal-header">
                <img src={closeIcon} alt='' onClick={onCloseClick} />
            </Modal.Header>
            <Modal.Body>
                <div className='row align-items-center'>
                    <div className='col-4'>
                        <select className="select-input" placeholder="Select By Doctor Name" defaultValue={'DEFAULT'} 
                        value={selectedDoctor} onChange={handleSelectedDoctor}>
                            <option disabled selected="selected" value="DEFAULT">Select Doctor By Name</option>
                            {doctorList?.length > 0 && doctorList?.map((doctor, index) => (
                                <option value={doctor.name} key={doctor.id} >{doctor.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='col-6'>
                        <label className='label-input'>
                            <input type='text' className='form-control search-input' placeholder='Search by name'
                                value={searchValue} onChange={handleSearchTextEnter} />
                            {searchValue && <button className='search-btn-close' onClick={clearSearchValue}>
                                <img src={searchCloseIcon} alt='' width={'24px'} />
                            </button>}
                            <button className='search-btn' onClick={onSearchClick}>
                                <img src={searchIcon} alt='' />
                                <span>Search</span>
                            </button>
                        </label>
                    </div>
                    <div className='col-2 text-end'>
                        <button className='apply-btn' onClick={selectedDoctorApply}>Apply</button>
                    </div>
                </div>
                <div className='table-container'>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Doctor ID</th>
                                <th>Doctor Name</th>
                                <th>Qualification</th>
                            </tr>
                        </thead>
                        <tbody>
                            {doctorList && doctorList.map((item, index) => {
                                return (
                                    <tr>
                                        <td><input type='checkbox' onChange={(event) => onDoctorCheckBoxClick(event, item)}
                                            checked={getCheckBoxStatus(item)} /></td>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.qualification}</td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                    <div className="flex justify-content-end table-page">
                        <TablePagination
                            component="div"
                            count={totalRecords}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={pageSize}
                            rowsPerPageOptions={[5, 10, 20, 40]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </Modal.Body>
            {showSpinner && <Spinner />}
        </Modal>
    )
}
