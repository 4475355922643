import React, { useEffect, useState } from "react";
import "./CoPharmacy.scss";

import check from "../../Assets/icons/check.svg";
import { Box, Modal, TablePagination } from "@mui/material";
import addIcon from "../../Assets/icons/addIcon.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import searchIcon from "../../Assets/icons/search_icon.svg";
import close from "../../Assets/icons/close-rounded.svg";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import Layout from "../LayoutComp/Layout";
import { backBtnIcon, filterIcon } from "../../Utils/ImageUtils";
import { readHelthCampCoordinator } from "../../Services/Coordinator";
import {
  ConvertYMD,
  format24HrDateOnly,
  formatTimeHM,
} from "../../Utils/DateUtils";
import { Form } from "react-bootstrap";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  addMedicine,
  deletedMdeicine,
  getPharmacyInventory,
  medicineList,
  updateMedicine,
} from "../../Services/CoPharmacy";
import { useSnackbar } from "notistack";
import { errorToast, successToast } from "../../Utils/toasters";
import { useRef } from "react";
import { getSlno } from "../../Utils/getSlno";
import Spinner from "../Spinner/Spinner";
import { ContactValidator } from "../../Utils/Validators";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  pr: 2,
};
const SearchBarComp = ({
  searchText,
  handleSearchClear,
  handleSearchInput,
}) => {
  return (
    <label className="label-input">
      <input
        type="text"
        className="form-control search-input"
        placeholder="Search by Medicine"
        onChange={handleSearchInput}
        value={searchText}
      />
      {searchText && (
        <button className="search-btn-close" onClick={handleSearchClear}>
          <img src={searchCloseIcon} alt="" width={"24px"} />
        </button>
      )}

      <button
        className="search-btn"
        //    onClick={()=>searchHandler()}
      >
        <img src={searchIcon} alt="" />
        <span>Search</span>
      </button>
    </label>
  );
};

const CoPharmacy = ({ sideNavState, setSideNavState }) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [rowsPerPageVal, setRowsPerPageVal] = useState(5);
  const [pageSize, setPageSize] = useState(5);
  const topRef = useRef(null);
  const bottomRef = useRef(null);

  const [offSet, setOffset] = useState(0);
  const [action, setAction] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [healthCampList, setHealthCampList] = useState([]);
  const [healthCampId, sethealthCampId] = useState(null);
  const [allMedicines, setAllMedicines] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [submitState, setSubmitState] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [healthCampInfo, setHealthCampInfo] = useState({});
  const [passMessage, setPassMessage] = useState("");
  const [message, setMessage] = useState(null);

  const [error, setError] = useState({
    contact_number: false,
    password: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  const [editId, setEditId] = useState(null);
  const [fieldValue, setFieldValue] = useState({
    item_name: "",
    item_qty: "",
    item_type: "",
    expiry_date: "",
    batch_no: "",
    mfd_by: "",
    remarks: "",
    healthcamp_id: "",
  });

  const openModalHandler = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  const handleSearchClear = () => {
    setSearchText("");
    getHealthCampData(0, "");

    // getAllDoctorsDetails(offset, "");
  };
  const searchHandler = () => {
    getHealthCampData(offSet, searchText);
  };
  useEffect(() => {
    getMedicineList(localStorage.getItem("healthcampId"), 0, searchText);
  }, [rowsPerPageVal, pageSize]);
  // useEffect(() => {
  const getMedicineList = async (id, offSet = 0, searchText = "") => {
    let params = {
      offset: offSet,
      limit: rowsPerPageVal,
    };
    if (searchText) params = { ...params, name: searchText };
    try {
      setShowSpinner(true);

      const res = await medicineList(id, params);

      setAllMedicines(getSlno(res?.data?.body, offSet));
      setTotalRecords(res.data.count);
      setShowSpinner(false);
    } catch (err) {
      setShowSpinner(false);
    }
  };

  const editHCHandler = (item) => {
    sethealthCampId(item.id);
    localStorage.setItem("healthcampId", item.id);
    setAction("edit");
    setPage(0);
    setPageSize(5);
    setRowsPerPageVal(5);
    setOffset(0);
    getMedicineList(
      item.id ||
        localStorage.getItem("healthcampId") ||
        localStorage.getItem("healthCampid")
    );
  };

  const handleEditMedicine = (id) => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    setEditId(id);
    const selectedRow = allMedicines.find((val) => val.id == id);
    setSubmitState(false);
    setFieldValue({
      item_name: selectedRow?.item_name ? selectedRow?.item_name : "",
      item_qty: selectedRow?.item_qty ? selectedRow?.item_qty : "",
      item_type: selectedRow?.item_type ? selectedRow?.item_type : "",
      expiry_date: selectedRow?.expiry_date
        ? new Date(selectedRow?.expiry_date)
        : "",
      batch_no: selectedRow?.batch_no ? selectedRow?.batch_no : "",
      mfd_by: selectedRow?.mfd_by ? selectedRow?.mfd_by : "",
      remarks: selectedRow?.remarks ? selectedRow?.remarks : "",
      healthcamp_id: selectedRow?.healthcamp_id
        ? selectedRow?.healthcamp_id
        : "",
    });
  };

  const deleteMedicineHandler = async (item) => {
    try {
      const res = await deletedMdeicine(item.healthcamp_id, item.id);
      if (res.status == 200) {
        successToast(enqueueSnackbar, res.data.message);
        getMedicineList(
          localStorage.getItem("healthcampId"),
          offSet,
          searchText
        );
      }
    } catch (err) {
      errorToast(enqueueSnackbar);
    }
  };
  useEffect(() => {
    getInventory(localStorage.getItem("healthcampId"), 0, searchText);
  }, [rowsPerPageVal, pageSize]);
  const getInventory = async (id, offSet = 0, searchText = "") => {
    let params = {
      offSet: offSet,
      limit: rowsPerPageVal,
    };
    if (searchText) params = { ...params, name: searchText };

    const res = await getPharmacyInventory(id, params);
    setInventories(getSlno(res?.data?.body, offSet));
    setTotalRecords(res.data.count);
  };

  const viewPageHandler = (item) => {
    setAction("view");
    localStorage.setItem("healthcampId", item.id);
    setPage(0);
    setPageSize(5);
    setRowsPerPageVal(5);
    setOffset(0);
    setHealthCampInfo(item);

    getInventory(item.id || localStorage.getItem("healthcampId"));
  };

  useEffect(() => {
    getHealthCampData(0, searchText);
  }, [rowsPerPageVal]);

  const getHealthCampData = async (offset = 0, searchText) => {
    let params = {
      offset: offset,
      limit: rowsPerPageVal,
    };
    if (searchText) params = { ...params, name: searchText };
    const res = await readHelthCampCoordinator(params);
    setHealthCampList(res.data.body);
    setTotalRecords(res.data.count);
  };
  const handleChangeRowsPerPage = (e) => {
    setPageSize(e?.targte?.value);
    setRowsPerPageVal(parseInt(e.target.value, 10));
  };
  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPageVal * pageIndex;
    if (action == null) {
      getHealthCampData(offSet, searchText);
    } else if (action == "view") {
      getInventory(localStorage.getItem("healthcampId"), offSet, searchText);
    } else {
      getMedicineList(localStorage.getItem("healthcampId"), offSet, searchText);
    }
    setOffset(offSet);
  };
  const setFieldValueHandler = (e) => {
    const { name, value } = e.target;
    if (name === "contact_number") {
      ContactValidator(setError, setMessage, value);
    }
    setFieldValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const setDateHandler = (date) => {
    setFieldValue((prevState) => ({
      ...prevState,
      expiry_date: date,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editId != null) {
      try {
        const data = {
          ...fieldValue,
          expiry_date: ConvertYMD(fieldValue.expiry_date),
        };
        const params = {};
        const res = await updateMedicine(
          fieldValue.healthcamp_id,
          editId,
          params,
          data
        );

        if (res.status == 200) {
          successToast(enqueueSnackbar, res.data.message);

          getMedicineList(
            localStorage.getItem("healthcampId"),
            offSet,
            searchText
          );
          setSubmitState(true);
          setFieldValue({
            item_name: "",
            item_qty: "",
            item_type: "",
            expiry_date: "",
            batch_no: "",
            mfd_by: "",
            remarks: "",
          });
          bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
      } catch (err) {
        // errorToast(enqueueSnackbar);
      }
    }

    if (editId == null) {
      try {
        const data = {
          ...fieldValue,
          healthcamp_id: localStorage.getItem("healthcampId"),
          expiry_date: ConvertYMD(fieldValue.expiry_date),
        };
        const res = await addMedicine(data);
        if (res.status == 201) {
          successToast(enqueueSnackbar, res.data.message);
          getMedicineList(localStorage.getItem("healthcampId"), 0, searchText);
          setFieldValue({
            item_name: "",
            item_qty: "",
            item_type: "",
            expiry_date: "",
            batch_no: "",
            mfd_by: "",
            remarks: "",
          });
          bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
      } catch (err) {
        errorToast(enqueueSnackbar);
      }
    }
  };
  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      <div>
        {action == null ? (
          <>
            <div id="copharmacy" className="copharmacy">
              <div className="d-flex flex-column mb-4  flex-md-row flex-lg-row flex-lg-row justify-content-between align-items-center align-items-md-end mt-4">
                <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
                  <label className="label-input">
                    <input
                      type="text"
                      className="form-control search-input"
                      placeholder="Search by name"
                      onChange={handleSearchInput}
                      value={searchText}
                    />
                    {searchText && (
                      <button
                        className="search-btn-close"
                        onClick={handleSearchClear}
                      >
                        <img src={searchCloseIcon} alt="" width={"24px"} />
                      </button>
                    )}
                    <button className="search-btn" onClick={searchHandler}>
                      <img src={searchIcon} alt="" />
                      <span>Search</span>
                    </button>
                  </label>
                </div>

                <div className="col-7">
                  <button
                    className="filter-btn"
                    onClick={() => setShowModal(true)}
                  >
                    <span>Filter</span>
                    <img src={filterIcon} alt="" className="ps-2" />
                  </button>
                </div>

                {/* <div className="text-end">
                  <button className="add-btn" onClick={() => setAction("Add")}>
                    <img src={addIcon} alt="" style={{ marginRight: "10px" }} />
                    Add Doctor
                  </button>
                </div> */}
              </div>
              <div className="dashboard-table-container-pharmacy box overflow-auto ">
                <table className="dashboard-table-pharmacy ">
                  <thead className="thead-cls">
                    <tr>
                      <th>SL No.</th>
                      <th>Health Camp Name</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Time</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {healthCampList.map((item, index) => {
                        return (
                          <tr
                            // onClick={() => navigate("/home/pathologistView")}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{format24HrDateOnly(item.from_date)}</td>
                            <td>{format24HrDateOnly(item.to_date)}</td>
                            <td>
                              <span>{formatTimeHM(item.start_time)}</span>-
                              <span>{formatTimeHM(item.end_time)}</span>
                            </td>
                            <td>
                              <span
                                className="me-2"
                                onClick={() => editHCHandler(item)}
                              >
                                Edit
                              </span>
                              <span onClick={() => viewPageHandler(item)}>
                                view
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-content-end table-page me-5">
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRecords}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPageVal}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </>
        ) : action == "edit" ? (
          <>
            <div className="padding-cls">
              <div
                className="mb-2"
                onClick={() => {
                  setAction(null);

                  getHealthCampData(0, searchText);
                  setPage(0);
                }}
              >
                <img alt="" src={backBtnIcon}></img>
              </div>
              <p className="text-primary medicine-cls mb-2">
                Add HealthCamp Medicine
              </p>

              <div ref={topRef} className="container-cls">
                <form onSubmit={handleSubmit} className="form-cls">
                  <div className="d-flex row justify-content-start">
                    <div className="row d-flex flex-row">
                      <div
                        className="col-lg-3 col-sm-12 col-md-3"
                        // style={{ width: "42.5%" }}
                      >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Enter Medicine Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="item_name"
                            placeholder="Enter Medicine Name"
                            onChange={setFieldValueHandler}
                            value={fieldValue.item_name}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-sm-12 col-md-3">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Quantity</Form.Label>
                          <Form.Control
                            type="number"
                            name="item_qty"
                            placeholder="Enter Quantity"
                            onChange={setFieldValueHandler}
                            value={fieldValue.item_qty}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-sm-12 col-md-3 date-picker-cls">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Expiry Date</Form.Label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              // name="expiry_date"
                              wrapperClassName="datepicker"
                              value={fieldValue.expiry_date}
                              onChange={setDateHandler}
                              className="customDatePicker"
                            />
                          </LocalizationProvider>
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-sm-12 col-md-3">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Batch No.</Form.Label>
                          <Form.Control
                            type="number"
                            name="batch_no"
                            placeholder="Batch No"
                            onChange={setFieldValueHandler}
                            value={fieldValue.batch_no}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-sm-12 col-md-3">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Add Medicine Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={5}
                            name="remarks"
                            placeholder="Add Medicine Description"
                            onChange={setFieldValueHandler}
                            value={fieldValue.remarks}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-sm-12 col-md-3">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Medicine Type</Form.Label>
                          <Form.Control
                            type="text"
                            name="item_type"
                            placeholder="Medicine Type"
                            onChange={setFieldValueHandler}
                            value={fieldValue.item_type}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-sm-12 col-md-3">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Manufactured By</Form.Label>
                          <Form.Control
                            type="text"
                            name="mfd_by"
                            placeholder="Manufactured By"
                            onChange={setFieldValueHandler}
                            value={fieldValue.mfd_by}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary px-5">
                      {submitState ? "Add" : "Update"}
                    </button>
                  </div>
                </form>
                <div id="newclass" className="copharmacy">
                  <div className="d-flex flex-column  flex-md-row flex-lg-row flex-lg-row justify-content-between align-items-center align-items-md-end mt-4">
                    {/* <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
                    <label className="label-input">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search by name"
                        onChange={handleSearchInput}
                        value={searchText}
                      />
                      {searchText && (
                        <button
                          className="search-btn-close"
                          onClick={handleSearchClear}
                        >
                          <img src={searchCloseIcon} alt="" width={"24px"} />
                        </button>
                      )}
                      <button className="search-btn" onClick={searchHandler}>
                        <img src={searchIcon} alt="" />
                        <span>Search</span>
                      </button>
                    </label>
                  </div> */}

                    {/* <div className="col-6">
                    <button
                      className="filter-btn"
                      onClick={() => setShowModal(true)}
                    >
                      <span>Filter</span>
                      <img src={filterIcon} alt="" className="ps-2" />
                    </button>
                  </div>

                  <div className="text-end">
                    <button
                      className="add-btn"
                      onClick={() => setAction("Add")}
                    >
                      <img
                        src={addIcon}
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                      Add Doctor
                    </button>
                  </div> */}
                  </div>
                  <div
                    ref={bottomRef}
                    className="dashboard-table-container-pharmacy-edit box overflow-auto "
                  >
                    <table className="dashboard-table-pharmacy-edit">
                      <thead className="thead-cls">
                        <tr>
                          <th>SL No.</th>
                          <th>Medicine Name</th>
                          <th>Qty</th>
                          <th>Expire Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {allMedicines.map((item, i) => {
                            return (
                              <tr
                                // onClick={() => navigate("/home/pathologistView")}
                                style={{ cursor: "pointer" }}
                              >
                                <td>{item.index}</td>
                                <td>{item?.item_name}</td>
                                <td>{item?.item_qty}</td>
                                <td>{format24HrDateOnly(item?.expiry_date)}</td>
                                <td>
                                  <span
                                    className="me-2"
                                    onClick={
                                      () => handleEditMedicine(item.id)
                                      //  setAction(false);
                                    }
                                  >
                                    Edit
                                  </span>
                                  <span
                                    onClick={() => deleteMedicineHandler(item)}
                                  >
                                    Delete
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex justify-content-end table-page me-5">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25]}
                    count={totalRecords}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPageVal}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </>
        ) : action == "view" ? (
          <>
            <div className="padding-cls">
              <div
                className="mb-2"
                onClick={() => {
                  setAction(null);
                  getHealthCampData(0, searchText);
                  setPage(0);
                }}
              >
                <img alt="" src={backBtnIcon}></img>
              </div>
              <p className="text-primary medicine-cls mb-2">
                Add HealthCamp Medicine
              </p>

              <div ref={topRef} className="container-cls">
                <div className="d-flex flex-row justify-content-between inventory">
                  <div>
                    <p className="date-time">Date</p>
                    <p>
                      {" "}
                      <span>
                        {format24HrDateOnly(healthCampInfo?.from_date)}
                      </span>
                      {"   "}
                      <span>{format24HrDateOnly(healthCampInfo?.to_date)}</span>
                    </p>
                  </div>
                  <p className="health-camp-name">{healthCampInfo.name}</p>
                  <div className="d-flex flex-column text-end">
                    <p className="date-time">Time</p>
                    <p>
                      <span>{formatTimeHM(healthCampInfo.start_time)}</span>-
                      <span>{formatTimeHM(healthCampInfo.end_time)}</span>
                    </p>
                  </div>
                </div>
                <div id="newclass" className="copharmacy">
                  <div className="d-flex flex-column  flex-md-row flex-lg-row flex-lg-row justify-content-between align-items-center align-items-md-end mt-4">
                    {/* <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
                    <label className="label-input">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search by name"
                        onChange={handleSearchInput}
                        value={searchText}
                      />
                      {searchText && (
                        <button
                          className="search-btn-close"
                          onClick={handleSearchClear}
                        >
                          <img src={searchCloseIcon} alt="" width={"24px"} />
                        </button>
                      )}
                      <button className="search-btn" onClick={searchHandler}>
                        <img src={searchIcon} alt="" />
                        <span>Search</span>
                      </button>
                    </label>
                  </div> */}

                    {/* <div className="col-6">
                    <button
                      className="filter-btn"
                      onClick={() => setShowModal(true)}
                    >
                      <span>Filter</span>
                      <img src={filterIcon} alt="" className="ps-2" />
                    </button>
                  </div>

                  <div className="text-end">
                    <button
                      className="add-btn"
                      onClick={() => setAction("Add")}
                    >
                      <img
                        src={addIcon}
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                      Add Doctor
                    </button>
                  </div> */}
                  </div>
                  <div
                    ref={bottomRef}
                    className="dashboard-table-container-pharmacy-edit box overflow-auto "
                  >
                    <table className="dashboard-table-pharmacy-edit">
                      <thead className="thead-cls">
                        <tr>
                          <th>SL No.</th>
                          <th>Medicine Name</th>
                          <th>Alloted</th>
                          <th>Distributed</th>
                          <th>Residue</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {inventories?.map((item, i) => {
                            return (
                              <tr
                                // onClick={() => navigate("/home/pathologistView")}
                                style={{ cursor: "pointer" }}
                              >
                                <td>{item.index}</td>
                                <td>{item?.item_name}</td>
                                <td>{item.total_received}</td>
                                <td>{item?.total_distributed}</td>
                                <td>{item.remaining}</td>
                              </tr>
                            );
                          })}
                        </>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex justify-content-end table-page me-5">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25]}
                    count={totalRecords}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPageVal}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      {/* {<AddMedicineModal open={open} handleClose={handleClose} />} */}
      {showSpinner && <Spinner />}
    </Layout>
  );
};

export default CoPharmacy;
