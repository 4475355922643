import React, { useRef, useState } from "react";
import RegisteredPatient from "../../Assets/icons/Health-camp-icons/RegisteredPatient.svg";
import Nurse from "../../Assets/icons/Health-camp-icons/Nurse.svg";
import Cordinator from "../../Assets/icons/Health-camp-icons/Cordinator.svg";
import Doctor from "../../Assets/icons/Health-camp-icons/Doctor.svg";
import Layout from "../../Components/LayoutComp/Layout";
import "./HealthAndWellness.scss";
import VisitedPatientsChart from "../HeaLthCampCharts/VisitedPatientsChart";
import PatientAdmitted from "../HeaLthCampCharts/PatientAdmitted";
import PatientRegistered from "../HeaLthCampCharts/PatientRegisteredChart";
import HealthCampCard from "../HealthCampCard/HealthCampCard";
import * as XLSX from "xlsx";
import { useLocation, useNavigate } from "react-router-dom";
import addIcon from "../../Assets/icons/addIcon.svg";
import { Container, Row, Col } from "react-bootstrap";
import filterIcon from "../../Assets/icons/filter_icon.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import searchIcon from "../../Assets/icons/search_icon.svg";
import { TablePagination } from "@mui/material";
import downloadIcon from "../../Assets/icons/download-button.svg";
import { Modal } from "react-bootstrap";
import FilterModal from "./FilterModal";
import { readService } from "../../Services/HttpService";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { getGenders } from "../../Services/Metadata/Metadata";
import { formatDateToWeek } from "../../Utils/DateUtils";
import { backBtnIcon } from "../../Utils/ImageUtils";

const cardData = [
  {
    icon: RegisteredPatient,
    bgImg: "RegisteredPatient.svg",
  },
  {
    icon: Doctor,
    bgImg: "Doctor.svg",
  },
  {
    icon: Cordinator,
    bgImg: "Cordinator.svg",
  },
  {
    icon: Nurse,
    bgImg: "Nurse.svg",
  },
];

const data = [
  { id: 1, name: "John Doe", age: 32, qualification: "Degree" },
  { id: 2, name: "Jane Smith", age: 27, qualification: "Degree" },
  { id: 3, name: "Bob Johnson", age: 41, qualification: "Degree" },
];

const columns = [
  { field: "id", header: "Doctors Id" },
  { field: "name", header: "Doctors Name" },
  { field: "qualification", header: "Qualification" },
  { field: "assignFrom", header: "Assign From" },
  { field: "assignTo", header: "Assign To" },
];

const HealthAndWellness = ({ setSideNavState, sideNavState }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const { healthCampId, healthCampList } = location.state;

  const [topArray, setTopArray] = useState([]);

  const loadData = async () => {
    const result = await readService("healthCamps?id=100009");
    const {
      pharmacist_count,
      pathologist_count,
      nurse_count,
      doctor_count,
      dataoperator_count,
      patients_count,
    } = result?.data?.body?.[0];

    const obj = [
      {
        name: "Registered Patients",
        value: patients_count,
        bgImg: "RegisteredPatient.svg",
        icon: RegisteredPatient,
      },
      {
        name: "Doctors",
        value: doctor_count,
        bgImg: "Doctor.svg",
        icon: Doctor,
      },
      // { name: "Co-ordinator", value: dataoperator_count },
      {
        name: "Nurse",
        value: nurse_count,
        bgImg: "Nurse.svg",
        icon: Nurse,
      },
      {
        name: "Data Operator",
        value: dataoperator_count,
        bgImg: "RegisteredPatient.svg",
        icon: RegisteredPatient,
      },
      {
        name: "Pharmacist",
        value: pharmacist_count,
        bgImg: "RegisteredPatient.svg",
        icon: Doctor,
      },
      {
        name: "Pathologist",
        value: pathologist_count,
        bgImg: "RegisteredPatient.svg",
        icon: Nurse,
      },
    ];
    setTopArray(obj);
  };

  useEffect(() => {
    loadData();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  const handleSearchClear = () => {
    setSearchText("");
  };

  const handleHealthCampCard = (index) => {
    if (index === 0) {
      navigate("../patient", { state: { data: data, columns: columns } });
    }
    if (index === 1) {
      navigate("../healthcampview", {
        state: { data: data, columns: columns },
      });
    }
    if (index === 2) {
      navigate("../healthcampview", {
        state: { data: data, columns: columns },
      });
    }
    if (index === 3) {
      navigate("../healthcampview", {
        state: { data: data, columns: columns },
      });
    }
  };

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [allData, setAllData] = useState([]);
  const [gender, setGender] = useState("");
  const [ageLower, setAgeLower] = useState(0);
  const [ageUpper, setAgeUpper] = useState(100);

  const [orderBy, setOrderBy] = useState("DESC");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [genderDrop, setGenderDrop] = useState([]);

  const [csvData, setCsvData] = useState("");

  const csvClick = useRef();

  const [getCSVFileStream, setGetCSVFileStream] = useState(false);

  const getGenderData = async () => {
    const result = await getGenders();
    setGenderDrop(result.data);
  };

  const getAllPatients = async (limit, offset, updateCount) => {
    let paramsObject = {};
    if (gender === "") {
      paramsObject = {};
    } else {
      paramsObject = { gender };
    }

    const url = `patients/${healthCampId}?name=${searchText}&ageLower=${ageLower}&ageUpper=${ageUpper}&limit=${limit}&offset=${offset}&orderBy=${orderBy}&getCSVFileStream=${getCSVFileStream}`;
    const result = await readService(url, paramsObject);
    setAllData(result?.data?.body);
    if (updateCount) {
      setCount(result.data.count);
    }
  };

  const onDownloadPress = async (limit, offset, csvFileStream) => {
    let paramsObject = {};
    if (gender === "") {
      paramsObject = {};
    } else {
      paramsObject = { gender };
    }

    const url = `patients/${healthCampId}?name=${searchText}&ageLower=${ageLower}&ageUpper=${ageUpper}&limit=${limit}&offset=${offset}&orderBy=${orderBy}&getCSVFileStream=${csvFileStream}`;
    const result = await readService(url, paramsObject);

    setCsvData(result?.data);

    csvClick.current.link.click();
  };

  useEffect(() => {
    if (csvData) {
      csvClick.current.link.click();
    }
  }, [csvData]);

  const searchHandler = async () => {
    getAllPatients(rowsPerPage, 0, 1);
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPage * pageIndex;
    getAllPatients(rowsPerPage, offSet, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getAllPatients(event.target.value, 0, 0);
  };

  useEffect(() => {
    console.log(" ==========> inside useEffect called");
    getAllPatients(5, 0, 1);
    setPage(0);
    getGenderData();
  }, [gender, ageLower]);

  const onFilterSubmit = () => {
    getAllPatients(rowsPerPage, 0, 1);
  };

  return (
    <>
      <div className="dashboard">
        <Layout setSideNavState={setSideNavState} sideNavState={sideNavState}>
          <span
            className="back-btn "
            onClick={() => navigate("/home/healthcamp")}
          >
            <img src={backBtnIcon} alt="" />
            <span>Back</span>
          </span>
          <div id="health-and-wellness">
            <div>
              <h1 className="main-heading-health">{healthCampList?.name}</h1>
            </div>
            <div className="d-flex col-lg-12 col-md-6">
              <Container>
                <Row>
                  <Col xs={5}>
                    <span>
                      <span className="venue-text">Venue:</span>
                      <b style={{ fontSize: "18px" }}>
                        {" "}
                        {healthCampList?.venue}
                      </b>
                    </span>
                  </Col>
                  <Col xs={4}>
                    <span>
                      <span className="venue-text">Timings:</span>&nbsp;
                      <b
                        style={{ fontSize: "18px" }}
                      >{`${healthCampList?.start_time} To ${healthCampList?.end_time}`}</b>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={5}>
                    <span>
                      <span className="venue-text">From Date:</span>
                      <b style={{ fontSize: "18px" }}>
                        {" "}
                        {formatDateToWeek(healthCampList?.from_date)}
                      </b>
                    </span>
                  </Col>
                  <Col xs={4}>
                    <span>
                      <span className="venue-text">To Date:</span>&nbsp;
                      <b style={{ fontSize: "18px" }}>
                        {formatDateToWeek(healthCampList?.to_date)}
                      </b>
                    </span>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="row graph-border px-0 py-1">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflow: "scroll",
                }}
              >
                {topArray.map((val, index) => {
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      className="col-md-6 col-lg-3 col-12 mb-md-2 mb-2 mb-lg-0"
                      onClick={() => handleHealthCampCard(index)}
                    >
                      <HealthCampCard
                        bgImg={val?.bgImg}
                        icon={val?.icon}
                        value={val?.value}
                        name={val.name}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {allData?.length ? (
              <div
                style={{
                  boxShadow: "0px 3px 6px #00000029",
                  padding: "40px 25px",
                  margin: "10px 0px",
                }}
              >
                <div className="health-wellness-table-top-container">
                  <div className="health-wellness-table-title">
                    Registered Patient
                  </div>

                  <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
                    <label className="label-input">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search by name"
                        onChange={handleSearchInput}
                        value={searchText}
                      />
                      {searchText && (
                        <button
                          className="search-btn-close"
                          onClick={handleSearchClear}
                        >
                          <img src={searchCloseIcon} alt="" width={"24px"} />
                        </button>
                      )}
                      <button className="search-btn" onClick={searchHandler}>
                        <img src={searchIcon} alt="" />
                        <span>Search</span>
                      </button>
                    </label>
                  </div>

                  <div className="col-6">
                    <button
                      className="filter-btn"
                      onClick={() => setShowModal(true)}
                    >
                      <span>Filter</span>
                      <img src={filterIcon} alt="" className="ps-2" />
                    </button>
                  </div>
                </div>
                <div>
                  <table
                    id="medical-diagnosis-table"
                    className=""
                    style={{ width: "100%", margin: 0 }}
                  >
                    <thead>
                      <tr>
                        <th>Patient ID</th>
                        <th>Patient Name</th>
                        <th>Age</th>
                        <th>Gender</th>
                        <th>Mobile No.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allData?.map((item, index) => {
                        const { id, full_name, age, gender, contact_number } =
                          item;
                        return (
                          <tr key={index}>
                            <td>{id || "-"}</td>
                            <td>{full_name || "-"}</td>
                            <td>{age || "-"}</td>
                            <td>{gender || "-"}</td>
                            <td>{contact_number || "-"}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {allData?.length ? (
                  <div className="flex justify-content-end table-page">
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[5, 10, 25]}
                      count={count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                ) : (
                  <div
                    className="text-center d-flex justify-content-center
        align-items-center"
                    style={{ minHeight: "10rem", fontSize: "24px" }}
                  >
                    <p>No Records Found!</p>
                  </div>
                )}
              </div>
            ) : (
              <div
                className="text-center d-flex justify-content-center
align-items-center"
                style={{ minHeight: "10rem", fontSize: "24px" }}
              >
                <p>No Records Found!</p>
              </div>
            )}

            {csvData ? (
              <CSVLink
                ref={csvClick}
                data={csvData}
                filename={`health-camp-${Date.now()}`}
                className="btn btn-primary"
                target="_blank"
                style={{ display: "none" }}
              />
            ) : null}
            {allData?.length ? (
              <button
                className={`add-btn`}
                onClick={() => {
                  let offSet = rowsPerPage * page;
                  onDownloadPress(rowsPerPage, offSet, true);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 6,
                  }}
                >
                  <div>Download</div>
                  <img src={downloadIcon} alt="" className="ps-2" />
                </div>
              </button>
            ) : null}
          </div>
          {showModal ? (
            <div>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: "#000",
                  zIndex: 10,
                  opacity: 0.7,
                }}
                onClick={() => setShowModal(false)}
              />
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 11,
                  width: "30%",
                }}
              >
                <FilterModal
                  onClose={() => setShowModal(false)}
                  setAgeLower={setAgeLower}
                  setAgeUpper={setAgeUpper}
                  setGender={setGender}
                  genderDrop={genderDrop}
                  onFilterSubmit={onFilterSubmit}
                />
              </div>
            </div>
          ) : null}
        </Layout>
      </div>
    </>
  );
};

export default HealthAndWellness;
