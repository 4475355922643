import React, { useState, useEffect, useRef } from "react";
import Layout from "../../LayoutComp/Layout";
import { useNavigate } from "react-router";
import backBtnIcon from "../../../Assets/icons/back.svg";
import "./UploadReport.scss";
import { useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import UploadIcon from "../../../Assets/icons/upload-icon.svg";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { getMedicalTestsMetadata } from "../../../Services/Metadata/Metadata";
import { uploadLabReport, updateLabReport } from "../../../Services/Pathologist";
import pdfImg from "../../../Assets/Images/prescription-pdf.png";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import { errorToast, successToast } from "../../../Utils/toasters";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";

const UploadReport = ({ sideNavState, setSideNavState }) => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [generalInfo, setGeneralInfo] = useState(false);
  const [page, setPage] = useState(0);
  const [imageFile, setImageFile] = useState();
  const [confirmatiomMsg, setConfirmationMsg] = useState("");
  const { patientId } = useSelector((state) => state.patientId);
  const [medicalTestData, setMedicalTestData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [reportData, setReportData] = useState({});
  const rowData = location.state?.reportData || {};

  useEffect(() => {
    if (location.state && location.state.editMode) {
      setEditMode(true);
    }
  }, [location]);

  useEffect(() => {
    const getMedicalTest = async () => {
      let response = await getMedicalTestsMetadata();
      if (response) {
        setMedicalTestData(response.data);
      }
    };
    getMedicalTest();
    if (rowData) {
      setReportData(rowData);
    } else {
      setReportData({
        report_name: "",
        report_type: "",
        remarks: ""
      })
    }
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const extensions = ["png", "jpeg", "pdf"];
    if (imageFile && !extensions.includes(imageFile.name.split(".")[1].toLowerCase())) {
      errorToast(enqueueSnackbar, "File Should be of type png/jpeg/pdf");
      return;
    }
    const formData = new FormData(e.target);
    formData.delete("report");
    if (imageFile) formData.append("report", imageFile);
    try {
      if (imageFile || (editMode && !imageFile)) {
        var response = {}
        if (!editMode) response = await uploadLabReport(patientId, formData);
        if (editMode) response = await updateLabReport(patientId, reportData?.id, reportData);
        if (response.status < 200 || response.status > 300) {
          errorToast(enqueueSnackbar, response.data);
        } else {
          successToast(enqueueSnackbar, editMode ? "Report Updated Successfully!!" : "Report Uploaded Successfully!!");
          navigate("/home/pathologistView");
          setPage(0);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
    navigateBack();
  };

  const navigateBack = () => {
    navigate("/home/pathologistView");
  };

  const updateOnTextChange = (e) => {
    let data = { ...reportData }
    data[e.target.name] = e.target.value
    setReportData(data)
  }

  return (
    <Layout
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
      hidenav={true}
    >
      {!generalInfo ? (
        <div>
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => navigate("/home/pathologistView")}
              style={{ cursor: "pointer" }}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>

          <div>
            <div id="uploadReportDetails" style={{ margin: "2%" }}>
              <div className="uploadReport">
                <form noValidate onSubmit={handleSubmit}>
                  <div className="d-flex row  align-items-center report-class">
                    <div className="col-lg-3 col-md-6 col-sm-12 text-start ">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label
                          className="font-style-bold"
                          style={{ color: "black" }}
                        >
                          Enter Report Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Report Name"
                          name="report_name"
                          onChange={updateOnTextChange}
                          value={reportData.report_name}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12 text-start">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label
                          className="font-style-bold"
                          style={{ color: "black" }}
                        >
                          Select Report Type
                        </Form.Label>
                        <Form.Select
                          name="report_type"
                          onChange={updateOnTextChange}
                          value={reportData.report_type}
                        >
                          <option value="" key="option">Select Report Type</option>
                          {medicalTestData.map((option) => (
                            <option
                              key={option.value}
                              value={option.test_name}
                            >
                              {option.test_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12 text-start ">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label
                          className="font-style-bold"
                          style={{ color: "black" }}
                        >
                          Enter Remark
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Remark"
                          name="remarks"
                          onChange={updateOnTextChange}
                          value={reportData.remarks}
                        />
                      </Form.Group>
                    </div>
                    <div
                      className="col-lg-3 col-md-3 col-sm-10 text-start"
                      style={{ marginTop: "20px", cursor: "pointer" }}
                      onClick={() => inputRef.current.click()}
                    >
                      { !editMode ? !imageFile && (
                        <>
                          <PrimaryButton
                            type="button"
                            name="Upload Report"
                            img={<img src={UploadIcon} />}
                            onClick={() =>
                              navigate("/home/upload-report-view")
                            }
                          />
                          <input
                            ref={inputRef}
                            type="file"
                            name="report"
                            style={{ display: "none" }}
                            onClick={() => inputRef.current.click()}
                            onChange={handleImageUpload}
                            accept="image/png, image/jpeg, application/pdf"
                          />
                        </>
                      ) : null}
                      {imageFile && (
                        <>
                          <input
                            ref={inputRef}
                            type="file"
                            name="report"
                            style={{ display: "none" }}
                            onClick={() => inputRef.current.click()}
                            onChange={handleImageUpload}
                            accept="image/png, image/jpeg, application/pdf"
                          />
                          <div style={{ width: "50px" }}>
                            {
                              <span style={{ color: "black" }}>{imageFile && imageFile.name}</span>
                            }

                            {imageFile &&
                              imageFile.type === "application/pdf" ? (
                              <img src={pdfImg}></img>
                            ) : (
                              <img
                                src={URL.createObjectURL(imageFile)}
                                width={50}
                                height={50}
                                alt="Cropped"
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 text-start">
                      <div>
                        <Button
                          className="loginButton reset-btn"
                          variant="primary"
                          type="submit"
                        >
                          {editMode ? "Update" : "Submit"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between">
            <div
              className="pre-back-btn-row d-flex justify-content-around align-items-center"
              onClick={() => setGeneralInfo(false)}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>
        </div>
      )}
      <ConfirmationModal
        isHidden={showConfirmationModal}
        onClose={closeConfirmationModal}
        message={confirmatiomMsg}
      />
    </Layout>
  );
};

export default UploadReport;
