import {
  createService,
  createServiceCoordinator,
  readService,
  updateService,
} from "../HttpService";

export const addCordinator = (body) => {
  return createService("coordinators", body);
};
export const updateCoordinator = (body) => {
  return updateService(`coordinators`, body);
};

export const readCordinator = (params) => {
  return readService("coordinators", params);
};
export const readDataOperator = (params) => {
  return readService("dataOperators", params);
};
export const readAllBuses = (params) => {
  return readService("buses", params);
};

export const getCoordinatorById = (params) => {
  return readService("coordinators", params);
};
export const getAllNursesByName = (params) => {
  return readService("nurses", params);
};
export const getAllDoctorsByName = (params) => {
  return readService("doctors", params);
};

export const getCoordinatorByName = (params) => {
  return readService("coordinators", params);
};

//Health camp services
export const readHelthCampCoordinator = (params) => {
  return readService("healthCamps", params);
};
export const getHealthCampById = (params) => {
  return readService("healthCamps", params);
};
export const getAllPharmacistByName = (params) => {
  return readService("pharmacists", params);
};
export const getAllPathologistByName = (params) => {
  return readService("pathologists", params);
};
export const getHealthCampByName = (params) => {
  return readService("healthCamps", params);
};
