import React, { useEffect, useState } from "react";
import close from "../../../../../Assets/icons/prescription-close.svg";
import { useLocation, useParams } from "react-router-dom";
import { readService } from "../../../../../Services/HttpService";
import { format24HrDateOnly } from "../../../../../Utils/DateUtils";
import { Document, Page, pdfjs } from "react-pdf";
import TableComp from "../../../../Doctorsvtwo/Table/TableComp";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const tableData = [
  {
    "Report type": "Prescription",
    "Report titel": "Report Name Here",
    Date: "7th Feb 2023",
    Status: "",
  },
  {
    "Report Name": "Prescription",
    "Report Type": "Report Name Here",
    Date: "7th Feb 2023",
    ActionMedical: "",
  },
  {
    "Report Name": "Prescription",
    "Report Type": "Report Name Here",
    Date: "7th Feb 2023",
    ActionMedical: "",
  },
];
const MedicalReports = ({ patientId }) => {
  const [isAction, setIsAction] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [report, setReport] = useState("");
  const [pdfState, setPdfState] = useState(false);
  const [jpgState, setJpgState] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigateTo = (data) => {
    const selectedRow = reportData.find((val) => (val.id = data));
    setSelectedRows(selectedRow);
    const fileExtension = selectedRow.report.split(".").pop().toLowerCase();
    if (fileExtension.slice(0, 3) == "pdf") {
      setPdfState(true);
      setReport(selectedRow.report);
      setJpgState(false);
    }
    if (
      fileExtension.slice(0, 3) == "png" ||
      fileExtension.slice(0, 4) == "jpeg"
    ) {
      setJpgState(true);
      setReport(selectedRow.report);
      setPdfState(false);
    }

    // setReport(isPngFile);
    setIsAction(false);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const param = useParams();
  useEffect(() => {
    const getLabReport = async () => {
      const params = {
        limit: rowsPerPage,
        offset: offset,
      };
      const response = await readService(
        `patient/labreports/${patientId}`,
        params
      );

      setReportData(response?.data?.body);

      setCount(response?.data?.count);
    };
    getLabReport();
  }, [rowsPerPage, page]);

  const getReqData = reportData?.map((val) => {
    const { report_type, report_name, upload_date, ActionMedical, id } = val;
    return {
      "Report Name": report_name,
      "Report Type": report_type,
      Date: format24HrDateOnly(upload_date),
      ActionMedical,
      id: id,
    };
  });
  return (
    <>
      {isAction ? (
        <TableComp
          isSearchBar={false}
          isFilter={false}
          tableData={getReqData}
          navigateTo={(data) => navigateTo(data)}
          count={count}
          page={page}
          setPage={setPage}
          setRowsPerPage={rowsPerPage}
          setOffset={setOffset}
          rowsPerPage={rowsPerPage}
        />
      ) : (
        <>
          <div
            className=" d-flex justify-content-between p-3 align-items-center mb-3 doctor-info-container"
            style={{ backgroundColor: "#FBFBFF", border: "" }}
          >
            <div className="" style={{ fontSize: "24px", fontWeight: "bold" }}>
              <p>{selectedRows.report_name}</p>
            </div>
            <div className="" style={{ fontSize: "24px", fontWeight: "bold" }}>
              <p>{selectedRows.report_type}</p>
            </div>
            <div style={{ cursor: "pointer" }}>
              <img
                alt=""
                src={close}
                onClick={() => setIsAction(true)}
                style={{ cursor: "pointer" }}
              ></img>
            </div>
          </div>
          <div>
            {pdfState ? (
              <>
                <Document file={report} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={1} />
                </Document>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
              </>
            ) : jpgState ? (
              <>
                <img src={report} alt="" style={{ width: "100%" }}></img>
              </>
            ) : (
              "No Files Found"
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MedicalReports;
