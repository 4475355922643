import React from 'react';
import searchIcon from "../../Assets/icons/search_icon.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import "./Filter.scss";

function Filter(props) {
    const { selectedSearchOption,updateFilter, selectedPlaceholder, handleSearchInput, searchText, handleSearchClear, searchHandler } = props

    const filterList = [
        { id: 1, value: "aadhar", label: "Search By Aadhar Card" },
        { id: 2, value: "name", label: "Search by Name" },
        { id: 3, value: "id", label: "Search By Patient Id" },
        { id: 4, value: "campId", label: "Search By Camp Id" }
    ]

    const selectedFilter = (value) => {
        handleSearchClear()
        let filter = filterList.find((item) => item.value === value)
        updateFilter(value,filter.label)
    }

    return (
        <div id="patient-filter">
            <div className="row">
                <div className="col-4 col-sm-18 mb-4 mb-md-0  col-md-4 col-lg-3">
                    <select
                        className="select-drop"
                        value={selectedSearchOption}
                        onChange={(e) => selectedFilter(e.target.value)}
                    >
                        {
                            filterList.map(item => {
                                return (<option value={item.value}>{item.label}</option>)
                            })
                        }
                    </select>
                </div>
                <div className="col-6 col-sm-18 mb-4 mb-md-0  col-md-8 col-lg-4" >
                    <label className="label-input">
                        <input
                            type="text"
                            className="form-control search-input"
                            placeholder={selectedPlaceholder}
                            onChange={handleSearchInput}
                            value={searchText}
                        />
                        {searchText && (
                            <button
                                className="search-btn-close"
                                onClick={handleSearchClear}
                            >
                                <img src={searchCloseIcon} alt="clearIcon" width={"24px"} />
                            </button>
                        )}
                        <button className="search-btn" onClick={searchHandler}>
                            <img src={searchIcon} alt="searchIcon" />
                            <span>Search</span>
                        </button>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default Filter