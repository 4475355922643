import React from 'react'
import { Outlet } from 'react-router-dom';
import './auth-layout.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function AuthLayout() {
  return (
    <div className='authLayout'>
      <Container className='container'>
        <Row className='containerInnerShell'>
          <Row className="boxShell">
            <Col sm='12' md='12' lg='6' >
              <Row className='formShell'>
                <Outlet />
              </Row>
            </Col>
            <Col lg='6' className="d-none d-lg-block">
              <Row className='healthImageShell'>
              </Row>
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  )
}

export default AuthLayout;
