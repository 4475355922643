// import { Link, useNavigate } from "react-router-dom";
// import generalInfoProfile from "../../../Assets/Images/Profile/profile.svg";
// import ChangePass from "../../../Assets/Images/Profile/change-pass.svg";
// import EmailIcon from "../../../Assets/Images/Profile/emailIcon.svg";
// import phoneIcon from "../../../Assets/Images/Profile/phone.svg";
// import UserEdit from "../../../Assets/Images/Profile/editprofile.svg";
// import ProfilePage from "./ProfilePage";
// import { useState } from "react";
// import ChangePassword from "./ChangePassword";
// import ChangeEmail from "./ChangeEmail";
// import ChangePhNumber from "./ChangePhNumber";
// import { Auth } from 'aws-amplify';

// const ProfileTabs = () => {
//   const navigate = useNavigate();
//   const [activePage, setActivePage] = useState("home");
//   const [navItemSelected, setNavItemSelected] = useState();

//   const navigateToPage = (page) => {
//     setActivePage(page);
//   };
//   const renderPage = () => {
//     switch (activePage) {
//       case "profilePage":
//         return <ProfilePage />;
//       case "changePassword":
//         return <ChangePassword />;
//       case "ChangeEmail":
//         return <ChangeEmail />;
//       case "ChangePhNumber":
//         return <ChangePhNumber />;
//       default:
//         return null;
//     }
//   };

//   const handleLogoutClicked = async () => {
//     try {
//       let response = await Auth.signOut({ global: true });
//       // if (response) {
//       navigate("/login");
//       // }
//     } catch (error) {
//       console.log("error signing out: ", error);
//     }
//   };

//   const profileMenu = [
//     { name: "Profile", url: "profilePage", icon: generalInfoProfile },
//     { name: "Change Password", url: "changePassword", icon: ChangePass },
//     { name: "Sign out", url: '', icon: UserEdit },
//   ]

//   const onSectionSelect = (event, index, url) => {
//     setActivePage(url)
//     setNavItemSelected(index);
//     navigate(url);
//   };

//   return (
//     <div>
//       <nav className="nav">
//         <div>
//           <div className="nav-list active">
//             {profileMenu.map(({ url, name, icon }, index) => (

//               <Link
//                 onClick={(e) => onSectionSelect(e, index, url)}
//                 // className="nav-link "
//                 className={navItemSelected == index ? "nav-link" : ""}
//               >
//                 <img src={icon} className="w-auto"></img>
//                 <span className="nav-link-name">{name}</span>
//               </Link>
//             ))}
//             {/* <Link
//               onClick={() => navigateToPage("changePassword")}
//               className="nav-link "
//             >
//               <img src={ChangePass} className="w-auto"></img>
//               <span className="nav-link-name">Change Password</span>
//             </Link> */}
//             {/* <Link
//               onClick={() => navigateToPage("ChangeEmail")}
//               className="nav-link "
//             >
//               <img src={EmailIcon} className="w-auto"></img>
//               <span className="nav-link-name">Change Email</span>
//             </Link> */}

//             {/* <Link
//               onClick={() => navigateToPage("ChangePhNumber")}
//               className="nav-link"
//             >
//               <img src={phoneIcon} className="w-auto"></img>
//               <span className="nav-link-name">Change Phone</span>
//             </Link> */}
//             {/* <Link className="nav-link" onClick={handleLogoutClicked}>
//               <img src={UserEdit} className="w-auto"></img>
//               <span className="nav-link-name">Sign out</span>
//             </Link> */}
//           </div>
//         </div>
//       </nav>
//       {renderPage()}
//     </div>
//   );
// };

// export default ProfileTabs;


import { Link, useNavigate } from "react-router-dom";
import generalInfoProfile from "../../../Assets/Images/Profile/profile.svg";
import ChangePass from "../../../Assets/Images/Profile/change-pass.svg";
// import EmailIcon from "../../../Assets/Images/Profile/emailIcon.svg";
// import phoneIcon from "../../../Assets/Images/Profile/phone.svg";
import UserEdit from "../../../Assets/Images/Profile/editprofile.svg";
import ProfilePage from "./ProfilePage";
import { useEffect, useState } from "react";
import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";
import ChangePhNumber from "./ChangePhNumber";
import { Auth } from 'aws-amplify';

const ProfileTabs = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState("profilePage");
  const [navItemSelected, setNavItemSelected] = useState(0);

  useEffect(() => {
    setNavItemSelected(0)
  }, [])

  const navigateToPage = (page, index) => {
    if (typeof page === "function") {
      handleLogoutClicked();
    } else {
      setActivePage(page);
      setNavItemSelected(index);
      navigate(page);
    }
  };

  const renderPage = () => {
    switch (activePage) {
      case "profilePage":
        return <ProfilePage />;
      case "changePassword":
        return <ChangePassword />;
      case "changeEmail":
        return <ChangeEmail />;
      case "changePhNumber":
        return <ChangePhNumber />;
      default:
        return null;
    }
  };

  const handleLogoutClicked = async () => {
    try {
      let response = await Auth.signOut({ global: true });
      localStorage.setItem("selectedNav","");

      navigate("/login");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const profileMenu = [
    { name: "Profile", url: "profilePage", icon: generalInfoProfile },
    { name: "Change Password", url: "changePassword", icon: ChangePass },
    // { name: "Change Email", url: "changeEmail", icon: EmailIcon },
    // { name: "Change Phone", url: "changePhNumber", icon: phoneIcon },
    { name: "Sign out", url: handleLogoutClicked, icon: UserEdit },
  ];

  return (
    <div>
      <nav className="nav">
        <div>
          <div className="nav-list actives">
            {profileMenu.map(({ url, name, icon }, index) => (
              <Link
                key={index}
                onClick={() => navigateToPage(url, index)}
                className={navItemSelected === index ? "nav-link active" : "nav-link"}
              >
                <img src={icon} className="w-auto" alt={name} />
                <span className="nav-link-name">{name}</span>
              </Link>
            ))}
          </div>
        </div>
      </nav>
      {renderPage()}
    </div>
  );
};

export default ProfileTabs;

