import React, { useState, useEffect } from 'react'
import './HealthCampPatient.scss'
import searchIcon from '../../Assets/icons/search_icon.svg';
import searchCloseIcon from '../../Assets/icons/search-close-icon.png';
import view from '../../Assets/icons/view.svg';
import { useLocation, useNavigate } from 'react-router';
import { readService } from '../../Services/HttpService';
import Spinner from '../Spinner/Spinner';
import { TablePagination } from "@mui/material";
import backBtnIcon from '../../Assets/icons/back.svg'

export default function HealthCampPatient({ sideNavState, setSideNavState }) {

    const [patientDetails, setPatientDetails] = useState([])
    const [totalRecords, setTotalRecords] = useState(0)
    const [showSpinner, setShowSpinner] = useState(false)
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(0);
    const [searchVal, setSearchVal] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [offset, setOffset] = useState(0);
    const navigate = useNavigate();

    const {state} = useLocation();
    const campId = state.campId;

    useEffect(() => {
        if (searchVal === '')
            getPatientDetails(offset, searchVal)
    }, [searchVal])

    const searchHandler = async () => {
        getPatientDetails(offset, searchVal);
    };

    useEffect(() => {
        getPatientDetails(offset, searchVal)
    }, [])

    useEffect(() => {
        getPatientDetails(0, searchVal)
    }, [rowsPerPage])

    const getPatientDetails = async (offset = 0, searchValue) => {
        setShowSpinner(true)
        let params = {
            offset: offset,
            limit: pageSize,
            campId: campId
        };
        if (searchValue)
            params = { ...params, searchText: searchValue };
        const response =
            await readService("/health/patient", params);
        if (response) {
            setShowSpinner(false)
            setPatientDetails(response)
            setTotalRecords(response.data.count);
            setOffset(response.data.offset)
        }
    }

    const handleChangePage = (event, newPage) => {
        let pageIndex = newPage;
        setPage(newPage);
        let offset = pageSize * pageIndex;
        getPatientDetails(offset, searchVal)
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(event.target.value)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const navigateBack = () => {
        navigate('/home/healthcamp');
    }

    return (
        <div id='healthcamp_patient_container' style={{
            width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
            marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
            transition: "0.5s",
            position: "absolute"
        }}>
            <div className='pt-3 px-3'>
                <span className='back-btn-row' onClick={navigateBack}>
                    <img src={backBtnIcon} alt='' />
                    <span>Back</span>
                </span>
            </div>
            <div className='row mx-3 my-3'>
                <div className='col-4 px-0'>
                    <label className='label-input'>
                        <input
                            type='text'
                            className='form-control search-input'
                            placeholder='Search by name'
                            onChange={(e) => setSearchVal(e.target.value)}
                            value={searchVal}
                        />
                        {searchVal && (
                            <button className="search-btn-close" onClick={() => setSearchVal("")}>
                                <img src={searchCloseIcon} alt="" width={"24px"} />
                            </button>
                        )}
                        <button className='search-btn' onClick={() => searchHandler()}>
                            <img src={searchIcon} alt='' />
                            <span>Search</span>
                        </button>
                    </label>
                </div>
                {/* <div className='col-3'>
                    <button className='filter-btn' 
                     onClick={() => setShowModal(true)}
                    >
                        <span>Filter</span>
                        <img src={filterIcon} alt='' className='ps-2' />
                    </button>
                </div>
                <div className='col-5 text-end'>
                    <button className='add-btn'
                        onClick={navigateToPatientCreateViewEdit}
                    >
                        <img src={addIcon} alt='' style={{ marginRight: '10px' }} />
                        Add New Patient
                    </button>
                </div> */}
            </div>

            <div className='healthCamp-table-container' style={{ margin: "2%" }} >
                <table className='healthcamp-table'>
                    <thead>
                        <tr>
                            <th>Sl. No.</th>
                            <th>Patient Name</th>
                            <th>Gender</th>
                            <th>Mobile No.</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        { patientDetails?.data?.patients &&
                          patientDetails?.data?.patients.map((item, index) => {
                            return (
                                <tr>
                                    <td>{index + offset + 1}</td>
                                    <td>{item.displayName}</td>
                                    <td>{item.gender}</td>
                                    <td>{item.phone}</td>
                                    <td>
                                        <img src={view} alt='' title='View'/>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </table>
                <div className="flex justify-content-end patient-table-page">
                    {patientDetails?.data?.patients.length > 0 ? <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25]}
                        count={totalRecords}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> : <div className='text-center d-flex justify-content-center
                             align-items-center' style={{minHeight: '10rem', fontSize: '24px'}}>
                        <p>No Records Found!</p>
                        </div>}
                </div>
            </div>
            {showSpinner && <Spinner />}
        </div>
    )
}
