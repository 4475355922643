import React, { useState, useEffect } from "react";
import "../View/Prescription/Prescription.scss";
import pdfImg from "../../../Assets/Images/prescription-pdf.png";
import view from "../../../Assets/icons/view.svg";
import { Box } from "@mui/material";
// import close from "../../../Assets/icons/prescription-close.svg"
import backBtnIcon from "../../../Assets/icons/back.svg";
import { readService } from "../../../Services/HttpService";
import { format24HrDateOnly } from "../../../Utils/DateUtils";
import { useSelector } from "react-redux";
import { TablePagination } from "@mui/material";

const Table = ({ type, id, visitId, enqueueSnackbar, errorToast }) => {
  const [viewBtn, setViewBtn] = useState(false);
  const [diagnosisDetails, setDiagnosisDetails] = useState([]);
  const { patientList } = useSelector((state) => state?.patientInfo);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [offset, setOffset] = useState(0);

  const onClickHandler = (item) => {
    setViewBtn(true);
    window.open(item?.prescription);
  };

  useEffect(() => {
    getPatientDiagnosis();
  }, [page, pageSize]);

  const getPatientDiagnosis = async () => {
    const params = {
      limit: pageSize,
      offset: offset,
    };
    try {
      const result = await readService(
        `patient/diagnosis/${id}/${visitId}`,
        params
      );
      setDiagnosisDetails(result?.data?.doctor_prescriptions);
      setOffset(result.offSet);
    } catch (error) {
      errorToast(enqueueSnackbar);
    }
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = pageSize * pageIndex;
    setOffset(offSet);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    getPatientDiagnosis(event.target.value, 0, 0);
  };

  return (
    <div id="Prescription">
      {!viewBtn ? (
        <>
          <div className="table-style">
            <div className="dashboard-table-container overflow-auto pb-3">
              <table className="dashboard-table ">
                <thead>
                  <tr>
                    <th>Prescription</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {diagnosisDetails?.map((item, i) => {
                    return (
                      <tr
                        onClick={() => onClickHandler(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>
                          {type == "medicalReport" ? (
                            "noteImg Check -UP Result"
                          ) : (
                            <>
                              <img className="" src={pdfImg}></img>
                              Prescription
                            </>
                          )}
                        </td>
                        <td>{format24HrDateOnly(item.upload_time)}</td>
                        <td>
                          {" "}
                          <img src={view} alt="" title="View" />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* <div className="flex justify-content-end table-page">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={totalRecords}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ backgroundColor: "#FBFBFF", border: "" }}>
            <div className="back-button-click">
              <span className="back-btns" onClick={() => setViewBtn(false)}>
                <img src={backBtnIcon} alt="" />
                <span>Back</span>
              </span>
            </div>
            <div>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  backgroundColor: "#7C7C7C1A",
                  marginBottom: "15px",
                  border: "1px solid #191BC3",
                }}
              >
                <Box>
                  <b>Patient Name:</b> {patientList?.full_name}
                </Box>
                <Box>
                  <b>Gender:</b> {patientList?.gender}
                </Box>
                <Box>
                  <b>Age:</b> {patientList?.age}
                </Box>
                <Box className={"patient"}>
                  <b>Patient ID:</b> {patientList?.id}
                </Box>
              </Box>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Table;
