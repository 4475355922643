import React, { useEffect, useState } from "react";
import "../View/Prescription/Prescription.scss";
import pdfImg from "../../../../Assets/Images/prescription-pdf.png";
import view from "../../../../Assets/icons/view.svg";
import drImg from "../../../../Assets/Images/drImg.png";
import close from "../../../../Assets/icons/prescription-close.svg";
import { useLocation } from "react-router-dom";
import backBtnIcon from "../../../../Assets/icons/back.svg";
import {
  HealthInfoTab,
  PatientDiagnosis,
} from "../../../../Services/ImsPatient/Patient";
import { getSlno } from "../../../../Utils/getSlno";
import { View, doclose } from "../../../../Utils/ImageUtils";
import { Box, Modal } from "@mui/material";
import { Form } from "react-bootstrap";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  getCategory,
  getOccurrence,
  getOutcome,
  getSeverity,
  getVerificationStatus,
} from "../../../../Services/Metadata/Metadata";
import MedicallAdvice from "./MedicalAdvice";
import LabTests from "./LabTests";
import Pharmacy from "./Pharmacy";

const AddFamilystyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  padding: "30px",
  borderRadius: "10px",
};
const PatientDiagnosisModal = ({ modal, setModal, popUpData }) => {
  const [count, setCount] = useState(1);
  const [severityData, setSeverityData] = useState([]);
  const [category, setCategory] = useState([]);
  const [occurrence, setOccurrence] = useState([]);
  const [verification, setVerification] = useState([]);
  const [outcome, setOutcome] = useState([]);
  const [fieldValue, setFieldValue] = useState({
    issue: "",
    start_date: "",
    end_date: "",
    severity: "",
    category: "",
    occurrence: "",
    verification_status: "",
    outcome: "",
  });
  console.log("popUpData", popUpData);
  useEffect(() => {
    const getSeverityApi = async () => {
      const result = await getSeverity();
      setSeverityData(result?.data);
      console.log("reeeee", result);
    };

    const getCategoryApi = async () => {
      const result = await getCategory();
      setCategory(result?.data);
    };
    const getOccurrenceApi = async () => {
      const result = await getOccurrence();
      setOccurrence(result?.data);
    };
    const getVerificationApi = async () => {
      const result = await getVerificationStatus();
      setVerification(result.data);
    };
    const getoutcomeApi = async () => {
      const result = await getOutcome();
      setOutcome(result.data);
    };
    getSeverityApi();
    getCategoryApi();
    getOccurrenceApi();
    getVerificationApi();
    getoutcomeApi();
  }, []);
  useEffect(() => {
    setFieldValue({
      issue: popUpData.issue,
      severity: popUpData.severity,
      start_date: popUpData.start_date,
      end_date: popUpData.end_date,
      severity: popUpData.severity,
      category: popUpData.category,
      occurrence: popUpData.occurrence,
      verification_status: popUpData.verification_status,
      outcome: popUpData.outcome,
    });
  }, [popUpData]);
  return (
    <Modal
      open={modal}
      onClose={() => {
        setModal(false);
        //  setFamilyMembersData({});
      }}
    >
      <Box sx={AddFamilystyle}>
        <form>
          <div className="text-end">
            <img
              alt=""
              src={doclose}
              onClick={() => {
                //  setFamilyMembersData({});
                setModal(false);
              }}
              style={{ width: "20px", cursor: "pointer" }}
            ></img>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group className="mb-3" co0ntrolId="formBasicEmail">
                <Form.Label>Title of the problem</Form.Label>
                <Form.Control
                  type="text"
                  name="issue"
                  placeholder="Enter Family Member Name"
                  // onChange={setFieldValue}
                  value={fieldValue.issue}
                />
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4 date-pick">
              <Form.Group className="mb-3" co0ntrolId="formBasicEmail">
                <Form.Label>Start Date</Form.Label>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    wrapperClassName="datepicker"
                    value={new Date(fieldValue.start_date)}
                    // onChange={handleDateChange}
                  />
                </LocalizationProvider>
                {/* <DatePicker
                   name="dob"
                   selected={
                     familyMembersData.dob
                       ? new Date(familyMembersData.dob)
                       : null
                   }
                   onChange={handleDateChange}
                   wrapperClassName="datepicker"
                   //  defaultValue={dayjs(familyMembersData.dob)}
                 /> */}
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4 date-pick">
              <Form.Group className="mb-3" co0ntrolId="formBasicEmail">
                <Form.Label>End Date</Form.Label>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    wrapperClassName="datepicker"
                    value={new Date(fieldValue.end_date)}
                    // onChange={handleDateChange}
                  />
                </LocalizationProvider>
                {/* <DatePicker
                   name="dob"
                   selected={
                     familyMembersData.dob
                       ? new Date(familyMembersData.dob)
                       : null
                   }
                   onChange={handleDateChange}
                   wrapperClassName="datepicker"
                   //  defaultValue={dayjs(familyMembersData.dob)}
                 /> */}
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Severity</Form.Label>
                <Form.Select
                  // onChange={setFieldValue}
                  aria-label="Default select example"
                  name="relationship"
                  value={fieldValue?.severity}
                >
                  <option selected="selected">Select Relationship</option>
                  {severityData?.map((item, index) => (
                    <option
                      name="relationship"
                      value={item.severity}
                      key={index}
                    >
                      {item.severity}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group className="mb-3" co0ntrolId="formBasicEmail">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  // onChange={setFieldValue}
                  aria-label="Default select example"
                  name="gender"
                  value={fieldValue.category}
                >
                  <option selected="selected">Select Relationship</option>
                  {category?.map((item, index) => (
                    <option name="gender" value={item.category} key={index}>
                      {item.category}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Occurence</Form.Label>
                <Form.Select
                  // onChange={setFieldValue}
                  aria-label="Default select example"
                  name="addiction"
                  value={fieldValue.occurrence}
                >
                  <option selected="selected">Select Relationship</option>
                  {occurrence?.map((item, index) => (
                    <option
                      name="addiction"
                      value={item.occurrence}
                      key={index}
                    >
                      {item.occurrence}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Verification Status</Form.Label>
                <Form.Select
                  // onChange={setFieldValue}
                  aria-label="Default select example"
                  name="relationship"
                  value={fieldValue.verification_status}
                >
                  <option selected="selected">Select Relationship</option>
                  {verification?.map((item, index) => (
                    <option
                      name="relationship"
                      value={item.verification_status}
                      key={index}
                    >
                      {item.verification_status}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Outcome</Form.Label>
                <Form.Select
                  // onChange={setFieldValue}
                  aria-label="Default select example"
                  name="relationship"
                  value={fieldValue.outcome}
                >
                  <option selected="selected">Select Relationship</option>
                  {outcome?.map((item, index) => (
                    <option
                      name="relationship"
                      value={item.outcome}
                      key={index}
                    >
                      {item.outcome}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

const Table = ({ type, patientId }) => {
  console.log("patientId----->", patientId);
  const [viewBtn, setViewBtn] = useState(false);
  const path = useLocation();
  const [modal, setModal] = useState(false);
  // const [closeModal,setCloseModal]=useState(false);
  const [HealthInfoData, setHealthInfoData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowsPerPageVal, setRowsPerPageVal] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [medicalAdviceData, setMedicalAdviceData] = useState([]);
  const [popUpData, setPopUpData] = useState({});
  const [labTestData, setLabTestData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [labTest, setLabTest] = useState({});
  const [pharmacyTableData, setPharmacyTableData] = useState([]);
  console.log("nameeeeeee", labTestData);

  const closeModal = () => {
    setModal(false);
  };

  const onClickHandler = () => {
    setViewBtn(true);
    // setModal(true)
    console.log("patient Diagnosis Table");
  };
  useEffect(() => {
    //  HealthInfo(23000004, offset);
    HealthInfo(patientId, offset);
  }, [rowsPerPageVal, page]);
  const HealthInfo = async (patientId, offset = 0) => {
    let params = {
      offset: offset,
      limit: rowsPerPageVal,
    };
    const healthInfo = await HealthInfoTab(patientId, params);
    console.log("offsetttttt", offset);
    setHealthInfoData(getSlno(healthInfo?.data.body, offset));
    setTotalRecords(healthInfo.data.count);
    console.log("healtInfo---->", healthInfo);
  };

  const handleChangeRowsPerPage = (e) => {
    console.log("rows", e?.target?.value);
    setPageSize(e?.targte?.value);
    setRowsPerPageVal(parseInt(e.target.value, 10));
    // getDoctorsAll(offSet,searchText)
    //  getHospitals(offSet,searchText)
  };
  const handleChangePage = (event, newPage) => {
    console.log("newPage", newPage);
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPageVal * pageIndex;
    HealthInfo(offSet);
    setOffset(offSet);
  };

  //  useEffect(()=>{
  //   getPatientDiagnosis(23000004,offset)
  //  },[rowsPerPageVal,page])

  //  const patientDaigTable=()=>{
  const patientDaigTable = async (patientId, visitId, offset = 0) => {
    console.log("patidd", patientId, visitId);
    let params = {
      offset: offset,
      limit: rowsPerPageVal,
    };
    const diagdata = await PatientDiagnosis(patientId, visitId, params);
    console.log("diagdata--->", diagdata);
    setDiagnosisData(getSlno(diagdata?.data?.doctor_diagnosis, offset));
    setMedicalAdviceData(diagdata?.data?.doctor_advice);
    setLabTestData(diagdata?.data?.lab_test?.tests);
    setLabTest(diagdata?.data?.lab_test?.remarks);
    setPharmacyTableData(diagdata?.data?.pharmacy);
    setTotalRecords(diagdata.data.count);
  };
  //  }

  return (
    <div id="Prescription">
      {!viewBtn ? (
        <>
          <div
            className="table-style"

            // style={{ padding: "10px", margin: "10px" }}
          >
            <div
              className="dashboard-table-container overflow-auto pb-3"
              style={{ margin: "2%" }}
            >
              {/* <p
                className="pt-3"
                style={{
                  fontSize: "21px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                abc
              </p> */}
              <table className="view-table ">
                <thead>
                  <tr>
                    <th>SLNO</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {HealthInfoData?.map((item, i) => {
                    return (
                      <tr
                        onClick={() => onClickHandler()}
                        style={{ cursor: "pointer" }}
                      >
                        <td>
                          {/* <img className="" src={pdfImg}></img> */}
                          {item?.index}
                        </td>
                        <td>{item?.visit_time}</td>
                        <td>
                          {" "}
                          <img
                            src={View}
                            alt=""
                            title="View"
                            onClick={() =>
                              patientDaigTable(
                                item.patient_id,
                                item.visit_id,
                                0
                              )
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* {doctorList?.data?.doctors?.length === 0 && (
              <div className="warning-text-card">No Records Found!</div>
            )} */}
              <div className="flex justify-content-end table-page">
                {/* <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRecords}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="table-style"

            // style={{ padding: "10px", margin: "10px" }}
          >
            <div
              className="dashboard-table-container overflow-auto pb-3"
              style={{ margin: "2%" }}
            >
              {/* <p
                className="pt-3"
                style={{
                  fontSize: "21px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                abc
              </p> */}
              <table className="view-table ">
                <thead>
                  <tr>
                    <th>SLNO</th>
                    <th>Title of Problem</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {diagnosisData?.map((item, i) => {
                    return (
                      <tr
                        // onClick={() => }
                        style={{ cursor: "pointer" }}
                      >
                        <td>
                          {/* <img className="" src={pdfImg}></img> */}
                          {item?.index}
                        </td>
                        <td>{item?.issue}</td>
                        <td>{item?.start_date}</td>
                        <td>
                          {" "}
                          <img
                            src={View}
                            alt=""
                            title="View"
                            onClick={() => {
                              setModal(true);
                              setPopUpData(item);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* {doctorList?.data?.doctors?.length === 0 && (
              <div className="warning-text-card">No Records Found!</div>
            )} */}
              <div className="flex justify-content-end table-page">
                {/* <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRecords}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
              </div>
            </div>
          </div>{" "}
          <MedicallAdvice
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            medicalAdviceData={medicalAdviceData}
            open={activeTab === 2}
            onTopBarClick={() => {
              if (activeTab === 2) {
                setActiveTab(0);
              } else {
                setActiveTab(2);
              }
            }}
          />
          {console.log(" ==========> labTest ---123", labTest)}
          <LabTests
            open={activeTab === 3}
            labTest={labTest}
            setLabTest={setLabTest}
            labTestData={labTestData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onTopBarClick={() => {
              if (activeTab === 3) {
                setActiveTab(0);
              } else {
                setActiveTab(3);
              }
            }}
          />
          <Pharmacy
            open={activeTab === 4}
            activeTab={activeTab}
            pharmacyTableData={pharmacyTableData}
            setActiveTab={setActiveTab}
            onTopBarClick={() => {
              if (activeTab === 4) {
                setActiveTab(0);
              } else {
                setActiveTab(4);
              }
            }}
          />
          {/* <div className="pt-3 px-3">
            <div className="pre-back-btn-row d-flex justify-content-start align-items-center" onClick={()=>setViewBtn(false)}>
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </div>
          </div>
          <div>
            {path.pathname.split('/')[2] == "healthcenterview" && (
              <>
                <div className="status-recover d-flex justify-content-end align-items-center mb-3">
                  <p className="me-3">status</p>
                  <div className="px-2 py-2">Patient Recovered</div>
                </div>
              </>
            )}
          </div>
          <div
            className=" d-flex justify-content-between p-3 align-items-center mb-3 doctor-info-container"
            style={{ backgroundColor: "#FBFBFF", border: "" }}
          >
            <div className="d-flex">
              <img className="me-3" src={drImg}></img>
              <div className="doctor-info">
                <p>Dr. Prashhant Gowda</p>
                <p>MBBS MD (Heart Specialist)</p>
              </div>
            </div>
            <div style={{ cursor: "pointer" }}>
              <img
                src={close}
                onClick={() => setViewBtn(false)}
                style={{ cursor: "pointer" }}
              ></img>
            </div>
          </div>

          <div
            className="medical-prescription"
            style={{ backgroundColor: "#FBFBFF" }}
          >
            <div className="text-center">
              <p className="mb-2">
                Medical Prescription by HVSM Medical Center
              </p>
              <p>Hosur Road 4th main, Bangaore</p>
            </div>

            <div className="row mt-5">
              <div className="col-6 right-border ps-5">
                <div className="grid-container">
                  <p className="font-style">Patient Name</p>
                  <p></p>
                  <p className="font-style">Patient Age</p>
                  <p></p>
                  <p className="font-style">Date</p>
                  <p></p>
                </div>
              </div>
              <div className="col-6 right-border ps-5">
                <div className="grid-container">
                  <p className="font-style">BP Level</p>
                  <p></p>
                  <p className="font-style">Sugar Level</p>
                  <p></p>
                  <p className="font-style">RBF Level</p>
                  <p></p>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <div className="col-6 right-border ps-5">
                  <div className="grid-container">
                    <p className="font-style">DOLO 650</p>
                    <p>2 times after food</p>
                    <p className="font-style">Pan 40</p>
                    <p>2 times after food</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
      {
        <PatientDiagnosisModal
          modal={modal}
          setModal={setModal}
          closeModal={closeModal}
          popUpData={popUpData}
        />
      }
    </div>
  );
};

export default Table;
