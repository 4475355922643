import React from "react";
import "./HollowButton.css";

const HollowButton = (props) => {
  const { onClick, name, className,type } = props;

  return (
    <button type={type} className={`${className} hollow-btn`} onClick={onClick}>
      {name}
    </button>
  );
};

export default HollowButton;
