import React, { useState, useEffect, useRef } from "react";
import { Button, InputGroup } from "react-bootstrap";
import "./Nurses.scss";
import backBtnIcon from "../../Assets/icons/back.svg";
import close_icon from "../../Assets/icons/close-rounded.svg";
import view from "../../Assets/icons/view.svg";
import edit from "../../Assets/icons/edit.svg";
import searchIcon from "../../Assets/icons/search_icon.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import AvatarImage from "../../Assets/Images/43w.png";
import Form from "react-bootstrap/Form";
import filterIcon from "../../Assets/icons/filter_icon.svg";
import { TablePagination } from "@mui/material";
import { createService, readService } from "../../Services/HttpService";
import Spinner from "../Spinner/Spinner";
import CommonFilterModal from "../CommonFilterModal/CommonFilterModal";
import { useNavigate } from "react-router-dom";
import addIcon from "../../Assets/icons/addIcon.svg";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";

import {
  getAllNurses,
  getDistrictData,
  getPlaceData,
  getStateData,
  readNurses,
  updateNurse,
} from "../../Services/MCH";
import { useSelector } from "react-redux";
import SuccessAlert from "../SuccessAlert/SuccessAlert";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  ConvertYMD,
  format24HrDateOnly,
  formatDate,
} from "../../Utils/DateUtils";
import PopupDropdwon from "../CommonPopupDropdown/PopupDropdown";
import { errorToast, successToast } from "../../Utils/toasters";
import { enqueueSnackbar } from "notistack";
import { ContactValidator, aadharNumber } from "../../Utils/Validators";
import { act } from "@testing-library/react";
import { getGenders } from "../../Services/Metadata/Metadata";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Nurses = ({ sideNavState }) => {
  // const navigate = useNavigate();

  const { works_at } = useSelector((s) => s.user.userProfile);
  const userType = useSelector((s) => s.user.userProfile.userGroup);
  const isMchAdmin = userType === "mch-admins";

  const [action, setAction] = useState(null);
  const [bloodGoup, setBloodGroup] = useState(null);
  const [fieldValue, setFieldValue] = useState({
    name: "",
    contact_number: "",
    state_id: "",
    place_id: "",
    district_id: "",
    blood_group: "",
    // age:"",
    email: "",
    dob: "",
    gender: "",
    doc_number: "",
    doc_type: "",
    // consult_fee: "",
    // experience: "",
    qualification: "",
    username: "",
    password: "",
    // specialization: "",
    // biography: "",
    image: "",
    works_at: "",
    id: "",
  });
  const [stateDropDown, setStateDropDown] = useState([]);

  const [distDrop, setDistDrop] = useState([]);
  const [placeDrop, setPlaceDrop] = useState([]);
  const [specialDD, setSpecialDD] = useState([]);
  const [qualificationDD, setQualificationDD] = useState([]);
  const [rowsPerPageVal, setRowsPerPageVal] = useState(5);
  const [searchText, setSearchText] = useState(null);
  const { userProfile } = useSelector((state) => state.user);

  const [page, setPage] = React.useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  // const [funcCall, setFuncCall] = useState("")
  const [nurseID, setNurseId] = useState("");
  const [doctorList, setDoctorList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [filterDataObj, setFilterDataObj] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [allNurses, setAllNurses] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [imsData, setImsData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [showDropdown, setShowDropDown] = useState(false);
  const [pages, setPages] = useState(0);
  const [pageSizes, setPageSizes] = useState(5);
  const [offsets, setOffsets] = useState(0);
  const [passMessage, setPassMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [aadharmessage, setAadharMessage] = useState(null);
  const [genderDrop, setGenderDrop] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState({
    contact_number: false,
    password: false,
    aadhar: false,
  });

  useEffect(() => {
    setSelectedId(userProfile?.id);
  }, [userProfile]);

  useEffect(() => {
    getState();
  }, []);
  const getState = async () => {
    const states = await getStateData();
    setStateDropDown(states.data);
  };

  useEffect(() => {
    if (fieldValue.state_id) getDistrict();
  }, [fieldValue.state_id]);

  const getDistrict = async () => {
    const district = await getDistrictData(fieldValue.state_id);
    setDistDrop(district?.data);
  };

  useEffect(() => {
    if (fieldValue.district_id) getPlace();
  }, [fieldValue.district_id]);

  const getPlace = async () => {
    const place = await getPlaceData(fieldValue.district_id);
    setPlaceDrop(place.data);
  };

  useEffect(() => {
    const specialization = async () => {
      const special = await readService("metadata/specializations", {});
      setSpecialDD(special.data);
    };
    specialization();
    const qualification = async () => {
      const special = await readService("metadata/qualifications", {});
      setQualificationDD(special.data);
    };

    qualification();
    // /metadata/bloodGroups
    const getBloodGroup = async () => {
      const bloodGrp = await readService("metadata/bloodGroups", {});
      setBloodGroup(bloodGrp.data);
    };
    getBloodGroup();

    const getGenderData = async () => {
      const result = await getGenders();
      setGenderDrop(result.data);
    };
    getGenderData();
  }, []);

  const closeModalFilter = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowDropDown(!showDropdown);
  };

  const searchHandler = async () => {
    getNursesAll(offset, searchText);
  };
  const formReset = () => {
    setFieldValue({
      name: "",
      contact_number: "",
      state_id: "",
      place_id: "",
      district_id: "",
      email: "",
      doc_type: "",
      doc_number: "",
      gender: "",
      blood_group: "",
      qualification: "",
      dob: "",
      biography: "",
      image: "",
      password: "",
      username: "",
    });
  };

  const handleAction = async (actionVal, idData) => {
    if (idData == null) {
      setAction(actionVal);
      return;
    }
    setNurseId(idData);
    if ((actionVal == "Edit" || actionVal == "View") && idData !== null) {
      const singleNur = await readService("Nurses", { id: idData });
      const {
        name,
        state_id,
        contact_number,
        place_id,
        district_id,
        email,
        doc_type,
        doc_number,
        dob,
        gender,
        blood_group,
        // consult_fee,
        // experience,
        qualification,
        // specialization,
        // biography,
        username,
        password,
        id,
        selectedId: works_at,
      } = singleNur?.data?.body[0];

      setFieldValue({
        ...fieldValue,
        name,
        state_id,
        contact_number: contact_number,
        place_id,
        district_id,
        email,
        doc_type,
        doc_number,
        dob: new Date(dob),
        gender,
        blood_group,
        // consult_fee,
        // experience,
        qualification,
        // specialization,
        // biography,
        username,
        password,
        id: id,
      });
    }

    setAction(actionVal);
  };

  const handleSubmit = async (e) => {
    setShowSpinner(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("ims_id", selectedId || "");
    formData.append("blood_group", fieldValue?.blood_group || "");
    formData.append("doc_type", fieldValue?.doc_type || "");
    formData.append("doc_number", fieldValue?.doc_number || "");
    formData.append("image", fieldValue.image || "");
    formData.append("name", fieldValue.name || "");
    formData.append("state_id", fieldValue.state_id || "");
    formData.append("place_id", fieldValue.place_id || "");
    formData.append("district_id", fieldValue.district_id || "");
    formData.append("email", fieldValue.email || "");
    formData.append("contact_number", fieldValue.contact_number || "");

    formData.append("gender", fieldValue.gender || "");

    formData.append("qualification", fieldValue.qualification || "");
    formData.append("username", fieldValue.username || "");
    formData.append("password", fieldValue.password || "");
    if (isMchAdmin) {
      formData.append("works_at", userProfile.works_at || "");
    } else {
      formData.append("works_at", selectedId || "");
    }
    formData.append("dob", ConvertYMD(fieldValue.dob) || "");

    const formDataObject = {};
    for (let pair of formData.entries()) {
      console.log((formDataObject[pair[0]] = pair[1]));
    }
    if (action == "Add") {
      const response = await createService("nurses", formData, {});
      if (response.status < 200 || response.status > 300) {
        let msg = response?.data?.errors?.map((err) => err.msg);
        errorToast(enqueueSnackbar, msg.join(""));
      } else {
        formReset();
        setAction(null);
        snackbarOpen();
        getNursesAll();
        successToast(enqueueSnackbar, response.data.message);

        //  setSuccessMsg(response.data.message);
      }
    }

    if (action == "Edit" && nurseID !== null) {
      const response = await updateNurse(nurseID, {}, formData);

      if (response.status < 200 || response.status > 300) {
        errorToast(enqueueSnackbar, response?.data?.message);
      } else if (response.status === 200) {
        formReset();
        setAction(null);
        snackbarOpen();
        getNursesAll();
        successToast(enqueueSnackbar, response?.data?.message);
      }
    }
    setShowSpinner(false);
  };

  useEffect(() => {
    const getAllNures = async () => {
      const allNurse = await getAllNurses();
    };
    getAllNures();
  }, []);
  useEffect(() => {
    getNursesAll(0, searchText, filterDataObj);
  }, [rowsPerPageVal, filterDataObj]);
  const getNursesAll = async (offset = 0, searchText, filterDataObj = {}) => {
    let params = {
      offset: offset,
      limit: rowsPerPageVal,
      ...filterDataObj,
    };
    if (searchText) params = { ...params, name: searchText };
    if (filterDataObj?.state && filterDataObj?.state !== "DEFAULT")
      params = { ...params, state: filterDataObj.state };

    if (filterDataObj?.city && filterDataObj?.city !== "DEFAULT")
      params = { ...params, city: filterDataObj.city };
    const res = await readNurses(params);
    setAllNurses(res.data.body);
    setTotalRecords(res.data.count);
  };
  const handleChangeRowsPerPage = (e) => {
    setPageSize(e?.targte?.value);
    setRowsPerPageVal(parseInt(e.target.value, 10));
    getNursesAll(0, searchText);
    setOffset(0);
  };
  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPageVal * pageIndex;
    getNursesAll(offSet, searchText);
    setOffset(offSet);
  };
  const backButtonHandler = () => {
    setAction(null);
    formReset();
  };

  const handleSearchClear = () => {
    setSearchText("");
    getNursesAll(offset, "");
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
    //  getNursesAll(offset, searchText);
  };

  const setFieldValueHandler = (e) => {
    const { name, value } = e.target;
    console.log("nameeee", name, value);
    if (value == "Select Blood Group" || value == null) {
      setFieldValue((prevState) => ({
        ...prevState,
        blood_group: "",
      }));
      return;
    }
    if (name === "contact_number") {
      ContactValidator(setError, setMessage, value);
    }
    if (name == "doc_number") {
      aadharNumber(setError, setAadharMessage, value);
    }

    setFieldValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFieldValue((prevState) => ({
      ...prevState,
      dob: date,
    }));
  };

  useEffect(() => {});

  const closeBtnHandler = () => {
    setAction(null);
    formReset();
  };
  const snackbarOpen = () => {
    setSnackOpen(true);
  };

  const getFilterObject = (param) => {
    setFilterDataObj(param);
  };

  useEffect(() => {
    getImsHospitals();
  }, []);

  useEffect(() => {
    if (fieldValue.doc_number == "") {
      setError((prev) => ({
        ...prev,
        aadhar: false,
      }));
    }
  }, [fieldValue.doc_number]);

  const getImsHospitals = async () => {
    const params = {
      limit: pageSize,
      offset: offset,
    };
    const result = await readService("imshospitals", params);
    setImsData(result.data.body);
    setTotalCount(result.data.count);
  };

  const handleDisablebtn = () => {
    if (
      error.contact_number == true ||
      error.aadhar == true ||
      fieldValue.contact_number == "" ||
      fieldValue.name == "" ||
      // !fieldValue.state_id ||
      // !fieldValue.district_id ||
      // !fieldValue.place_id ||
      // !fieldValue.email ||
      // !fieldValue.blood_group ||
      // !fieldValue.dob ||
      !fieldValue.gender ||
      // !fieldValue.doc_number ||
      // !fieldValue?.qualification ||
      !fieldValue.username ||
      (action === "Add" && (!fieldValue.password || !selectedId))
      // || fieldValue.password.length < 8
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleDropDownShow = () => {
    if (userProfile?.userGroup === "ims-admins") {
      setShowDropDown(true);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="nurse-comp">
      {action == "Add" || action == "Edit" || action == "View" ? (
        <form onSubmit={handleSubmit}>
          <div
            className="pt-1 mobile-class"
            style={{
              width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
              marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
              transition: "0.5s",
              position: "absolute",
              overflow: "hidden",
            }}
          >
            <div className="add-doctor-form">
              {action != "View" && (
                <div className=" px-1">
                  <span
                    className="back-btn"
                    onClick={() => backButtonHandler()}
                  >
                    <img src={backBtnIcon} alt="" />

                    <span>Back</span>
                  </span>
                </div>
              )}

              <div className="form-style">
                <div className="d-flex flex-row justify-content-between ">
                  <h5>
                    {action == "Add"
                      ? "Add"
                      : action == "Edit"
                      ? "Edit"
                      : "View"}{" "}
                    Nurse
                  </h5>
                  {action === "View" && (
                    <div>
                      <img
                        src={close_icon}
                        alt=""
                        onClick={() => closeBtnHandler()}
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex row justify-content-start">
                  <div className="d-flex flex-row">
                    {/* <div className="col-lg-3 col-sm-12 col-md-6 mr-70">
                      <div
                        className="img-upload d-flex flex-column align-items-center justify-content-center"
                        onClick={() => inputRef?.current?.click()}
                      >
                        <div>
                          <img src={AvatarImage} />
                          <input
                            ref={inputRef}
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileSelect}
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="d-flex row  justify-content-start"> */}
                    <div className="d-flex row justify-content-start">
                      {/* <div className="d-flex row"> */}
                      <div className="col-lg-4 col-sm-12 col-md-6">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            Name<span className="required-field">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={fieldValue.name}
                            placeholder="Enter Name"
                            onChange={setFieldValueHandler}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-4 col-sm-12 col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Contact Number
                            <span className="required-field">*</span>
                          </Form.Label>
                          <Form.Control
                            name="contact_number"
                            type="number"
                            placeholder="Enter Contact Number"
                            onChange={setFieldValueHandler}
                            value={fieldValue.contact_number}
                            className="no-spinners"
                          />
                          {error.contact_number && (
                            <p className="text-danger">{message}</p>
                          )}
                        </Form.Group>
                      </div>
                      <div className=" col-lg-4 col-sm-12 col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>State</Form.Label>
                          <Form.Select
                            onChange={setFieldValueHandler}
                            aria-label="Default select example"
                            name="state_id"
                            value={fieldValue.state_id}
                          >
                            <option selected="selected">Select State</option>
                            {stateDropDown?.length > 0 &&
                              stateDropDown?.map((state, index) => (
                                <option
                                  name="state_id"
                                  value={state.id}
                                  key={index}
                                >
                                  {state.name}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>District</Form.Label>
                          <Form.Select
                            onChange={setFieldValueHandler}
                            aria-label="Default select example"
                            name="district_id"
                            value={fieldValue.district_id}
                          >
                            <option selected="selected">Select District</option>
                            {distDrop?.length > 0 &&
                              distDrop?.map((state, index) => (
                                <option value={state.id} key={index}>
                                  {state.name}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Place</Form.Label>
                          <Form.Select
                            onChange={setFieldValueHandler}
                            aria-label="Default select example"
                            name="place_id"
                            value={fieldValue.place_id}
                          >
                            <option selected="selected">Select Place</option>
                            {placeDrop?.length > 0 &&
                              placeDrop?.map((place, index) => (
                                <option key={index} value={place.id}>
                                  {place.name}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Blood Group</Form.Label>
                          <Form.Select
                            onChange={setFieldValueHandler}
                            aria-label="Default select example"
                            name="blood_group"
                            value={fieldValue.blood_group}
                          >
                            <option selected="selectd">
                              Select Blood Group
                            </option>
                            {bloodGoup?.length > 0 &&
                              bloodGoup.map((item, index) => {
                                return (
                                  <option
                                    name="blood_group"
                                    value={item.group}
                                    key={index}
                                  >
                                    {item.group}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      {/* <div
                            className="col-lg-5 col-md-6 col-sm-12"
                            style={{ width: "42.5%" }}
                          >
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicName"
                            >
                              <Form.Label>Age</Form.Label>
                              <Form.Control
                                type=""
                                placeholder="qualification"
                                onChange={(e) =>
                                  setQualification(e.target.value)
                                }
                                value={qualification}
                              />
                            </Form.Group>
                          </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="d-flex row justify-content-start">
                  <div className="col-lg-4 col-md-6 col-sm-12 date-input-add-nurse">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Date Of Birth</Form.Label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="customDatePicker"
                          wrapperClassName="datepicker"
                          format="dd/MM/yyyy"
                          value={fieldValue.dob}
                          onChange={handleDateChange}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type=""
                        name="email"
                        placeholder="Email"
                        onChange={setFieldValueHandler}
                        value={fieldValue.email}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Gender<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Select
                        name="gender"
                        value={fieldValue.gender}
                        onChange={setFieldValueHandler}
                        aria-label="select"
                      >
                        <option selected="selected">Select</option>
                        {/* <option value="Male">Male</option>
                        <option value="Female">Female</option> */}
                        {genderDrop?.length > 0 &&
                          genderDrop?.map(({ gender }, index) => (
                            <option value={gender} key={index}>
                              {gender}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Document Type</Form.Label>
                      <Form.Select
                        name="doc_type"
                        value={fieldValue.doc_type}
                        onChange={setFieldValueHandler}
                        aria-label="select"
                      >
                        <option>Select</option>
                        <option value="AADHAR">Aadhar Card</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Document No</Form.Label>
                      <Form.Control
                        type="number"
                        name="doc_number"
                        value={fieldValue.doc_number}
                        placeholder="Document Number"
                        onChange={setFieldValueHandler}
                      />
                    </Form.Group>
                    {error.aadhar && (
                      <p className="text-danger">{aadharmessage}</p>
                    )}
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Qualification</Form.Label>
                      <Form.Select
                        onChange={setFieldValueHandler}
                        aria-label="Default select example"
                        name="qualification"
                        value={fieldValue.qualification}
                      >
                        <option selected="selected">Qualification</option>
                        {qualificationDD?.length > 0 &&
                          qualificationDD.map((item, index) => (
                            <option
                              name="qualification"
                              value={item.degree}
                              key={index}
                            >
                              {item.degree}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {action == "Add" && (
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Username<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type=""
                          name="username"
                          placeholder="Username"
                          onChange={setFieldValueHandler}
                          value={fieldValue.username}
                        />
                      </Form.Group>
                    </div>
                  )}

                  {action == "Add" && (
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Password<span className="required-field">*</span>
                        </Form.Label>
                       
                      </Form.Group> <InputGroup>
                          <Form.Control
                            type={!showPassword ? "password" : "text"}
                            name="password"
                            placeholder="Password"
                            onChange={setFieldValueHandler}
                            value={fieldValue.password}
                          />
                          <div className="search-icon" onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </div>
                        </InputGroup>
                    </div>
                  )}

                  {action == "Add" && !isMchAdmin && (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12"
                      onClick={handleDropDownShow}
                    >
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Select MCH<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="select"
                          onChange={(e) => console.log("jkl")}
                          name="id"
                          disabled={userProfile?.userGroup !== "ims-admins"}
                          value={selectedId}
                          // disabled={viewType}
                          style={{ backgroundColor: "#fff" }}
                        >
                          <option disabled selected="selected" value="select">
                            Select MCH
                          </option> 
                          {imsData.length > 0 &&
                            imsData?.map((item) => (
                              <option
                                style={{ visibility: "hidden" }}
                                value={item.id}
                                key={item.id}
                              >
                                {item.name}
                              </option>   
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                </div>
                {(action == "Add" || action == "Edit") && (
                  <p className="text-danger note-text">
                    <span>
                      <LabelImportantIcon />
                    </span>
                    : Kindly complete all the required fields to submit the
                    form.
                  </p>
                )}
              </div>

              {action !== "View" && (
                <div className="d-flex justify-content-start mt-3 gap-2">
                  {action !== "Edit" && (
                    <Button
                      className="reset-btn-nurse ms-3 px-5"
                      variant="outline-primary"
                      onClick={formReset}
                    >
                      <b>Reset</b>
                    </Button>
                  )}

                  <div>
                    <Button
                      className="loginButton reset-btn"
                      variant="primary"
                      type="submit"
                      // onClick={handleClick}
                      disabled={handleDisablebtn()}
                    >
                      {action == "Edit" ? "Update" : "Save"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      ) : (
        <div
          id="healthCamp_container"
          style={{
            width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
            marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
            transition: "0.5s",
            position: "absolute",
            // overflow: "hidden",
          }}
        >
          <div className="border-box">
            <br />

            <div
              className="d-flex flex-column mx-0 mx-md-3  flex-md-row flex-lg-row flex-lg-row justify-content-between align-items-center align-items-md-end mt-4"
              style={{
                marginBottom: "20px",
                paddingLeft: "35px",
                paddingRight: "35px",
              }}
            >
              <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
                <label className="label-input">
                  <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search by name"
                    onChange={handleSearchInput}
                    value={searchText}
                  />
                  {searchText && (
                    <button
                      className="search-btn-close"
                      onClick={handleSearchClear}
                    >
                      <img src={searchCloseIcon} alt="" width={"24px"} />
                    </button>
                  )}
                  <button className="search-btn" onClick={searchHandler}>
                    <img src={searchIcon} alt="" />
                    <span>Search</span>
                  </button>
                </label>
              </div>

              <div className="col-5">
                <button
                  className="filter-btn"
                  onClick={() => setShowModal(true)}
                >
                  <span>Filter</span>
                  <img src={filterIcon} alt="" className="ps-2" />
                </button>
              </div>

              <div className="text-end">
                <button className="add-btn" onClick={() => setAction("Add")}>
                  <img src={addIcon} alt="" style={{ marginRight: "10px" }} />
                  Add Nurse
                </button>
              </div>
            </div>
            <div style={{ height: "500px", overflow: "auto" }}>
              <div
                className="table-style"
                // style={{ overflow: "scroll", height: "100vh" }}
                // style={{ padding: "10px", margin: "10px" }}
              >
                {/* <div> */}
                <div
                  className="doctor-table-container"
                  style={{
                    margin: "2%",
                    height:
                      rowsPerPageVal >= 10 && rowsPerPageVal < 25
                        ? "850px"
                        : rowsPerPageVal >= 25
                        ? "1450px"
                        : "",
                    // zIndex: "-999",
                  }}
                ></div>
                <table className="doctor-table">
                  {allNurses.length ? (
                    <>
                      <thead
                        style={{
                          position: "sticky",
                          top: "0",
                          background: "white",
                          // zIndex:'-999'
                        }}
                      >
                        <tr>
                          <th>Nurse ID</th>
                          <th>Nurse Name</th>
                          <th>Usename</th>
                          <th>State</th>
                          <th>City</th>
                          {/* <th>Status</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                    </>
                  ) : (
                    ""
                  )}

                  <tbody>
                    {allNurses.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.id}</td>
                          <td>
                            {/* <img
                              className="doctor-profile-img"
                              src={Avatar}
                              alt="profile_img"
                            />{" "} */}
                            {item.name}
                          </td>
                          <td>{item.username}</td>
                          <td>{item.state}</td>
                          <td>{item.place}</td>
                          {/* <td>
                            <img src={StatusIcon} alt="profile_img" />
                          </td> */}
                          <td>
                            <span
                              onClick={() => handleAction("View", item.id)}
                              className="me-1"
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                src={view}
                                alt=""
                              />
                            </span>
                            <span
                              className="ms-1"
                              onClick={() => handleAction("Edit", item.id)}
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                src={edit}
                                alt=""
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {allNurses?.length === 0 && (
                <div className="warning-text-card">No Records Found!</div>
              )}
              <div className="flex justify-content-end table-page">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={totalRecords}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPageVal}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
      {showDropdown && (
        <PopupDropdwon
          isHidden={showDropdown}
          onClose={closeModal}
          data={imsData}
          setSelectedId={setSelectedId}
          totalCount={totalCount}
          setImsData={setImsData}
          page={pages}
          pageSize={pageSizes}
          setPage={setPages}
          setPageSize={setPageSizes}
          offset={offsets}
          setOffset={setOffsets}
          setTotalCount={setTotalCount}
        />
      )}
      {
        <CommonFilterModal
          isHidden={showModal}
          onClose={closeModalFilter}
          setFilterDataObj={setFilterDataObj}
          getFilterObject={getFilterObject}
          filterDataObj={filterDataObj}
          comp="nurse"
        />
      }
      {showSpinner && <Spinner />}
    </div>
  );
};

export default Nurses;
