import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./CommonFilterModal.scss";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  getDistrictsByState,
  getPlacesByDistrict,
  getStates,
} from "../../Services/Metadata/Metadata";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ConvertYMD } from "../../Utils/DateUtils";

export default function CommonFilterModal(props) {
  const { getFilterObject, hospitals } = props;
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [stateList, setStateList] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [placesData, setPlacesData] = useState([]);
  const [districtId, setDestrictId] = useState("");
  const [selectedMCH, setSelectedMCH] = useState("");
  const [date, setDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    getStateList();
    setSelectedState("DEFAULT");
    setDestrictId("DEFAULT");
    setSelectedCity("DEFAULT");
    setSelectedMCH("DEFAULT");
  }, [getFilterObject]);

  // console.log("distrcit data is=================", hospitals)

  const closeFilterModal = () => {
    setSelectedState("DEFAULT");
    setSelectedCity("DEFAULT");
    setDestrictId("DEFAULT");
    setSelectedMCH("DEFAULT");
    setDate("");
    setToDate("");
    props.onClose();
  };

  // console.log("select is===============================", props.comp)
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const getStateList = async () => {
    try {
      let response = await getStates();
      setStateList(response.data);
    } catch (e) {
      console.log("Error while fetching state list", e);
    }
  };

  const handleDistrict = async (e) => {
    setDestrictId(e.target.value);
    let places = await getPlacesByDistrict(e.target.value);
    setPlacesData(places.data);
  };

  const handleState = async (e) => {
    setSelectedState(e.target.value);
    let district = await getDistrictsByState(e.target.value);
    setDistrictData(district.data);
  };
  const getSelectedHospitals = async (e) => {
    setSelectedMCH(e.target.value);
  };

  const applyFilterValues = () => {
    let filterObj = {};
    if (selectedState) {
      filterObj = { ...filterObj, state_id: selectedState };
    }
    if (districtId) {
      filterObj = { ...filterObj, district_id: districtId };
    }
    if (selectedCity) {
      filterObj = { ...filterObj, place_id: selectedCity };
    }
    if (date) {
      filterObj = { ...filterObj, from_date: ConvertYMD(date) };
    }
    if (toDate) {
      filterObj = { ...filterObj, to_date: ConvertYMD(toDate) };
    }
    if (selectedMCH && props.comp === "Cordinator") {
      filterObj = { ...filterObj, works_at: selectedMCH };
    }
    if (selectedState || districtId || selectedCity || date || toDate) {
      getFilterObject(filterObj);
      setSelectedState("DEFAULT");
      setSelectedCity("DEFAULT");
      setDestrictId("DEFAULT");
      setSelectedMCH("DEFAULT");
      setDate("");
      setToDate("");
      props.onClose();
    }
  };

  const handleToDate = (date) => {
    setToDate(date);
  };
  const handleFromDate = (date) => {
    setDate(date);
  };

  return (
    <Modal
      dialogClassName="filter-modal"
      backdrop="static"
      show={props.isHidden}
      onHide={props.onClose}
      centered
    >
      <Modal.Header className="filter-modal-header">Filter</Modal.Header>
      <Modal.Body>
        {props.comp === "Cordinator" && (
          <div className="filter-row">
            <p>Select MCH</p>
            <select
              className="select-input"
              placeholder="Select state"
              defaultValue={"DEFAULT"}
              onChange={getSelectedHospitals}
              value={selectedMCH}
            >
              <option value="DEFAULT" disabled>
                Select MCH
              </option>
              {hospitals?.length > 0 &&
                hospitals?.map((hospital, index) => (
                  <option value={hospital.id} key={hospital.id}>
                    {hospital.name}
                  </option>
                ))}
            </select>
          </div>
        )}
        <div className="filter-row">
          <p>Select State</p>
          <select
            className="select-input"
            placeholder="Select state"
            defaultValue={"DEFAULT"}
            onChange={handleState}
            value={selectedState}
          >
            <option value="DEFAULT" disabled>
              Select state
            </option>
            {stateList &&
              stateList.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
        </div>
        {props.comp == "HalthCamp" ||
        props.comp == "mch" ||
        props.comp == "nurse" ||
        props.comp == "doctor" ||
        props.comp == "pharmacyims" ? (
          <div className="filter-row">
            <p>Select District</p>
            <select
              className="select-input"
              placeholder="Select District"
              defaultValue={"DEFAULT"}
              onChange={handleDistrict}
              value={districtId}
            >
              <option value="DEFAULT" disabled>
                Select District
              </option>
              {districtData.length > 0 &&
                districtData.map((district, index) => (
                  <option value={district.id} key={index}>
                    {district.name}
                  </option>
                ))}
            </select>
          </div>
        ) : (
          ""
        )}
        {props.comp === "Cordinator" && (
          <div className="filter-row">
            <p>Select District</p>
            <select
              className="select-input"
              placeholder="Select District"
              defaultValue={"DEFAULT"}
              onChange={handleDistrict}
              value={districtId}
            >
              <option value="DEFAULT" disabled>
                Select District
              </option>
              {districtData.length > 0 &&
                districtData.map((district, index) => (
                  <option value={district.id} key={index}>
                    {district.name}
                  </option>
                ))}
            </select>
          </div>
        )}

        <div className="filter-row">
          <p>Select Place</p>
          <select
            className="select-input"
            placeholder="Select City"
            defaultValue={"DEFAULT"}
            onChange={handleCityChange}
            value={selectedCity}
          >
            <option value="DEFAULT" disabled>
              Select Place
            </option>
            {placesData &&
              placesData.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
        </div>
        {props.comp == "HalthCamp" ||
          (props.comp == "pharmacyims" && (
            <div className="filter-row">
              <p>From Date</p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  wrapperClassName="datepicker"
                  value={date}
                  onChange={handleFromDate}
                />
              </LocalizationProvider>
            </div>
          ))}
        {props.comp == "HalthCamp" ||
          props.comp == "pharmacyims" && (
            <div className="filter-row">
              <p>To Date</p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  wrapperClassName="datepicker"
                  value={toDate}
                  onChange={handleToDate}
                />
              </LocalizationProvider>
            </div>
          )}
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-card">
          <button className="btn-cancel" onClick={closeFilterModal}>
            Cancel
          </button>
          <button className="btn-apply" onClick={applyFilterValues}>
            Apply
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
