import React, { useEffect, useState } from "react";
import TableComp from "../../NurseTableComp/TableComp";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@mui/material";
import "./NurseHealthCamp.scss";
import searchCloseIcon from "../../../../Assets/icons/search-close-icon.png";
import searchIcon from "../../../../Assets/icons/search_icon.svg";
import whitEye from "../../../../Assets/icons/view-white.svg";
import { setPatientInfo } from "../../../Redux/slices/userSlice";
import { patientHealthCampList } from "../../../../Services/AddPatient/AddPatient";
import Filter from "../../../Filter/Filter";

const thHeadData = [
  "Sl No",
  "Patient Name",
  "Gender",
  "Mobile No",
  "ActionNurseGiView",
];

const SearchBarComp = ({
  searchText,
  handleSearchClear,
  handleSearchInput,
  handleSearchClick,
}) => {
  return (
    <label className="label-input">
      <input
        type="text"
        className="form-control search-input"
        placeholder="Search by name"
        onChange={handleSearchInput}
        value={searchText}
      />
      {searchText && (
        <button
          className="search-btn-close"
          onClick={handleSearchClear}
          style={{
            border: "none",
            position: "absolute",
            right: 90,
            margin: "auto",
            top: 4,
          }}
        >
          <img src={searchCloseIcon} alt="" width={"24px"} />
        </button>
      )}
      <button className="search-btn" onClick={handleSearchClick}>
        <img src={searchIcon} alt="" />
        <span>Search</span>
      </button>
    </label>
  );
};

const NurseHealthCamp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [allData, setAllData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  let location = useLocation();
  const { patientId } = useSelector((state) => state.patientId);
  const [patientInfoData, setPatientInfoData] = useState([]);
  const [selectedSearchOption, setSelectedSearchOption] = useState("name");
  const [selectedPlaceholder, setPlaceholder] = useState("Search by Name");

  useEffect(() => {
    dispatch(setPatientInfo(patientInfoData));
  }, []);

  const getPatientDetails = async (newPage, newPageSize, newSearchText) => {
    const offset = newPage * newPageSize;
    let params = {
      limit: newPageSize,
      offset: offset,
    };
    if (newSearchText) params = { ...params, [selectedSearchOption]: newSearchText };
    let patient = await patientHealthCampList(patientId, params);
    if (patient) {
      setCount(patient.data.count);
      setAllData(patient.data.body);
      setPage(newPage);
      setPageSize(newPageSize);
    }
  };

  const handleSearchClear = () => {
    setSearchText("");
    getPatientDetails(0, pageSize, "");
  };

  const searchHandler = () => {
    getPatientDetails(0, pageSize, searchText);
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    getPatientDetails(newPage, pageSize, searchText);
  };

  const handleChangeRowsPerPage = (event) => {
    getPatientDetails(0, parseInt(event.target.value, 10), searchText);
  };

  const updateFilter = (value, label) => {
    handleSearchClear();
    setSelectedSearchOption(value);
    if (label) setPlaceholder(label);
  };

  const extractedData = patientInfoData?.map((obj, i) => {
    let index = i + 1;
    let ActionNurseGiView = thHeadData[4];
    const { id, first_name, gender, contact_number } = obj;
    return { id, first_name, gender, contact_number, ActionNurseGiView };
  });

  useEffect(() => {
    getPatientDetails(0, pageSize, searchText);
  }, []);

  return (
    <>
      {false ? (
        <TableComp
          placeHolder="Search By Nurse Name"
          tableData={extractedData}
          patientInfo={patientInfoData}
          isSearchBar={true}
          thHeadData={thHeadData}
        />
      ) : (
        <div id="nurse-health-camp-patients">
          <div className="col-10 col-sm-18 mb-4 mb-md-0 mt-3 col-md-6 col-lg-10">
            <Filter
              selectedSearchOption={selectedSearchOption}
              updateFilter={updateFilter}
              selectedPlaceholder={selectedPlaceholder}
              handleSearchInput={handleSearchInput}
              searchText={searchText}
              handleSearchClear={handleSearchClear}
              searchHandler={searchHandler}
            />
          </div>

          <div className="patientdo" style={{ margin: "2%" }}>
            {allData?.length ? (
              <table className="" style={{ width: "100%", margin: 0 }}>
                <thead>
                  <tr>
                    <th>Sl No</th>
                    <th>Patient Name</th>
                    <th>Gender</th>
                    <th>Mobile No</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allData?.map((item, index) => {
                    const { full_name, gender, contact_number } = item;
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{full_name}</td>
                        <td>{gender}</td>
                        <td>{contact_number}</td>
                        <td>
                          <div
                            className="d-flex justify-content-center"
                            onClick={(event) => {
                              event.stopPropagation();
                              navigate("/home/nursegeneralitp", {
                                state: {
                                  patientInfo: [item],
                                },
                              });
                            }}
                          >
                            <p className="view-all px-2 py-1">
                              view
                              <img src={whitEye} alt="view icon" />
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null}

            {allData?.length ? (
              <div className="flex justify-content-end table-page">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                className="text-center d-flex justify-content-center
    align-items-center"
                style={{ minHeight: "10rem", fontSize: "24px" }}
              >
                <p>No Records Found!</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default NurseHealthCamp;
