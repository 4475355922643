
import React, { Component } from 'react';
import OtpInput from 'react-otp-input';
import './verificationCodeStyle.scss'
import { Button } from "react-bootstrap";
import { NavLink as RouterLink, Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
export default class VerificationCode extends Component {
  state = { otp: '' };

  handleChange = (otp) => this.setState({ otp });

  render() {
    return (
      <div className='verificationCodeContainer'>
        <Stack direction="row" spacing={2}>
          <Avatar alt="key" src="./assets/images/auth/Group 38360.png" sx={{ width: 64, height: 64, background: 'whitesmoke' }} />
        </Stack>
        <div className='my-4'><span className='verification'>Verification</span><span className='code'>Code</span></div>
        <div className='enterOtp'>Enter OTP</div>
        <div className='mt-3'>
          <OtpInput
            value={this.state.otp}
            onChange={this.handleChange}
            numInputs={4}
            isInputNum={true}
            placeholder='____'
            separator={<span className='separator'></span>}
            inputStyle={{ background: '#FFFFFF 0% 0% no-repeat padding-box', borderRadius: '5px', borderColor: '#707070', width: '40px', height: '40px', color: 'black' }}
          />
        </div>
        <div className='didntReciveCode mt-3 mb-4'>If you didn’t receive a code! <span className='resend'>Resend</span></div>
        <Button as={Link} className="verifyButton" to='/new-password' variant="primary" type="submit">
          Verify
        </Button>
        <div className='invalidVerificationCode mt-2'>Invalid verification code</div>
      </div>

    );
  }
}
