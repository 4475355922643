import React from "react";
import "./TopBar.scss";
import DownTriangle from "../../../../../../../Assets/icons/down-triangle.svg";
import FrontTriangle from "../../../../../../../Assets/icons/front-triangle.svg";

const TopBar = (props) => {
  const { open, content, onClick } = props;

  return (
    <div id="top-bar">
      <div className="topbar-main-container mb-3" onClick={onClick}>
        <img src={open ? DownTriangle : FrontTriangle} alt="logo" />
        <div>{content}</div>
      </div>
    </div>
  );
};

export default TopBar;
