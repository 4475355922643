import React from 'react';
import './Spinner.css';

export default function Spinner() {
    return (
        <>
            <div className='spinner-overlay'>
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </>
    )
}