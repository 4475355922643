import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import "./ConfirmModal.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const ConfirmModal = (props) => {
  const { open, onClose, message, onSubmit } = props;

  return (
    <Modal id="confirm-modal" open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {message}
        </Typography>
        <div className="button-pair">
          <PrimaryButton
            name="Yes"
            onClick={onSubmit}
            className="button-width"
          />
          <PrimaryButton
            name="No"
            onClick={onClose}
            className="hollow-button-modal button-width"
          />
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
