import { patientId } from "../../Components/Redux/slices/userSlice";
import { readService } from "../HttpService";

export const HealthInfoTab = (patientId, params) => {
  return readService(`patient/visits/${patientId}`, params);
};
export const PatientDiagnosis = (patientId, visitId, params) => {
  return readService(`patient/diagnosis/${patientId}/${visitId}`, params);
};

export const HealthBasicInfo = (patientId, params) => {
  return readService(`patient/healthBasicInfo/${patientId}`, params);
};

export const getPatientVitals = (patientId, params) => {
  return readService(`patient/healthInfo/${patientId}`, params);
};
export const getPatientVitalList = (patientId, params) => {
  return readService(`patient/visits/${patientId}`, params);
};
export const PatientGeneralInfo = (patientId, params) => {
  return readService(`patient/generalInfo/${patientId}`, params);
};

export const PatientVitalsIndividual = (patientId, visitId, params) => {
  return readService(`patient/healthInfo/${patientId}/${visitId}`, params);
};
