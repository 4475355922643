import React, { useEffect, useState } from "react";
import Layout from "../../LayoutComp/Layout";
import "./patientdo.scss";
import searchCloseIcon from "../../../Assets/icons/search-close-icon.png";
import searchIcon from "../../../Assets/icons/search_icon.svg";
import PatientFiterModal from "../../PatientFilterModal/PatientFiterModal";
import filterIcon from "../../../Assets/icons/filter_icon.svg";
import { useNavigate } from "react-router";
import { TablePagination } from "@mui/material";
import { setPatientId } from "../../Redux/slices/userSlice";
import {setCampData} from "../../Redux/slices/campDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { readHelthCampCoordinator } from "../../../Services/Coordinator";
import { format24HrDateOnly, formatTimeHM } from "../../../Utils/DateUtils";
import CommonFilterModal from "../../CommonFilterModal/CommonFilterModal";
import { setHealthCamp } from "../../Redux/slices/healthCampSlice";
import { PatientGeneralInfo } from "../../../Services/ImsPatient/Patient";

const PatientDO = ({ sideNavState, setSideNavState }) => {
  const [item, setItem] = useState({});
  const { userProfile } = useSelector((state) => state.user);
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [rowsPerPageVal, setRowsPerPageVal] = useState(5);
  const [healthCampData, setHealthCampData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = React.useState(0);
  const [filterDataObj, setFilterDataObj] = useState({});
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSearchClear = () => {
    setSearchText("");
    getHealthCampList(0, "");
  };

  const handlePatientId = (id) => {
    dispatch(setPatientId(id));
  };

  const closeModal = () => {
    setShowModal(!showModal);
  };
  useEffect(() => {
    getHealthCampList(offset, searchText, filterDataObj);
  }, [filterDataObj]);

  const getFilterObject = (param) => {
    setFilterDataObj(param);
  };
  // console.log("filteredDataObj", filterDataObj);

  const handleNavigate = (item) => {
    if (userProfile.userGroup == "data-operators") {
      dispatch(setCampData(item))
      navigate("/home/allPatientsPage");
    }
    if (userProfile.userGroup == "doctors") {
      navigate(`/home/patienttabs/${item.id}`);
    }
    if (userProfile.userGroup == "nurses") {
      handlePatientId(item.id);
      navigate("/home/nursepatienttab", {
        state: {
          patient_id: item.id,
        },
      });
    }
    dispatch(setHealthCamp(item?.id));
    localStorage.setItem("healthCampid", item?.id);

    if (userProfile.userGroup == "pharmacists") {
      navigate(`/home/parmacistView/${item.id}`);
    }
    //  storethedatat in sessionStorage
    sessionStorage.setItem("itemName", item.name);
    console.log("data_store", item.name);
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  const searchHandler = () => {
    getHealthCampList(offset, searchText);
  };


  useEffect(() => {
    getHealthCampList(0, searchText, filterDataObj);
  }, [rowsPerPageVal, pageSize, filterDataObj]);


  const getHealthCampList = async (offset = 0, searchText, filterDataObj) => {
    let params = {
      offset: offset,
      limit: rowsPerPageVal,
      ...filterDataObj,
    };
    if (searchText) params = { ...params, name: searchText };
    if (filterDataObj?.state && filterDataObj.state !== "DEFAULT")
      params = { ...params, state: filterDataObj.state };

    if (filterDataObj?.city && filterDataObj?.city !== "DEFAULT")
      params = { ...params, city: filterDataObj?.city };
    const res = await readHelthCampCoordinator(params);
    setHealthCampData(res.data.body);
    setTotalRecords(res.data.count);
  };

  
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPageVal(parseInt(e.target.value, 10));
    setPageSize(e?.targte?.value);

    getHealthCampList(offset, searchText);

    // getDoctorsAll(offSet,searchText)
    //  getHospitals(offSet,searchText)
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = rowsPerPageVal * pageIndex;
    console.log("pageChangeddd", offset);
    getHealthCampList(offset, searchText);
    setOffset(offset);
  };
  useEffect(() => {
    if (searchText == "") {
      getHealthCampList(0, "");
    }
  }, [searchText]);

  // useEffect(() => {
  //   getPatientGeneralInfo();
  // }, []);
  // const getPatientGeneralInfo = async () => {
  //   const generalInfo = await PatientGeneralInfo(
  //     location?.state?.patientId?.id,
  //     {}
  //   );
  //   setItem(generalInfo.data);
  // };

  // console.log("patientInfo---->", patientInfo);
  //

  
  return (
    <Layout
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
      hidenav={true}
    >
      <div id="hello">
        <div className="d-flex flex-column  flex-md-row flex-lg-row flex-lg-row  align-items-center align-items-md-end pt-4">
          <div
            className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4"
            style={{ marginLeft: "20px" }}
          >
            <label className="label-input">
              <input
                type="text"
                className="form-control search-input"
                placeholder="Search Health Camp"
                onChange={handleSearchInput}
                value={searchText}
              />
              {searchText && (
                <button
                  className="search-btn-close"
                  onClick={handleSearchClear}
                >
                  <img src={searchCloseIcon} alt="" width={"24px"} />
                </button>
              )}

              <button className="search-btn" onClick={searchHandler}>
                <img src={searchIcon} alt="" />
                <span>Search</span>
              </button>
            </label>
          </div>
          <div className="col-6 ms-2">
            <button className="filter-btn" onClick={() => setShowModal(true)}>
              <span>Filter</span>
              <img src={filterIcon} alt="" className="ps-2" />
            </button>
          </div>
        </div>
        <div className="patientdo" style={{ margin: "2%" }}>
          <table className=" " style={{ width: "100%", margin: "0" }}>
            {healthCampData?.length ? (
              <thead>
                <tr>
                  <th>Camp ID</th>
                  <th>Camp Name</th>
                  <th>State</th>
                  <th>District</th>
                  <th>City</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Time</th>
                </tr>
              </thead>
            ) : undefined}
            <tbody>
              {healthCampData.map((item, i) => {
                return (
                  <tr
                    onClick={() => handleNavigate(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>
                      <>{item?.id}</>
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.state}</td>
                    <td>{item?.district}</td>
                    <td>{item?.place}</td>
                    <td>{format24HrDateOnly(item?.from_date)}</td>
                    <td>{format24HrDateOnly(item?.to_date)}</td>
                    <td>
                      <span>{formatTimeHM(item?.start_time)}</span>-
                      <span>{formatTimeHM(item?.end_time)}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {healthCampData?.length === 0 && (
            <div className="warning-text-card">No Records Found!</div>
          )}
          {healthCampData?.length ? (
            <div className="flex justify-content-end table-page">
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRecords}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPageVal}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          ) : undefined}
        </div>
      </div>
      {
        <CommonFilterModal
          isHidden={showModal}
          onClose={closeModal}
          getFilterObject={getFilterObject}
          comp="HalthCamp"
          filterDataObj={filterDataObj}
        />
      }
    </Layout>
  );
};

export default PatientDO;
