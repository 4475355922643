import React, { useEffect, useState } from "react";
import "./LabReport.scss";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from "../../../Assets/icons/crossCloseIcon.svg";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const LabReport = () => {
    let location = useLocation()
    const [report, setReport] = useState("");
    const [pdfState, setPdfState] = useState(false);
    const [jpgState, setJpgState] = useState(false);
      const [numPages, setNumPages] = useState(null);
        const [pageNumber, setPageNumber] = useState(1);

    let navigate = useNavigate()
    let { report_name, report_type, report_url } = location.state
    console.log("report_url--->", report_url);
   
   useEffect(()=>{
    const fileExtension = report_url.split(".").pop().toLowerCase();
    console.log("Url is"+report_url)
     console.log("fileExtension",fileExtension)
     if(fileExtension.slice(0,3)==='pdf'){
      console.log("ffile is pdf")
        setPdfState(true);
        setReport(report_url)
        setJpgState(false)
     }
     if (
       fileExtension.slice(0, 3) == "png" ||
       fileExtension.slice(0, 4) == "jpeg"
     ) {
       setJpgState(true);
       setReport(report_url);
       setPdfState(false);
     }
   },[])
     function onDocumentLoadSuccess({ numPages }) {
       setNumPages(numPages);
     }
    return (
      <>
        <Box sx={{ margin: "4%", borderRadius: "8px" }} className="lab-report">
          <Box>
            <b>{report_name}</b>{" "}
          </Box>
          <Box>
            <b>{report_type}</b>{" "}
          </Box>
          <Box
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/home/pathologistView")}
          >
            <img src={CloseIcon} />
          </Box>
        </Box>
        <Box sx={{ margin: "4%" }}>
          {pdfState ? (
            <>
              <Document file={report} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={1} />
              </Document>
              <p>
                Page {pageNumber} of {numPages}
              </p>
            </>
          ) : jpgState ? (
            <>
              <img src={report} alt="" style={{ width: "100%" }}></img>
            </>
          ) : (
            "No Files Found"
          )}
        </Box>
      </>
    );
};

export default LabReport;