import React from 'react'
import Layout from '../LayoutComp/Layout'

const AccessDenied = ({ setSideNavState, sideNavState }) => {
  return (
    <Layout
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
     
    >
      <h1 className='text-center' style={{position:'relative',left:'-11%'}} >You Are Not Allowed to access this Page!!</h1>
    </Layout>
  );
};

export default AccessDenied