import Router from "./config/Router";
import "./App.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import awsConfig from "./Components/Config/Config";
import { Amplify } from "aws-amplify";
import { Outlet } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "../src/Components/Redux/Store/store";
// import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from "notistack";

Amplify.configure(awsConfig);

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* <PersistGate persistor={persistor}> */}
          <SnackbarProvider maxSnack={3}>
            <Router />
            <Outlet />
          </SnackbarProvider>

          {/* </PersistGate> */}
        </LocalizationProvider>
      </Provider>
    </div>
  );
}

export default App;
