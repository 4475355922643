import { Grid, TablePagination } from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../CustomTable/CustomTable";
import Layout from "../../LayoutComp/Layout";
import RenderSelect from "../../RenderSelect/RenderSelect";
import SearchInput from "../../SearchInput/SearchInput";
import "./HealthSurveyDetail.scss";
import view from "../../../Assets/icons/view.svg";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../Assets/Images/avatar.png";
import dayjs from "dayjs";

const SingleRow = (props) => {
  const navigate = useNavigate();

  const { data, type } = props;

  return (
    <tr
      // onClick={() => console.log(" ==========> pressed")}
      style={{ cursor: "pointer" }}
    >
      <td>655768</td>
      <td>
        <span>
          <img className="admin-profile-img" src={Avatar} alt="profile_img" />
        </span>
        <span>Priyanka Singhal</span>
      </td>
      <td>40</td>
      <td>Female</td>
      <td>{dayjs("01-12-2022").format("DD MMM YYYY")}</td>
      <td>
        <span
          onClick={() =>
            // navigateToPatientNewTabPage()
            // navigate("/home/health-survey/details/1")
            console.log(" ==========> pressed")
          }
        >
          <img src={view} alt="" />
        </span>
      </td>
    </tr>
  );
};

const SinglePair = (props) => {
  const { label, value } = props;

  return (
    <div className="single-pair-container">
      <div className="single-pair-label">{label}:</div>
      <div className="single-pair-value">{value}</div>
    </div>
  );
};

const HealthSurveyDetail = (props) => {
  const { sideNavState, setSideNavState } = props;

  const [searchText, setSearchText] = useState("");

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchClear = () => {
    setSearchText("");
  };

  const searchHandler = async () => {
    console.log(" ==========> handle search called");
  };

  const data1 = [1, 2, 3, 4, 5];

  const HEALTH_SURVEY_HEADING = [
    "Patient Id",
    "Patient Name",
    "Age",
    "Gender",
    "Submitted Date",
    "Action",
  ];

  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      <div id="health-survey-detail">
        <div style={{ padding: 20 }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent={"center"}
            className="create-survey-header-container"
          >
            <Grid item xs={8}>
              <div className="create-survey-heading">
                Free Health And Wellness Day Camp
              </div>
            </Grid>
            <Grid item xs={4}>
              <SinglePair label="Date" value="15th March 2022 Sunday" />
            </Grid>
            <Grid item xs={8}>
              <SinglePair
                label="Venue"
                value="Vimlalaya hospital, near to huskur gate, Haridwar"
              />
            </Grid>
            <Grid item xs={4}>
              <SinglePair label="Timings" value="10AM To 5:00PM" />
            </Grid>
          </Grid>
        </div>
        <div className="health-survey-heading-text">Patient's List</div>
        <div className="health-survey-heder-bar">
          <SearchInput
            searchText={searchText}
            onChange={handleSearchInput}
            onSearchClear={handleSearchClear}
            onSearchClick={searchHandler}
          />
          <div
            style={{ display: "flex", width: "100%", flexDirection: "column" }}
          >
            <div>Select Age Group</div>
            {/* <div>Select Age Group</div> */}
            <Grid container>
              <Grid item xs={4}>
                <RenderSelect
                  name="select"
                  // value="label"
                  options={[
                    { label: "label", value: "value" },
                    { label: "label2", value: "value2" },
                  ]}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="patientdo">
          <CustomTable
            Row={SingleRow}
            data={data1}
            Headings={HEALTH_SURVEY_HEADING}
          />
          <div className="flex justify-content-end table-page">
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={100}
              page={1}
              onPageChange={() => console.log(" ==========>hello ")}
              rowsPerPage={10}
              onRowsPerPageChange={() => console.log(" ==========> hello")}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HealthSurveyDetail;
