import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import Avatar from "../../Assets/Images/avatar.png";
import StatusIcon from "../../Assets/icons/Status.svg";
import ArrowUp from "../../Assets/icons/cloud-arrow-up.svg";
import "./doctors.scss";
import backBtnIcon from "../../Assets/icons/back.svg";
import close_icon from "../../Assets/icons/close-rounded.svg";
import view from "../../Assets/icons/view.svg";
import edit from "../../Assets/icons/edit.svg";
import searchIcon from "../../Assets/icons/search_icon.svg";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import Form from "react-bootstrap/Form";
import { TablePagination } from "@mui/material";
import {
  createService,
  readService,
  updateService,
} from "../../Services/HttpService";
import Spinner from "../Spinner/Spinner";
import Layout from "../LayoutComp/Layout";
import FullWidthTabs from "../TabComp/FullWidthTabs";

const RegesteredDoctors = ({ sideNavState, setSideNavState }) => {
  const [action, setAction] = useState(null);
  const [page, setPage] = React.useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  // const [funcCall, setFuncCall] = useState("")
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [qualification, setQualification] = useState("");
  const [specialisation, setSpecialisation] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [doctorList, setDoctorList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [offset, setOffset] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    getAllDoctorsDetails(0, searchText);
  }, [pageSize]);

  const getAllDoctorsDetails = async (offSet = 0, searchText) => {
    setShowSpinner(true);
    let params = {
      offset: offSet,
      limit: pageSize,
    };
    if (searchText) params = { ...params, searchText: searchText };
    const data = await readService("/health/doctor", params);
    if (data) {
      setDoctorList(data);
      setShowSpinner(false);
      setTotalRecords(data.data.count);
      setOffset(data.data.offset);
    }
  };

  const getSingleDoctor = (doct_id) => {
    const doctor = doctorList?.data?.doctors.filter(
      (val) => val._id == doct_id
    );
    return doctor;
  };
  const setFields = (singleDoc) => {
    setName(singleDoc[0].displayName);
    setEmail(singleDoc[0].email);
    setPhone(singleDoc[0].phone);
    setQualification(singleDoc[0].qualification);
    setSpecialisation(singleDoc[0].specialization);
    setDoctorId(singleDoc[0]._id);
  };

  const handleAction = (actionVal, doct_id) => {
    if (doct_id == null) {
      setAction(actionVal);
      return;
    }
    if (actionVal == "View" && doct_id !== null) {
      const singleDoc = getSingleDoctor(doct_id);
      setFields(singleDoc);
    }
    if (actionVal == "Edit" && doct_id !== null) {
      const singleDoc = getSingleDoctor(doct_id);
      setFields(singleDoc);
    }
    setAction(actionVal);
  };

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offset = pageSize * pageIndex;
    getAllDoctorsDetails(offset, searchText);
    setOffset(offset);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addDoctor = async () => {
    let response;

    setShowSpinner(true);
    let body = {
      displayName: name,
      email: email,
      phone: phone,
      qualification: qualification,
      specialization: specialisation,
    };
    const params = {
      doctorId: doctorId,
    };
    if (action == "Edit") {
      response = await updateService(`/health/doctor`, params, body);
    } else {
      response = await createService("/health/doctor", body);
    }
    if (response) {
      alert("created successfully");
      setAction(null);
      setShowSpinner(false);
    } else {
      setShowSpinner(false);
    }
  };

  const searchHandler = async () => {
    getAllDoctorsDetails(offset, searchText);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addDoctor(e);
  };

  const handleReset = () => {
    setName("");
    setEmail("");
    setPhone("");
    setQualification("");
    setSpecialisation("");
  };
  const backButtonHandler = () => {
    setAction(null);
    setName("");
    setEmail("");
    setPhone("");
    setQualification("");
    setSpecialisation("");
  };

  const handleSearchClear = () => {
    setSearchText("");
    getAllDoctorsDetails(offset, "");
  };

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="doct-comp">
      {action == "Add" || action == "Edit" || action == "View" ? (
        <form onSubmit={handleSubmit}>
          <div
            className="pt-1 mobile-class"
            style={{
              width: sideNavState ? "calc(100% - 305px)" : "calc(100% - 160px)",
              marginLeft: sideNavState ? "calc(100% - 80%)" : "0",
              transition: "0.5s",
              position: "absolute",
              top: "12%",
            }}
          >
            <div className="add-doctor-form">
              {action != "View" && (
                <div className=" px-1">
                  <span
                    className="back-btn"
                    onClick={() => backButtonHandler()}
                  >
                    <img src={backBtnIcon} alt="" />

                    <span>Back</span>
                  </span>
                </div>
              )}

              <div className="form-style">
                <div className="d-flex flex-row justify-content-between ">
                  <h5>Doctor's Profile</h5>
                  {action === "View" && (
                    <div>
                      <img
                        src={close_icon}
                        alt=""
                        onClick={() => setAction(null)}
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex row justify-content-start">
                  <div className="d-flex flex-row">
                    <div className="col-lg-3 col-sm-12 col-md-6 mr-70">
                      <div
                        className="file-upload square border d-flex flex-column align-items-center justify-content-center"
                        onClick={() => inputRef.current.click()}
                      >
                        <div>
                          <img src={ArrowUp} alt="arrow" />
                          <input
                            ref={inputRef}
                            type="file"
                            style={{ display: "none" }}
                          />
                        </div>
                        <div className="upload-box d-flex align-items-center justify-content-center">
                          <p>Drop your image here</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="d-flex row  justify-content-start"> */}
                    <div style={{ marginLeft: "62px" }}>
                      <div className="d-flex row">
                        <div
                          className="col-lg-5 col-sm-12 col-md-6"
                          style={{ width: "42.5%" }}
                        >
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Doctor Name</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter mobile number"
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                            />
                          </Form.Group>
                        </div>
                        <div
                          className="col-lg-5 col-sm-12 col-md-6"
                          style={{ width: "42.5%" }}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter email"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            />
                          </Form.Group>
                        </div>
                        <div
                          className=" col-lg-5 col-sm-12 col-md-6"
                          style={{ width: "42.5%" }}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>State</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter email"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            />
                          </Form.Group>
                        </div>

                        <div
                          className="col-lg-5 col-md-6 col-sm-12"
                          style={{ width: "42.5%" }}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              type=""
                              placeholder="qualification"
                              onChange={(e) => setQualification(e.target.value)}
                              value={qualification}
                            />
                          </Form.Group>
                        </div>
                        <div
                          className="col-lg-5 col-md-6 col-sm-12"
                          style={{ width: "42.5%" }}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type=""
                              placeholder="qualification"
                              onChange={(e) => setQualification(e.target.value)}
                              value={qualification}
                            />
                          </Form.Group>
                        </div>
                        <div
                          className="col-lg-5 col-md-6 col-sm-12"
                          style={{ width: "42.5%" }}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>Age</Form.Label>
                            <Form.Control
                              type=""
                              placeholder="qualification"
                              onChange={(e) => setQualification(e.target.value)}
                              value={qualification}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex row justify-content-start">
                  <div
                    className="col-lg-3 col-md-6 col-sm-12"
                    style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Select aria-label="select">
                        <option>Select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Select>
                    </Form.Group>
                  </div>

                  <div
                    className="col-lg-3 col-md-6 col-sm-12"
                    style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Gender</Form.Label>
                      <Form.Select aria-label="select">
                        <option>Select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-3 col-md-6 col-sm-12"
                    style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Skills</Form.Label>
                      <Form.Select aria-label="select">
                        <option>Select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-3 col-md-6 col-sm-12"
                    style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Doctor’s Availability</Form.Label>
                      <Form.Control
                        type=""
                        placeholder="Specialization"
                        onChange={(e) => setSpecialisation(e.target.value)}
                        value={specialisation}
                      />
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-3 col-md-6 col-sm-12"
                    style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Consultation Fees</Form.Label>
                      <Form.Control
                        type=""
                        placeholder="Specialization"
                        onChange={(e) => setSpecialisation(e.target.value)}
                        value={specialisation}
                      />
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-3 col-md-6 col-sm-12"
                    style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Experience</Form.Label>
                      <Form.Control
                        type=""
                        placeholder="Specialization"
                        onChange={(e) => setSpecialisation(e.target.value)}
                        value={specialisation}
                      />
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-3 col-md-6 col-sm-12"
                    style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>PHC</Form.Label>
                      <Form.Select aria-label="select">
                        <option>Select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-3 col-md-6 col-sm-12"
                    style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Where are you working?</Form.Label>
                      <Form.Select aria-label="select">
                        <option>Select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-3 col-md-6 col-sm-12"
                    style={{ width: "30%" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Specialization</Form.Label>
                      <Form.Select aria-label="select">
                        <option>Select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-lg-11 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontWeight: 600 }}>
                        <b>Biography</b>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="specialisation"
                        onChange={(e) => setSpecialisation(e.target.value)}
                        value={specialisation}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              {action !== "View" && (
                <div className="d-flex justify-content-start mt-3 gap-2">
                  {action !== "Edit" && (
                    <Button
                      className="reset-btn"
                      variant="outline-primary"
                      onClick={handleReset}
                    >
                      <b>Reset</b>
                    </Button>
                  )}

                  <div>
                    <Button
                      className="loginButton reset-btn"
                      variant="primary"
                      type="submit"
                      // onClick={handleClick}
                    >
                      {action == "Edit" ? "Update" : "Save"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      ) : (
        <div
          id="healthCamp_container"
          style={{
            width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
            marginLeft: sideNavState ? "calc(100% - 80%)" : "0%",
            transition: "0.5s",
            position: "absolute",
          }}
        >
          <div className="d-flex   flex-md-row flex-lg-row flex-lg-row justify-content-betwesen align-items-center align-items-md-end mt-4">
            <div
              className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4"
              style={{ marginLeft: "20px" }}
            >
              <label className="label-input">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search by Doctor's name"
                  onChange={handleSearchInput}
                  value={searchText}
                />
                {searchText && (
                  <button
                    className="search-btn-close"
                    onClick={handleSearchClear}
                  >
                    <img src={searchCloseIcon} alt="" width={"24px"} />
                  </button>
                )}

                <button className="search-btn" onClick={searchHandler}>
                  <img src={searchIcon} alt="" />
                  <span>Search</span>
                </button>
              </label>
            </div>

            <div
              style={{ display: "flex", marginLeft: "15px" }}
              classname="d-flex justify-content-start me-15"
            >
              <Button
                className="approve-btn"
                style={{ background: "green" }}
                type="submit"
                // onClick={handleClick}
              >
                Approve
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                className="approve-btn "
                style={{ background: "#F68488" }}
                type="submit"
                // onClick={handleClick}
              >
                Decline
              </Button>
            </div>
          </div>
          <div
            className="table-style"
            // style={{ padding: "10px", margin: "10px" }}
          >
            <div
              className="doctor-table-container overflow-auto"
              style={{ margin: "2%" }}
            >
              <table className="doctor-table">
                <thead>
                  <tr>
                    <th>
                      <input type="checkbox" />
                      &nbsp;&nbsp;Select All
                    </th>
                    <th>Doctor’s Name</th>
                    <th>Qualification</th>
                    <th>State</th>
                    <th>District</th>
                    <th>City</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {doctorList?.data?.doctors.map((item, i) => {
                    return (
                      <tr>
                        <td>
                          <input type="checkbox" /> 01
                        </td>
                        <td>
                          <img
                            className="doctor-profile-img"
                            src={Avatar}
                            alt="profile_img"
                          />{" "}
                          {item.displayName}
                        </td>
                        <td>Degree</td>
                        <td>Karnataka</td>
                        <td>Bangalore</td>
                        <td>Mangalore</td>
                        <td>
                          <span
                            onClick={() => handleAction("View", item._id)}
                            className="me-1"
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={view}
                              alt=""
                            />
                          </span>
                          <span
                            className="ms-1"
                            onClick={() => handleAction("Edit", item._id)}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={edit}
                              alt=""
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {doctorList?.data?.doctors?.length === 0 && (
                <div className="warning-text-card">No Records Found!</div>
              )}
              <div className="flex justify-content-end table-page">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={totalRecords}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showSpinner && <Spinner />}
    </div>
  );
};

export default RegesteredDoctors;
