import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import closeIcon from '../../Assets/icons/modal-close-icon.svg';
import searchIcon from '../../Assets/icons/search_icon.svg';
import searchCloseIcon from '../../Assets/icons/search-close-icon.png';
import './CommonPopup.scss';
import { readService } from '../../Services/HttpService';
import { TablePagination } from '@mui/material';
import Spinner from '../Spinner/Spinner';

export default function PopupDropdwon(props) {

    const { page, pageSize, setPage, setPageSize, setOffset, offset, setTotalCount } = props;
    const [showSpinner, setShowSpinner] = useState(false)
    const [searchValue, setSearchValue] = useState('');
    const [selectedImsData, setSelectedImsData] = useState(props.data);
    const [selectedOption, setSelectedOption] = useState(null);

    // console.log("user profile is===================", userProfile)

    useEffect(() => {
        if (searchValue === '')
            getAllImsData(0);
    }, [searchValue]);

    useEffect(() => {
        setSelectedImsData(props.data)
    }, [props.data]);

    const onCloseClick = () => {
        props.onClose();
        setSearchValue('');
    }

    const handleSearchTextEnter = (event) => {
        setSearchValue(event.target.value)
    }

    const onSearchClick = async () => {
        const params = {
            name: searchValue
        }
        try {
            const response = await readService("imshospitals", params);
            props.setImsData(response.data.body);
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    }

    const clearSearchValue = () => {
        setSearchValue('');
        getAllImsData(offset, '');
    }

    const handleChangePage = (event, newPage) => {
        let pageIndex = newPage;
        setPage(newPage);
        let offset = pageSize * pageIndex;
        getAllImsData(offset, searchValue)
        setOffset(offset);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const selectedDoctorApply = () => {
        props.setSelectedId(selectedOption);
        props.onClose();
    }


    const getAllImsData = async (offset = 0, searchValue) => {
        console.log(searchValue)
        try {
            setShowSpinner(true);
            const params = {
                offset: offset,
                limit: pageSize,
                searchText: searchValue
            };
            const response = await readService("imshospitals", params);
            props.setImsData(response?.data?.body)
            setTotalCount(response?.data?.count)
            setShowSpinner(false)
            setOffset(response?.data?.offset)
        } catch (e) {
            console.log('Error while fetching Doctor list', e);
            setShowSpinner(false)
        }
    }

    const handleOptionChange = (event) => {
        console.log("vsal is=========", event.target.value)
        setSelectedOption(event.target.value);
    };

    return (
        <div id="common-popup">
            <Modal size="lg" dialogClassName="common-popup" backdrop="static" show={props.isHidden} onHide={props.onClose} centered>
                <Modal.Header className="assign-modal-header">
                    <img src={closeIcon} alt='' onClick={onCloseClick} />
                </Modal.Header>
                <Modal.Body>
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <label className='label-input'>
                                <input type='text' className='form-control search-input' placeholder='Search by MCH name'
                                    value={searchValue} onChange={handleSearchTextEnter} />
                                {searchValue && <button className='search-btn-close' onClick={clearSearchValue}>
                                    <img src={searchCloseIcon} alt='' width={'24px'} />
                                </button>}
                                <button className='search-btn' onClick={onSearchClick}>
                                    <img src={searchIcon} alt='' />
                                    <span>Search</span>
                                </button>
                            </label>
                        </div>
                        <div className='col-2 text-end'>
                            <button className='apply-btn' onClick={selectedDoctorApply}>Apply</button>
                        </div>
                    </div>
                    <div className='table-container'>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>SL.No</th>
                                    <th>MCH Id</th>
                                    <th>MCH Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data && props.data.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>
                                                <input
                                                    type='radio'
                                                    onChange={handleOptionChange}
                                                    value={item.id}
                                                    checked={selectedOption === item.id}
                                                />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="flex justify-content-end table-page">
                            <TablePagination
                                component="div"
                                count={props.totalCount}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={pageSize}
                                rowsPerPageOptions={[5, 10, 20, 40]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </Modal.Body>
                {showSpinner && <Spinner />}
            </Modal>
        </div>
    )
}
