import React from "react";
import Layout from "../../LayoutComp/Layout";
import FullWidthTabs from "../../TabComp/FullWidthTabs";
import HealthCP from "./HealthCampPatient/HealthCP";
import MCHReferredPat from "./MCHReferredPat/MCHReferredPat";
import { useNavigate, useParams } from "react-router-dom";
import backBtnIcon from "../../../Assets/icons/back.svg";

const PatientTabs = ({ sideNavState, setSideNavState }) => {
  const params = useParams();

  const { id } = params;

  // console.log("pid is============================", id)

  const childrenArry = [<HealthCP healthCampId={id} />, <MCHReferredPat sideNavState={sideNavState} campId={id} />];
  const navigate = useNavigate();

  const tabstitle = ["Health Camp Patient", "MCH referred Patient"];

  return (
    <Layout
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
      hidenav={true}
    >
      <div className="py-2 px-3" style={{ cursor: "pointer" }}>
        <span
          className="back-btn-row"
          onClick={() => navigate("/home/doctorsindex")}
        >
          <img src={backBtnIcon} alt="" />
          <span>Back</span>
          
        </span>
      </div>
      <FullWidthTabs tabstitle={tabstitle} DoctorsTabData={childrenArry} />
    </Layout>
  );
};

export default PatientTabs;
