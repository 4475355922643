import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../../Components/Header/Header'
import SideNav from '../../Components/SideNav/SideNav'
import { SideNavProvider } from "../../Components/SideNavContextAPI/SideNavContext";
// eslint-disable-next-line import/no-anonymous-default-export
export default function ({sideNavState,setSideNavState}) {
  return (
    <SideNavProvider>
      <div>
        <Header />
        <SideNav
          sideNavState={sideNavState}
          setSideNavState={setSideNavState}
        />
        <Outlet />
      </div>
    </SideNavProvider>
  );
}
