import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SuccessLottie from "./SuccessLottie";

const InputFieldModal = ({
  open,
  handleClose,
  handleContentChange,
  content,
}) => {
  const style = {
    position: "absolute",
    top: "70%",
    left: "70%",
    transform: "translate(-50%, -50%)",
    width: 330,
    height: 330,
    bgcolor: "background.paper",
    //   border: "2px solid #000",
    //   boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onClick={handleContentChange}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={`${content.title != "Verified"}` && style}>
        {content.title == "Verified" ? (
          <>
            <Typography
              id="modal-modal-title"
              className="text-center"
              variant="h6"
              component="h2"
            >
              {content.title}
            </Typography>{" "}
            <SuccessLottie />
          </>
        ) : (
          <>
            <div>
              <Typography
                id="modal-modal-title"
                className="text-center"
                variant="h6"
                component="h2"
              >
                {content.title}
              </Typography>
              <p className="text-center">{content.subTitle}</p>
              <div className="text-center">
                <img src={content.icon} />
              </div>
              <p className="text-center">{content.bottomData}</p>
              <p className="text-center">{content.bottomDataTwo}</p>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default InputFieldModal;
