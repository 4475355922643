import React from 'react'
import './forgotPasswordStyle.scss'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Form, Row, Button } from "react-bootstrap";
import { NavLink as RouterLink, Link } from 'react-router-dom';

function ForgotPassword() {
  return (
    <div className='forgotPasswordContainer'>
      <Stack direction="row" spacing={2}>
        <Avatar alt="key" src="./assets/images/auth/Group 38360.png" sx={{ width: 64, height: 64, background: 'whitesmoke' }} />
      </Stack>
      <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 4 }}>
        <span className='forgotText'> Forgot</span>&nbsp;<span className='passwordText'> Password? </span>
      </Stack>
      <Form className="form">
        <Row>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label className="formLabel">Please enter your username to receive the OTP</Form.Label>
            <Form.Control className="formControl" type="email" placeholder="Enter email" />
          </Form.Group>
        </Row>
        <div className="d-flex justify-content-center ">
          <Button as={Link} className="requestOTPButton mt-3" to='/verification-code' variant="primary" type="submit">
            Request OTP
          </Button>
        </div>
      </Form>
    </div >
  )
}
export default ForgotPassword;
