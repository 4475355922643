import React, { useEffect, useRef, useState } from "react";
import backBtnIcon from "../../Assets/icons/back.svg";
import view from "../../Assets/icons/view.svg";
import edit from "../../Assets/icons/edit.svg";
import ArrowUp from "../../Assets/icons/cloud-arrow-up.svg";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import "./busdriver.scss";
import searchCloseIcon from "../../Assets/icons/search-close-icon.png";
import searchIcon from "../../Assets/icons/search_icon.svg";
import { Button, Form } from "react-bootstrap";
import addIcon from "../../Assets/icons/addIcon.svg";
import { TablePagination } from "@mui/material";
import Layout from "../LayoutComp/Layout";
import Spinner from "../Spinner/Spinner";
import busViewClose from "../../Assets/icons/bus-view-close.svg";
import { createService, readService } from "../../Services/HttpService";
import { getGenders } from "../../Services/Metadata/Metadata";
import moment from "moment";
import { updateBusDriver } from "../../Services/BusDriver";
import { useSelector } from "react-redux";
import { errorToast, successToast } from "../../Utils/toasters";
import { enqueueSnackbar } from "notistack";
import { ContactValidator, aadharNumber } from "../../Utils/Validators";
import "../Bus/bus.scss";
import { ConvertYMD } from "../../Utils/DateUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  pr: 2,
};

const initialState = {
  name: "",
  contact_number: "",
  doc_type: "aadhar",
  doc_number: "",
  license_number: "",
  license_issue_date: "",
  license_expiry_date: "",
  gender: "",
  licenseFront: "hello",
  licenseBack: "dummy",
  // works_at: "100001",
};

// gender works_at name

const BusDriver = ({ sideNavState, setSideNavState }) => {
  const { works_at } = useSelector((s) => s?.user?.userProfile);

  const [action, setAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [genderDrop, setGenderDrop] = useState([]);
  const [aadharmessage, setAadharMessage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageBack, setSelectedImageBack] = useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [busDriverId, setBusDriverId] = useState(0);

  const [busDriverDetails, setBusDriverDetails] = useState(initialState);

  const [page, setPage] = React.useState(0);
  const [message, setMessage] = useState(null);

  const [error, setError] = useState({
    contact_number: false,
    password: false,
    aadhar: false,
  });

  const [allData, setAllData] = useState([]);
  const [count, setCount] = useState(0);
  // const [open,setOpen]=useState(false)

  const setFieldValueHandler = (e) => {
    const { name, value } = e.target;
    if (name === "contact_number") {
      ContactValidator(setError, setMessage, value);
    }
    if (name == "doc_number") {
      aadharNumber(setError, setAadharMessage, value);
    }
    setBusDriverDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const backButtonHandler = () => {
    setAction(null);
    setSelectedImage(null);
    setSelectedImageBack(null);
  };

  const getGenderData = async () => {
    const result = await getGenders();
    setGenderDrop(result.data);
  };

  const handleReset = () => {
    setBusDriverDetails(initialState);
  };
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  const handleSearchClear = () => {
    setSearchText("");
    // getAllDoctorsDetails(offset, "");
  };
  const searchHandler = async () => {
    getAllBusDrivers(rowsPerPage, 0, 1);
  };

  const disableDates = () => {
    var today, dd, mm, yyyy;
    today = new Date();
    dd = today.getDate() + 1;
    mm = today.getMonth() + 1;
    yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleAction = (actionVal, bus_id) => {
    setBusDriverId(bus_id);

    const singleData = allData.find((s) => s.id === bus_id);

    let dummy = {};

    Object.keys(busDriverDetails).map((item) => {
      if (item === "license_issue_date") {
        dummy = {
          ...dummy,
          license_issue_date: new Date(singleData.license_issue_date),
        };
      } else if (item === "license_expiry_date") {
        dummy = {
          ...dummy,
          license_expiry_date: new Date(singleData.license_expiry_date),
        };
      } else if (item === "licenseFront") {
        dummy = { ...dummy, licenseFront: singleData?.license_front_url };
      } else if (item === "licenseBack") {
        dummy = { ...dummy, licenseBack: singleData?.license_back_url };
      } else {
        dummy = { ...dummy, [item]: singleData[item] };
      }
    });

    setBusDriverDetails(dummy);
    setAction(actionVal);
  };
  console.log("busdriver detail------>", busDriverDetails);
  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPage * pageIndex;
    getAllBusDrivers(rowsPerPage, offSet, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getAllBusDrivers(event.target.value, 0, 0);
  };
  const closeModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append(`works_at`, works_at);

    const newArray = Object.keys(busDriverDetails);
    newArray.map((item, i) => {
      if (item == "license_issue_date" || item == "license_expiry_date") {
        formData.append(`${item}`, ConvertYMD(busDriverDetails[item]));
      } else {
        formData.append(`${item}`, busDriverDetails[item]);
      }
    });

    if (action == "Add") {
      const response = await createService("busDrivers", formData, {});
      if (response.status < 200 || response.status > 300) {
        errorToast(enqueueSnackbar, response.data);
      } else {
        successToast(enqueueSnackbar, "Created Successfully!!");
        setSelectedImage(null);
        setSelectedImageBack(null);
        setPage(0);
      }
    }

    if (action == "Edit" && busDriverId !== null) {
      const response = await updateBusDriver(busDriverId, formData, {});
      if (response.status < 200 || response.status > 300) {
        errorToast(enqueueSnackbar, response.data);
      } else {
        successToast(enqueueSnackbar);
        setSelectedImage(null);
        setSelectedImageBack(null);
        setPage(0);
      }
    }

    setAction(null);
    getAllBusDrivers(5, 0, 1);
    setPage(0);
  };
  console.log("Bus diver detail--->", busDriverDetails);

  const getAllBusDrivers = async (limit, offset, updateCount) => {
    const result = await readService(
      `busDrivers?limit=${limit}&offset=${offset}&name=${searchText}`
    );

    const result2 = await readService(`busDrivers?limit=${100}&offset=${0}`);

    console.log(" ==========> result", result);
    console.log(" ==========> result2", result2);

    setAllData(result?.data?.body);
    if (updateCount) {
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    getAllBusDrivers(5, 0, 1);
    getGenderData();
  }, []);

  const handleFileSelect = (event) => {
    const name = event.target.name;
    console.log("name--->", name);
    const file = event.target.files[0];
    if (name == "licenseFront") {
      setSelectedImage(URL.createObjectURL(file));
    }
    if (name == "licenseBack") {
      setSelectedImageBack(URL.createObjectURL(file));
    }
    setBusDriverDetails((prevState) => ({
      ...prevState,
      [name]: file,
    }));
  };
  const handleDisablebtn = () => {
    if (
      error.contact_number == true ||
      error.aadhar == true ||
      !busDriverDetails.name ||
      !busDriverDetails.contact_number ||
      !busDriverDetails.license_number ||
      !busDriverDetails.license_expiry_date ||
      !busDriverDetails.license_issue_date ||
      !busDriverDetails.gender
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (busDriverDetails.doc_number == "") {
      setError((prev) => ({
        ...prev,
        aadhar: false,
      }));
    }
  }, [busDriverDetails.doc_number]);
  const handleDateChange = (date) => {
    setBusDriverDetails((prevState) => ({
      ...prevState,
      license_issue_date: date,
    }));
  };
  const handleDateChangeexp = (date) => {
    setBusDriverDetails((prevState) => ({
      ...prevState,
      license_expiry_date: date,
    }));
  };

  const disableFutureDates = (date) => {
    return date > new Date();
  };
  const disablePrevDates = (date) => {
    return date < new Date();
  };
  return (
    <Layout
      id="doctorId"
      style={{ border: "none", boxShadow: "none" }}
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
    >
      {action == "Add" || action == "Edit" || action == "View" ? (
        <form onSubmit={handleSubmit}>
          <>
            <div className="add-doctor-form">
              {action != "View" && (
                <div className=" px-1">
                  <span
                    className="back-btn"
                    onClick={() => backButtonHandler()}
                  >
                    <img src={backBtnIcon} alt="" />

                    <span>Back</span>
                  </span>
                </div>
              )}

              <div className="form-style px-3 py-2 ">
                <div className="d-flex flex-row justify-content-between py-2 mt-2 mb-2 ">
                  <div
                    className="px-2 py-2 "
                    style={{
                      background: "#EFEEFE",
                      color: "#1F21C4",
                      fontWeight: "bold",
                    }}
                  >
                    Add Driver
                  </div>
                  {action == "View" && (
                    <div onClick={() => backButtonHandler()}>
                      <img src={busViewClose}></img>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Driver Name<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type=""
                        name="name"
                        placeholder="Driver Name"
                        value={busDriverDetails.name}
                        onChange={setFieldValueHandler}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Mobile Number<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Mobile Number"
                        name="contact_number"
                        value={busDriverDetails.contact_number}
                        onChange={setFieldValueHandler}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                        className="no-spinners"
                      />
                      {error.contact_number && (
                        <p className="text-danger">{message}</p>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Aadhar Card Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Aadhar"
                        name="doc_number"
                        value={busDriverDetails.doc_number}
                        onChange={setFieldValueHandler}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      />
                      {error.aadhar && (
                        <p className="text-danger">{aadharmessage}</p>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Licence Number<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type=""
                        placeholder="Licence Number"
                        name="license_number"
                        value={busDriverDetails.license_number}
                        onChange={setFieldValueHandler}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 date-input-add-nurse">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Issue Date<span className="required-field">*</span>
                      </Form.Label>
                      {/* <Form.Control
                        type="date"
                        placeholder="Issue Date"
                        name="license_issue_date"
                        value={busDriverDetails.license_issue_date}
                        onChange={setFieldValueHandler}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="customDatePicker"
                          wrapperClassName="datepicker"
                          value={busDriverDetails.license_issue_date}
                          onChange={handleDateChange}
                          sx={{ width: "100%" }}
                          shouldDisableDate={disableFutureDates}
                        />
                      </LocalizationProvider>
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 date-input-add-nurse">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Valid Till<span className="required-field">*</span>
                      </Form.Label>
                      {/* <Form.Control
                        type="date"
                        // min={new Date().now}
                        placeholder="Valid Till"
                        name="license_expiry_date"
                        value={busDriverDetails.license_expiry_date}
                        onChange={setFieldValueHandler}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="customDatePicker"
                          wrapperClassName="datepicker"
                          value={busDriverDetails.license_expiry_date}
                          onChange={handleDateChangeexp}
                          sx={{ width: "100%" }}
                          shouldDisableDate={disablePrevDates}
                        />
                      </LocalizationProvider>
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Gender<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="select"
                        onChange={setFieldValueHandler}
                        name="gender"
                        value={busDriverDetails.gender}
                        disabled={action === "View"}
                        style={{ backgroundColor: "#fff" }}
                      >
                        <option>Select Gender</option>
                        {genderDrop.length > 0 &&
                          genderDrop?.map(({ gender }, index) => (
                            <option value={gender} key={index}>
                              {gender}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 d-flex align-items-center justify-content-center">
                    <div className="file-upload-bus">
                      <div
                        className="file-upload-inner-bus square border d-flex flex-column align-items-center justify-content-center"
                        onClick={() => {
                          if (action !== "View") inputRef1.current.click();
                        }}
                      >
                        <div>
                          <img
                            className="uploaded-img"
                            src={selectedImage || ArrowUp}
                            alt="arrow"
                          />
                          <input
                            name="licenseFront"
                            ref={inputRef1}
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileSelect}
                          />
                        </div>
                        <p className="mt-2">Upload front photo of Licence</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex align-items-center justify-content-center">
                    <div className="file-upload-bus">
                      <div
                        className="file-upload-inner-bus square border d-flex flex-column align-items-center justify-content-center"
                        onClick={() => {
                          if (action !== "View") inputRef2.current.click();
                        }}
                      >
                        <div>
                          <img
                            className="uploaded-img"
                            src={selectedImageBack || ArrowUp}
                            alt="arrow"
                          />
                          <input
                            name="licenseBack"
                            ref={inputRef2}
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileSelect}
                          />
                        </div>
                        {/* <div className="upload-box d-flex align-items-center justify-content-center"> */}
                        <p className="mt-2">Upload Rear photo of Licence</p>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  {(action == "Add" || action == "Edit") && (
                    <p className="text-danger note-text">
                      <span>
                        <LabelImportantIcon />
                      </span>
                      : Kindly complete all the required fields to submit the
                      form.
                    </p>
                  )}
                </div>

                {/* <hr
                    style={{
                      border: "2px solid #707070",
                      margin: "20px 0px 23px 0px",
                    }}
                  ></hr> */}

                {/* <div
                    className="d-inline flex-row px-2 py-2 mt-3"
                    style={{
                      background: "#EFEEFE",
                      color: "#1F21C4",
                      fontWeight: "bold",
                      marginBottom: "30px",
                    }}
                  >
                    Driver Details
                  </div> */}
                {/* <div className="d-flex row justify-content-start mt-4">
                    <div
                      className="col-lg-4 col-md-6 col-sm-12"
                      //   style={{ width: "30%" }}
                    >
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Driver Name</Form.Label>
                        <Form.Control
                          type=""
                          placeholder="KA51 AB 2023"
                          //   onChange={(e) => setQualification(e.target.value)}
                          //   value={qualification}
                        />
                      </Form.Group>
                    </div>

                    <div
                      className="col-lg-4 col-md-6 col-sm-12"
                      //   style={{ width: "30%" }}
                    >
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type=""
                          placeholder="9828828288"
                          //   onChange={(e) => setQualification(e.target.value)}
                          //   value={qualification}
                        />
                      </Form.Group>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 col-sm-12"
                      //   style={{ width: "30%" }}
                    >
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Adhar Card Number</Form.Label>
                        <Form.Control
                          type=""
                          placeholder="882828228"
                          //   onChange={(e) => setQualification(e.target.value)}
                          //   value={qualification}
                        />
                      </Form.Group>
                    </div>
                  </div> */}
              </div>
              {action !== "View" && (
                <div className="d-flex justify-content-start mt-3 ms-4 gap-2">
                  {action !== "Edit" && (
                    <Button
                      style={{ padding: "10px 40px" }}
                      //   className="reset-btn"
                      variant="outline-primary"
                      onClick={handleReset}
                    >
                      <b>Reset</b>
                    </Button>
                  )}

                  <div>
                    <Button
                      //   style={{ padding: "10px 40px" }}
                      className="loginButton reset-btn"
                      variant="primary"
                      type="submit"
                      // disabled={handleDisablebtn()}
                      // onClick={handleClick}
                    >
                      {action == "Edit" ? "Update" : "Save"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        </form>
      ) : (
        <div id="doctorId" className="py-2 box-shadow-cls">
          <div className="box-border">
            <br />
            <div className="d-flex flex-column mx-0 mx-md-3  flex-md-row flex-lg-row flex-lg-row justify-content-between align-items-center align-items-md-end mt-4">
              <div className="col-10 col-sm-18 mb-4 mb-md-0  col-md-6 col-lg-4">
                <label className="label-input">
                  <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search by name"
                    onChange={handleSearchInput}
                    value={searchText}
                  />
                  {searchText && (
                    <button
                      className="search-btn-close"
                      onClick={handleSearchClear}
                    >
                      <img src={searchCloseIcon} alt="" width={"24px"} />
                    </button>
                  )}
                  <button className="search-btn" onClick={searchHandler}>
                    <img src={searchIcon} alt="" />
                    <span>Search</span>
                  </button>
                </label>
              </div>

              {/* <div className="col-6">
                <button
                  className="filter-btn"
                  onClick={() => setShowModal(true)}
                >
                  <span>Filter</span>
                  <img src={filterIcon} alt="" className="ps-2" />
                </button>
              </div> */}

              <div className="text-end">
                <button
                  className="add-btn"
                  onClick={() => {
                    setBusDriverDetails(initialState);
                    setAction("Add");
                  }}
                >
                  <img src={addIcon} alt="" style={{ marginRight: "10px" }} />
                  Add Driver
                </button>
              </div>
            </div>
            <div
              className=""
              // style={{ padding: "10px", margin: "10px" }}
            >
              <div className="patientdo" style={{ margin: "2%" }}>
                <table className="" style={{ width: "100%", margin: 0 }}>
                  {allData.length ? (
                    <>
                      <thead>
                        <tr>
                          <th>Driver ID</th>
                          <th>Driver Name</th>
                          <th>Mobile Number</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                    </>
                  ) : (
                    ""
                  )}

                  <tbody>
                    {/* {doctorList?.data?.doctors.map((item, i) => { */}
                    {/* return ( */}

                    {allData?.map((item, i) => {
                      return (
                        <tr>
                          <td>{item?.id}</td>
                          <td>{item?.name}</td>
                          <td>{item?.contact_number}</td>
                          <td>
                            <span
                              onClick={() => handleAction("View", item.id)}
                              className="me-1"
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                src={view}
                                alt=""
                              />
                            </span>
                            <span
                              className="ms-1"
                              onClick={() => handleAction("Edit", item.id)}
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                src={edit}
                                alt=""
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {allData.length ? (
                  <div className="flex justify-content-end table-page">
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[5, 10, 25]}
                      count={count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                ) : (
                  <div
                    className="text-center d-flex justify-content-center
            align-items-center"
                    style={{ minHeight: "10rem", fontSize: "24px" }}
                  >
                    <p>No Records Found!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <FilterModal visible={showModal} onClose={handleFilterClose}>
          <CoPathologistFilterModal />
        </FilterModal> */}
      {/* {showSpinner && <Spinner />} */}
    </Layout>
  );
};

export default BusDriver;
