import { Avatar, Box, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import ShadowContainer from "../../../Layout/ShadowContainer/ShadowContainer";
import { DOCUMENT_OPTIONS, GENDER_OPTIONS } from "../../../Utils/constants";
import HollowButton from "../../HollowButton/HollowButton";
import Layout from "../../LayoutComp/Layout";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import RenderInput from "../../RenderInput/RenderInput";
import RenderSelect from "../../RenderSelect/RenderSelect";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";

import "./DataOperatorForm.css";
import {
  getDistrictsByState,
  getPlacesByDistrict,
  getStates,
} from "../../../Services/Metadata/Metadata";
import { readService } from "../../../Services/HttpService";
import RenderSelectBlood from "../../RenderSelect/RenderSelectBlood";
import { readMCH } from "../../../Services/MCH";
import RenderSelectMch from "../../RenderSelect/RenderSelectMch";
import GenderSelect from "../../RenderSelect/GenderSelect";
import DocSelect from "../../RenderSelect/DocSelect";
import QualificationSelect from "../../RenderSelect/QualificationSelect";
import {
  addDataOper,
  updateDataOperator,
} from "../../../Services/DataOperator";
import { useSelector } from "react-redux";
import { backBtnIcon } from "../../../Utils/ImageUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { readDataOperator } from "../../../Services/Coordinator";
import { errorToast, successToast } from "../../../Utils/toasters";
import { enqueueSnackbar } from "notistack";
import PopupDropdwon from "../../CommonPopupDropdown/PopupDropdown";
import { ContactValidator, aadharNumber } from "../../../Utils/Validators";

const DataOperatorForm = (props) => {
  const { sideNavState, setSideNavState } = props;

  const { works_at } = useSelector((s) => s.user.userProfile);
  const userType = useSelector((s) => s.user.userProfile.userGroup);
  const isMchAdmin = userType === "mch-admins";

  const [stateDropDown, setStateDropDown] = useState([]);
  const [distDrop, setDistDrop] = useState([]);
  const [placeDrop, setPlaceDrop] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [bloodGoup, setBloodGroup] = useState([]);
  const [qualificationDD, setQualificationDD] = useState([]);
  const [hospitaldrop, setHospitalDrop] = useState([]);
  const [imsData, setImsData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [showDropdown, setShowDropDown] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [popUpPage, setPopUpPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [offset, setOffset] = useState(5);

  const { userProfile } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [passMessage, setPassMessage] = useState("");
  const [aadharmessage, setAadharMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState({
    contact_number: false,
    password: false,
    aadhar: false,
  });

  const [initialValues, setInitialValues] = useState({
    name: "",
    contact_number: "",
    //  email: "",
    state_id: stateId,
    district_id: districtId,
    place_id: placeId,
    doc_number: "",
    doc_type: "",
    qualification: "",
    username: "",
    password: "",
    gender: "",
    blood_group: "",
    works_at: userProfile.userGroup == "mch-admins" ? userProfile.works_at : "",

    // emergency_ward:""
  });

  useEffect(() => {
    if (selectedId) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        works_at: selectedId,
      }));
    }
  }, [selectedId]);

  useEffect(() => {
    const qualification = async () => {
      const special = await readService("metadata/qualifications", {});
      setQualificationDD(special.data);
    };

    qualification();
    const getBloodGroup = async () => {
      const bloodGrp = await readService("metadata/bloodGroups", {});
      setBloodGroup(bloodGrp.data);
    };
    getBloodGroup();
    const getHospital = async () => {
      const hospitalCount = await readMCH();
      setHospitalDrop(hospitalCount.data.body);
    };
    getHospital();
  }, []);

  useEffect(() => {
    getState();
  }, []);
  const getState = async () => {
    const states = await getStates();
    setStateDropDown(states.data);
  };

  console.log(" ==========> selected id", selectedId);
  useEffect(() => {
    if (stateId) getDistrict();
  }, [stateId]);
  const getDistrict = async () => {
    const district = await getDistrictsByState(stateId);
    setDistDrop(district.data);
  };
  useEffect(() => {
    if (districtId) getPlaceData();
  }, [districtId]);
  const getPlaceData = async () => {
    const place = await getPlacesByDistrict(districtId);
    setPlaceDrop(place.data);
  };

  useEffect(() => {
    setInitialValues((prevValues) => ({
      ...prevValues,
      state_id: stateId,
      district_id: districtId,
      place_id: placeId,
    }));
  }, [stateId, districtId, placeId]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event) => {
    // event.preventDefault()
    const { name, value } = event.target || event;
    if (name == "password") {
      if (value.length < 8) {
        setError((prev) => ({
          ...prev,
          password: true,
        }));
        setPassMessage("Password must contain minimum 8 characters");
      } else {
        setError((prev) => ({
          ...prev,
          password: false,
        }));
      }
    }
    if (name == "doc_number") {
      aadharNumber(setError, setAadharMessage, value);
    }
    if (name === "contact_number") {
      ContactValidator(setError, setMessage, value);
    }
    setInitialValues((prevMchObject) => ({
      ...prevMchObject,
      [name]: value,
    }));
    if (event.target.name == "state_id") {
      setStateId(event.target.value);
    }
    if (event.target.name == "district_id") {
      setDistrictId(event.target.value);
    }
    if (event.target.name == "place_id") {
      setPlaceId(event.target.value);
    }

    if (name === "gender") {
      setInitialValues((prevMchObject) => ({
        ...prevMchObject,
        gender: value,
      }));
    }
    if (name === "works_at") {
      setInitialValues((prevMchObject) => ({
        ...prevMchObject,
        works_at: value,
      }));
    }
  };
  const handleSubmit = async () => {
    if (location.state.action == "edit") {
      try {
        const resp = await updateDataOperator(
          location.state.doId,
          {},
          initialValues
        );
        console.log(" ==========> resp", resp);
        if (resp.status < 200 || resp.status > 300) {
          errorToast(enqueueSnackbar, resp.data);
        } else {
          successToast(enqueueSnackbar, resp.data.message);
          navigate("/home/data-operator");
        }
      } catch (err) {
        errorToast(enqueueSnackbar);
      }
    } else {
      try {
        const response = await addDataOper(initialValues);
        console.log(" ==========> response 1234", response);
        if (response.status < 200 || response.status > 300) {
          errorToast(enqueueSnackbar, response.data);
        } else {
          successToast(enqueueSnackbar, response.data.message);
          navigate("/home/data-operator");
        }
      } catch (err) {
        errorToast(enqueueSnackbar);
      }
      // console.log("response", response);
    }
  };
  useEffect(() => {
    if (location.state.action == "view" || location.state.action == "edit")
      getDataOperatorById();
  }, []);

  const getDataOperatorById = async () => {
    const respose = await readDataOperator({ id: location.state.doId });
    const val = respose.data.body;
    setInitialValues({
      name: val[0].name,
      contact_number: val[0].contact_number,
      blood_group: val[0].blood_group,
      gender: val[0].gender,
      doc_type: val[0].doc_type,
      doc_number: val[0].doc_number,
      qualification: val[0].qualification,
      username: val[0].username,
      works_at: val[0].works_at,
    });
    setSelectedId(val[0].works_at);
    setStateId(val[0].state_id);
    setDistrictId(val[0].district_id);
    setPlaceId(val[0].place_id);

    if (location.state.action == "view" || location.state.action == "edit") {
      if (stateId == "" || districtId == "" || placeId == "") {
        return null;
      }
    }
  };

  useEffect(() => {
    setInitialValues((prevValues) => ({
      ...prevValues,
      state_id: stateId,
      district_id: districtId,
      place_id: placeId,
    }));
  }, [stateId, districtId, placeId]);

  const closeModalDropDown = () => {
    setShowDropDown(!showDropdown);
  };

  const resetForm = () => {
    setInitialValues({
      name: "",
      contact_number: "",
      //  email: "",
      state_id: "",
      district_id: "",
      place_id: "",
      doc_number: "",
      doc_type: "",
      qualification: "",
      username: "",
      password: "",
      gender: "",
      blood_group: "",
    });
  };
  const handleDisablebtn = () => {
    if (
      error.contact_number == true ||
      initialValues.contact_number == "" ||
      !initialValues.name ||
      !initialValues.gender ||
      error.aadhar == true ||
      (!isMchAdmin ? !selectedId : false) ||
      !initialValues.username ||
      (location.state.action === "add" &&
        (!initialValues.password || initialValues.password.length < 8))
    ) {
      return true;
    }
    return false;
  };
  console.log("location.state.action", location.state.action);
  useEffect(() => {
    if (initialValues.doc_number == "") {
      setError((prev) => ({
        ...prev,
        aadhar: false,
      }));
    }
  }, [initialValues.doc_number]);
  return (
    <Layout sideNavState={sideNavState} setSideNavState={setSideNavState}>
      {/* <ShadowContainer padding="30px" styles={{ marginBottom: "24px" }}> */}
      <span
        className="back-btn "
        onClick={() => navigate("/home/data-operator")}
      >
        <img src={backBtnIcon} alt="" />
        <span>Back</span>
      </span>
      <div className="form-style px-3 py-3">
        {showDropdown && (
          <PopupDropdwon
            isHidden={showDropdown}
            onClose={closeModalDropDown}
            data={imsData}
            setSelectedId={setSelectedId}
            totalCount={totalCount}
            setImsData={setImsData}
            page={popUpPage}
            pageSize={pageSize}
            setPage={setPopUpPage}
            setPageSize={setPageSize}
            offset={offset}
            setOffset={setOffset}
            setTotalCount={setTotalCount}
          />
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ handleBlur, handleSubmit, values, handleReset }) => (
            <Form>
              <>
                <div className="hospital-form-heading">
                  {location.state.action == "add"
                    ? "Add New"
                    : location.state.action == "edit"
                      ? "Edit New"
                      : "View New"}{" "}
                  Data Operator
                </div>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <RenderInput
                      name="name"
                      value={initialValues.name}
                      onChange={handleChange}
                      title="Name"
                      placeholder="Name"
                      requiredprop={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RenderInput
                      name="contact_number"
                      value={initialValues.contact_number}
                      onChange={handleChange}
                      title="Contact Number"
                      placeholder="Contact Number"
                      type="number"
                      className="no-spinners"
                      requiredprop={true}
                    />
                    {error.contact_number && (
                      <p className="text-danger">{message}</p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <RenderSelect
                      name="state_id"
                      defaultValue={initialValues.state_id}
                      placeholder="Select District"
                      value={initialValues.state_id}
                      title="State"
                      options={stateDropDown}
                      onChange={handleChange}
                      optType="state"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RenderSelect
                      name="district_id"
                      defaultValue={districtId}
                      placeholder="Select District"
                      value={initialValues.district_id}
                      title="District"
                      onChange={handleChange}
                      options={distDrop}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RenderSelect
                      name="place_id"
                      defaultValue={placeId}
                      value={initialValues.place_id}
                      onChange={handleChange}
                      title="Place"
                      placeholder="Place"
                      options={placeDrop}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RenderSelectBlood
                      name="blood_group"
                      value={initialValues.blood_group}
                      onChange={handleChange}
                      title="Blood Group"
                      placeholder="Blood Group"
                      options={bloodGoup}
                    // requiredprop={true}
                    />
                    {/* </Grid> */}
                  </Grid>
                  {/* </Grid> */}
                  <Grid item xs={4}>
                    <GenderSelect
                      name="gender" // Ensure the name prop is set to "gender"
                      title="Gender"
                      value={initialValues.gender || ""}
                      onChange={handleChange}
                      options={GENDER_OPTIONS}
                      placeholder="Gender"
                      optType="gender"
                      requiredprop={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DocSelect
                      name="doc_type"
                      title="Document Type"
                      value={initialValues.doc_type}
                      defaultValue={initialValues.doc_type}
                      onChange={handleChange}
                      options={DOCUMENT_OPTIONS}
                    // requiredprop={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RenderInput
                      name="doc_number"
                      title="Document Number"
                      value={initialValues.doc_number}
                      onChange={handleChange}
                      placeholder={`Enter Number`}
                      type="number"
                    // requiredprop={true}
                    />
                    {error.aadhar && (
                      <p className="text-danger">{aadharmessage}</p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <QualificationSelect
                      name="qualification"
                      defaultValue={initialValues.qualification}
                      value={initialValues.qualification}
                      onChange={handleChange}
                      title="Education"
                      placeholder="Education"
                      options={qualificationDD}
                      optType="qualification"
                    // requiredprop={true}
                    />
                  </Grid>
                  {location.state.action === "add" && (
                    <Grid item xs={4}>
                      <RenderInput
                        name="username"
                        value={initialValues.username}
                        onChange={handleChange}
                        title="User Name"
                        placeholder="User Name"
                        requiredprop={true}
                      />
                    </Grid>
                  )}

                  {location.state.action === "add" && (
                    <Grid item xs={4}>
                      <RenderInput
                        name="password"
                        value={initialValues.password}
                        onChange={handleChange}
                        title="Password"
                        placeholder="Password"
                        requiredprop={true}
                        type={!showPassword ? "password" : "text"}
                        toggleShowPassword={toggleShowPassword}
                        showPassword={showPassword}
                        fromPassword={true}
                      />
                      {error.password && (
                        <p className="text-danger">{passMessage}</p>
                      )}
                    </Grid>
                  )}

                  {/* <Grid item xs={4}> */}
                  {/* <select style={{ width: "100%" }}>
                      <option>Select MCH</option>
                      {imsData.length > 0 &&
                        imsData?.map((item) => (
                          <option
                            style={{ visibility: "hidden" }}
                            value={item.id}
                            key={item.id}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select> */}
                  {/* </Grid> */}
                  {/* {userProfile.userGroup != "mch-admins" && ( */}

                  {!isMchAdmin ? (
                    <Grid
                      item
                      xs={4}
                      onClick={() => {
                        setShowDropDown(true);
                      }}
                    >
                      <RenderSelectMch
                        name="works_at"
                        disabled
                        title="Select MCH"
                        // value={initialValues.works_at}
                        value={selectedId}
                        onChange={(e) => console.log("jkl")}
                        options={imsData}
                        requiredprop={true}

                      // optType="mch"
                      />
                    </Grid>
                  ) : null}

                  {/* )} */}

                  {/* <Grid item xs={4}>
                <RenderInput
                  name="ims"
                  value={values.ims}
                  onChange={handleChange}
                  title="Select IMS"
                  placeholder="IMS"
                />
              </Grid> */}
                </Grid>
                {(location.state.action == "add" ||
                  location.state.action == "edit") && (
                    <p className="text-danger note-text">
                      <span>
                        <LabelImportantIcon />
                      </span>
                      : Kindly complete all the required fields to submit the
                      form.
                    </p>
                  )}
                <div
                  style={{
                    height: 1,
                    backgroundColor: "#707070",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                />
                {(location.state.action == "edit" ||
                  location.state.action == "add") && (
                    <Box className="button-pair-data-operator">
                      <HollowButton
                        name="Reset"
                        type="button"
                        onClick={resetForm}
                        className="hollow-button"
                      />
                      <PrimaryButton
                        name={location.state.action == "edit" ? "Update" : "Add"}
                        onClick={() => handleSubmit}
                        className="hollow-button"
                        disableprop={handleDisablebtn()}
                      />
                    </Box>
                  )}
              </>
            </Form>
          )}
        </Formik>
        {/* </ShadowContainer> */}
      </div>
    </Layout>
  );
};

export default DataOperatorForm;
