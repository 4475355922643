import React, { useContext } from "react";
import Layout from "../../../../LayoutComp/Layout";
import { SideNavContext } from "../../../../SideNavContextAPI/SideNavContext";
import FullWidthTabs from "../../../../TabComp/FullWidthTabs";
import GeneralInfoComp from "../Tabs/GeneralInfoComp";
import HealthInfo from "../Tabs/HealthInfo";
import Prescription from "../Tabs/Prescription";
import LabTest from "../Tabs/LabTest";
import Pharmacy from "../Tabs/Pharmacy";
import MedicalReports from "../Tabs/MedicalReports";
import backBtnIcon from "../../../../../Assets/icons/back.svg";
import { useLocation, useNavigate } from "react-router-dom";
import MedicalDiagnosis from "../Tabs/MedicalDiagnosis";
import GeneralInfoTab from "../../../../Patient/HealthCampPatient/View/GeneralInfoTab/GeneralInfoTab";
const GeneralIfoTabsPage = ({ sideNavState, setSideNavState }) => {
  //   const { sideNavState, setSideNavState } = useContext(SideNavContext);
  const navigate = useNavigate();
  const location=useLocation()
  const childrenArry = [
    <GeneralInfoTab patientId={location.pathname.split("/")[3]} />,
    <HealthInfo />,
    <MedicalDiagnosis />,
    // <Prescription />,
    // <Pharmacy />,
    <MedicalReports />,
  ];
  const tabstitle = [
    "General Information",
    "Health Information",
    "Medical Diagnosis",
    // "Prescription",
    // "Pharmacy",
    "Medical report",
  ];
  return (
    <Layout
      sideNavState={sideNavState}
      setSideNavState={setSideNavState}
      hidenav={true}
    >
      <div className="py-2 px-3" style={{ cursor: "pointer" }}>
        <span className="back-btn-row" onClick={() => navigate(-1)}>
          <img src={backBtnIcon} alt="" />
          <span>Back</span>
        </span>
      </div>
      <FullWidthTabs tabstitle={tabstitle} DoctorsTabData={childrenArry} />
    </Layout>
  );
};

export default GeneralIfoTabsPage;
