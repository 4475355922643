import { createService, deleteService, readService, updateService } from "../HttpService";


export const addMedicine = (body) => {
  return createService("pharmacy", body);
};

export const medicineList=(id,params)=>{
    return readService(`pharmacy/${id}`, params);
}

export const updateMedicine = (healthId,id, params, body) => {
  return updateService(
    `pharmacy/${healthId}/${id}`,
    params,

    body
  );
};
export const deletedMdeicine = (healthId,id) => {
  return deleteService(
    `pharmacy/${healthId}/${id}`
  );
};

export const getPharmacyInventory = (id, params) => {
  return readService(`pharmacyInventory/${id}`, params);
};