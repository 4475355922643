import React, { useState } from "react";
import TableComp from "../../../Table/TableComp";
import PrimaryButton from "../../../../PrimaryButton/PrimaryButton";
import AddIncon from "../../../../../Assets/icons/addIcon.svg";
import backBtnIcon from "../../../../../Assets/icons/back.svg";
import redSwitch from "../../../../../Assets/icons/switch-red.svg";
import "./medicaldiagnosis.scss";
import { Form, Button } from "react-bootstrap";
import { DatePicker } from "@mui/x-date-pickers";
import { TablePagination } from "@mui/material";

import view from "../../../../../Assets/icons/view.svg";
import edit from "../../../../../Assets/icons/edit.svg";
import moment from "moment";
import ReferredToMch from "./Components/MedicalDiagnosisTabComponents/ReferredToMch";
import LabTests from "./Components/MedicalDiagnosisTabComponents/LabTests";
import Pharmacy from "./Components/MedicalDiagnosisTabComponents/Pharmacy";
import NewMedicalDiagnosisModal from "./Components/MedicalDiagnosisTabComponents/NewMedicalDiagnosisModal";
import { useEffect } from "react";
import { useSnackbar } from "notistack";

import {
  readService,
  updateService,
} from "../../../../../Services/HttpService";
import { useLocation, useParams } from "react-router-dom";
import AddMedicalDiagnosis from "./Components/MedicalDiagnosisTabComponents/AddMedicalDiagnosis";
import { errorToast, successToast } from "../../../../../Utils/toasters";
// import edit from "../../Assets/icons/edit.svg";

const tableData = [
  {
    "Sl No": 1,
    Date: "7th Feb 2023",
    ActionDoctorMedicalDiag: "",
  },
];

const PatientVisitTable = (props) => {
  const { navigateTo, editNavigateTo, setCurrentType } = props;

  const params = useParams();

  const { id } = params;

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [count, setCount] = useState(0);
  const [allData, setAllData] = useState([]);

  const [page, setPage] = React.useState(0);

  // const patientId = "23000004";

  const getAllVisits = async (limit, offset, updateCount) => {
    const result = await readService(
      `patient/visits/${id}?limit=${limit}&offset=${offset}`
    );

    setAllData(result?.data?.body);
    if (updateCount) {
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    getAllVisits(5, 0, 1);
  }, []);

  const handleChangePage = (event, newPage) => {
    let pageIndex = newPage;
    setPage(newPage);
    let offSet = rowsPerPage * pageIndex;
    getAllVisits(rowsPerPage, offSet, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getAllVisits(event.target.value, 0, 1);
  };

  const handleAction = () => {
    console.log(" ==========> ");
  };

  return (
    <div id="patient-visit-table">
      <div className="medical-diagnosis-table">
        <div className="patientdo" style={{ margin: "2%" }}>
          {allData?.length ? (
            <table className="" style={{ width: "100%", margin: 0 }}>
              <thead>
                <tr>
                  <th>Sl No</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allData?.map((item, index) => {
                  const { patient_id, visit_id, visit_time } = item;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{moment(visit_time).format("Do MMM YYYY")}</td>

                      <td>
                        <span
                          onClick={() => {
                            setCurrentType("View");
                            navigateTo(visit_id);
                          }}
                          className="me-1"
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={view}
                            alt=""
                          />
                        </span>
                        <span
                          className="ms-1"
                          onClick={() => {
                            setCurrentType("Edit");
                            navigateTo(visit_id);
                          }}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={edit}
                            alt=""
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : null}

          {allData?.length ? (
            <div className="flex justify-content-end table-page">
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          ) : (
            <div
              className="text-center d-flex justify-content-center
            align-items-center"
              style={{ minHeight: "10rem", fontSize: "24px" }}
            >
              <p>No Records Found!</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MedicalDiagnosisTable = (props) => {
  const {
    data,
    setShowModal,
    setSelectedDiagnosis,
    setModalAction,
    setCurrentType,
    type,
  } = props;

  const viewType = type === "View";

  const [allData, setAllData] = useState([]);

  useEffect(() => {
    setAllData(data);
  }, [data]);

  const handleAction = (type) => {
    setModalAction(type);
  };

  return (
    // <div id="doctorId" className="py-2 box-shadow-cls">
    <div className="medical-diagnosis-table">
      <div className="patientdo" style={{ margin: "2%" }}>
        {allData?.length ? (
          <table
            id="medical-diagnosis-table"
            className=""
            style={{ width: "100%", margin: 0 }}
          >
            <thead>
              <tr>
                <th>Sl No</th>
                <th>Title Of problem</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* {doctorList?.data?.doctors.map((item, i) => { */}
              {/* return ( */}
              {allData?.map((item, index) => {
                const { start_date, issue } = item;
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{issue}</td>
                    <td>
                      {start_date
                        ? moment(start_date).format("Do MMM YYYY")
                        : "-"}
                    </td>

                    <td>
                      <span
                        onClick={() => {
                          setShowModal(true);
                          setSelectedDiagnosis(item);
                          handleAction("View");
                        }}
                        className="me-1"
                      >
                        <img style={{ cursor: "pointer" }} src={view} alt="" />
                      </span>
                      {!viewType ? (
                        <span
                          className="ms-1"
                          onClick={() => {
                            setShowModal(true);
                            setSelectedDiagnosis(item);
                            handleAction("Edit");
                          }}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={edit}
                            alt=""
                          />
                        </span>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}

        {allData?.length ? (
          <div className="flex justify-content-end table-page">
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={allData.length}
              page={0}
              onPageChange={() => console.log(" ==========> helo")}
              rowsPerPage={5}
              onRowsPerPageChange={() => console.log(" ==========> helo")}
            />
          </div>
        ) : (
          <div
            className="text-center d-flex justify-content-center
            align-items-center"
            style={{ minHeight: "10rem", fontSize: "24px" }}
          >
            <p>No Records Found!</p>
          </div>
        )}
      </div>
    </div>
  );
};

const MedicalDiagnosis = () => {
  const params = useParams();

  const { id } = params;
  const { search } = useLocation();
  const urlParams = Object.fromEntries([...new URLSearchParams(search)]);
  const { healthCampId } = urlParams;

  const [action, setAction] = useState(null);

  const [activeTab, setActiveTab] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [visitId, setVisitId] = useState(null);
  const [mainObject, setMainObject] = useState({});

  const [doctorAdvice, setDoctorAdvice] = useState({});
  const [doctorDiagnosis, setDoctorDiagnosis] = useState({});
  const [labTest, setLabTest] = useState({});

  const [modalAction, setModalAction] = useState("");

  const [selectedDiagnosis, setSelectedDiagnosis] = useState({});

  const addHealthInfoHandler = (actionVal) => {
    setAction(actionVal);
  };
  const navigateTo = (visit) => {
    setVisitId(visit);
    setAction("view");
  };
  const editNavigateTo = () => {
    setAction("edit");
  };

  const { enqueueSnackbar } = useSnackbar();

  const updateDoctorDiagnosis = async (array) => {
    console.log(" ==========> array before update", array);
    try {
      const result = await updateService(
        `patient/diagnosis/${id}/${visitId}`,
        {},
        {
          doctor_diagnosis: JSON.stringify(array),
        }
      );
      successToast(enqueueSnackbar);
      await getDiagnosisData(5, 0, 1);
      // setShowModal(false);
      // setActiveTab(activeTab + 1);
    } catch (error) {
      errorToast(enqueueSnackbar);
    }
  };
  // doctor_diagnosis

  const getDiagnosisData = async (limit, offset, updateCount) => {
    console.log(" ==========> visitId", visitId);
    if (visitId) {
      const result = await readService(`patient/diagnosis/${id}/${visitId}`);
      setMainObject(result?.data);

      const { doctor_advice, doctor_diagnosis, lab_test } = result?.data;
      setDoctorAdvice(doctor_advice);
      setDoctorDiagnosis(doctor_diagnosis);
      setLabTest(lab_test);
    }
  };

  useEffect(() => {
    getDiagnosisData();
  }, [visitId]);

  const [currentType, setCurrentType] = useState("");

  return (
    <div id="coctormedicaldiag">
      {action == "Add" ? (
        <>
          <div className="border-cls">
            <div className="py-2 px-3">
              <span
                className="back-btn-row"
                onClick={() => setAction(null)}
                style={{ cursor: "pointer" }}
              >
                <img src={backBtnIcon} alt="" />
                <span>Back</span>
              </span>
            </div>
            <div className="medical-diag-container text-center px-3 ">
              <button
                className="btn mb-2"
                style={{ backgroundColor: "#191BC3", color: "white" }}
              >
                Medical Diagnosis
              </button>

              <div className="d-flex row py-3">
                <div className="col-lg-6 col-md-6 col-sm-12 text-start ">
                  <Form.Group className="mb-3 " controlId="formBasicEmail">
                    <Form.Label className="font-style-bold">
                      Allergies
                    </Form.Label>
                    <Form.Control
                      type=""
                      placeholder="Write Here"
                      //   onChange={(e) => setSpecialisation(e.target.value)}
                      //   value={specialisation}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 text-start date-picker-cls ">
                  <p>To Date</p>
                  <DatePicker
                    wrapperClassName="datepicker"
                    //   inputStyle={{ height: "20px" }}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 text-start date-picker-cls ">
                  <p>To Date</p>
                  <DatePicker
                    wrapperClassName="datepicker"
                    //   inputStyle={{ height: "20px" }}
                  />
                </div>
              </div>
              <hr></hr>
              <div className="d-flex row ">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Severity</Form.Label>
                    <Form.Select aria-label="select">
                      <option>UnComplicated</option>
                      <option value="1">abc</option>
                      <option value="2">abc</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Category</Form.Label>
                    <Form.Select aria-label="select">
                      <option>Eye</option>
                      <option value="1">abc</option>
                      <option value="2">abc</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Occurrence</Form.Label>
                    <Form.Select aria-label="select">
                      <option>Inputs required</option>
                      <option value="1">abc</option>
                      <option value="2">abc</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Verification Status</Form.Label>
                    <Form.Select aria-label="select">
                      <option>Confirmed</option>
                      <option value="1">abc</option>
                      <option value="2">abc</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Outcome</Form.Label>
                    <Form.Select aria-label="select">
                      <option>Resolved</option>
                      <option value="1">abc</option>
                      <option value="2">abc</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <hr className="mt-3"></hr>

                <div className="d-flex flex-row py-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="px-2">
                      <p
                        className=""
                        style={{ fontWeight: "bold", textAlign: "start" }}
                      >
                        Comments
                      </p>
                      {/* <div className="comments" style={{ marginRight: "10px" }}> */}
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontWeight: 600 }}>
                          {/* <b>Biography</b> */}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={8}
                          placeholder="Specialization"
                          // onChange={(e) => setSpecialisation(e.target.value)}
                          // value={specialisation}
                        />
                      </Form.Group>
                      {/* </div> */}
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 "
                    //   style={{ marginLeft: "10px" }}
                  >
                    <div className="px-2">
                      <p
                        className=""
                        style={{ fontWeight: "bold", textAlign: "start" }}
                      >
                        Advice
                      </p>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontWeight: 600 }}>
                          {/* <b>Biography</b> */}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={8}
                          placeholder="Specialization"
                          // onChange={(e) => setSpecialisation(e.target.value)}
                          // value={specialisation}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row d-flex justify-content-center py-3">
                  <div
                    className="col-lg-4 col-md-6 col-sm-12"
                    style={{ textAlign: "left" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Referred To MCH
                      </Form.Label>
                      <Form.Select aria-label="select">
                        <option>Resolved</option>
                        <option value="1">abc</option>
                        <option value="2">abc</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontWeight: "bold" }}>
                        MCH Name
                      </Form.Label>
                      <Form.Select aria-label="select">
                        <option>Resolved</option>
                        <option value="1">abc</option>
                        <option value="2">abc</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontWeight: 600 }}>
                        {/* <b>Biography</b> */}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={6}
                        placeholder="Specialization"
                        // onChange={(e) => setSpecialisation(e.target.value)}
                        // value={specialisation}
                      />
                    </Form.Group>
                  </div>
                </div>
                <hr></hr>
                <div className="pb-3">
                  <p style={{ textAlign: "start", fontWeight: "bold" }}>
                    Prescription
                  </p>
                  <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Label style={{ fontWeight: 600 }}></Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={20}
                      placeholder="Specialization"
                      // onChange={(e) => setSpecialisation(e.target.value)}
                      // value={specialisation}
                    />
                  </Form.Group>
                </div>
                <hr></hr>
                <div>
                  <button
                    className="btn mb-3 px-4 my-3"
                    style={{ backgroundColor: "#191BC3", color: "white" }}
                  >
                    Lab Test
                  </button>
                </div>

                <div className="lab-test py-4" style={{ display: "flex" }}>
                  <div className="dashboard-table-container box overflow-auto ">
                    <table className="">
                      <thead>
                        <tr>
                          <th>Test Name</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {[1, 2, 3, 4, 5].map((item, i) => {
                            return (
                              <tr
                                // onClick={() => navigate("/home/pathologistView")}
                                style={{ cursor: "pointer" }}
                              >
                                <td>Mobile No.</td>
                                <td>
                                  <div
                                    className="d-flex justify-content-center align-items-center"
                                    // onClick={generalInfoHandler}
                                  >
                                    <p className="viewAll-patho px-2 py-1">
                                      <img src={redSwitch} alt="profile_img" />
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      </tbody>
                    </table>
                  </div>
                  <div className="box">
                    {/* <div className="d-flex justify-content-end">
              <PrimaryButton
                name="Report"
                // img={<img src={UploadIcon} />}
                className="hollow-button"
              />
            </div> */}
                    <h6>Description</h6>
                    <div className=" comments desc-box">
                      <p className="px-3 py-4">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for 'lorem ipsum' will uncover many web sites still in
                        their infancy. Various versions have evolved over the
                        years, sometimes by accident, sometimes on purpose
                        (injected humour and the like). It is a long established
                        fact that a reader will be distracted by the readable
                        content of a page when like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for 'lorem ipsum' will uncover many web sites still in
                        their infancy. Various versions have evolved over the
                        years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </p>
                      {/* <Form.Group
                    className="mb-3"
                    controlId="formBasicEmail"
                    style={{ height: "100%" }}
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Add Discription"
                      onChange={(e) => setDesc(e.target.value)}
                      value={desc}
                      style={{ height: "100%" }}
                    />
                  </Form.Group> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row ">
            <button
              className="reset-btn-nhealth me-3"
              variant="outline-primary"
              //   onClick={handleReset}
            >
              <b>Reset</b>
            </button>
            <button
              className="reset-btn-nhealth-save"
              // variant="outline-primary"
              //   onClick={handleReset}
            >
              <b>Submit</b>
            </button>
          </div>
        </>
      ) : action == "view" ? (
        <>
          <div className="py-2 px-3">
            <span
              className="back-btn-row"
              onClick={() => setAction(null)}
              style={{ cursor: "pointer" }}
            >
              <img src={backBtnIcon} alt="" />
              <span>Back</span>
            </span>
          </div>
          <div className="text-center view-border-cls">
            {/* <div className="d-flex justify-content-center align-items-center">
              <div className="btn-container">
                <button
                  className="btn mb-2"
                  style={{ backgroundColor: "#191BC3", color: "white" }}
                >
                  Medical diagnosis
                </button>
              </div>
            </div> */}
            {/* <div className=" flex flex-row mb-3  text-end">
              <PrimaryButton
                name="Add New Medical Diagnosis"
                img={<img src={AddIncon} />}
                className="py-2 px-3"
                style={{ color: "green" }}
                onClick={() => setShowModal(true)}
              />
            </div> */}
            <NewMedicalDiagnosisModal
              showModal={showModal}
              setShowModal={setShowModal}
              diagnosisDetail={selectedDiagnosis}
              mainObject={mainObject}
              updateDoctorDiagnosis={updateDoctorDiagnosis}
              modalAction={modalAction}
              // action=
            />
            {/* <MedicalDiagnosisTable /> */}
            <AddMedicalDiagnosis
              table={
                <MedicalDiagnosisTable
                  data={mainObject.doctor_diagnosis}
                  setShowModal={setShowModal}
                  type={currentType}
                  setSelectedDiagnosis={setSelectedDiagnosis}
                  setModalAction={setModalAction}
                  setCurrentType={setCurrentType}
                  // modalAction,setModalAction
                />
              }
              type={currentType}
              setShowModal={setShowModal}
              mainObject={mainObject}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setModalAction={setModalAction}
              open={activeTab === 1}
              onTopBarClick={() => {
                if (activeTab === 1) {
                  setActiveTab(0);
                } else {
                  setActiveTab(1);
                }
              }}
            />
            <ReferredToMch
              mainObject={mainObject}
              id={id}
              visitId={visitId}
              doctorAdvice={mainObject?.doctor_advice}
              activeTab={activeTab}
              type={currentType}
              setActiveTab={setActiveTab}
              // doctorDiagnosis={doctorDiagnosis}
              open={activeTab === 2}
              onTopBarClick={() => {
                if (activeTab === 2) {
                  setActiveTab(0);
                } else {
                  setActiveTab(2);
                }
              }}
            />
            <LabTests
              open={activeTab === 3}
              id={id}
              visitId={visitId}
              labTest={labTest}
              setLabTest={setLabTest}
              mainObject={mainObject}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              type={currentType}
              onTopBarClick={() => {
                if (activeTab === 3) {
                  setActiveTab(0);
                } else {
                  setActiveTab(3);
                }
              }}
            />
            <Pharmacy
              mainObject={mainObject}
              open={activeTab === 4}
              id={id}
              visitId={visitId}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              healthCampId={healthCampId}
              type={currentType}
              onTopBarClick={() => {
                if (activeTab === 4) {
                  setActiveTab(0);
                } else {
                  setActiveTab(4);
                }
              }}
            />
            {/* <div className="d-flex justify-content-start mt-3 ms-4 gap-2">
              {action !== "Edit" && (
                <Button
                  style={{ padding: "0px 40px" }}
                  //   className="reset-btn"
                  variant="outline-primary"
                  onClick={() => console.log(" ==========>clicked ")}
                >
                  <b>Reset</b>
                </Button>
              )}

              <div>
                <Button
                  //   style={{ padding: "10px 40px" }}
                  className="loginButton reset-btn"
                  variant="primary"
                  type="submit"
                  // onClick={handleClick}
                >
                  {action == "Edit" ? "Update" : "Save"}
                </Button>
              </div>
            </div> */}
          </div>
        </>
      ) : action == "edit" ? (
        <>
          <div className="border-cls">
            <div className="py-2 px-3">
              <span
                className="back-btn-row"
                onClick={() => setAction(null)}
                style={{ cursor: "pointer" }}
              >
                <img src={backBtnIcon} alt="" />
                <span>Back</span>
              </span>
            </div>
            <div className="medical-diag-container text-center px-3 ">
              <button
                className="btn mb-2"
                style={{ backgroundColor: "#191BC3", color: "white" }}
              >
                Medical Diagnosis
              </button>
              <div className="d-flex row py-3">
                <div className="col-lg-6 col-md-6 col-sm-12 text-start ">
                  <Form.Group className="mb-3 " controlId="formBasicEmail">
                    <Form.Label className="font-style-bold">
                      Allergies
                    </Form.Label>
                    <Form.Control
                      type=""
                      placeholder="Write Here"
                      //   onChange={(e) => setSpecialisation(e.target.value)}
                      //   value={specialisation}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 text-start date-picker-cls ">
                  <p>To Date</p>
                  <DatePicker
                    wrapperClassName="datepicker"
                    //   inputStyle={{ height: "20px" }}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 text-start date-picker-cls ">
                  <p>To Date</p>
                  <DatePicker
                    wrapperClassName="datepicker"
                    //   inputStyle={{ height: "20px" }}
                  />
                </div>
              </div>
              <hr></hr>
              <div className="d-flex row ">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Severity</Form.Label>
                    <Form.Select aria-label="select">
                      <option>UnComplicated</option>
                      <option value="1">abc</option>
                      <option value="2">abc</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Category</Form.Label>
                    <Form.Select aria-label="select">
                      <option>Eye</option>
                      <option value="1">abc</option>
                      <option value="2">abc</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Occurrence</Form.Label>
                    <Form.Select aria-label="select">
                      <option>Inputs required</option>
                      <option value="1">abc</option>
                      <option value="2">abc</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Verification Status</Form.Label>
                    <Form.Select aria-label="select">
                      <option>Confirmed</option>
                      <option value="1">abc</option>
                      <option value="2">abc</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Outcome</Form.Label>
                    <Form.Select aria-label="select">
                      <option>Resolved</option>
                      <option value="1">abc</option>
                      <option value="2">abc</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <hr className="mt-3"></hr>

                <div className="d-flex flex-row py-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="px-2">
                      <p
                        className=""
                        style={{ fontWeight: "bold", textAlign: "start" }}
                      >
                        Comments
                      </p>
                      {/* <div className="comments" style={{ marginRight: "10px" }}> */}
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontWeight: 600 }}>
                          {/* <b>Biography</b> */}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={8}
                          placeholder="Specialization"
                          // onChange={(e) => setSpecialisation(e.target.value)}
                          // value={specialisation}
                        />
                      </Form.Group>
                      {/* </div> */}
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 "
                    //   style={{ marginLeft: "10px" }}
                  >
                    <div className="px-2">
                      <p
                        className=""
                        style={{ fontWeight: "bold", textAlign: "start" }}
                      >
                        Advice
                      </p>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontWeight: 600 }}>
                          {/* <b>Biography</b> */}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={8}
                          placeholder="Specialization"
                          // onChange={(e) => setSpecialisation(e.target.value)}
                          // value={specialisation}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row d-flex justify-content-center py-3">
                  <div
                    className="col-lg-4 col-md-6 col-sm-12"
                    style={{ textAlign: "left" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Referred To MCH
                      </Form.Label>
                      <Form.Select aria-label="select">
                        <option>Resolved</option>
                        <option value="1">abc</option>
                        <option value="2">abc</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontWeight: "bold" }}>
                        MCH Name
                      </Form.Label>
                      <Form.Select aria-label="select">
                        <option>Resolved</option>
                        <option value="1">abc</option>
                        <option value="2">abc</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontWeight: 600 }}>
                        {/* <b>Biography</b> */}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={6}
                        placeholder="Specialization"
                        // onChange={(e) => setSpecialisation(e.target.value)}
                        // value={specialisation}
                      />
                    </Form.Group>
                  </div>
                </div>
                <hr></hr>
                <div className="pb-3">
                  <p style={{ textAlign: "start", fontWeight: "bold" }}>
                    Prescription
                  </p>
                  <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Label style={{ fontWeight: 600 }}></Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={20}
                      placeholder="Specialization"
                      // onChange={(e) => setSpecialisation(e.target.value)}
                      // value={specialisation}
                    />
                  </Form.Group>
                </div>
                <hr></hr>
                <div>
                  <button
                    className="btn mb-3 px-4 my-3"
                    style={{ backgroundColor: "#191BC3", color: "white" }}
                  >
                    Lab Test
                  </button>
                </div>

                <div className="lab-test py-4" style={{ display: "flex" }}>
                  <div className="dashboard-table-container box overflow-auto ">
                    <table className="dashboard-table ">
                      <thead>
                        <tr>
                          <th>Test Name</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {[1, 2, 3, 4, 5].map((item, i) => {
                            return (
                              <tr
                                // onClick={() => navigate("/home/pathologistView")}
                                style={{ cursor: "pointer" }}
                              >
                                <td>Mobile No.</td>
                                <td>
                                  <div
                                    className="d-flex justify-content-center align-items-center"
                                    // onClick={generalInfoHandler}
                                  >
                                    <p className="viewAll-patho px-2 py-1">
                                      <img src={redSwitch} alt="profile_img" />
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      </tbody>
                    </table>
                  </div>
                  <div className="box">
                    {/* <div className="d-flex justify-content-end">
              <PrimaryButton
                name="Report"
                // img={<img src={UploadIcon} />}
                className="hollow-button"
              />
            </div> */}
                    <h6>Description</h6>
                    <div className=" comments desc-box">
                      <p className="px-3 py-4">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for 'lorem ipsum' will uncover many web sites still in
                        their infancy. Various versions have evolved over the
                        years, sometimes by accident, sometimes on purpose
                        (injected humour and the like). It is a long established
                        fact that a reader will be distracted by the readable
                        content of a page when like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for 'lorem ipsum' will uncover many web sites still in
                        their infancy. Various versions have evolved over the
                        years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </p>
                      {/* <Form.Group
                    className="mb-3"
                    controlId="formBasicEmail"
                    style={{ height: "100%" }}
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Add Discription"
                      onChange={(e) => setDesc(e.target.value)}
                      value={desc}
                      style={{ height: "100%" }}
                    />
                  </Form.Group> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row mt-3">
            <button
              className="reset-btn-nhealth-save"
              // variant="outline-primary"
              //   onClick={handleReset}
            >
              <b>Update</b>
            </button>
          </div>
        </>
      ) : (
        <>
          {/* <TableComp
            isSearchBar={false}
            isFilter={false}
            tableData={tableData}
            navigateTo={navigateTo}
            editNavigateTo={editNavigateTo}
          /> */}
          <PatientVisitTable
            setCurrentType={setCurrentType}
            navigateTo={navigateTo}
            editNavigateTo={editNavigateTo}
          />
        </>
      )}
    </div>
  );
};

export default MedicalDiagnosis;
