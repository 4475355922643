import {
  Navigate,
  useRoutes,
} from "react-router-dom";
import AuthLayout from "../Screens/auth/auth-layout";
import Login from "../Components/Login/login";
import ForgotPassword from "../Components/ForgotPassword/forgotPassword";
import NewPassword from "../Components/NewPassword/newPassword";
import VerificationCode from "../Components/VerificationCode/verificationCode";
import Home from "../Screens/Home/Home";
import {  useState } from "react";
import Doctors from "../Components/Doctors/doctors";
import CreateHealthCamp from "../Components/CreateHealthCamp/CreateHealthCamp";
import HealthCamp from "../Components/HealthCamp/HealthCamp";
import Patient from "../Components/Patient/Patient";
import PatientCreateViewEdit from "../Components/PatientCreateViewEdit/PatientCreateViewEdit";
import HealthCampPatient from "../Components/HealthCampPatient/HealthCampPatient";
import Dashboard from "../Screens/dashboard/dashboard";
import View from "../Components/Patient/HealthCampPatient/View/View";
import Nurses from "../Components/Nurses/Nurses";
import HealthCenterView from "../Components/Patient/HealthCenterView/HealthCenterView";
import AddPatient from "../Components/Patient/HealthCampPatient/Add Patients/AddPatient";
import ViewAllPatient from "../Components/Patient/HealthCampPatient/Add Patients/ViewAllPatients/ViewAllPatient";
import PatientDO from "../Components/DataOperator/Patient/PatientDO";
import AllPatientsPage from "../Components/DataOperator/Patient/AllPatientsPage/AllPatientsPage";
import AddPatientForm from "../Components/DataOperator/Patient/AddPatientForm";
import QuickRegistration from "../Components/DataOperator/Patient/QuickRegistration";
import HealthAndWellness from "../Components/HealthAndWellness/HealthAndWellness";
import HealthCampView from "../Components/HealthAndWellness/HealthCampView";
import PathologistPatient from "../Components/Pathologist/Pathologist";
import PathologistViewDetails from "../Components/Pathologist/PathologistViewDetails/PathologistViewDetails";
import PathologistPatientDetails from "../Components/Pathologist/PathologistPatientDetail/PathologistPatientDetail";
import Admin from "../Components/Admin/Admin";
import AdminForm from "../Components/Admin/AdminForm/AdminForm";
import DataOperator from "../Components/DataOperator/DataOperator";
import DataOperatorForm from "../Components/DataOperator/DataOperatorForm/DataOperatorForm";
import HealthSurvey from "../Components/HealthSurvey/HealthSurvey";
import CreateSurvey from "../Components/HealthSurvey/CreateSurvey/CreateSurvey";
// import Parmacist from "../Components/Parmacist/Parmacist";
import ParmacistView from "../Components/Parmacist/ParmacistView/ParmacistView";
import MedicineDetails from "../Components/Parmacist/MedicineDetails/MedicineDetails";
import HealthSurveyDetail from "../Components/HealthSurvey/Details/HealthSurveyDetail";
import Hospital from "../Components/Hospital/Hospital";
import CreateHospital from "../Components/Hospital/CreateHospital/CreateHospital";
// import HospitalDetails from "../Components/Hospital/HospitalDetails/HospitaLDetails.js";
// import DoctorIndex from "../Components/Doctorsvtwo/DoctorIndex";
import PatientTabs from "../Components/Doctorsvtwo/PatientTablePage/PatientTabs";
import GeneralIfoTabsPage from "../Components/Doctorsvtwo/PatientTablePage/HealthCampPatient/GeneralInfoTabsPage/GeneralIfoTabsPage";
// import { SideNavContext } from "../Components/SideNavContextAPI/SideNavContext";
// import LabTest from "../Components/Doctorsvtwo/PatientTablePage/HealthCampPatient/Tabs/LabTest";
import MCHTabsPage from "../Components/Doctorsvtwo/PatientTablePage/MCHReferredPat/MCHTabsPage/MCHTabsPage";
// import NurseIndex from "../Components/NursesvTwo/NurseIndex";
import NursePatientTabs from "../Components/NursesvTwo/NurseTablePage/NursePatientTabs";
import NurseGeneralInfoTab from "../Components/NursesvTwo/NurseTablePage/NurseHealthCamp/NurseGeneralInfTabsPage/NurseGeneralInfoTab";
import NMCHTabs from "../Components/NursesvTwo/NurseTablePage/NurseHealthCamp/NurseGeneralInfTabsPage/NurseMCHreferred pat/NMCHTabs/NMCHTabs";
import CoPathologist from "../Components/CoPathologist/CoPathologist";
import CoParmacist from "../Components/CoPharmacist/CoParmacist";
import Bus from "../Components/Bus/Bus";
import BusDriver from "../Components/BusDriver/BusDriver";
import PageNotFound from "../Components/NotFound/PageNotFound";
import { ProtectedRoute } from "../Components/ProtectedRoute/ProtectedRoute";
import Coordinator from "../Components/Coordinator-ims/Coordinator";
import PharmacyIms from "../Components/Pharmacy/PharmacyIms";

import CoPharmacy from "../Components/CoPharmacy/CoPharmacy";

import UploadReport from "../Components/Pathologist/UploadReportView/UploadReport";
import LabReport from "../Components/Pathologist/LabReport/LabReport";
import Profile from "../Components/Account/Profile/Profile";

export default function Router() {
  const [sideNavState, setSideNavState] = useState(true);

  const routes = useRoutes([
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        { element: <Navigate to="login" />, index: true },
        { path: "login", element: <Login /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "verification-code", element: <VerificationCode /> },
        { path: "new-password", element: <NewPassword /> },
      ],
    },
    {
      path: "home",
      element: (
        <Home sideNavState={sideNavState} setSideNavState={setSideNavState} />
      ),
      children: [
        {
          path: "nurses",
          element: (
            <>
              {/* <Nurses
                sideNavState={sideNavState}
                setSideNavState={setSideNavState}
              /> */}
              <ProtectedRoute
                element={
                  <Nurses
                    sideNavState={sideNavState}
                    setSideNavState={setSideNavState}
                  />
                }
              />
            </>
          ),
        },
        {
          path: "dashboard",
          element: (
            // <Dashboard
            //   sideNavState={sideNavState}
            //   setSideNavState={setSideNavState}
            // />
            
            <ProtectedRoute
              element={
                <Dashboard
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },

        {
          path: "doctors",
          element: (
            <ProtectedRoute
              element={
                <Doctors
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "admin",
          element: (
            <ProtectedRoute
              element={
                <Admin
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "health-survey",
          element: (
            <ProtectedRoute
              element={
                <HealthSurvey
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "data-operator",
          element: (
            <ProtectedRoute
              element={
                <DataOperator
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "admin/add-new",
          element: (
            <AdminForm
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "health-survey/add-new",
          element: (
            <CreateSurvey
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "health-survey/details/:id",
          element: (
            <HealthSurveyDetail
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "data-operator/form",
          element: (
            <DataOperatorForm
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "healthcamp",
          element: (
            <ProtectedRoute
              element={
                <HealthCamp
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "hospital",
          element: (
            <ProtectedRoute
              element={
                <Hospital
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "hospital/view",
          element: (
            <CreateHospital
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "hospital/edit",
          element: (
            <CreateHospital
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "hospital/add-new",
          element: (
            <CreateHospital
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "createhealthcamp",
          element: (
            <CreateHealthCamp
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },

        {
          path: "/home/Profile",
          element: (
            <Profile
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "patient",
          element: (
            <ProtectedRoute
              element={
                <Patient
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "view",
          element: (
            <View
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "healthcenterview",
          element: (
            <HealthCenterView
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "addnewpatient",
          element: (
            <AddPatient
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },

        {
          path: "patientcrud",
          element: (
            <PatientCreateViewEdit
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },

        {
          path: "viewAllPatient",
          element: (
            <ViewAllPatient
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "healthcamppatient",
          element: (
            <HealthCampPatient
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "dataoprpat",
          element: (
            <ProtectedRoute
              element={
                <PatientDO
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "doctorsindex",
          element: (
            <ProtectedRoute
              element={
                <PatientDO
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "nurseindex",
          element: (
            <ProtectedRoute
              element={
                <PatientDO
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "doctorsgeneralitp/:id",
          element: (
            <GeneralIfoTabsPage
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "nursegeneralitp",
          element: (
            <NurseGeneralInfoTab
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "nursenmch",
          element: (
            <NMCHTabs
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "patienttabs/:id",
          element: (
            <PatientTabs
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "nursepatienttab",
          element: (
            <NursePatientTabs
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "doctorsmchtabs",
          element: (
            <MCHTabsPage
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },

        {
          path: "pathologistpatient",
          element: (
            // <ProtectedRoute
            //   element={
            <PathologistPatient
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
            //   }
            // />
          ),
        },
        {
          path: "labReportView",
          element: (
            // <ProtectedRoute
            //   element={
            <LabReport
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
            //   }
            // />
          ),
        },


        {
          path: "parmacist",
          element: (
            <ProtectedRoute
              element={
                // /parmacistView/100009
                // <Parmacist
                //   sideNavState={sideNavState}
                //   setSideNavState={setSideNavState}
                // />
                <PatientDO
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "parmacistView/:id",
          element: (
            <ParmacistView
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "addnewpatientform",
          element: (
            <AddPatientForm
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "addquickregistration",
          element: (
            <QuickRegistration
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        // addnewpatientform
        {
          path: "allPatientsPage", // for data operators
          element: (
            <AllPatientsPage
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "pathologistView",
          element: (
            <PathologistViewDetails
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "pathologistPatientDetail",
          element: (
            <PathologistPatientDetails
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "upload-report-view",
          element: (
            <UploadReport
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "edit-report-view",
          element: (
            <UploadReport
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "pathologist-co-ordinator",
          element: (
            <CoPathologist
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "pharmacist-co-ordinator",
          element: (
            <CoParmacist
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "co-pharmacy",
          element: (
            <CoPharmacy
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "bus-info",
          element: (
            <ProtectedRoute
              element={
                <Bus
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "bus-driver",
          element: (
            <ProtectedRoute
              element={
                <BusDriver
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },

        {
          path: "medicineDetails/:id",
          element: (
            <MedicineDetails
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "healthandwellness",
          element: (
            <HealthAndWellness
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "HealthCampView",
          element: (
            <HealthCampView
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "co-ordinator",
          element: (
            <ProtectedRoute
              element={
                <Coordinator
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "pharmacy-ims",
          element: (
            <ProtectedRoute
              element={
                <PharmacyIms
                  sideNavState={sideNavState}
                  setSideNavState={setSideNavState}
                />
              }
            />
          ),
        },
        {
          path: "home/*",
          element: <PageNotFound />,
        },
      ],
    },
    {
      path: "*",
      element: <PageNotFound />,
    },

    // {
    //   path: "*",
    //   element: <Navigate to="/home/nurses/nurse" />,
    // },

    // {
    //   path: 'login',
    //   element: <LoginPage />,
    // },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/app" />, index: true },
    //     { path: '404', element: <Page404 /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    // {
    //   path: '*',
    //   element: <Navigate to="/404" replace />,
    // },
  ]);

  return routes;
}
