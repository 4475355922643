import {
  createService,
  readService,
  updateService,
  deleteService,
} from "../HttpService";

// export const updatePathologist = async (id, formData) => {
//   const response = await updateService(`pathologists/${id}`, formData, {});
//   return response;
// };

export const updatePathologist = async (id, formData) => {
  const result = await updateService(`pathologists/${id}`, {}, formData);
  return result;
};

export const uploadLabReport = (id, body) => {
  return createService(`patient/labreports/${id}`, body);
};

export const updateLabReport = (patientId,reportId,body) => {
  return updateService(`patient/labreports/${patientId+"/"+reportId}`,{},body);
};

export const getMedicalReport = (id) => {
  return readService(`patient/medicalTests/${id}`);
};

export const deleteLabReport = (id, labId) => {
  return deleteService(`patient/labreports/${id}/${labId}`);
};
