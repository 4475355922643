import React from "react";
import "./AddButton.css";
import addIcon from "../../Assets/icons/addIcon.svg";

const AddButton = (props) => {
  const { name, icon, onClick } = props;

  return (
    <div className="text-end">
      <button className="add-btn" onClick={onClick}>
        <img
          src={icon ? icon : addIcon}
          alt=""
          style={{ marginRight: "10px" }}
        />
        {name}
      </button>
    </div>
  );
};

export default AddButton;
