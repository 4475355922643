import React from "react";
import "./Profile.scss";
import AccountHolder from "../../../Assets/Images/Profile/43w.png";
import EditProfile from "../../../Assets/Images/Profile/editprofile.svg";
import ProfileTabs from "./ProfileTabs";
import DefaultImage from "../../../Assets/Images/defaultProfile.png";

const Profile = () => {
  return (
    <>
      <div className="py-2 px-3" style={{ cursor: "pointer" }}></div>
      <div id="profile-container" className="text-center">
        <div className="row d-flex ">
          <div className="col-3 profile-content ">
            <div className="img">
              <img
                src={DefaultImage}
                style={{ width: 220 }}
                // className="w-auto"
              ></img>
              <img src={EditProfile} className="edit-icon"></img>
              <p className="edit-prfile-name">Edit Profile Image</p>
              <br />
              <br />
              <ProfileTabs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
